import {
  FlatList,
  View,
  TouchableOpacity,
  Text,
  ActivityIndicator,
} from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import { Input, Button } from "react-native-elements";
import { styles } from "../../App";
import React, { useState, useEffect } from "react";
import { FormType, Group } from "../../src/models/index";
import { DataStore, Predicates, SortDirection } from "@aws-amplify/datastore";
import { Hub } from "aws-amplify";
import * as UserPermissions from "../../utils/UserPermissions";
import * as NavUtils from "../../utils/NavUtils";
import { commonStyles } from "../../styles/commonStyles";
import * as AppColors from "../../styles/AppColors";
import { SearchBar } from "react-native-elements";
import { LinkButton, SubmitButton } from "../../components/ButtonComponents";
import { Asset } from "expo-asset";
import AppLoading from "expo-app-loading";
import { ScrollView } from "react-native-gesture-handler";
import ErrorMessageComponent from "../../components/ErrorMessageComponent";
import * as DBUtils from "../../utils/DBUtils";
import * as Utils from "../../utils/Utils";
import { useConfirmation } from "../../components/confirmation/ConfirmationService";

export const GroupListScreen = ({ navigation, route }) => {
  const [selectedId, setSelectedId] = useState(null);
  const [groups, setGroups] = useState([]);
  const [search, setSearch] = useState("");
  const [errors, setErrors] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [errorsDBClear, setErrorsDBClear] = useState(false);
  const confirm = useConfirmation();

  async function showGroupList() {
    const isNotCofirmed = await UserPermissions.isNotConfirmed();
    if (isNotCofirmed) {
      setErrors(
        "User not confirmed. Ask Owner or Admin to check the user status on Users Screen."
      );
      return;
    }
    const groups = await DataStore.query(Group, Predicates.ALL, {
      sort: (s) => s.groupName(SortDirection.ASCENDING),
    });
    await DBUtils.checkDBSync(groups, setErrors, setGroups);
    Utils.checkAndShowTrialEndsSoonDialog(
      false,
      navigation,
      confirm,
      undefined
    );
    //setGroups(groups);
  }

  //this even is called everytime - first time after login there is nothing to show until load above gets data
  useEffect(() => {
    (async () => {
      await showGroupList();
    })();
  }, []);

  const Item = ({ item, onPress, style }) => (
    //  return(
    <TouchableOpacity onPress={onPress} style={[commonStyles.rowItem, style]}>
      <View style={{ flexDirection: "row" }}>
        <Text style={commonStyles.listTextMainRow}>{item.groupName}</Text>
      </View>
      <Text style={commonStyles.listTextSubRow}>{item.groupPurpose}</Text>
    </TouchableOpacity>
    //)
  );

  const renderItem = ({ item, index }) => {
    const backgroundColor =
      index % 2 === 0 ? AppColors.ROW_BRIGHT : AppColors.ROW_AFTER_BRIGHT;
    return (
      <Item
        item={item}
        onPress={() => {
          setSelectedId(item.id);
          NavUtils.showViewGroupScreen(navigation, item.id);
        }}
        style={{ backgroundColor: backgroundColor }}
      />
    );
  };
  const updateSearch = async (search) => {
    setSearch(search);
    const searchCapWords = search
      .split(" ")
      .map(Utils.capitalizeFirstCharOfWord)
      .join(" ");
    const searchCapFirstWord = Utils.capitalizeFirstCharOfWord(search);
    const searchResult = await DataStore.query(Group, (c) =>
      c.or((c) =>
        c
          .groupName("contains", search)
          .groupName("contains", searchCapWords)
          .groupPurpose("contains", search)
          .groupPurpose("contains", searchCapFirstWord)
      )
    );
    // const groups = await DataStore.query(Group);
    //cont searchResult = groups.filter((c) => c.firstName.indexOf)
    setGroups(searchResult);
    // setIsSearching(false);
  };

  return (
    <SafeAreaView style={commonStyles.pageContainer}>
      {errors.length > 0 && <ErrorMessageComponent errorMsg={errors} />}
      {!(errors.length > 0) && (
        <>
          <LinkButton
            title={`< Back to Client List`}
            submit={() =>
              NavUtils.showPersonListScreen(navigation, FormType.CLIENT)
            }
          />
          <Button
            testID="AddGroup"
            containerStyle={commonStyles.buttonRowTop}
            title="Add Group"
            onPress={() =>
              NavUtils.showAddEditGroupScreen(navigation, undefined)
            }
          />
          {!isSearching && (
            <SearchBar
              placeholder="Type group name or purpose"
              onChangeText={updateSearch}
              value={search}
              lightTheme={true}
              inputStyle={{ color: "black" }}
              containerStyle={{ padding: 0 }}
              inputContainerStyle={{ backgroundColor: "ultralighgray" }}
            />
          )}
          {!isSearching && (
            <ScrollView>
              <FlatList
                data={groups}
                renderItem={renderItem}
                keyExtractor={(item) => item.id}
                extraData={selectedId}
              />
            </ScrollView>
          )}
        </>
      )}
    </SafeAreaView>
  );
};
