import { Auth, DataStore } from "aws-amplify";
import { useConfirmation } from "../components/confirmation/ConfirmationService";
import { AddUserToT, RolesDefault, SubReceipt } from "../src/models";
import { getLoggedInUserDBDetails } from "./DBUtils";
import * as DBUtils from "./DBUtils";
import { WhichStore } from "../src/models";
import * as Analytics from "expo-firebase-analytics";
import { isCurrentlyLoggedInUserAnOwner } from "./UserPermissions";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { loadStripe } from "@stripe/stripe-js";

export function replaceNullAndUndefined(obj, replaceValue = "") {
  const replacer = (key, value) =>
    String(value) === "null" || String(value) === "undefined"
      ? replaceValue
      : value;
  return JSON.parse(JSON.stringify(obj, replacer));
}

export async function getUserEmail() {
  const user = await Auth.currentAuthenticatedUser();
  const userEmail = user.attributes.email;
  return userEmail;
}

export async function getOwnerEmail() {
  const owner = await getLoggedInUsersOwnerDBDetails();
  return owner?.userEmail;
}
export async function getUserCognitoAKAOwnerId() {
  const user = await Auth.currentAuthenticatedUser();
  return user.attributes.sub;
}

export function isAndroid() {
  if (
    location.host.includes("android") ||
    (__DEV__ && location.host.includes("192.168.4.28:19007"))
  )
    return true;
  return false;
}
export function isIOS() {
  if (
    location.host.includes("ios") ||
    (__DEV__ && location.host.includes("192.168.4.28:19006")) //||
    // (__DEV__ && location.host.includes("localhost:19006")) //useful for troubleshooting in web by putting ios url directly in webbrowser
  )
    return true;
  return false;
}
export function isWeb() {
  if (isAndroid() || isIOS()) return false;
  return true;
}

export function isAVQUrl() {
  if (location.host.includes("avq")) return true;
  return false;
}

export function getFreeTrialLimit() {
  if (isAVQUrl()) return 365;
  return 15;
}

export async function getAppVersion() {
  let v = "0";
  if (!isWeb()) {
    try {
      v = await AsyncStorage.getItem("@appVersion");
      v = v === undefined || v === null ? "_" : v;
      if (__DEV__) console.log("get app version " + v);
    } catch (e) {
      if (__DEV__) console.error("get app version error" + e);
      v = "";
    }
    return v;
  } else return "Web";
}

export async function getFreeTrialDetails() {
  //returns array of true if trial over, diff of limit and actual days, const for days after which to show warning
  const loggedInUser = await getLoggedInUserDBDetails();
  if (loggedInUser === undefined || loggedInUser === null) {
    if (__DEV__)
      console.log(
        "Bypassing free trial check as loggedInUser is" + loggedInUser
      );
    return [false, 1000, 100]; //designed to prevent buy warning and buy component.
  }
  let daysLimit = loggedInUser.daysFree;
  let createdOnDate = loggedInUser?.createdOnDate;

  //if date is null, add it
  if (createdOnDate === null || createdOnDate === undefined) {
    createdOnDate = new Date();
    //save it in table too

    const updatedUser = await DataStore.save(
      AddUserToT.copyOf(loggedInUser, (updated) => {
        const returnedTarget = Object.assign(updated, {
          createdOnDate: createdOnDate.toISOString(),
        });
      })
    );
  }

  //owner data if one exists
  if (loggedInUser.userRole === RolesDefault.OWNER) {
    //if owner
  } else {
    //find owner
    const loggedInUsersOwner = await getLoggedInUsersOwnerDBDetails();
    if (loggedInUsersOwner === undefined || loggedInUsersOwner === null) {
      if (__DEV__)
        console.log(
          "Bypassing free trial check as loggedInUsersOwner is" +
            loggedInUsersOwner
        );
      return [false, 1000, 100]; //designed to prevent buy warning msg and buy button in-screen component.
    }
    daysLimit = loggedInUsersOwner?.daysFree;
    createdOnDate = loggedInUsersOwner?.createdOnDate;
    //if date is null, add it
    if (createdOnDate === null || createdOnDate === undefined) {
      createdOnDate = new Date();
      //save it in table too
      const updatedUser = await DataStore.save(
        AddUserToT.copyOf(loggedInUsersOwner, (updated) => {
          const returnedTarget = Object.assign(updated, {
            createdOnDate: createdOnDate.toISOString(),
          });
        })
      );
    }
  }
  if (daysLimit === undefined || daysLimit === null || daysLimit < 0) {
    daysLimit = getFreeTrialLimit(); //default As of 22july, 6 displays locked and 8 displays warning
  }
  const durationMs = new Date().getTime() - new Date(createdOnDate).getTime();
  const daysPassed = durationMs / (1000 * 3600 * 24);
  const startWarningBeforeNDaysTrialEnds = 5;

  //first element of array is freeTrialOver
  if (daysPassed >= daysLimit)
    return [true, daysLimit - daysPassed, startWarningBeforeNDaysTrialEnds];
  return [false, daysLimit - daysPassed, startWarningBeforeNDaysTrialEnds];
}

export async function getLoggedInUsersOwnerDBDetails() {
  const userEmail = await getUserEmail();
  const ownerMatched = await DataStore.query(
    AddUserToT,
    (d) => d.tenant("eq", userEmail).userEmail("eq", userEmail) // when useremail = tenantemail, that row is owner
    // other rows are also inserted by owner so owner column is same -> cognito id of owner.
  );
  if (ownerMatched?.length > 1) {
    throw new Error(
      ownerMatched.length + " users OWNERS for  email=" + userEmail
    );
    return undefined;
  }
  return ownerMatched[0];
}
//is freeTrialOver, isTrialOver, isFreeTrialOver isSubscribed
export async function isPlanActive() {
  //Only OWNER has inserts into receipts table but everyone gets them on local DB
  // if (!isWeb()) {
  if (true) {
    const receipts = await DataStore.query(SubReceipt, (c) =>
      c.or((c) =>
        c
          .store("eq", WhichStore.GOOGLE)
          .store("eq", WhichStore.APPLE)
          .store("eq", WhichStore.WEB)
      )
    );
    if (__DEV__)
      console.log("isPlanActive - Receipts from DB", JSON.stringify(receipts));
    const receiptsWithPlanActive = receipts.filter((item) => {
      const bypass = item.bypass ?? "N";
      const kharedi = item.kharedi ?? "false";
      const store = item.store ?? WhichStore.APPLE;
      return (
        kharedi === "true" || item.bypass === "Y" //|| store === WhichStore.APPLE
      ); //REMOVED but could be added - bypass APPLE while under API observation and also use bypass flag as necessary- set bypass to Y in dynamoDB.
      //After that, Logout may not be needed- first just try refresh, then logout and then public logout as last resort
    });
    //if at least one receipt has plan active then return true
    if (__DEV__)
      console.log(
        "isPlanActive - Receipts with Plan Active",
        JSON.stringify(receiptsWithPlanActive)
      );

    return receiptsWithPlanActive.length > 0;
  } else {
    //if web allow for now
    if (__DEV__) console.log("Plan Active Web");

    return true;
  }
}

export async function checkAndShowTrialEndsSoonDialog(
  shouldShowEndsSoon, // we want to show ends soon only on certain pages like list and not for every click.
  navigation,
  confirm,
  setFreeTrialOver // used to set state in the caller so screen can show button to view plans
) {
  const isPlanActiveResult = await isPlanActive();
  if (!isPlanActiveResult) {
    let msg = "Your Free Trial has ended!";
    let showMsg = false;
    //is free trial pop up needed. After 5 days to go. Add field in user table for limit
    const freeTrialDetails = await getFreeTrialDetails();
    if (__DEV__) console.log("Details = " + freeTrialDetails);
    if (freeTrialDetails[0]) {
      //priority to lock dialog first. Hence if/else and first check if locked.
      //trial is over. Show buy now button on main screen
      if (setFreeTrialOver !== undefined) setFreeTrialOver(true);
      showMsg = true;
      // await Analytics.logEvent("TrialPopUpOver", {
      //   trialOver: 1,
      // });
    } else if (
      shouldShowEndsSoon &&
      freeTrialDetails[1] < freeTrialDetails[2]
    ) {
      msg = "Trial Ends Soon!";
      showMsg = true;
      // await Analytics.logEvent("TrialPopUpEndsSoon", {
      //   trialEndsSoon: 1,
      // });
    }
    if (showMsg) {
      confirm({
        variant: "danger",
        catchOnCancel: true,
        title: msg + " Buy Now to continue using Cloud CRM",
        description:
          "Press View Plans Now to Grow your business! If you already bought the plan, then press Later and just press Refresh button on the title bar for your purchase to be effective on this device. For Help, visit Main Menu",
        submitText: "View Plans",
        cancelText: "Later",
      })
        .then(async () => {
          showSubscriptionPlans(navigation);
          // await Analytics.logEvent("TrialPopUp", {
          //   showPlan: 1,
          // });
          if (__DEV__) console.log("yes");
        })
        .catch(() => {
          if (__DEV__) console.log("cancel");
        });
    }
  } else {
    if (__DEV__) console.log("Plan is Active - dont show buy box");
  }
}

export async function areYouSureDialog(
  confirm,
  yesFunction,
  yesTitle = "Yes, Delete",
  noTitle = "No",
  dialogTitle = "Are you sure?",
  dialogMsg = "Data will be permanently deleted. Once data is deleted, you will not be able to recover it. Press Yes to confirm.",
  noFunction,
  navigation
) {
  confirm({
    variant: "danger",
    catchOnCancel: true,
    title: dialogTitle,
    description: dialogMsg,
    submitText: yesTitle,
    cancelText: noTitle,
  })
    .then(async () => {
      await yesFunction();
      if (__DEV__) console.log("yes in areyousure dialog");
    })
    .catch(() => {
      if (__DEV__) console.log("catch in areYouSure dialog");
    });
}
export async function showSubscriptionPlans(navigation) {
  const isOwner = await isCurrentlyLoggedInUserAnOwner();
  if (isIOS() && isOwner)
    webkit.messageHandlers.doIOSAction.postMessage({
      action: "VIEW_PLAN",
    });
  else if (isAndroid() && isOwner)
    Android.doAndroidAction("VIEW_PLAN", false, await DBUtils.getTotalUsers());
  else if (isWeb() && isOwner) {
    navigation.navigate("showCart");
  } else {
    //non-owners in ALL channels
    navigation.navigate("BuyNowFromWebScreen");
  }
}
export function refresh() {
  if (isAndroid()) Android.doAndroidAction("REFRESH", false, 1);
  else if (isIOS())
    webkit.messageHandlers.doIOSAction.postMessage({ action: "REFRESH" });
  //last two params dont matter so hardcoded to save time awaiting.
  else {
    reloadBaseURLForWeb(); //does not work within app - only above doAndroidAction works to refresh to the base url
  }
}
export function getStore() {
  if (isIOS()) return WhichStore.APPLE;
  if (isAndroid()) return WhichStore.GOOGLE;
  return WhichStore.WEB;
}

export function capitalizeFirstCharOfWord(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function reloadBaseURLForWeb() {
  //removes varg etc from previous session.
  const protocol = location.protocol;
  const host = location.host;
  const port = location.port;
  if (__DEV__) {
    console.log("host=", host, "protocol=", protocol);
  }
  let url = `${protocol}//${host}`;
  //host includes port so following is unnecessary
  // if (port !== undefined || port !== null || port.trim().length > 0) {
  //   url = url + ":" + port;
  // }
  window.location.href = url;
  // location.assign(protocol + host); // do this to change URL containing vargs, receipt etc.}
}

export function getBaseURL() {
  //removes varg etc from previous session.
  const protocol = location.protocol;
  const host = location.host;
  const port = location.port;
  if (__DEV__) {
    console.log("host=", host, "protocol=", protocol);
  }
  let url = `${protocol}//${host}`;
  //host includes port so following is unnecessary
  // if (port !== undefined || port !== null || port.trim().length > 0) {
  //   url = url + ":" + port;
  // }
  return url;
  // location.assign(protocol + host); // do this to change URL containing vargs, receipt etc.}
}
export async function getCurrency() {
  const profile = await getLoggedInUsersOwnerDBDetails();
  return profile?.businessCurrency;
}
export function isDevEnv() {
  return !getBaseURL().includes(getFullProdURL());
}
export function getFullProdURL() {
  return "https://cloudcrm.siyamiapps.com";
}
