import {
  FlatList,
  View,
  TouchableOpacity,
  Text,
  ActivityIndicator,
  Platform,
  Image,
} from "react-native";
// import * as Linking from "expo-linking";
import { SafeAreaView } from "react-native-safe-area-context";
import { MaterialIcons } from "@expo/vector-icons";
import { Button } from "react-native-elements";
import React, { useState, useEffect } from "react";
import {
  BillStatus,
  Client,
  Device,
  SubReceipt,
  WhichStore,
} from "../../../src/models/index";
import { DataStore, Predicates, SortDirection } from "@aws-amplify/datastore";
import { Auth, Hub } from "aws-amplify";
import * as NavUtils from "../../../utils/NavUtils";
import { commonStyles } from "../../../styles/commonStyles";
import * as AppColors from "../../../styles/AppColors";
import * as Utils from "../../../utils/Utils";
import { SearchBar } from "react-native-elements";
import { ScrollView } from "react-native-gesture-handler";
import ErrorMessageComponent from "../../../components/ErrorMessageComponent";
import * as DBUtils from "../../../utils/DBUtils";
import { ZenObservable } from "zen-observable-ts";
// import { Page, PageHeader } from "../../../screens/LoggedInHomeScreen";
import { useConfirmation } from "../../../components/confirmation/ConfirmationService";
import { Bills, FormType } from "../../../src/models";
import { LinkButton } from "../../ButtonComponents";
// import Dinero from "dinero.js";
import * as MailComposer from "expo-mail-composer";
import * as Linking from "expo-linking";
import { Subject } from "@material-ui/icons";

const isPaidUser = Utils.isPlanActive();
export const BillsListScreen = ({ navigation, route }) => {
  const [selectedId, setSelectedId] = useState(null);
  const [bills, setBills] = useState([]);
  const [search, setSearch] = useState("");
  const [errors, setErrors] = useState("");
  const [isReady, setIsReady] = useState(false);
  const [isImageReady, setIsImageReady] = useState(false);
  const [refresh, doRefresh] = useState(false);
  const [freeTrialOver, setFreeTrialOver] = useState(false);
  const [forceShowMenu, setForceShowMenu] = useState(false);
  const [clientBillMap, setClientBillMap] = useState(new Map());
  const [isSearching, setIsSearching] = useState(false);
  const [currency, setCurrency] = useState("");

  const confirm = useConfirmation();

  async function showBillsList() {
    const bills = await DataStore.query(Bills, Predicates.ALL, {
      sort: (s) => s.createdOn(SortDirection.DESCENDING),
    });
    const clientIdMap = new Map();
    //iterate map to get client object for each id
    for (let b of bills) {
      if (b.clientId !== undefined) {
        const client = await DataStore.query(Client, b.clientId);
        if (client?.id !== undefined) clientIdMap.set(client?.id, client);
      }
    }
    setClientBillMap(clientIdMap);
    setBills(bills);
    let cur = await Utils.getCurrency();
    setCurrency(cur ?? "");
    await Utils.checkAndShowTrialEndsSoonDialog(
      true,
      navigation,
      confirm,
      setFreeTrialOver
    );
    setIsReady(true);
    if (bills?.length === 0)
      setErrors("No Bills Found. Enter Bills by visiting Client Page");
    else setErrors("");
  }

  useEffect(() => {
    const s = async () => await showBillsList();
    s();
    return () => {};
  }, [refresh]); //unused refresh but check if issues - will be used for pull to refresh later with refreshcontrol

  const Item = ({ item, client, onPress, style }) => {
    let billStatus = item.billStatus;
    let subject = "Regarding Bill Payment";
    const bodyPay = `Hi, Please pay your bill of ${
      currency + " " + item.amountDue
    } at your earliest convenience`;
    const bodyThanks = `Hi, Thank you for paying your bill of ${
      currency + " " + item.amountDue
    }`;
    const body = item.billStatus === BillStatus.PAID ? bodyThanks : bodyPay;
    return (
      <TouchableOpacity onPress={onPress} style={[commonStyles.rowItem, style]}>
        {/* <View style={[commonStyles.rowItem, style]}> */}
        {/* <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <Text style={commonStyles.listTextMainRow}>{item.purpose}</Text>
          <Text style={commonStyles.listTextMainRow}>
            {Dinero().getCurrency() + " " + item.amountDue}
          </Text>
        </View> */}
        <Text style={commonStyles.listTextMainRow}>{item.purpose}</Text>
        <View
          style={{
            marginVertical: 4,
            flexDirection: "row",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <Text style={commonStyles.listTextMainRow}>
            {(currency ? currency + " " : "") + item.amountDue}
          </Text>
          <Text
            style={{
              color: billStatus === BillStatus.UNPAID ? "red" : "green",
            }}
          >
            {billStatus}
          </Text>
          {/* padding needed to ensure press is captured by link and not by outer button */}
          <View style={{ marginTop: -6, alignItems: "center", padding: 4 }}>
            <LinkButton
              title={`Mark As ${
                billStatus === BillStatus.UNPAID
                  ? BillStatus.PAID
                  : BillStatus.UNPAID
              }`}
              submit={async () => {
                const ret = await DataStore.save(
                  Bills.copyOf(item, (updated) => {
                    const returnedTarget = Object.assign(updated, {
                      billStatus:
                        billStatus === BillStatus.UNPAID
                          ? BillStatus.PAID
                          : BillStatus.UNPAID,
                    });
                  })
                );
                doRefresh(!refresh);
              }}
            />
          </View>
        </View>
        <View style={{ flexDirection: "row" }}>
          <Text style={commonStyles.listTextSubRow}>
            {client.firstName + " " + client.lastName}
          </Text>
        </View>
        {/* <Text style={commonStyles.listTextSubRow}>{client.cellPhone}</Text>
        <Text style={commonStyles.listTextSubRow}>{client.email}</Text> */}
        <Text style={commonStyles.listTextSubRow}>{client.address}</Text>
        <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
          {client.cellPhone && (
            <Button
              buttonStyle={{ margin: 0, padding: 4 }}
              // containerStyle={{ width: "30%" }}
              containerStyle={{ margin: 0, paddingTop: 4, marginRight: 8 }}
              title="Call"
              onPress={() => {
                Linking.openURL(`tel:${client.cellPhone}`);
              }}
              type="clear"
            />
          )}
          {client.cellPhone && (
            <Button
              buttonStyle={{ margin: 0, padding: 4 }}
              // containerStyle={{ width: "30%" }}
              containerStyle={{ margin: 0, paddingTop: 4, marginRight: 8 }}
              title="SMS"
              onPress={() => {
                var url = `sms:${client.cellPhone}`; //mac
                if (Utils.isAndroid()) {
                  url = `sms:${client.cellPhone}?body=${body}`;
                } else if (Utils.isIOS()) {
                  url = `sms:/open?addresses=${client.cellPhone}&body=${body}`;
                } else {
                  //mac keep default
                }
                if (__DEV__) console.log("smsurl=" + url);
                Linking.openURL(url);
              }}
              type="clear"
            />
          )}
          {client.email && (
            <Button
              buttonStyle={{ margin: 0, padding: 4 }}
              // containerStyle={{ width: "30%" }}
              containerStyle={{ margin: 0, paddingTop: 4, marginRight: 8 }}
              title="Email"
              //Removed below for IOS and instead using mailto url scheme
              // onPress={async () => {
              //   let result = await MailComposer.composeAsync({
              //     recipients: [personState.email],
              //     subject: "",
              //     body: "Hi, ",
              //     ccRecipients: [],
              //     bccRecipients: [],
              //   });
              //   //    alert(result.status);
              //   return result;
              // }}
              onPress={async () => {
                if (Utils.isIOS()) {
                  let encodedSub = encodeURIComponent(subject);
                  let encodedBody = encodeURIComponent(body);
                  Linking.openURL(
                    `mailto:${client.email}?subject=${encodedSub}&body=${encodedBody}`
                  );
                  return;
                } else {
                  let result = await MailComposer.composeAsync({
                    recipients: [client.email],
                    subject: subject,
                    body: body,
                    ccRecipients: [],
                    bccRecipients: [],
                  });
                  return result;
                }
              }}
              type="clear"
            />
          )}
        </View>
      </TouchableOpacity>
    );
  };

  const renderItem = ({ item, index }) => {
    const client = clientBillMap.get(item.clientId); //lookup from the map using item.clientid;
    const backgroundColor =
      index % 2 === 0 ? AppColors.ROW_BRIGHT : AppColors.ROW_AFTER_BRIGHT;
    return (
      <Item
        item={item}
        client={client}
        onPress={() => {
          setSelectedId(item.id);
          NavUtils.showViewClientScreen(navigation, client.id, client.formType);
        }}
        style={{ backgroundColor: backgroundColor }}
      />
    );
  };

  const updateSearch = async (search) => {
    setSearch(search);
    const internalSearch = search.toLowerCase();
    const all = await DataStore.query(Bills);
    const searchResult = all.filter((b) => {
      let c = clientBillMap.get(b.clientId);
      return b.purpose?.toLowerCase().includes(internalSearch)
        ? true
        : b.details?.toLowerCase().includes(internalSearch)
        ? true
        : b.amountDue?.toLowerCase().includes(internalSearch)
        ? true
        : b.billStatus?.toLowerCase().startsWith(internalSearch)
        ? true
        : c.firstName?.toLowerCase().includes(internalSearch)
        ? true
        : c.lastName?.toLowerCase().includes(internalSearch)
        ? true
        : c.cellPhone?.includes(internalSearch)
        ? true
        : c.email?.toLowerCase().includes(internalSearch)
        ? true
        : c.notes?.toLowerCase().includes(internalSearch)
        ? true
        : c.address?.toLowerCase().includes(internalSearch)
        ? true
        : false;
    });

    setBills(searchResult);
  };
  const showContent = isPaidUser || !freeTrialOver;
  return (
    <SafeAreaView style={commonStyles.pageContainer}>
      {errors.length > 0 && <ErrorMessageComponent errorMsg={errors} />}

      {/* {isSearching && <ActivityIndicator size="large" />} */}
      {/* {!(errors.length > 0) && ( */}
      <>
        <LinkButton
          title={`< Back to Client List`}
          submit={() =>
            NavUtils.showPersonListScreen(navigation, FormType.CLIENT)
          }
        />

        {!isSearching && (
          <SearchBar
            placeholder="Type Bill purpose or details or amount or name or phone or email or address"
            onChangeText={updateSearch}
            value={search}
            lightTheme={true}
            inputStyle={{ color: "black" }}
            containerStyle={{ padding: 0, marginTop: 12 }}
            inputContainerStyle={{ backgroundColor: "ultralighgray" }}
          />
        )}
        {!isSearching && (
          <ScrollView>
            <FlatList
              data={bills}
              renderItem={renderItem}
              keyExtractor={(item) => item.id}
              extraData={selectedId}
            />
          </ScrollView>
        )}
      </>
      {/* )} */}
    </SafeAreaView>
  );
};
