import {
  FlatList,
  TextInput,
  TouchableOpacity,
  Text,
  View,
} from "react-native";
import { Input, Button } from "react-native-elements";
import { styles } from "../../App";
import React, { useState, useEffect } from "react";
import { Client, CustomForm } from "../../src/models/index";
import { DataStore } from "@aws-amplify/datastore";
import { Auth } from "aws-amplify";
import { getGroupNameAsTenant } from "../../utils/getGroupNameAsTenant";
import { commonStyles } from "../../styles/commonStyles";
import { SubmitAndCancelButtons, SubmitButton } from "../ButtonComponents";
import { getCustomForm } from "../../utils/DBUtils";
import { ScrollView } from "react-native-gesture-handler";

export const PersonFormComponent = ({ form, updateForm }) => {
  const customFieldsObject = JSON.parse(form.customFields);
  const max = customFieldsObject.length;
  const customFieldArray = customFieldsObject.map((field, index) => {
    return (
      <>
        {field.show && (
          <View>
            <Text style={commonStyles.textFieldName}>{field.name}</Text>
            <Input
              maxLength={500}
              testID={"customField" + (index + 1)}
              value={field.value}
              placeholder="Type Text "
              onChangeText={(e) => {
                const customFieldsString = JSON.stringify([
                  ...customFieldsObject.slice(0, index),
                  {
                    seq: field.seq,
                    name: field.name,
                    value: e,
                    show: field.show,
                  },
                  ...customFieldsObject.slice(index + 1, max),
                ]);

                const updatedForm = {
                  ...form,
                  customFields: customFieldsString,
                };
                // updateForm({
                //   ...form,
                //   customFields: customFieldsString,
                // });
                updateForm(updatedForm);
              }}
            />
          </View>
        )}
      </>
    );
  });
  return (
    <ScrollView>
      <Input
        maxLength={100}
        testID="firstName"
        labelStyle={{ marginTop: 8 }}
        value={form.firstName}
        // placeholder="* First Name is required"
        placeholder="First Name"
        onChangeText={(e) => updateForm({ ...form, firstName: e })}
      />
      <Input
        maxLength={100}
        testID="lastName"
        value={form.lastName}
        placeholder="Last Name"
        onChangeText={(e) => updateForm({ ...form, lastName: e })}
      />
      <Input
        maxLength={100}
        testID="cellPhone"
        value={form.cellPhone}
        placeholder="Cell Phone"
        onChangeText={(e) => updateForm({ ...form, cellPhone: e })}
        keyboardType="phone-pad"
      />
      <Input
        maxLength={100}
        testID="email"
        value={form.email}
        placeholder="Email"
        onChangeText={(e) => updateForm({ ...form, email: e })}
        keyboardType="email-address"
      />
      <Input
        maxLength={500}
        testID="address"
        value={form.address}
        placeholder="Address"
        onChangeText={(e) => updateForm({ ...form, address: e })}
      />
      <Input
        testID="notes"
        value={form.notes}
        maxLength={5000}
        multiline={true}
        placeholder="Notes"
        onChangeText={(e) => updateForm({ ...form, notes: e })}
        numberOfLines={4}
      />
      {customFieldArray}
    </ScrollView>
  );
};
//https://stackoverflow.com/questions/45673783/replace-array-entry-with-spread-syntax-in-one-line-of-code

export function initialStateForPersonForm(formType) {
  let customFieldsInitialData = getDefaultCustomFieldsInitialData(formType);
  return {
    firstName: "",
    lastName: "",
    cellPhone: "",
    email: "",
    address: "",
    notes: "",
    dob: "",
    joinDate: "",
    photo: "",
    gender: "",
    otherData: "",

    customFields: customFieldsInitialData,
  };
}

export async function getCustomFieldsFromDB(formType: any) {
  let customFieldsInitialData = getDefaultCustomFieldsInitialData(formType);
  //stringify needed for initial seed as default as Graphql takes json as string
  //These will be pulled from database once we make the template configurable
  //with ability to change name and show/hide fields.
  //lookup template by form type
  //If found blank then use following as default.
  const customFormOriginal = await getCustomForm(formType);
  if (
    customFormOriginal !== undefined &&
    customFormOriginal.length === 1 && // more than one is a problem
    customFormOriginal[0].customFieldsMetaData !== undefined
  ) {
    // customFieldsInitialData = JSON.stringify(
    //   customFormOriginal[0].customFieldsMetaData
    // );
    // nostringify as DB stores as string!! if not, you will get MAP if not function of form error - calling map on string
    //is not  a good idea. Map works on json object array
    customFieldsInitialData = customFormOriginal[0].customFieldsMetaData;
  }
  return customFieldsInitialData;
}

export function getDefaultCustomFieldsInitialData(
  formType: any,
  version = 1.0
) {
  const defaultCustomFieldsInitialData = JSON.stringify([
    { seq: 1, name: "Custom Field 1", value: "", show: true },
    { seq: 2, name: "Custom Field 2", value: "", show: true },
    { seq: 3, name: "Custom Field 3", value: "", show: true },
    { seq: 4, name: "Custom Field 4", value: "", show: true },
    { seq: 5, name: "Custom Field 5", value: "", show: true },
    { seq: 6, name: "Field 6", value: "", show: false },
    { seq: 7, name: "Field 7", value: "", show: false },
    { seq: 8, name: "Field 8", value: "", show: false },
    { seq: 9, name: "Field 9", value: "", show: false },
    { seq: 10, name: "Field 10", value: "", show: false },
    { seq: 11, name: "Field 11", value: "", show: false },
    { seq: 12, name: "Field 12", value: "", show: false },
    { seq: 13, name: "Field 13", value: "", show: false },
    { seq: 14, name: "Field 14", value: "", show: false },
    { seq: 15, name: "Field 15", value: "", show: false },
    { seq: 16, name: "Field 16", value: "", show: false },
    { seq: 17, name: "Field 17", value: "", show: false },
    { seq: 18, name: "Field 18", value: "", show: false },
    { seq: 19, name: "Field 19", value: "", show: false },
    { seq: 20, name: "Field 20", value: "", show: false },
    { seq: 21, name: "Field 21", value: "", show: false },
    { seq: 22, name: "Field 22", value: "", show: false },
    { seq: 23, name: "Field 23", value: "", show: false },
    { seq: 24, name: "Field 24", value: "", show: false },
    { seq: 25, name: "Field 25", value: "", show: false },
    { seq: 26, name: "Field 26", value: "", show: false },
    { seq: 27, name: "Field 27", value: "", show: false },
    { seq: 28, name: "Field 28", value: "", show: false },
    { seq: 29, name: "Field 29", value: "", show: false },
    { seq: 30, name: "Field 30", value: "", show: false },
    { seq: 31, name: "Field 31", value: "", show: false },
    { seq: 32, name: "Field 32", value: "", show: false },
    { seq: 33, name: "Field 33", value: "", show: false },
    { seq: 34, name: "Field 34", value: "", show: false },
    { seq: 35, name: "Field 35", value: "", show: false },
    { seq: 36, name: "Field 36", value: "", show: false },
    { seq: 37, name: "Field 37", value: "", show: false },
    { seq: 38, name: "Field 38", value: "", show: false },
    { seq: 39, name: "Field 39", value: "", show: false },
    { seq: 40, name: "Field 40", value: "", show: false },
    { seq: 41, name: "Field 41", value: "", show: false },
    { seq: 42, name: "Field 42", value: "", show: false },
    { seq: 43, name: "Field 43", value: "", show: false },
    { seq: 44, name: "Field 44", value: "", show: false },
    { seq: 45, name: "Field 45", value: "", show: false },
    { seq: 46, name: "Field 46", value: "", show: false },
    { seq: 47, name: "Field 47", value: "", show: false },
    { seq: 48, name: "Field 48", value: "", show: false },
    { seq: 49, name: "Field 49", value: "", show: false },
    { seq: 50, name: "Field 50", value: "", show: false },
  ]);

  //TODO
  //  const defaultCustomFieldsInitialDataVerion2 = JSON.stringify([....

  if (version === undefined || version === 1.0 || version === null)
    return defaultCustomFieldsInitialData;
  else return defaultCustomFieldsInitialData; // Add  lines before for other versions with default ELSE being this line
}
