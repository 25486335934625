import { DataStore } from "@aws-amplify/datastore";
import React, { useEffect, useState } from "react";
import { ScrollView, Text, View } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import { Button, CheckBox, Input } from "react-native-elements";
import { styles } from "../../App";
import { Client, CustomForm, FormType } from "../../src/models/index";
import { commonStyles } from "../../styles/commonStyles";
import { getCustomForm } from "../../utils/DBUtils";
import { getGroupNameAsTenant } from "../../utils/getGroupNameAsTenant";
import { BackButton, SubmitAndCancelButtons } from "../ButtonComponents";
import {
  getCustomFieldsFromDB,
  getDefaultCustomFieldsInitialData,
} from "./PersonFormComponent";
import * as AppColors from "../../styles/AppColors";

export const CustomizeFieldsFormComponent = ({ navigation, route }) => {
  const { formType } = route.params;
  const initFormStateString = getDefaultCustomFieldsInitialData(formType);
  const initFormState = JSON.parse(initFormStateString);
  const [form, updateForm] = useState([initFormState]);

  useEffect(() => {
    (async () => {
      const customFieldsFromDB = await getCustomFieldsFromDB(formType);
      const jsonObject = JSON.parse(customFieldsFromDB);
      updateForm(jsonObject);
    })();
    return () => {
      //cleanup
    };
  }, []);

  async function submit() {
    var groupForTenant = await getGroupNameAsTenant();
    var customForm;
    var postData = {
      formType: formType,
      customFieldsMetaData: JSON.stringify(form),
    };
    //avoids true groupname for tenant when null
    if (groupForTenant !== null)
      postData = { ...postData, tenant: groupForTenant };
    // if form exists in db then use update else create entry
    // const customFormOriginal = await DataStore.query(CustomForm, (c) =>
    //   c.formType("eq", FormType.CLIENT)
    // );
    const customFormOriginal = await getCustomForm(formType);
    if (customFormOriginal === undefined || customFormOriginal.length === 0)
      customForm = await DataStore.save(new CustomForm(postData));
    else {
      if (customFormOriginal.length > 1) {
        //prompt email as only one form needed
        throw new Error(
          "Only one custom form allowed. Contact support@siyamiapps.com"
        );
        return;
      }

      customForm = await DataStore.save(
        CustomForm.copyOf(customFormOriginal[0], (updated) => {
          const returnedTarget = Object.assign(updated, postData);
        })
      );
    }

    route.params.onSubmitCustomFieldChanges();
    navigation.goBack();
  }
  const max = form.length;
  const customFieldArray = form.map((field, index) => {
    return (
      <ScrollView>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            backgroundColor:
              index % 2 === 0
                ? AppColors.ROW_BRIGHT
                : AppColors.ROW_AFTER_BRIGHT,
          }}
        >
          <Input
            maxLength={100}
            containerStyle={{ width: "60%", marginBottom: 0 }}
            testID={"customField" + (index + 1)}
            value={field.name}
            placeholder="Type Custom Name "
            onChangeText={(e) => {
              updateForm([
                ...form.slice(0, index),
                {
                  seq: index + 1,
                  name: e,
                  value: field.value,
                  show: field.show,
                },
                ...form.slice(index + 1, max),
              ]);
            }}
          />
          <CheckBox
            containerStyle={{
              width: "40%",
              marginBottom: 0,
              backgroundColor: AppColors.ROW_AFTER_BRIGHT,
              borderWidth: 0,
            }}
            title={field.show ? "Shown" : "Hidden"}
            checked={field.show}
            onPress={() => {
              updateForm([
                ...form.slice(0, index),
                {
                  seq: index + 1,
                  name: field.name,
                  value: field.value,
                  show: !field.show,
                },
                ...form.slice(index + 1, max),
              ]);
            }}
          />
        </View>
      </ScrollView>
    );
  });
  return (
    <SafeAreaView style={commonStyles.pageContainer}>
      <SubmitAndCancelButtons
        navigation={navigation}
        submit={submit}
        cancel={() => navigation.goBack()}
        flexDirection={"row"}
      />
      <ScrollView>
        {/* <BackButton navigation={navigation} back={() => navigation.goBack()} /> */}

        <Text>Type custom name. Check the box to show the field</Text>
        {customFieldArray}
      </ScrollView>
    </SafeAreaView>
  );
};
