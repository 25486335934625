import React, { useState, useEffect } from "react";
import { FormType, Part } from "../../../src/models/index";
import { DataStore } from "@aws-amplify/datastore";
import { commonStyles } from "../../../styles/commonStyles";
import * as NavUtils from "../../../utils/NavUtils";
import {
  LinkButton,
  SubmitAndCancelButtons,
} from "../../../components/ButtonComponents";
import * as Utils from "../../../utils/Utils";
import * as DBUtils from "../../../utils/DBUtils";
import { getGroupNameAsTenant } from "../../../utils/getGroupNameAsTenant";
import { Card, Button, Input } from "react-native-elements";
import { View, Text } from "react-native";
import { useConfirmation } from "../../../components/confirmation/ConfirmationService";
import { ScrollView } from "react-native-gesture-handler";
import { SafeAreaView } from "react-native-safe-area-context";

export const ViewPartComponent = ({ navigation, route }) => {
  const confirm = useConfirmation();

  const [partState, setPartState] = useState({
    name: "",
    details: "",
    unitPrice: "",
    quantityAvailable: "",
    createdOn: new Date(),
  });
  const [currency, setCurrency] = useState("");
  //getLoggedInUsersOwnerDBDetails for currency
  const partId = route.params?.partId;
  useEffect(() => {
    async function loadPart() {
      if (partId !== undefined) {
        //in the first render of view parent, its empty so do not waste going thru loop
        const part = await DataStore.query(Part, partId);
        let cur = await Utils.getCurrency();
        setCurrency(cur ?? "");
        setPartState({ ...part });
      } else {
        //default for add new part  are already default
        console.assert(false, "Part id missing in view");
      }
    }
    loadPart();
    return () => {
      //cleanup
    };
  }, []);

  async function showEditPart() {
    NavUtils.showAddEditPartScreen(navigation, partId);
  }

  async function deletePart() {
    await Utils.areYouSureDialog(confirm, async () => {
      const partToDelete = await DataStore.query(Part, partId);
      await DataStore.delete(partToDelete);
      NavUtils.showPartsInventoryScreen(navigation);
    });
  }

  return (
    <SafeAreaView style={commonStyles.pageContainer}>
      <LinkButton
        title="< Back to Parts Inventory"
        submit={() => NavUtils.showPartsInventoryScreen(navigation)}
      />
      <ScrollView style={{ margin: 0, marginTop: 10, padding: 0 }}>
        <Card containerStyle={commonStyles.mainCardContainer}>
          <Card.Title style={{ marginTop: 8 }}></Card.Title>
          <Card
            containerStyle={{
              padding: 4,
              margin: 0,
              backgroundColor: "#ebedf0",
              borderWidth: 3,
              borderColor: "black",
              zIndex: 5,
            }}
          >
            <Text
              style={[
                commonStyles.textFieldName,
                { marginLeft: 0, marginBottom: 4 },
              ]}
            >
              Part Name
            </Text>
            <Text style={{ marginBottom: 4 }}>{partState.name}</Text>

            <Text
              style={[
                commonStyles.textFieldName,
                { marginLeft: 0, marginBottom: 4 },
              ]}
            >
              Unit Price
            </Text>
            <Text style={{ marginBottom: 4 }}>
              {(currency ? currency + " " : "") + partState.unitPrice}
            </Text>
            <Text
              style={[
                commonStyles.textFieldName,
                { marginLeft: 0, marginBottom: 4 },
              ]}
            >
              Available Quantity
            </Text>
            <Text style={{ marginBottom: 4 }}>
              {partState.quantityAvailable}
            </Text>
            <Text
              style={[
                commonStyles.textFieldName,
                { marginLeft: 0, marginBottom: 4 },
              ]}
            >
              Part Details
            </Text>
            <Text style={{ marginBottom: 6 }}>{partState.details}</Text>
            <Text
              style={[
                commonStyles.textFieldName,
                { marginLeft: 0, marginBottom: 4 },
              ]}
            >
              Updated On
            </Text>
            <Text style={{ marginBottom: 4 }}>
              {new Date(partState.createdOn).toLocaleDateString()}
            </Text>
            <Button
              buttonStyle={commonStyles.buttonRow}
              // containerStyle={{ width: "30%" }}
              title="Edit"
              onPress={() => showEditPart()}
            />
            <Button
              buttonStyle={commonStyles.buttonDeleteRow}
              // containerStyle={{ width: "30%" }}
              title="Delete"
              onPress={() => deletePart()}
            />
          </Card>
        </Card>
      </ScrollView>
    </SafeAreaView>
  );
};
