import { DataStore } from "@aws-amplify/datastore";
import React, { useEffect, useState } from "react";
import { SafeAreaView } from "react-native-safe-area-context";
import { Card, Input } from "react-native-elements";
import { ScrollView } from "react-native-gesture-handler";
import { styles } from "../../App";
import { SubmitAndCancelButtons } from "../../components/ButtonComponents";
import {
  getCustomFieldsFromDB,
  initialStateForPersonForm,
  PersonFormComponent,
} from "../../components/person/PersonFormComponent";
import { Group, FormType } from "../../src/models/index";
import { commonStyles } from "../../styles/commonStyles";
import { getGroupNameAsTenant } from "../../utils/getGroupNameAsTenant";
import * as NavUtils from "../../utils/NavUtils";

export const AddEditGroupScreen = ({ navigation, route }) => {
  //props needed for going to given screen using navigation
  const [form, updateForm] = useState({
    groupName: "",
    groupPurpose: "",
  });

  async function updatePageState() {
    if (route.params?.groupId === undefined) {
    } else {
      const groupOriginal = await DataStore.query(Group, route.params.groupId);
      updateForm({ ...form, ...groupOriginal });
    }
  }

  useEffect(() => {
    (async () => {
      await updatePageState();
    })();
  }, []);

  async function saveData() {
    var groupForTenant = await getGroupNameAsTenant();
    var group;
    var postData = { ...form };
    //avoids true groupname for tenant when null
    if (groupForTenant !== null) postData = { ...form, tenant: groupForTenant };

    if (route.params?.groupId === undefined) {
      group = await DataStore.save(new Group(postData));
    } else {
      //can this be saved in state or as a constant outside of function but it will get
      //reinitialized when componenet re-renders. Not needed in state as no re-render needed when it change
      // and more importantly it will complicate form state.
      //useRef?
      const groupOriginal = await DataStore.query(Group, route.params.groupId);
      group = await DataStore.save(
        Group.copyOf(groupOriginal, (updated) => {
          const returnedTarget = Object.assign(updated, postData);
        })
      );
    }
    return group;
  }
  async function submit() {
    var group = await saveData();
    NavUtils.showViewGroupScreen(navigation, group.id);
  }
  const cancel = () => {
    if (route.params?.groupId === undefined) {
      //if on add, then history is removed so go back to list screen as goBack() does not work
      NavUtils.showGroupListScreen(navigation);
    } else {
      //View screen is cancel screen from edit
      NavUtils.showViewGroupScreen(navigation, route.params?.groupId);
    }
  };
  return (
    <SafeAreaView style={commonStyles.pageContainer}>
      <SubmitAndCancelButtons
        submit={async () => await submit()}
        cancel={() => cancel()}
        flexDirection="row"
      />
      <ScrollView>
        <Card
          containerStyle={{
            padding: 4,
            margin: 0,
            backgroundColor: "#ebedf0",
            borderWidth: 3,
            // borderColor: "black",
            zIndex: 5,
          }}
        >
          <Input
            containerStyle={{ marginTop: 4 }}
            testID="groupName"
            maxLength={100}
            value={form.groupName}
            placeholder="Type Group Name"
            onChangeText={(e) => updateForm({ ...form, groupName: e })}
          />
          <Input
            containerStyle={{ marginTop: 4 }}
            testID="purpose"
            numberOfLines={4}
            maxLength={1000}
            multiline={true}
            value={form.groupPurpose}
            placeholder="Type purpose"
            onChangeText={(e) => updateForm({ ...form, groupPurpose: e })}
          />
        </Card>
      </ScrollView>
    </SafeAreaView>
  );
};
