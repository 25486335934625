import { FlatList, TouchableOpacity, Text } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import { Input, Button } from "react-native-elements";
import { styles } from "../../App";
import React, { useState, useEffect } from "react";
import { Client } from "../../src/models/index";
import { DataStore } from "@aws-amplify/datastore";
import { commonStyles } from "../../styles/commonStyles";
import * as NavUtils from "../../utils/NavUtils";
import { PersonViewComponent } from "../../components/person/PersonViewComponent";
import { ScrollView } from "react-native-gesture-handler";
import { LinkButton } from "../../components/ButtonComponents";
import { useConfirmation } from "../../components/confirmation/ConfirmationService";
import * as Utils from "../../utils/Utils";

export const ViewClientScreen = ({ navigation, route }) => {
  const confirm = useConfirmation();

  //props needed for going to given screen using navigation
  const { formType } = route.params;

  const [clientState, setClientState] = useState({});
  const clientId = route.params?.clientId
    ? route.params.clientId
    : "baee685c-c020-4a2a-a6a4-f46caca16643";

  async function updatePageState() {
    const clientOriginal = await DataStore.query(Client, clientId);
    setClientState(clientOriginal);
  }

  useEffect(() => {
    (async () => {
      await updatePageState();
    })();
  }, []);

  async function showEditClient() {
    // navigation.navigate("AddClientScreenName", {
    //   clientId: clientId,
    // });

    NavUtils.showAddEditClientScreen(navigation, clientId, formType);
  }

  async function deleteClient() {
    await Utils.areYouSureDialog(confirm, async () => {
      const clientToDelete = await DataStore.query(Client, clientId);
      await DataStore.delete(clientToDelete);
      navigation.navigate("ClientListScreenName");
    });
  }

  // throw new Error("testapp"); // TESTING FEEDBACK CRASH FORM FOR SENTRY
  return (
    <SafeAreaView style={commonStyles.pageContainer}>
      <LinkButton
        title={`< Back to ${formType} List`}
        submit={() => NavUtils.showPersonListScreen(navigation, formType)}
      />
      <ScrollView style={{ margin: 0, padding: 0 }}>
        <PersonViewComponent
          formType={formType}
          personState={clientState}
          editPerson={showEditClient}
          editButtonText="Edit"
          deletePerson={deleteClient}
          deleteButtonText="Delete"
          listPersons={() =>
            NavUtils.showClientListScreen(navigation, formType)
          }
          listPersonsButtonText={`View ${formType} List"`}
        />
      </ScrollView>
    </SafeAreaView>
  );
};
