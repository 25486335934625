import { Auth, DataStore } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { ScrollView, Text, View } from "react-native";
import { SubmitAndCancelButtons } from "../../components/ButtonComponents";
import { EulaTracker } from "../../src/models";
import { EulaAccepted } from "../../src/models";
import { getGroupNameAsTenant } from "../../utils/getGroupNameAsTenant";
import {
  Button,
  Provider,
  Paragraph,
  Dialog,
  Portal,
} from "react-native-paper";
import { TermsScreen } from "./TermsScreen";

export function EulaScreen({ navigation, route, setShowEula }) {
  const [visible, setVisible] = React.useState(false);

  const showDialog = () => setVisible(true);

  const hideDialog = () => setVisible(false);
  return (
    <>
      <SubmitAndCancelButtons
        submit={async () => {
          if (__DEV__) console.log("accepted agreement");
          const user = await Auth.currentAuthenticatedUser();
          const email = user.attributes.email;
          var groupForTenant = await getGroupNameAsTenant();
          var eula;
          var eulaSaveData = {
            tenant: groupForTenant,
            accepted: EulaAccepted.YES,
            email: email,
          };
          setShowEula(false);
          eula = await DataStore.save(new EulaTracker(eulaSaveData));
        }}
        cancel={() => {
          //dont insert for cancel as we only check for existence of Y
          setVisible(true);
          if (__DEV__) console.log("cancelled agreement");
        }}
        titleSubmit="I Agree"
        titleCancel="Disagree"
        flexDirection="row"
      />
      <View>
        <Portal>
          <Dialog visible={visible} onDismiss={hideDialog}>
            <Dialog.Title>Alert</Dialog.Title>
            <Dialog.Content>
              <Paragraph>
                Lawyers made us ask you! You are welcome to explore the Menu to
                see available options but you need to accept agreement to
                continue using the App.
              </Paragraph>
            </Dialog.Content>
            <Dialog.Actions>
              <Button onPress={hideDialog}>Close</Button>
            </Dialog.Actions>
          </Dialog>
        </Portal>
      </View>
      <TermsScreen navigation={navigation} route={route} />
    </>
  );
}
