import { Auth } from "aws-amplify";

export async function getGroupNameAsTenant() {
  const user = await Auth.currentAuthenticatedUser();

  // the array of groups that the user belongs to
  const groups = user.signInUserSession.accessToken.payload["cognito:groups"];
  const userEmail = user.attributes.email;
  if (__DEV__) console.log("groups|email", groups + "|" + userEmail);
  var groupForTenant = userEmail;

  if (groups === undefined) {
    //retain group = userEmail field so in future records created by owner are seen by other users in the group
    //solo business.
  } else if (
    // DEFAULT is not used but nice to have it here. Basically if
    groups.length === 0 ||
    (groups.length === 1 && groups[0] === "DEFAULT") ||
    groups.length > 2
  ) {
    if (__DEV__) console.log("SHOULD ******* NEVER ******* BE HERE", groups);

    //retain group = userEmail
  } else if (groups.length === 1 && groups[0] !== "DEFAULT") {
    groupForTenant = groups[0];
  } else if (groups.length === 2 && groups[0] === "DEFAULT") {
    //in case first is default, use second
    groupForTenant = groups[1];
  } else if (groups.length === 2 && groups[0] !== "DEFAULT") {
    //in case second is default, use first
    groupForTenant = groups[0];
  } else {
    if (__DEV__) console.log("Error NO MATCH ", groups);
    // groupForTenant = null;
  }
  if (__DEV__) console.log("groupname", groupForTenant);
  return groupForTenant;
}
