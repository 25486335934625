import {
  Image,
  View,
  Modal,
  SafeAreaView,
  ImageBackground,
} from "react-native";
import { Button, Card, Text, Input, Overlay } from "react-native-elements";
import React, { useState, useEffect } from "react";
import { commonStyles } from "../../styles/commonStyles";
import { ScrollView } from "react-native-gesture-handler";
import { Auth } from "aws-amplify";
import { Ionicons } from "@expo/vector-icons";
import { MaterialIcons } from "@expo/vector-icons";
import { FontAwesome5 } from "@expo/vector-icons";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import FeatureCRM from "./FeatureCRM";
import { LinkButton } from "../../components/ButtonComponents";
import * as Linking from "expo-linking";
import * as Utils from "../../utils/Utils";
import { LinearGradient } from "expo-linear-gradient";
import { TryFreeNotesScreen } from "./TryFreeNotesScreen";

export const SignUpScreen = ({ navigation, route }) => {
  const [signupForm, updateSignupForm] = useState({
    userEmail: "",
    pwd: "",
    cpd: "",
  });
  const [errorsSignup, setErrorsSignup] = useState("");
  const [errorJSONSignup, setErrorJSONSignup] = useState({});
  const [signupSubmitted, setSignupSubmitted] = useState(false);

  useEffect(() => {
    const showWhichHome = async () => {
      const user = await Auth.currentAuthenticatedUser();
      if (user) {
        navigation.navigate("LoggedInHomeScreenName");
        return;
      } else {
      }
    };
    showWhichHome();
    return () => {
      //cleanup
    };
  }, []);

  const signup = async () => {
    try {
      if (signupForm.userEmail?.trim().length <= 0) {
        setErrorsSignup("Please enter your email.");
        return;
      }
      if (
        !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
          signupForm.userEmail
        )
      ) {
        setErrorsSignup("Please enter valid format for Email.");
        return;
      }
      if (signupForm.pwd?.trim().length <= 0) {
        setErrorsSignup("Please enter password.");
        return;
      }
      if (signupForm.pwd.length < 8) {
        setErrorsSignup(
          "Password length is less than 8. Please enter longer password"
        );
        return;
      }
      if (signupForm.cpd?.trim().length <= 0) {
        setErrorsSignup("Please confirm password.");
        return;
      }

      if (signupForm.pwd !== signupForm.cpd) {
        setErrorsSignup(
          "Password and Confirm Password fields do not match. Please match and try again."
        );
        return;
      }
      const signupResult = await Auth.signUp(
        signupForm.userEmail,
        signupForm.pwd
      );
      if (signupResult) {
        //navigation.navigate("LoggedInHomeScreenName");
        setSignupSubmitted(true);
        setErrorsSignup("");
        return;
      } else {
        setErrorsSignup(
          "Unknown error occurred. Please contact us at support@siyamiapps.com"
        );
      }
    } catch (error) {
      if (__DEV__) console.log("sign up error", error);
      setErrorJSONSignup(error);
      setErrorsSignup(error?.message);
    }
  };
  return (
    <SafeAreaView style={{ flex: 1 }}>
      <ScrollView>
        <ImageBackground
          source={require("../../assets/images/orange.jpg")}
          style={{
            flex: 1,
            height: "50%",
          }}
        >
          <View
            style={{
              flex: 1,
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Card
              containerStyle={{
                flex: 1,
                padding: 10,
                backgroundColor: "#ebedf0",
                borderWidth: 3,
                margin: 10,
              }}
            >
              {signupSubmitted && (
                <Card.Title h4 style={{ color: "green" }}>
                  Verify Email
                </Card.Title>
              )}
              {signupSubmitted && (
                <>
                  <Text
                    style={{ margin: 20, color: "green", fontWeight: "bold" }}
                  >
                    Thank you for signing up. For security reasons, we need to
                    verify your email. Please check email from Cloud CRM and
                    press Verify Email link in that message. Please check all
                    folders of your email. Even after 2 minutes, if you do not
                    see an email, then please check whether{" "}
                    {signupForm.userEmail} is your correct email. If not then
                    sign up again with your correct email address. After Email
                    Verification is successful, press Login link below to enjoy
                    Cloud CRM.
                  </Text>
                  <View
                    style={{
                      marginVertical: 0,
                      marginHorizontal: 20,
                      width: 300,
                    }}
                  >
                    <LinkButton
                      title={`Login with ${signupForm.userEmail}`}
                      submit={() => {
                        //  navigation.navigate("ViewClientScreenName", {
                        //   clientId: id,
                        //   formType: formType,
                        // });
                        navigation.navigate("SignInScreenName", {
                          signedUpEmail: signupForm.userEmail,
                        });
                      }}
                    />
                    <View style={{ marginVertical: 5 }} />
                    <LinkButton
                      title={`Sign Up Again with different Email`}
                      submit={() => setSignupSubmitted(false)}
                    />
                  </View>
                </>
              )}
              {!signupSubmitted && <Card.Title h4>Create Account</Card.Title>}

              {!signupSubmitted && (
                <>
                  {errorsSignup?.length > 0 && (
                    <Text style={{ margin: 20, color: "red" }}>
                      {errorsSignup?.trim()}
                    </Text>
                  )}
                  <Input
                    maxLength={100}
                    value={signupForm.userEmail}
                    placeholder="Your Email to be used for login"
                    onChangeText={(e) =>
                      updateSignupForm({ ...signupForm, userEmail: e.trim() })
                    }
                    keyboardType="email-address"
                  />
                  <Input
                    maxLength={100}
                    value={signupForm.pwd}
                    placeholder="Enter Password"
                    onChangeText={(e) =>
                      updateSignupForm({ ...signupForm, pwd: e.trim() })
                    }
                    secureTextEntry
                  />
                  <Input
                    maxLength={100}
                    value={signupForm.cpd}
                    placeholder="Confirm Password"
                    onChangeText={(e) =>
                      updateSignupForm({ ...signupForm, cpd: e.trim() })
                    }
                    secureTextEntry
                  />
                  <Button
                    style={{
                      marginVertical: 10,
                      // marginHorizontal: 10,
                      // width: 300,
                    }}
                    buttonStyle={{
                      backgroundColor: "#cf9002",
                    }}
                    onPress={() => signup()}
                    title="Sign Up Now for Free Trial"
                  />
                  <View style={{ marginVertical: 5 }} />
                  <LinkButton
                    title={`Go To Home Page`}
                    submit={() =>
                      navigation.navigate("LoggedOutHomeScreenName")
                    }
                  />
                </>
              )}
              {Utils.isWeb() && (
                <TryFreeNotesScreen navigation={navigation} route={route} />
              )}
            </Card>
          </View>
        </ImageBackground>
      </ScrollView>
    </SafeAreaView>
  );
};
