import React, { useState, useEffect } from "react";
import { FormType, Appointment } from "../../src/models/index";
import { DataStore } from "@aws-amplify/datastore";
import { commonStyles } from "../../styles/commonStyles";
import * as NavUtils from "../../utils/NavUtils";
import {
  LinkButton,
  SubmitAndCancelButtons,
} from "../../components/ButtonComponents";
import * as Utils from "../../utils/Utils";
import * as DBUtils from "../../utils/DBUtils";
import { getGroupNameAsTenant } from "../../utils/getGroupNameAsTenant";
import { Button, Card, Input } from "react-native-elements";
import { View, Text } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import { TimePicker } from "react-native-simple-time-picker";

import { SafeAreaView } from "react-native-safe-area-context";
import { useConfirmation } from "../../components/confirmation/ConfirmationService";

export const ViewAppointmentComponent = ({ navigation, route }) => {
  const confirm = useConfirmation();

  const [appointmentState, setAppointmentState] = useState({
    title: "",
    details: "",
    createdOn: new Date(),
    appointmentDate: new Date(),
    timeZoneOffset: new Date().getTimezoneOffset(),
    hoursStart: 9,
    minutesStart: 0,
    ampmStart: "am",
    hoursEnd: 9,
    minutesEnd: 0,
    ampmEnd: "am",
  });
  //getLoggedInUsersOwnerDBDetails for currency
  const appointmentId = route.params?.appointmentId;
  useEffect(() => {
    async function loadAppointment() {
      if (appointmentId !== undefined) {
        //in the first render of view parent, its empty so do not waste going thru loop
        const appointment = await DataStore.query(Appointment, appointmentId);
        setAppointmentState({ ...appointment });
      } else {
        //default for add new appointment  are already default
        console.assert(false, "Appointment id missing in view");
      }
    }
    loadAppointment();
    return () => {
      //cleanup
    };
  }, []);

  async function showEditAppointment() {
    NavUtils.showAddEditAppointmentScreen(navigation, appointmentId, "");
    //appointmentid decides date so pass blank
  }

  async function deleteAppointment() {
    await Utils.areYouSureDialog(confirm, async () => {
      const appointmentToDelete = await DataStore.query(
        Appointment,
        appointmentId
      );
      await DataStore.delete(appointmentToDelete);
      NavUtils.showAppointmentsScreen(navigation);
    });
  }
  const formattedStart =
    appointmentState.minutesStart < 10
      ? "0" + appointmentState.minutesStart
      : appointmentState.minutesStart;
  const formattedEnd =
    appointmentState.minutesEnd < 10
      ? "0" + appointmentState.minutesEnd
      : appointmentState.minutesEnd;
  return (
    <SafeAreaView style={commonStyles.pageContainer}>
      <LinkButton
        title="< Back to Appointments"
        submit={() => NavUtils.showAppointmentsScreen(navigation)}
      />
      <ScrollView style={{ margin: 0, marginTop: 10, padding: 0 }}>
        <Card containerStyle={commonStyles.mainCardContainer}>
          <Card.Title style={{ marginTop: 8 }}></Card.Title>
          <Card
            containerStyle={{
              padding: 4,
              margin: 0,
              backgroundColor: "#ebedf0",
              borderWidth: 3,
              borderColor: "black",
              zIndex: 5,
            }}
          >
            <Text
              style={[
                commonStyles.textFieldName,
                { marginLeft: 0, marginBottom: 4 },
              ]}
            >
              Date
            </Text>
            <Text style={{ marginBottom: 4 }}>
              {new Date(appointmentState.appointmentDate).toLocaleDateString()}
            </Text>
            <Text
              style={[
                commonStyles.textFieldName,
                { marginLeft: 0, marginBottom: 4 },
              ]}
            >
              Title
            </Text>
            <Text style={{ marginBottom: 4 }}>{appointmentState.title}</Text>
            <Text
              style={[
                commonStyles.textFieldName,
                { marginLeft: 0, marginBottom: 4 },
              ]}
            >
              Start Time
            </Text>
            <Text style={{ marginBottom: 4 }}>
              {appointmentState.hoursStart +
                ":" +
                formattedStart +
                " " +
                appointmentState.ampmStart}
            </Text>
            <Text
              style={[
                commonStyles.textFieldName,
                { marginLeft: 0, marginBottom: 4 },
              ]}
            >
              End Time
            </Text>
            <Text style={{ marginBottom: 4 }}>
              {appointmentState.hoursEnd +
                ":" +
                formattedEnd +
                " " +
                appointmentState.ampmEnd}
            </Text>
            <Text
              style={[
                commonStyles.textFieldName,
                { marginLeft: 0, marginBottom: 4 },
              ]}
            >
              Details
            </Text>
            <Text style={{ marginBottom: 6 }}>{appointmentState.details}</Text>

            <Button
              buttonStyle={commonStyles.buttonRow}
              // containerStyle={{ width: "30%" }}
              title="Edit"
              onPress={() => showEditAppointment()}
            />
            <Button
              buttonStyle={commonStyles.buttonDeleteRow}
              // containerStyle={{ width: "30%" }}
              title="Delete"
              onPress={() => deleteAppointment()}
            />
          </Card>
        </Card>
      </ScrollView>
    </SafeAreaView>
  );
};
