import React, { useState, useEffect } from "react";
import { FormType, Bills } from "../../src/models/index";
import { DataStore } from "@aws-amplify/datastore";
import { commonStyles } from "../../styles/commonStyles";
import * as NavUtils from "../../utils/NavUtils";
import { LinkButton, SubmitAndCancelButtons } from "../ButtonComponents";
import * as Utils from "../../utils/Utils";
import { BillStatus } from "../../src/models";
import * as DBUtils from "../../utils/DBUtils";
import { getGroupNameAsTenant } from "../../utils/getGroupNameAsTenant";
import { Card, Input } from "react-native-elements";
import CustomDatePicker from "../CustomDatePicker.web";
import { View, Text } from "react-native";

export const AddEditBillsComponent = ({ navigation, route }) => {
  const [refresh, doRefresh] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [form, updateForm] = useState({
    purpose: "",
    details: "",
    amountDue: "",
    billStatus: BillStatus.UNPAID,
    createdOn: new Date(),
  });
  const [currency, setCurrency] = useState("");
  //getLoggedInUsersOwnerDBDetails for currency
  const billId = route.params?.billId;
  const clientId = route.params?.clientId;
  useEffect(() => {
    async function loadBills() {
      if (billId !== undefined) {
        //in the first render of view parent, its empty so do not waste going thru loop
        const bill = await DataStore.query(Bills, billId);
        let cur = await Utils.getCurrency();
        setCurrency(cur ?? "");
        updateForm({
          purpose: bill?.purpose,
          details: bill?.details,
          amountDue: bill?.amountDue,
          billStatus: bill?.billStatus,
          createdOn: new Date(bill?.createdOn),
        });
        setEditMode(true);
      } else {
        //default for add new bill  are already default
      }
    }
    loadBills();
    return () => {
      //cleanup
    };
  }, [!refresh]);
  // second render time - parent has id due to its own useeffect
  //but need to check that id go undefined to some_id so this componenet can render

  async function addOrUpdateBill() {
    const tenant = await getGroupNameAsTenant();
    if (!editMode) {
      //mark flag is used so we dont create new entry but update existing as state doesn't change yet
      // if(__DEV__) console.log("in save of event new");
      await DataStore.save(
        new Bills({
          clientId: clientId,
          tenant: tenant,
          purpose: form.purpose,
          details: form.details,
          // amountDue: JSON.stringify(amountDue.toJSON()),
          amountDue: form.amountDue,
          billStatus: form.billStatus,
          // billStatusLocal === "undefined" ? billStatus : billStatusLocal,
          createdOn: new Date(form.createdOn).toISOString(),
          otherData: "",
        })
      );
    } else {
      const billsOriginal = await DataStore.query(Bills, billId);
      const ret = await DataStore.save(
        Bills.copyOf(billsOriginal, (updated) => {
          const returnedTarget = Object.assign(updated, {
            ...form,
            createdOn: new Date(form.createdOn).toISOString(),
          });
        })
      );
    }
    const formType = await DBUtils.getFormType(clientId);

    NavUtils.showViewClientScreen(
      navigation,
      clientId,
      formType ?? FormType.CLIENT
    );
  }

  return (
    <>
      <Card containerStyle={commonStyles.mainCardContainerWithThickBorder}>
        <Card.Title style={{ marginTop: 8 }}></Card.Title>
        {/* // <Overlay */}
        <Card
          containerStyle={{
            padding: 4,
            margin: 0,
            backgroundColor: "#ebedf0",
            borderWidth: 3,
            borderColor: "black",
            zIndex: 5,
          }}
        >
          <CustomDatePicker
            selected={form.createdOn}
            onChange={(date) => updateForm({ ...form, createdOn: date })}
          />
          <Input
            containerStyle={{ marginTop: 4 }}
            testID="purpose"
            maxLength={200}
            value={form.purpose}
            placeholder="Purpose of the Bill"
            onChangeText={(e) => updateForm({ ...form, purpose: e })}
          />
          <Input
            keyboardType="decimal-pad"
            containerStyle={{ marginTop: 4 }}
            testID="amountDue"
            maxLength={100}
            value={form.amountDue}
            placeholder="Amount of the Bill"
            onChangeText={(e) =>
              // setAmountDue(Dinero({ amount: parseInt(e) }))
              updateForm({ ...form, amountDue: e })
            }
          />

          <Input
            containerStyle={{ marginTop: 4 }}
            testID="details"
            numberOfLines={4}
            maxLength={10000}
            multiline={true}
            value={form.details}
            placeholder="Details of the Bill"
            onChangeText={(e) => updateForm({ ...form, details: e })}
          />
          <View style={{ flexDirection: "row" }}>
            <Text
              style={{
                margin: 10,
                alignItems: "center",
                color: form.billStatus === BillStatus.UNPAID ? "red" : "green",
              }}
            >
              {form.billStatus}
            </Text>
            <View style={{ marginTop: 4, alignItems: "center" }}>
              <LinkButton
                title={`Mark As ${
                  form.billStatus === BillStatus.UNPAID
                    ? BillStatus.PAID
                    : BillStatus.UNPAID
                }`}
                submit={async () => {
                  updateForm({
                    ...form,
                    billStatus:
                      form.billStatus === BillStatus.UNPAID
                        ? BillStatus.PAID
                        : BillStatus.UNPAID,
                  });
                }}
              />
            </View>
          </View>
          <SubmitAndCancelButtons
            submit={async () => await addOrUpdateBill()}
            cancel={async () => {
              const formType = await DBUtils.getFormType(clientId);
              NavUtils.showViewClientScreen(
                navigation,
                clientId,
                formType ?? FormType.CLIENT
              );
            }}
            flexDirection="row"
          />
        </Card>
        {/* // </Overlay> */}
      </Card>
    </>
  );
};
