import { DataStore } from "@aws-amplify/datastore";
import React, { useEffect, useState } from "react";
import { ScrollView, Text } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import { Button, Input, Overlay } from "react-native-elements";
import { styles } from "../../App";
import { Client, CustomForm } from "../../src/models/index";
import { commonStyles } from "../../styles/commonStyles";
import { getGroupNameAsTenant } from "../../utils/getGroupNameAsTenant";
import { SubmitAndCancelButtons } from "../ButtonComponents";
import {
  getCustomFieldsFromDB,
  getDefaultCustomFieldsInitialData,
} from "./PersonFormComponent";
import { Modal, View, StyleSheet } from "react-native";
import DatePicker from "react-datepicker";
import CustomDatePicker from "../CustomDatePicker.web";

export const Test = ({ navigation, route }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [startDate, setStartDate] = useState(new Date());

  return (
    <SafeAreaView
      style={{ alignItems: "center", flex: 1, justifyContent: "center" }}
    >
      <ScrollView>
        <Text> THIS IS TEST SCREEN</Text>
        <Overlay ModalComponent={Modal}>
          <Text>Hello from Overlay!</Text>
          <CustomDatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
          />
        </Overlay>

        <Modal
          animationType="slide"
          onRequestClose={() => setIsVisible(false)}
          visible={isVisible}
        >
          <View
            style={{ alignItems: "center", flex: 1, justifyContent: "center" }}
          >
            {" "}
            <Text>inside</Text>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
            />
            <Button onPress={() => setIsVisible(false)} title={"Close Modal"} />
          </View>
        </Modal>
        <Button onPress={() => setIsVisible(true)} title={"Simple modal"} />
      </ScrollView>
    </SafeAreaView>
  );
};
