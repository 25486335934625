import React, { useState, useEffect } from "react";
import { FormType, Product } from "../../src/models/index";
import { DataStore } from "@aws-amplify/datastore";
import { commonStyles } from "../../styles/commonStyles";
import * as NavUtils from "../../utils/NavUtils";
import {
  LinkButton,
  SubmitAndCancelButtons,
} from "../../components/ButtonComponents";
import * as Utils from "../../utils/Utils";
import * as DBUtils from "../../utils/DBUtils";
import { getGroupNameAsTenant } from "../../utils/getGroupNameAsTenant";
import { Card, Button, Input } from "react-native-elements";
import { View, Text } from "react-native";
import { useConfirmation } from "../../components/confirmation/ConfirmationService";
import { ScrollView } from "react-native-gesture-handler";
import { SafeAreaView } from "react-native-safe-area-context";

export const ViewProductComponent = ({ navigation, route }) => {
  const confirm = useConfirmation();

  const [productState, setProductState] = useState({
    name: "",
    details: "",
    unitPrice: "",
    quantityAvailable: "",
    createdOn: new Date(),
  });
  const [currency, setCurrency] = useState("");
  //getLoggedInUsersOwnerDBDetails for currency
  const productId = route.params?.productId;
  useEffect(() => {
    async function loadProduct() {
      if (productId !== undefined) {
        //in the first render of view parent, its empty so do not waste going thru loop
        const product = await DataStore.query(Product, productId);
        let cur = await Utils.getCurrency();
        setCurrency(cur ?? "");
        setProductState({ ...product });
      } else {
        //default for add new product  are already default
        console.assert(false, "Prod id missing in view");
      }
    }
    loadProduct();
    return () => {
      //cleanup
    };
  }, []);

  async function showEditProduct() {
    NavUtils.showAddEditProductScreen(navigation, productId);
  }

  async function deleteProduct() {
    await Utils.areYouSureDialog(confirm, async () => {
      const productToDelete = await DataStore.query(Product, productId);
      await DataStore.delete(productToDelete);
      NavUtils.showProductCatalogScreen(navigation);
    });
  }

  return (
    <SafeAreaView style={commonStyles.pageContainer}>
      <LinkButton
        title="< Back to Product Catalog"
        submit={() => NavUtils.showProductCatalogScreen(navigation)}
      />
      <ScrollView style={{ margin: 0, marginTop: 10, padding: 0 }}>
        <Card containerStyle={commonStyles.mainCardContainer}>
          <Card.Title style={{ marginTop: 8 }}></Card.Title>
          <Card
            containerStyle={{
              padding: 4,
              margin: 0,
              backgroundColor: "#ebedf0",
              borderWidth: 3,
              borderColor: "black",
              zIndex: 5,
            }}
          >
            <Text
              style={[
                commonStyles.textFieldName,
                { marginLeft: 0, marginBottom: 4 },
              ]}
            >
              Product Name
            </Text>
            <Text style={{ marginBottom: 4 }}>{productState.name}</Text>

            <Text
              style={[
                commonStyles.textFieldName,
                { marginLeft: 0, marginBottom: 4 },
              ]}
            >
              Unit Price
            </Text>
            <Text style={{ marginBottom: 4 }}>
              {(currency ? currency + " " : "") + productState.unitPrice}
            </Text>
            <Text
              style={[
                commonStyles.textFieldName,
                { marginLeft: 0, marginBottom: 4 },
              ]}
            >
              Available Quantity
            </Text>
            <Text style={{ marginBottom: 4 }}>
              {productState.quantityAvailable}
            </Text>
            <Text
              style={[
                commonStyles.textFieldName,
                { marginLeft: 0, marginBottom: 4 },
              ]}
            >
              Product Details
            </Text>
            <Text style={{ marginBottom: 6 }}>{productState.details}</Text>
            <Text
              style={[
                commonStyles.textFieldName,
                { marginLeft: 0, marginBottom: 4 },
              ]}
            >
              Updated On
            </Text>
            <Text style={{ marginBottom: 4 }}>
              {new Date(productState.createdOn).toLocaleDateString()}
            </Text>
            <Button
              buttonStyle={commonStyles.buttonRow}
              // containerStyle={{ width: "30%" }}
              title="Edit"
              onPress={() => showEditProduct()}
            />
            <Button
              buttonStyle={commonStyles.buttonDeleteRow}
              // containerStyle={{ width: "30%" }}
              title="Delete"
              onPress={() => deleteProduct()}
            />
          </Card>
        </Card>
      </ScrollView>
    </SafeAreaView>
  );
};
