import { Text, View } from "react-native";
import { Input, Card, ButtonGroup } from "react-native-elements";
import React, { useState, useEffect } from "react";
import { FormType, Event, EventRepeat } from "../../src/models/index";
import { DataStore } from "@aws-amplify/datastore";
import { commonStyles } from "../../styles/commonStyles";
import * as NavUtils from "../../utils/NavUtils";

import * as DBUtils from "../../utils/DBUtils";
import { getGroupNameAsTenant } from "../../utils/getGroupNameAsTenant";
import CustomDatePicker from "../CustomDatePicker.web";
import { SubmitAndCancelButtons } from "../ButtonComponents";

export const AddEditEventComponent = ({ navigation, route }) => {
  const [name, setName] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [repeatsPeriod, setRepeatsPeriod] = useState(EventRepeat.NO);
  const [selectedIndex, setSelectedIndex] = useState(0); //yearly
  const [refresh, doRefresh] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [eventToEdit, setEventToEdit] = useState({});
  const eventId = route.params?.eventId;
  const clientId = route.params?.clientId;

  useEffect(() => {
    async function loadEvent() {
      if (eventId !== undefined) {
        //in the first render of view parent, its empty so do not waste going thru loop
        const events = (await DataStore.query(Event)).filter(
          (e) => e.id === eventId
        );
        const item = events[0];
        setEventToEdit(item);
        setName(item.name);
        setStartDate(new Date(item.startTime));
        setRepeatsPeriod(item.repeats);
        setEditMode(true);
        if (item.repeats === EventRepeat.DAY) setSelectedIndex(1);
        else if (item.repeats === EventRepeat.WEEK) setSelectedIndex(2);
        else if (item.repeats === EventRepeat.MONTH) setSelectedIndex(3);
        else if (item.repeats === EventRepeat.YEAR) setSelectedIndex(4);
        else setSelectedIndex(0);
      } else {
        setEditMode(false);
        setName("");
        setStartDate(new Date());
        setRepeatsPeriod(EventRepeat.NO);
        setSelectedIndex(0);
      }
    }
    loadEvent();
    return () => {
      //cleanup
    };
  }, [!refresh]);
  // second render time - parent has id due to its own useeffect
  //but need to check that id go undefined to some_id so this componenet can render

  async function addOrUpdateEvent() {
    const tenant = await getGroupNameAsTenant();

    if (!editMode) {
      // if(__DEV__) console.log("in save of event new");
      await DataStore.save(
        new Event({
          clientId: clientId,
          tenant: tenant,
          name: name,
          notes: "",
          startTime: startDate.toISOString(),
          otherData: "",
          repeats: repeatsPeriod,
          repeatsCustom: -1,
          timezoneOffset: new Date().getTimezoneOffset(),
        })
      );
    } else {
      //update
      const eventTemp = { ...eventToEdit };
      eventTemp.name = name;
      eventTemp.startTime = startDate.toISOString();
      eventTemp.repeats = repeatsPeriod;
      const eventOriginal = await DataStore.query(Event, eventToEdit.id);
      const ret = await DataStore.save(
        Event.copyOf(eventOriginal, (updated) => {
          const returnedTarget = Object.assign(updated, eventTemp);
        })
      );
    }
    const formType = await DBUtils.getFormType(clientId);

    NavUtils.showViewClientScreen(
      navigation,
      clientId,
      formType ?? FormType.CLIENT
    );
  }

  const buttons = [
    EventRepeat.NO,
    EventRepeat.DAY,
    EventRepeat.WEEK,
    EventRepeat.MONTH,
    EventRepeat.YEAR,
  ];
  function updateIndex(selectedIndex) {
    setSelectedIndex(selectedIndex);
    if (selectedIndex === 1) setRepeatsPeriod(EventRepeat.DAY);
    else if (selectedIndex === 2) setRepeatsPeriod(EventRepeat.WEEK);
    else if (selectedIndex === 3) setRepeatsPeriod(EventRepeat.MONTH);
    else if (selectedIndex === 4) setRepeatsPeriod(EventRepeat.YEAR);
    else if (selectedIndex === 0) setRepeatsPeriod(EventRepeat.NO);
  }
  return (
    <>
      <Card containerStyle={commonStyles.mainCardContainerWithThickBorder}>
        <Card.Title style={{ marginTop: 8 }}></Card.Title>
        {/*  <Overlay fullScreen ModalComponent={Modal} width="auto" height="auto"> */}
        <Card
          containerStyle={{
            padding: 4,
            margin: 0,
            backgroundColor: "#ebedf0",
            borderWidth: 3,
            borderColor: "black",
          }}
        >
          <CustomDatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
          />

          <View style={{ marginVertical: 10 }} />
          <Input
            style={{ marginVertical: 10 }}
            maxLength={100}
            testID="name"
            value={name}
            placeholder="Type Event Name such as Birthday"
            onChangeText={(e) => setName(e)}
          />
          <Text style={{ marginLeft: 8, marginVertical: 10 }}>
            Repeat Period
          </Text>

          <ButtonGroup
            textStyle={{ fontSize: 12 }}
            onPress={updateIndex}
            selectedIndex={selectedIndex}
            buttons={buttons}
            containerStyle={{
              flexWrap: "wrap",
              margin: 0,
              padding: 0,
            }}
          />
          <View style={{ marginVertical: 40 }} />
          <SubmitAndCancelButtons
            submit={async () => await addOrUpdateEvent()}
            cancel={async () => {
              const formType = await DBUtils.getFormType(clientId);
              NavUtils.showViewClientScreen(
                navigation,
                clientId,
                formType ?? FormType.CLIENT
              );
            }}
            flexDirection="row"
          />
          <View style={{ marginVertical: 10 }} />
        </Card>
        {/* // </Overlay> */}
      </Card>
    </>
  );
};
