import React, { useState, useEffect } from "react";
import { FormType, Appointment } from "../../src/models/index";
import { DataStore } from "@aws-amplify/datastore";
import { commonStyles } from "../../styles/commonStyles";
import * as NavUtils from "../../utils/NavUtils";
import {
  LinkButton,
  SubmitAndCancelButtons,
} from "../../components/ButtonComponents";
import * as Utils from "../../utils/Utils";
import * as DBUtils from "../../utils/DBUtils";
import { getGroupNameAsTenant } from "../../utils/getGroupNameAsTenant";
import { Card, Input } from "react-native-elements";
import CustomDatePicker from "../../components/CustomDatePicker.web";
import { View, Text } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import { TimePicker } from "react-native-simple-time-picker";
import { SafeAreaView } from "react-native-safe-area-context";
import { parse } from "date-fns";

export const AddEditAppointmentComponent = ({ navigation, route }) => {
  const [refresh, doRefresh] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [form, updateForm] = useState({
    title: "",
    details: "",
    createdOn: new Date(),
    appointmentDate: new Date(),
    timeZoneOffset: new Date().getTimezoneOffset(),
    hoursStart: 9,
    minutesStart: 0,
    ampmStart: "am",
    hoursEnd: 9,
    minutesEnd: 0,
    ampmEnd: "am",
  });

  // const [hoursStart, setHoursStart] = React.useState(0);
  // const [minutesStart, setMinutesStart] = React.useState(0);
  // const [ampmStart, setAMPMStart] = React.useState("am");

  // const [hoursEnd, setHoursEnd] = React.useState(0);
  // const [minutesEnd, setMinutesEnd] = React.useState(0);
  // const [ampmEnd, setAMPMEnd] = React.useState("am");

  const handleChangeStart = (value: {
    hours: number;
    minutes: number;
    ampm: string;
  }) => {
    updateForm({
      ...form,
      hoursStart: value.hours,
      minutesStart: value.minutes,
      ampmStart: value.ampm,
    });
  };
  const handleChangeEnd = (value: {
    hours: number;
    minutes: number;
    ampm: string;
  }) => {
    updateForm({
      ...form,
      hoursEnd: value.hours,
      minutesEnd: value.minutes,
      ampmEnd: value.ampm,
    });
  };
  //getLoggedInUsersOwnerDBDetails for currency
  const appointmentId = route.params?.appointmentId;

  useEffect(() => {
    async function loadAppointment() {
      if (appointmentId !== undefined) {
        //in the first render of view parent, its empty so do not waste going thru loop
        const appointment = await DataStore.query(Appointment, appointmentId);
        updateForm({
          ...form,
          title: appointment?.title,
          details: appointment?.details,
          createdOn: new Date(appointment?.createdOn),
          appointmentDate: new Date(appointment?.appointmentDate),
          hoursStart: appointment?.hoursStart,
          minutesStart: appointment?.minutesStart,
          ampmStart: appointment?.ampmStart,
          hoursEnd: appointment?.hoursEnd,
          minutesEnd: appointment?.minutesEnd,
          ampmEnd: appointment?.ampmEnd,
        });
        setEditMode(true);
      } else {
        //default for add new appointment  are already default except date
        const selectedDateFromCalScreen = route.params?.selectedDate;
        if (
          selectedDateFromCalScreen !== "" &&
          selectedDateFromCalScreen !== undefined &&
          selectedDateFromCalScreen !== null
        ) {
          const selDate = parse(
            selectedDateFromCalScreen,
            "yyyy-MM-dd",
            new Date()
          );

          updateForm({
            ...form,
            appointmentDate: selDate,
          });
        }
      }
    }
    loadAppointment();

    return () => {
      //cleanup
    };
  }, [!refresh]);
  // second render time - parent has id due to its own useeffect
  //but need to check that id go undefined to some_id so this componenet can render

  async function saveData() {
    const tenant = await getGroupNameAsTenant();
    var appointment;
    if (!editMode) {
      //mark flag is used so we dont create new entry but update existing as state doesn't change yet
      // if(__DEV__) console.log("in save of event new");
      appointment = await DataStore.save(
        new Appointment({
          ...form,
          tenant: tenant,
          createdOn: form.createdOn.toISOString(),
          appointmentDate: form.appointmentDate.toISOString(),
          hoursStart: parseInt(form.hoursStart),
          minutesStart: parseInt(form.minutesStart),
          hoursEnd: parseInt(form.hoursEnd),
          minutesEnd: parseInt(form.minutesEnd),
        })
      );
    } else {
      const appointmentsOriginal = await DataStore.query(
        Appointment,
        appointmentId
      );
      if (appointmentsOriginal !== undefined && form !== undefined)
        appointment = await DataStore.save(
          Appointment.copyOf(appointmentsOriginal, (updated) => {
            const returnedTarget = Object.assign(updated, {
              ...form,
              createdOn: form.createdOn.toISOString(),
              appointmentDate: form.appointmentDate.toISOString(),
              hoursStart: parseInt(form.hoursStart),
              minutesStart: parseInt(form.minutesStart),
              hoursEnd: parseInt(form.hoursEnd),
              minutesEnd: parseInt(form.minutesEnd),
            });
          })
        );
    }
    return appointment;
  }

  async function submit() {
    var appointment = await saveData();
    NavUtils.showViewAppointmentScreen(navigation, appointment.id);
  }
  const cancel = () => {
    if (route.params?.appointmentId === undefined) {
      //if on add, then history is removed so go back to list screen as goBack() does not work
      NavUtils.showAppointmentsScreen(navigation);
    } else {
      //View screen is cancel screen from edit
      NavUtils.showViewAppointmentScreen(
        navigation,
        route.params?.appointmentId
      );
    }
  };

  return (
    <ScrollView>
      <Card containerStyle={commonStyles.mainCardContainerWithThickBorder}>
        <Card.Title style={{ marginTop: 8 }}></Card.Title>
        {/* // <Overlay */}
        <Card
          containerStyle={{
            padding: 4,
            margin: 0,
            backgroundColor: "#ebedf0",
            borderWidth: 3,
            borderColor: "black",
            zIndex: 5,
          }}
        >
          <Text
            style={[
              commonStyles.textFieldName,
              { marginLeft: 0, marginBottom: 4 },
            ]}
          >
            Appointment Date
          </Text>
          <CustomDatePicker
            selected={form.appointmentDate}
            onChange={(date) => updateForm({ ...form, appointmentDate: date })}
          />
          <Text
            style={[
              commonStyles.textFieldName,
              { marginLeft: 0, marginBottom: 4 },
            ]}
          >
            Title
          </Text>
          <Input
            containerStyle={{ marginTop: 4 }}
            testID="title"
            maxLength={200}
            value={form.title}
            placeholder="Name of the Appointment"
            onChangeText={(e) => updateForm({ ...form, title: e })}
          />
          <Text
            style={[
              commonStyles.textFieldName,
              { marginLeft: 0, marginBottom: 4 },
            ]}
          >
            Start Time
          </Text>
          <TimePicker
            value={{
              hours: form.hoursStart,
              minutes: form.minutesStart,
              ampm: form.ampmStart,
            }}
            onChange={handleChangeStart}
            isAmpm={true}
            // ampmLocalization={{ am: "am", pm: "pm" }}
          />
          <Text
            style={[
              commonStyles.textFieldName,
              { marginLeft: 0, marginBottom: 4 },
            ]}
          >
            End Time
          </Text>
          <TimePicker
            value={{
              hours: form.hoursEnd,
              minutes: form.minutesEnd,
              ampm: form.ampmEnd,
            }}
            onChange={handleChangeEnd}
            isAmpm={true}
            // ampmLocalization={{ am: "am", pm: "pm" }}
          />

          <Text
            style={[
              commonStyles.textFieldName,
              { marginLeft: 0, marginBottom: 4 },
            ]}
          >
            Details
          </Text>
          <Input
            containerStyle={{ marginTop: 4 }}
            testID="details"
            numberOfLines={4}
            maxLength={10000}
            multiline={true}
            value={form.details}
            placeholder="Details of the Appointment"
            onChangeText={(e) => updateForm({ ...form, details: e })}
          />

          <SubmitAndCancelButtons
            submit={async () => await submit()}
            cancel={async () => cancel()}
            flexDirection="row"
          />
        </Card>
        {/* // </Overlay> */}
      </Card>
    </ScrollView>
  );
};
