import { DataStore } from "@aws-amplify/datastore";
import React, { useEffect, useState } from "react";
import { Text, View, Modal, ActivityIndicator } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import { Button, Card, CheckBox, Input, Overlay } from "react-native-elements";
import { ScrollView } from "react-native-gesture-handler";
import {
  LinkButton,
  SubmitAndCancelButtons,
} from "../../components/ButtonComponents";
import CustomDatePicker from "../../components/CustomDatePicker.web";
import { AddUserToT, FormType, Task, TaskStatus } from "../../src/models/index";
import { commonStyles } from "../../styles/commonStyles";
import { getGroupNameAsTenant } from "../../utils/getGroupNameAsTenant";
import * as NavUtils from "../../utils/NavUtils";
import * as Utils from "../../utils/Utils";
import * as DBUtils from "../../utils/DBUtils";
import { format, compareAsc } from "date-fns";
import Amplify, { API } from "aws-amplify";
import ErrorMessageComponent from "../../components/ErrorMessageComponent";
import * as Linking from "expo-linking";

export const PromoScreen = ({ navigation, route }) => {
  //props needed for going to given screen using navigation
  const [form, updateForm] = useState({
    id: "",
    tenant: "",
  });
  const [errors, setErrors] = useState("");
  const [success, setSuccess] = useState("");
  const [showActivityIndicator, setShowActivityIndicator] = useState(false);
  const [isPaidUser, setPaidUser] = useState(false);
  async function updatePageState() {
    // path of /isValid/id gives all ids - DONT DO IT -- lot of codes!
    //for testing only
    // const ret = await API.put("PromoCodeAPI", "/promocode", {
    // const res = await API.get("PromoCodeAPI", "/promocode/id", {});
    // if (__DEV__) console.log("Promo=", res);
    //TODO - save app sync table record with promo too to lookup and display promo status on load
    const paid = await Utils.isPlanActive();
    setPaidUser(paid);
    if (paid) {
      setErrors(
        "You are already subscribed to the App. Promo Code is valid only for New Users. Press Contact Us link below to send an email to support@siyamiapps.com"
      );
    } else {
      const userLoggedIn = await DBUtils.getLoggedInUserDBDetails();
      if (userLoggedIn?.promoCodeUsed?.trim().length ?? 0 > 0) {
        //promo code is used and now check how many days left in promo to show to user
        const timePassedFromPromoAdded =
          new Date().getTime() -
          new Date(userLoggedIn?.promoCreatedOnDate).getTime();
        const daysPassedSincePromoActivation = Math.round(
          timePassedFromPromoAdded / (24 * 60 * 60 * 1000)
        );
        const days = userLoggedIn?.daysFree - daysPassedSincePromoActivation;
        if (__DEV__)
          console.log(
            "promo useeffect loaded user=" + JSON.stringify(userLoggedIn)
          );
        setSuccess(
          days >= 0
            ? "Your Free Trial will expire in " +
                days +
                " days. To avoid interruption in growing your business, please consider buying the subscription now by pressing Menu and selecting Buy Premium Plan"
            : "Your Free Trial expired " +
                -1 * days +
                " days ago. Press Menu and chose Buy Premium Plan to continue growing your business"
        );
      }
      /**** ONE PROBLEM TODO - fix the logic to determine FREE trial over to use promoCreatedDate for promo users(promocode not empty) instead of created Date */
    }
  }

  useEffect(() => {
    (async () => {
      await updatePageState();
    })();
  }, []);

  async function getPromo() {
    const res = await API.get("PromoCodeAPI", "/promocode/" + form.id, {});
    if (__DEV__) console.log("Promo get=", res);
    return res;
  }
  async function saveData(code) {
    var groupForTenant = await getGroupNameAsTenant();
    var postData = {
      ...form,
    };
    //avoids true taskname for tenant when null
    if (groupForTenant !== null)
      postData = {
        ...form,
        tenant: groupForTenant,
      };

    // const ret = await API.post("PromoRESTAPI", "/isValid", { // POST not allowed from client - get 403
    const ret = await API.put("PromoCodeAPI", "/promocode", {
      body: {
        ...postData,
        ...code,
        //  id: "999",
        status: "U",
        createdByApp: new Date().toISOString(),
      },
    });
    return ret;
  }
  async function submit() {
    // for testing direct put
    // var ret = await saveData();
    // if (__DEV__) console.log("updated promo=", ret);
    // // NavUtils.showViewTaskScreen(navigation, task.id);
    // setErrors(
    //   "Congratulations! Your Free Trial is now active for " + 365 + " days"
    // );
    if (form.id?.trim().length <= 0) {
      setErrors("Please enter the code");
      return;
    }
    setShowActivityIndicator(true);
    var res = await getPromo();
    if (res?.length === 1) {
      const code = res[0];
      if (code.status === "U") {
        setErrors(
          "ERROR: Code is already redeemed. Please check the code and try again. If you still receive an error, press Contact Us link below to send an email to support@siyamiapps.com"
        );
      } else {
        var ret = await saveData(code);
        if (__DEV__) console.log("promo updated=", ret);
        const userLoggedIn = await DBUtils.getLoggedInUserDBDetails();
        if (__DEV__) console.log("promo user=", userLoggedIn);

        const userOriginal = await DataStore.query(
          AddUserToT,
          userLoggedIn?.id
        );
        if (__DEV__) console.log("promo user original=", userOriginal);

        const updatedUser = await DataStore.save(
          AddUserToT.copyOf(userOriginal, (updated) => {
            const returnedTarget = Object.assign(updated, {
              promoCodeUsed: form.id,
              daysFree: code.daysFree,
              promoCreatedOnDate: new Date().toISOString(),
            });
          })
        );
        if (__DEV__) console.log("promo user updated=", updatedUser);

        setSuccess(
          "Congratulations! Your Free Trial is now active for " +
            code.daysFree +
            " days. To avoid interruption in growing your business, please consider buying the subscription before your trial expires by pressing Menu and selecting Buy Premium Plan "
        );
        setErrors("");
      }
    } else {
      setErrors(
        "ERROR: Code not found. Please check the code and try again. If you still receive an error, press Contact Us link below to send an email to support@siyamiapps.com"
      );
      setSuccess("");
    }
    setShowActivityIndicator(false);
  }
  const cancel = () => {
    NavUtils.showClientListScreen(navigation, FormType.CLIENT);
  };
  return (
    <SafeAreaView style={commonStyles.pageContainer}>
      <LinkButton
        title={`< Back to Client List`}
        submit={() =>
          NavUtils.showPersonListScreen(navigation, FormType.CLIENT)
        }
      />
      <View style={{ marginVertical: 5 }} />

      {errors.length > 0 && <ErrorMessageComponent errorMsg={errors} />}
      {success.length > 0 && <ErrorMessageComponent errorMsg={success} />}
      {!(success.length > 0) && (
        <>
          <View style={{ marginVertical: 10 }} />
          <SubmitAndCancelButtons
            submit={async () => await submit()}
            cancel={() => cancel()}
            flexDirection="row"
          />
          <View style={{ marginVertical: 10 }} />

          {showActivityIndicator && <ActivityIndicator size="large" />}

          <Card
            containerStyle={{
              padding: 4,
              margin: 0,
              backgroundColor: "#ebedf0",
              borderWidth: 3,
              // borderColor: "black",
              zIndex: 5,
            }}
          >
            <Input
              containerStyle={{ marginTop: 4 }}
              testID="code"
              maxLength={50}
              value={form.id}
              placeholder="Type Promo Code"
              onChangeText={(e) => updateForm({ ...form, id: e })}
            />
          </Card>
        </>
      )}
      <Button
        type="clear"
        title="Contact Us"
        onPress={() =>
          //<a href="mailto:test@example.com?subject=Testing out mailto!&body=This is only a test!">Second Example</a>

          Linking.openURL(
            "mailto:support@siyamiapps.com?subject=Regarding Cloud CRM for Client Records&body=Hi Team, Please help us with coupon code below: " +
              form.id
          )
        }
      />
      {/* </View> */}
    </SafeAreaView>
  );
};
