import { FlatList, TouchableOpacity, Text, View } from "react-native";
import { Input, Button, Card, Divider } from "react-native-elements";
import { styles } from "../../App";
import React, { useState, useEffect } from "react";
import { DataStore } from "@aws-amplify/datastore";
import { commonStyles } from "../../styles/commonStyles";
import * as NavUtils from "../../utils/NavUtils";
import { ScrollView } from "react-native-gesture-handler";
import { EventComponent } from "./EventComponent";
import { NotesComponent } from "./NotesComponent";
import { GroupsListComponent } from "./GroupsListComponent";
import { TasksListComponent } from "./TasksListComponent";
import * as MailComposer from "expo-mail-composer";
import * as Utils from "../../utils/Utils";
import * as SMS from "expo-sms";
import * as Linking from "expo-linking";
import { BillsComponent } from "./BillsComponent";

export const PersonViewComponent = ({
  formType,
  personState,
  editPerson,
  editButtonText,
  deletePerson,
  deleteButtonText,
  listPersons,
  listPersonsButtonText,
}) => {
  var customFieldsArray = [];
  if (personState.customFields) {
    const customFieldsObject = JSON.parse(personState.customFields);
    customFieldsArray = customFieldsObject.map((field, index) => {
      return (
        <>
          {field.value && (
            <>
              <Text
                style={[
                  commonStyles.textFieldName,
                  { marginLeft: 0, marginBottom: 4 },
                ]}
              >
                {field.name}
              </Text>
              <Text style={commonStyles.textRow}>{field.value}</Text>
            </>
          )}
        </>
      );
    });
  }

  return (
    <>
      <Card containerStyle={commonStyles.mainCardContainerWithThickBorder}>
        <Text style={commonStyles.pageSubHeader}>
          {personState.firstName + " " + personState.lastName}
        </Text>
        {__DEV__ && <Text>{personState.id}</Text>}

        {personState.cellPhone && (
          <View style={{ flexDirection: "row", marginBottom: 8 }}>
            <Text
              style={[
                commonStyles.textFieldName,
                { marginLeft: 0, marginRight: 10 },
              ]}
            >
              {personState.cellPhone}
            </Text>
            <Button
              buttonStyle={{ margin: 0, padding: 0 }}
              // containerStyle={{ width: "30%" }}
              containerStyle={{ margin: 0, paddingTop: 4, marginRight: 10 }}
              title="Call"
              onPress={() => {
                Linking.openURL(`tel:${personState.cellPhone}`);
              }}
              type="clear"
            />
            <Button
              buttonStyle={{ margin: 0, padding: 0 }}
              // containerStyle={{ width: "30%" }}
              containerStyle={{ margin: 0, paddingTop: 4 }}
              title="SMS"
              // onPress={async () => {
              //   const isAvailable = await SMS.isAvailableAsync();
              //   if (isAvailable) {
              //     // do your SMS stuff here
              //     const { result } = await SMS.sendSMSAsync(
              //       ["8602558329"],
              //       `Hi `,
              //       {
              //         // attachments: {
              //         //   uri: "path/myfile.png",
              //         //   mimeType: "image/png",
              //         //   filename: "myfile.png",
              //         // },
              //       }
              //     );
              //     alert(result);
              //   } else {
              //     // misfortune... there's no SMS available on this device
              //     //todo
              //     alert(
              //       "SMS not available on your device. Please contact your SMS provider"
              //     );
              //   }
              // }}
              onPress={() => {
                Linking.openURL(`sms:${personState.cellPhone}`);
                // Linking.openURL(
                //   `sms:8602558329,8604692771?body=hi, how are you`
                // );
              }}
              type="clear"
            />
          </View>
        )}
        {personState.email && (
          <View style={{ flexDirection: "row", marginBottom: 8 }}>
            <Text
              style={[
                commonStyles.textFieldName,
                { marginLeft: 0, marginRight: 10 },
              ]}
            >
              {personState.email}
            </Text>
            <Button
              buttonStyle={{ margin: 0, padding: 0 }}
              // containerStyle={{ width: "30%" }}
              containerStyle={{ margin: 0, paddingTop: 4, marginRight: 10 }}
              title="Email"
              //Removed below for IOS and instead using mailto url scheme
              // onPress={async () => {
              //   let result = await MailComposer.composeAsync({
              //     recipients: [personState.email],
              //     subject: "",
              //     body: "Hi, ",
              //     ccRecipients: [],
              //     bccRecipients: [],
              //   });
              //   //    alert(result.status);
              //   return result;
              // }}
              onPress={() => {
                Linking.openURL(`mailto:${personState.email}`);
              }}
              type="clear"
            />
          </View>
        )}
        <Text style={commonStyles.textRow}>{personState.address}</Text>
        <Text style={commonStyles.textRow}>{personState.notes}</Text>
        {customFieldsArray}
        <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
          <Button
            buttonStyle={commonStyles.buttonRow}
            containerStyle={{ width: "30%" }}
            title={editButtonText}
            onPress={editPerson}
          />
          <Button
            buttonStyle={commonStyles.buttonDeleteRow}
            containerStyle={{ width: "30%" }}
            title={deleteButtonText}
            onPress={deletePerson}
          />
          {/* <Button
        buttonStyle={commonStyles.buttonRow}
        type="outline"
        title={listPersonsButtonText}
        onPress={listPersons}
      />*/}
        </View>
      </Card>
      <EventComponent personState={personState} />
      <BillsComponent personState={personState} />

      <NotesComponent personState={personState} />

      <GroupsListComponent personState={personState} />

      <TasksListComponent personState={personState} />
    </>
  );
};
