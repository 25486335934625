import React, { useEffect, useState } from "react";
import * as NavUtils from "../../utils/NavUtils";
import * as DBUtils from "../../utils/DBUtils";
import * as UserPermissions from "../../utils/UserPermissions";
import PersonsSelectionScreen from "../../components/person/PersonsSelectionScreen";

export default function TaskMemberSelectionScreen({ navigation, route }) {
  const taskId = route.params?.taskId;
  async function showClientList() {
    const isNotCofirmed = await UserPermissions.isNotConfirmed();
    if (isNotCofirmed) {
      setErrors(
        "User not confirmed. Ask Owner or Admin to check the user status on Users Screen."
      );
      return;
    }
    const clients = await DBUtils.getClients(undefined);
    if (taskId !== undefined) {
      //important as id on taskclient table is uuid for n-n relationship and NOT taskId
      const members = await DBUtils.getMembersForTaskId(taskId);
      members?.forEach((item) => {
        selectedClients.set(item.client.id, item.client);
      });
      setSelected(selectedClients);
    }
    //order is important to have setClients after setSelected for render to be called.
    setClients(clients);
  }

  async function seed() {
    const isNotCofirmed = await UserPermissions.isNotConfirmed();
    if (isNotCofirmed) {
      setErrors(
        "User not confirmed. Ask Owner or Admin to check the user status on Users Screen."
      );
      return;
    }
    const retMap = new Map();
    const retArray = [];
    if (taskId !== undefined) {
      //important as id on tsdkclient table is uuid for n-n relationship and NOT groupId
      const members = await DBUtils.getMembersForTaskId(taskId);
      members?.forEach((item) => {
        retMap.set(item.client.id, item.client);
        retArray.push(item.client);
      });
    }
    return [retMap, retArray];
  }

  return (
    <>
      <PersonsSelectionScreen
        navigation={navigation}
        seed={seed}
        forwardTo={async (who) => {
          //save selected
          const taskClientMembers = await DBUtils.getMembersForTaskId(taskId);
          const taskClientMembersMap = new Map();
          taskClientMembers?.forEach(async (taskClient) => {
            if (who.get(taskClient.client.id) === undefined) {
              //delete
              //**** DISASTER IF YOU DELETE  CLIENT DATA. CAREFUL to delete only taskclient and NOT client */
              await DBUtils.deleteTaskClient(taskClient);
            } //db entry not found in selected list- so delete
            taskClientMembersMap.set(taskClient.client.id, taskClient.client);
          });
          //find insert ones
          const task = await DBUtils.getTask(taskId); //here primay key is same as taskid

          who.forEach(async (client, keyClientId, map) => {
            if (taskClientMembersMap.get(keyClientId) === undefined) {
              //insert new one in relationship
              await DBUtils.saveTaskClient(task, client);
            }
          });
          NavUtils.showViewTaskScreen(navigation, taskId);
        }}
        nextButtonTitle="Save"
      />
    </>
  );
}
