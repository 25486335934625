import { Auth, DataStore } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { ScrollView, Text, View } from "react-native";
import { SubmitAndCancelButtons } from "../../components/ButtonComponents";
import { EulaTracker } from "../../src/models";
import { EulaAccepted } from "../../src/models";
import { getGroupNameAsTenant } from "../../utils/getGroupNameAsTenant";
import { Provider, Paragraph, Dialog, Portal } from "react-native-paper";
import { commonStyles } from "../../styles/commonStyles";
import * as Linking from "expo-linking";
import { Button } from "react-native-elements";

export function ReturnsScreen({ navigation, route }) {
  return (
    <>
      <View style={{ margin: 10, borderWidth: 1, borderColor: "gray" }}>
        <Text style={{ margin: 5, color: "red" }}>
          * Cancellation/Return policy
        </Text>

        <Text style={commonStyles.pageNotes}>
          You can cancel subscription anytime to avoid future renewals. Free
          trial provides all the features and ample time so you can decide
          before purchasing. Hence, subscription for current period is
          non-refundable. You can cancel anytime from the website or by pressing
          Contact Us link below. Grow your business now by building lifelong
          relationships!
        </Text>
        <Button
          type="clear"
          title="Contact Us"
          onPress={() =>
            //<a href="mailto:test@example.com?subject=Testing out mailto!&body=This is only a test!">Second Example</a>
            Linking.openURL(
              "mailto:support@siyamiapps.com?subject=Cancel Subscription- Cloud CRM for Client Records&body=Hi Team, Please cancel my subscription from next cycle onwards. Here is my order or invoice id: ( type your message on the screen here) "
            )
          }
        />
      </View>
    </>
  );
}
