/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://mttxz6wmdfh7nha3moawiwrmga.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "PromoCodeAPI",
            "endpoint": "https://b2obu9ijug.execute-api.us-east-1.amazonaws.com/prodi",
            "region": "us-east-1"
        },
        {
            "name": "stripeAPI",
            "endpoint": "https://09ynb8ukr5.execute-api.us-east-1.amazonaws.com/prodi",
            "region": "us-east-1"
        },
        {
            "name": "webSubStatusAPI",
            "endpoint": "https://ztbhpz867e.execute-api.us-east-1.amazonaws.com/prodi",
            "region": "us-east-1"
        },
        {
            "name": "appleRAPI",
            "endpoint": "https://vwwbm3mkdb.execute-api.us-east-1.amazonaws.com/prodi",
            "region": "us-east-1"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-1:e0201009-e503-4d62-b0f5-7327be89e9d6",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_0IfaeQdek",
    "aws_user_pools_web_client_id": "6elvff0a2rpj2bccpdrh9sadav",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": "8",
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "PromoCodesTable-prodi",
            "region": "us-east-1"
        },
        {
            "tableName": "WebSubscriptionStatusTable-prodi",
            "region": "us-east-1"
        }
    ]
};


export default awsmobile;
