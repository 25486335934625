import * as NavUtils from "../utils/NavUtils";
import React, { useState, useEffect } from "react";
import { Input, Button } from "react-native-elements";
import { commonStyles } from "../styles/commonStyles";
import { View } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { FormType } from "../src/models";

export const SubmitAndCancelButtons = ({
  navigation = undefined,
  submit,
  cancel = undefined,
  titleSubmit = "Submit",
  titleCancel = "Cancel",
  flexDirection = "column",
}) => {
  if (navigation === undefined) {
    navigation = useNavigation();
  }
  if (cancel === undefined) {
    cancel = () => {
      NavUtils.showClientListScreen(navigation, FormType.CLIENT);
    };
  }

  return (
    <View
      style={{ flexDirection: flexDirection, justifyContent: "space-between" }}
    >
      <Button
        data-testid="cancel"
        containerStyle={[
          commonStyles.buttonRow,
          { width: flexDirection === "row" ? "30%" : "100%" },
        ]}
        type="outline"
        title={titleCancel}
        onPress={cancel}
      />
      <SubmitButton
        title={titleSubmit}
        navigation={navigation}
        submit={submit}
        width={flexDirection === "row" ? "30%" : "100%"}
      />
    </View>
  );
};

export const BackButton = ({ navigation, back }) => {
  if (back === undefined) {
    back = () => {
      navigation.goBack();
    };
  }
  return (
    <>
      <Button
        data-testid="back"
        style={commonStyles.buttonRow}
        type="outline"
        title="Back"
        onPress={back}
      />
    </>
  );
};
export const SubmitButton = ({
  title = "Submit",
  navigation,
  submit,
  width = "100%",
}) => {
  return (
    <>
      <Button
        data-testid="submit"
        containerStyle={[commonStyles.buttonRow, { width: width }]}
        title={title}
        onPress={submit}
      />
    </>
  );
};

export const LinkButton = ({
  title = "Go Back",
  navigation,
  submit,
  width = "100%",
}) => {
  return (
    <>
      <Button
        buttonStyle={[
          commonStyles.buttonLinkBack,
          { justifyContent: "flex-start", marginTop: 5 },
        ]}
        titleStyle={{ fontSize: 14, fontWeight: "bold" }}
        containerStyle={[
          commonStyles.buttonContainerStyleLinkBankRow,
          { width: width },
        ]}
        title={title}
        onPress={submit}
        type="clear"
      />
    </>
  );
};
