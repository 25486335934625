import React, { useState, useEffect } from "react";
import { FormType, Part } from "../../../src/models/index";
import { DataStore } from "@aws-amplify/datastore";
import { commonStyles } from "../../../styles/commonStyles";
import * as NavUtils from "../../../utils/NavUtils";
import {
  LinkButton,
  SubmitAndCancelButtons,
} from "../../../components/ButtonComponents";
import * as Utils from "../../../utils/Utils";
import * as DBUtils from "../../../utils/DBUtils";
import { getGroupNameAsTenant } from "../../../utils/getGroupNameAsTenant";
import { Card, Input } from "react-native-elements";
import CustomDatePicker from "../../../components/CustomDatePicker.web";
import { View, Text, ScrollView } from "react-native";

export const AddEditPartComponent = ({ navigation, route }) => {
  const [refresh, doRefresh] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [form, updateForm] = useState({
    name: "",
    details: "",
    unitPrice: "",
    quantityAvailable: "",
    createdOn: new Date(),
  });
  const [currency, setCurrency] = useState("");
  //getLoggedInUsersOwnerDBDetails for currency
  const partId = route.params?.partId;
  useEffect(() => {
    async function loadPart() {
      if (partId !== undefined) {
        //in the first render of view parent, its empty so do not waste going thru loop
        const part = await DataStore.query(Part, partId);
        let cur = await Utils.getCurrency();
        setCurrency(cur ?? "");
        updateForm({
          name: part?.name,
          details: part?.details,
          unitPrice: part?.unitPrice,
          quantityAvailable: part?.quantityAvailable,
          createdOn: new Date(part?.createdOn),
        });
        setEditMode(true);
      } else {
        //default for add new part  are already default
      }
    }
    loadPart();
    return () => {
      //cleanup
    };
  }, [!refresh]);
  // second render time - parent has id due to its own useeffect
  //but need to check that id go undefined to some_id so this componenet can render

  async function saveData() {
    const tenant = await getGroupNameAsTenant();
    var part;
    if (!editMode) {
      //mark flag is used so we dont create new entry but update existing as state doesn't change yet
      // if(__DEV__) console.log("in save of event new");
      part = await DataStore.save(
        new Part({
          tenant: tenant,
          name: form.name,
          details: form.details,
          unitPrice: form.unitPrice,
          quantityAvailable: isNaN(parseFloat(form.quantityAvailable))
            ? 0
            : parseFloat(form.quantityAvailable),
          createdOn: new Date(form.createdOn).toISOString(),
        })
      );
    } else {
      const partsOriginal = await DataStore.query(Part, partId);
      if (partsOriginal !== undefined)
        part = await DataStore.save(
          Part.copyOf(partsOriginal, (updated) => {
            const returnedTarget = Object.assign(updated, {
              ...form,
              createdOn: new Date(form.createdOn).toISOString(),
              quantityAvailable: isNaN(parseFloat(form.quantityAvailable))
                ? 0
                : parseFloat(form.quantityAvailable),
            });
          })
        );
    }
    return part;
  }

  async function submit() {
    var part = await saveData();
    NavUtils.showViewPartScreen(navigation, part.id);
  }
  const cancel = () => {
    if (route.params?.partId === undefined) {
      //if on add, then history is removed so go back to list screen as goBack() does not work
      NavUtils.showPartsInventoryScreen(navigation);
    } else {
      //View screen is cancel screen from edit
      NavUtils.showViewPartScreen(navigation, route.params?.partId);
    }
  };

  return (
    <ScrollView>
      <Card containerStyle={commonStyles.mainCardContainerWithThickBorder}>
        <Card.Title style={{ marginTop: 8 }}></Card.Title>
        {/* // <Overlay */}
        <Card
          containerStyle={{
            padding: 4,
            margin: 0,
            backgroundColor: "#ebedf0",
            borderWidth: 3,
            borderColor: "black",
            zIndex: 5,
          }}
        >
          <CustomDatePicker
            selected={form.createdOn}
            onChange={(date) => updateForm({ ...form, createdOn: date })}
          />
          <Text
            style={[
              commonStyles.textFieldName,
              { marginLeft: 0, marginBottom: 4 },
            ]}
          >
            Part Name
          </Text>
          <Input
            containerStyle={{ marginTop: 4 }}
            testID="name"
            maxLength={200}
            value={form.name}
            placeholder="Name of the Part"
            onChangeText={(e) => updateForm({ ...form, name: e })}
          />
          <Text
            style={[
              commonStyles.textFieldName,
              { marginLeft: 0, marginBottom: 4 },
            ]}
          >
            Unit Price
          </Text>
          <Input
            keyboardType="decimal-pad"
            containerStyle={{ marginTop: 4 }}
            testID="unitPrice"
            maxLength={100}
            value={form.unitPrice}
            placeholder="Unit Price of the Part"
            onChangeText={(e) =>
              // setAmountDue(Dinero({ amount: parseInt(e) }))
              updateForm({ ...form, unitPrice: e })
            }
          />
          <Text
            style={[
              commonStyles.textFieldName,
              { marginLeft: 0, marginBottom: 4 },
            ]}
          >
            Available Quantity
          </Text>
          <Input
            keyboardType="decimal-pad"
            containerStyle={{ marginTop: 4 }}
            testID="quantityAvailable"
            maxLength={20}
            value={form.quantityAvailable}
            placeholder="Available Quantity"
            onChangeText={(e) =>
              // setAmountDue(Dinero({ amount: parseInt(e) }))
              updateForm({ ...form, quantityAvailable: e })
            }
          />
          <Text
            style={[
              commonStyles.textFieldName,
              { marginLeft: 0, marginBottom: 4 },
            ]}
          >
            Part Details
          </Text>
          <Input
            containerStyle={{ marginTop: 4 }}
            testID="details"
            numberOfLines={4}
            maxLength={10000}
            multiline={true}
            value={form.details}
            placeholder="Details of the Part"
            onChangeText={(e) => updateForm({ ...form, details: e })}
          />

          <SubmitAndCancelButtons
            submit={async () => await submit()}
            cancel={async () => cancel()}
            flexDirection="row"
          />
        </Card>
        {/* // </Overlay> */}
      </Card>
    </ScrollView>
  );
};
