import { FlatList, TouchableOpacity, Text, View } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import { Input, Button, Card } from "react-native-elements";
import { styles } from "../../App";
import React, { useState, useEffect } from "react";
import { Client, AddUserToT, FormType } from "../../src/models/index";
import { DataStore } from "@aws-amplify/datastore";
import { Auth } from "aws-amplify";
import { getGroupNameAsTenant } from "../../utils/getGroupNameAsTenant";
import { Hub } from "aws-amplify";
import * as UserPermissions from "../../utils/UserPermissions";
import * as NavUtils from "../../utils/NavUtils";
import * as Utils from "../../utils/Utils";
import { commonStyles } from "../../styles/commonStyles";
import * as AppColors from "../../styles/AppColors";
import { ScrollView } from "react-native-gesture-handler";
import ErrorMessageComponent from "../../components/ErrorMessageComponent";
import { LinkButton } from "../../components/ButtonComponents";
import { loadStripe } from "@stripe/stripe-js";
import { ReturnsScreen } from "../agreements/ReturnsScreen";

const successUrl =
  Utils.getBaseURL() + "/order?session_id={CHECKOUT_SESSION_ID}";
const cancelUrl = Utils.getBaseURL(); // "http://localhost:19006/LoggedInHomeScreenName/showCart", //make this work as better experience
const strip_pk = Utils.isDevEnv()
  ? "pk_test_51KAgDbLEO4KdHO437y7TWDLztHHvevXqqjaoYMT21gRqwbnI1dkrOz49Nj7sq8IYcfhNs43JsVPKHOaDexWhjQOy00anPToqdL"
  : "pk_live_51KAgDbLEO4KdHO43oTXly3baI7uzCVZTsGBQTLIbeGJlPJs4e1OmeRnSftmiceCYqNhQVwqFgiwqYmQC3OP5MpXl00uLklH46u";

export const CartScreen = ({ navigation, route }) => {
  const [errors, setErrors] = useState("");
  //this even is called everytime - first time after login there is nothing to show until load above gets data
  useEffect(() => {
    (async () => {})();
    return () => {
      //cleanup
    };
  }, []);

  const PurchaseButton = ({ price }) => {
    return (
      <View style={{ marginBottom: 10 }}>
        <Button
          containerStyle={commonStyles.buttonRow}
          title="Select"
          onPress={async () => {
            const stripe = await loadStripe(strip_pk);
            const { error } = await stripe?.redirectToCheckout({
              lineItems: [
                {
                  price: price,
                  quantity: 1,
                },
              ],
              mode: "subscription",
              //  success_url: "http://yoursite.com/order/success?session_id={CHECKOUT_SESSION_ID}",
              successUrl: successUrl,
              cancelUrl: cancelUrl,
            });
            if (__DEV__) console.log("Stripe=" + error);
          }}
        />
      </View>
    );
  };
  return (
    <SafeAreaView style={commonStyles.pageContainer}>
      {errors.length > 0 && <ErrorMessageComponent errorMsg={errors} />}
      <>
        <LinkButton
          title={`< Back to Client List`}
          submit={() =>
            NavUtils.showPersonListScreen(navigation, FormType.CLIENT)
          }
        />
        <ScrollView>
          <ReturnsScreen navigation={navigation} route={route} />
          <Card containerStyle={commonStyles.mainCardContainerWithThickBorder}>
            <Card.Title style={{ marginTop: 8 }}>1 User Plan</Card.Title>

            <PurchaseButton
              price={
                Utils.isDevEnv()
                  ? "price_1KBt8lLEO4KdHO435CwSThPe"
                  : "price_1KBvioLEO4KdHO43PvCjkpPi"
              }
            />
            <>
              <Card
                containerStyle={{
                  padding: 4,
                  marginBottom: 8,
                  // backgroundColor: "",
                }}
              >
                <Text style={{ marginBottom: 4 }}>Renews Yearly</Text>
                <Text
                  style={{ fontSize: 16, fontWeight: "bold", marginBottom: 4 }}
                >
                  USD 90 per year
                </Text>

                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginBottom: 4,
                  }}
                ></View>
              </Card>
            </>
          </Card>

          <Card containerStyle={commonStyles.mainCardContainerWithThickBorder}>
            <Card.Title style={{ marginTop: 8 }}>5 User Plan</Card.Title>
            <PurchaseButton
              price={
                Utils.isDevEnv()
                  ? "price_1KAmowLEO4KdHO43OFJV5tEX"
                  : "price_1KBviuLEO4KdHO43ZXe9jUNW"
              }
            />
            <>
              <Card
                containerStyle={{
                  padding: 4,
                  marginBottom: 8,
                  // backgroundColor: "",
                }}
              >
                <Text style={{ marginBottom: 4 }}>Renews Yearly</Text>
                <Text
                  style={{ fontSize: 16, fontWeight: "bold", marginBottom: 4 }}
                >
                  USD 225 per year
                </Text>

                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginBottom: 4,
                  }}
                ></View>
              </Card>
            </>
          </Card>

          <Card containerStyle={commonStyles.mainCardContainerWithThickBorder}>
            <Card.Title style={{ marginTop: 8 }}>10 User Plan</Card.Title>
            <PurchaseButton
              price={
                Utils.isDevEnv()
                  ? "price_1KBtClLEO4KdHO434DwqWHqn"
                  : "price_1KBvizLEO4KdHO43jndcjV3i"
              }
            />
            <>
              <Card
                containerStyle={{
                  padding: 4,
                  marginBottom: 8,
                  // backgroundColor: "",
                }}
              >
                <Text style={{ marginBottom: 4 }}>Renews Yearly</Text>
                <Text
                  style={{ fontSize: 16, fontWeight: "bold", marginBottom: 4 }}
                >
                  USD 350 per year
                </Text>

                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginBottom: 4,
                  }}
                ></View>
              </Card>
            </>
          </Card>

          <Card containerStyle={commonStyles.mainCardContainerWithThickBorder}>
            <Card.Title style={{ marginTop: 8 }}>25 User Plan</Card.Title>
            <PurchaseButton
              price={
                Utils.isDevEnv()
                  ? "price_1KBtF2LEO4KdHO43oWdQFyw6"
                  : "price_1KBvj6LEO4KdHO43UIZEvlMH"
              }
            />
            <>
              <Card
                containerStyle={{
                  padding: 4,
                  marginBottom: 8,
                  // backgroundColor: "",
                }}
              >
                <Text style={{ marginBottom: 4 }}>Renews Monthly</Text>
                <Text
                  style={{ fontSize: 16, fontWeight: "bold", marginBottom: 4 }}
                >
                  USD 100 per month
                </Text>

                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginBottom: 4,
                  }}
                ></View>
              </Card>
            </>
          </Card>

          <Card containerStyle={commonStyles.mainCardContainerWithThickBorder}>
            <Card.Title style={{ marginTop: 8 }}>100 User Plan</Card.Title>
            <PurchaseButton
              price={
                Utils.isDevEnv()
                  ? "price_1KBtGSLEO4KdHO43DUxMDD11"
                  : "price_1KBvihLEO4KdHO43T8mzKHuC"
              }
            />
            <>
              <Card
                containerStyle={{
                  padding: 4,
                  marginBottom: 8,
                  // backgroundColor: "",
                }}
              >
                <Text style={{ marginBottom: 4 }}>Renews Monthly</Text>
                <Text
                  style={{ fontSize: 16, fontWeight: "bold", marginBottom: 4 }}
                >
                  USD 300 per month
                </Text>

                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginBottom: 4,
                  }}
                ></View>
              </Card>
            </>
          </Card>
        </ScrollView>
      </>
    </SafeAreaView>
  );
};
