import { DataStore, Predicates, SortDirection } from "@aws-amplify/datastore";
import React, { useEffect, useState } from "react";
import {
  BulkProtocolType,
  Client,
  EmailHistory,
  EmailToType,
  EmailType,
} from "../../src/models/index";
import * as NavUtils from "../../utils/NavUtils";
import * as UserPermissions from "../../utils/UserPermissions";
import PersonsSelectionScreen from "../../components/person/PersonsSelectionScreen";
import { RestoreOutlined } from "@material-ui/icons";
import { getGroupNameAsTenant } from "../../utils/getGroupNameAsTenant";
import { useConfirmation } from "../../components/confirmation/ConfirmationService";
import * as Utils from "../../utils/Utils";

export default function EmailPersonsSelectionScreen({ navigation, route }) {
  const emailId = route.params?.emailId;
  const bulkProtocolType = route.params?.bulkProtocolType
    ? route.params?.bulkProtocolType
    : BulkProtocolType.EMAIL;
  const confirm = useConfirmation();

  async function seed() {
    const retMap = new Map();
    const retArray = [];
    const preSelected = await DataStore.query(Client, Predicates.ALL, {
      sort: (s) => s.firstName(SortDirection.ASCENDING),
    });
    if (emailId !== undefined) {
      const email = await DataStore.query(EmailHistory, emailId);
      //O(n) issues for large emails... Use Map BUT may be not since DB recipients string is being concatenated check and not json object array
      preSelected.forEach((item) => {
        if (email.recipients?.includes(item.id)) {
          retMap.set(item.id, item);
          retArray.push(item);
        }
      });
    }
    Utils.checkAndShowTrialEndsSoonDialog(
      false,
      navigation,
      confirm,
      undefined
    );
    return [retMap, retArray];
  }

  async function saveData(who) {
    const emailOriginal = await DataStore.query(EmailHistory, emailId);

    const recipientIds = [];
    for (let value of who.values()) {
      recipientIds.push(value.id);
    }
    let groupForTenant = await getGroupNameAsTenant();
    let result;
    // IMPORTANT - if undefined goes in DB, Search stops working..
    let postData = {
      subject: emailOriginal?.subject ? emailOriginal.subject : "",
      body: emailOriginal?.body ? emailOriginal.body : "",
      emailType: EmailType.DRAFT,
      recipients: JSON.stringify(recipientIds),
      sentAt: new Date().toISOString(),
      toType: emailOriginal?.toType ? emailOriginal.toType : EmailToType.BCC,
      bulkProtocolType: bulkProtocolType,
    };
    //avoids true groupname for tenant when null
    if (groupForTenant !== null)
      postData = { ...postData, tenant: groupForTenant };

    if (route.params?.emailId === undefined) {
      result = await DataStore.save(new EmailHistory(postData));
    } else {
      result = await DataStore.save(
        EmailHistory.copyOf(emailOriginal, (updated) => {
          const returnedTarget = Object.assign(updated, postData);
        })
      );
    }

    return result;
  }

  return (
    <>
      <PersonsSelectionScreen
        navigation={navigation}
        seed={seed}
        forwardTo={async (who) => {
          const result = await saveData(who);
          NavUtils.showEmailComposeScreen(
            navigation,
            who,
            result.id,
            bulkProtocolType
          );
        }}
        cancel={() =>
          NavUtils.showEmailHistoryScreen(navigation, bulkProtocolType)
        }
      />
    </>
  );
}
