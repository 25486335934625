import { StyleSheet, View, Platform } from "react-native";
import React from "react";
import { blueGrey } from "@material-ui/core/colors";
import { StatusBar } from "expo-status-bar";
import * as AppColors from "./AppColors";
import * as Utils from "../utils/Utils";

const textFieldValue = {
  fontSize: 14,
  marginTop: 8,
  // marginLeft: 0,
  color: "gray",
};
const marginTop = Utils.isIOS() ? 0 : StatusBar.currentHeight || 0;
export const commonStyles = StyleSheet.create({
  pageContainer: {
    flex: 1,
    padding: 4,
    marginTop: marginTop,
    //  marginTop:{     Platform.OS === "android" ?
  },
  pageContainerMain: {
    flex: 1,
    padding: 4,
    marginTop: StatusBar.currentHeight || 0, //may need 8 instead of 0. Keep same for IOS to show status bar
  },
  pageHeader: {
    // marginTop: -16,
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    height: 48,
    width: "100%",
    backgroundColor: AppColors.ACCENT,
  },
  appIcon: {
    margin: 0,
    height: 48,
    width: 48,
  },
  pageHeaderName: {
    fontSize: 24,
    fontWeight: "bold",
  },
  mainMenuButton: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: 10,
  },
  mainMenuOptionButtons: {
    height: 32,
    width: 32,
  },
  pageSubHeader: {
    fontSize: 20,
    fontWeight: "bold",
    marginBottom: 4,
  },
  pageSubHeaderSmall: {
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: 4,
  },
  pageSubHeaderMedium: {
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: 4,
    marginLeft: 4,
    marginTop: 4,
  },
  textRow: {
    fontSize: 16,
    marginBottom: 8,
  },
  buttonRow: {
    marginBottom: 8,
    marginTop: 8,
  },
  buttonContainerStyleLinkBankRow: {
    marginBottom: 0,
    marginTop: 0,
    padding: 0,
  },
  buttonRow4M: {
    marginBottom: 4,
    marginTop: 4,
  },
  buttonRowTop: {
    marginBottom: 8,
    marginTop: 16,
  },
  buttonDeleteRow: {
    marginBottom: 8,
    marginTop: 8,
    backgroundColor: "#bb0000",
  },
  buttonLinkBack: {
    marginBottom: 0,
    marginTop: 0,
    padding: 0,
  },
  listTextMainRow: {
    fontSize: 16,
    fontWeight: "bold",
    marginVertical: 0,
  },
  listTextMainRowNotBold: {
    fontSize: 16,
    marginVertical: 0,
  },
  listTextSubRow: {
    fontSize: 14,
    color: "gray",
    marginVertical: 2,
  },
  rowItem: {
    padding: 8,
    marginVertical: 0,
    marginHorizontal: 0,
  },
  textFieldName: {
    fontSize: 16,
    fontWeight: "bold",
    marginTop: 8,
    marginLeft: 8,
  },
  textFieldValue: textFieldValue,
  textFieldValueDark: {
    ...textFieldValue,
    color: "black",
  },
  mainCardContainer: {
    padding: 0,
    paddingLeft: 4,
    margin: 0,
    // marginTop: 0,// doesnt seem to work so adding view of 8 for margin
    // marginLeft: 0,
    // marginRight: 0,
    // marginBottom: 8,
  },
  mainCardContainerWithThickBorder: {
    padding: 0,
    paddingLeft: 4,
    paddingRight: 4,

    marginTop: 0,
    marginLeft: 0,
    marginRight: 0,
    // marginTop: 0,// doesnt seem to work so adding view of 8 for margin
    // marginLeft: 0,
    // marginRight: 0,
    marginBottom: 8,
    borderWidth: 1,
    borderColor: "darkgray",
  },
  pageNotes: {
    color: "#666666",
    fontSize: 14,
    margin: 4,
  },
  criticalErrorStyle: {
    color: "red",
    fontSize: 20,
  },
});
