import React from "react";
import { commonStyles } from "../styles/commonStyles";
import { Text } from "react-native";

const ErrorMessageComponent = (props) => {
  return (
    <>
      <Text style={commonStyles.criticalErrorStyle}>{props.errorMsg}</Text>
    </>
  );
};
export default ErrorMessageComponent;
