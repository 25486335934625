import { FlatList, TouchableOpacity, Text, View } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import { Input, Button } from "react-native-elements";
import { styles } from "../../App";
import React, { useState, useEffect } from "react";
import { Client, AddUserToT, RolesDefault } from "../../src/models/index";
import { DataStore } from "@aws-amplify/datastore";
import { Auth } from "aws-amplify";
import { getGroupNameAsTenant } from "../../utils/getGroupNameAsTenant";
import * as NavUtils from "../../utils/NavUtils";
import { ScrollView } from "react-native-gesture-handler";
import { commonStyles } from "../../styles/commonStyles";
import * as Strings from "../../resources/Strings";
import { AddUserToTenantStatus } from "../../src/models";

export const AddUserScreen = ({ navigation, route }) => {
  //props needed for going to given screen using navigation
  const [form, updateForm] = useState({
    firstName: "",
    lastName: "",
    cellPhone: "",
    userEmail: "",
    address: "",
    otherData: "",
    notes: "",
    daysFree: 7, // not really used for subuser as owner props drive
    userRole: RolesDefault.READ_WRITE, // default all users are read write currently until we do picker or button group for admin, rw, ro
  });
  const [pd, setPd] = useState("");
  const [cpd, setCpd] = useState("");

  const [errors, setErrors] = useState("");

  async function addNewUser() {
    const existingUser = await DataStore.query(AddUserToT, (c) =>
      c.userEmail("eq", form.userEmail.trim())
    );
    if (existingUser !== undefined && existingUser.length > 0) {
      setErrors(
        "User already exists with this email. Use different email or delete existing user"
      );
      return;
    }
    if (
      !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        form.userEmail
      )
    ) {
      setErrors(
        "Please enter valid format for Email Address. This email will be used by user to login to the App"
      );
      return;
    }
    if (pd.length < 8) {
      setErrors("Password length is less than 8. Please enter longer password");
      return;
    }
    if (pd !== cpd) {
      setErrors(
        "Password and Confirm Password fields do not match. Please match and try again."
      );
      return;
    }
    //check if user exists in cognito directory and ask to use different email if so
    try {
      const { userEmail } = form;
      var groupForTenant = await getGroupNameAsTenant();
      const { cognitoUser } = await Auth.signUp({
        username: userEmail,
        password: pd, //make input box
        //following started failing most likely after amplify update? or some cognito directory change for email or username validation
        // validationData: [
        //   {
        //     Name: "username",
        //     Value: 'swatirpharande@gmail.com',
        //   },
        // ],
      });
      if (__DEV__) console.log("cognito user created", cognitoUser);

      const user = await DataStore.save(
        new AddUserToT({
          ...form,
          tenant: groupForTenant, //or tenant like other models? check lambdas too
          userStatus: AddUserToTenantStatus.UNCONFIRMED,
        })
      );
      NavUtils.showViewUserScreen(navigation, user.id);
      setErrors("");
    } catch (error) {
      console.error("sign up error", error);
      setErrors(JSON.stringify(error, null, 2));
    }
  }

  return (
    <SafeAreaView style={commonStyles.pageContainer}>
      <ScrollView>
        {errors.length > 0 && (
          <Text style={{ margin: 10, color: "red" }}>{errors}</Text>
        )}

        <Text>* Email is used as username for login.</Text>
        <Input
          maxLength={100}
          value={form.userEmail}
          placeholder="User Email to be used for login"
          onChangeText={(e) => updateForm({ ...form, userEmail: e.trim() })}
          keyboardType="email-address"
        />

        <Text>* Password must be 8 characters or more.</Text>

        <Input
          maxLength={100}
          value={pd}
          placeholder="Enter Password"
          onChangeText={(e) => setPd(e)}
          secureTextEntry
        />
        <Text>* Confirm Password must match Password above.</Text>

        <Input
          maxLength={100}
          value={cpd}
          placeholder="Confirm Password"
          onChangeText={(e) => setCpd(e)}
          secureTextEntry
        />
        <Input
          maxLength={100}
          value={form.firstName}
          placeholder="First Name"
          onChangeText={(e) => updateForm({ ...form, firstName: e.trim() })}
        />
        <Input
          maxLength={100}
          value={form.lastName}
          placeholder="Last Name"
          onChangeText={(e) => updateForm({ ...form, lastName: e.trim() })}
        />
        <Input
          maxLength={100}
          value={form.cellPhone}
          placeholder="Cell Phone"
          onChangeText={(e) => updateForm({ ...form, cellPhone: e.trim() })}
          keyboardType="numeric"
        />
        <View style={{ margin: 10, borderWidth: 1, borderColor: "gray" }}>
          <Text style={{ margin: 10, color: "red" }}>*Important Note</Text>

          <Text style={commonStyles.pageNotes}>{Strings.unConfirmedHelp}</Text>
        </View>
        <Button title="Submit" onPress={() => addNewUser()} />
        <Button
          type="outline"
          title="Cancel"
          containerStyle={{ marginTop: 10 }}
          onPress={() => NavUtils.showUserListScreen(navigation)}
        />
      </ScrollView>
    </SafeAreaView>
  );
};
