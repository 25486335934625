import { FlatList, TouchableOpacity, Text, View } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import { Input, Button, Card } from "react-native-elements";
import React, { useState, useEffect } from "react";
import { Group, GroupClient } from "../../src/models/index";
import { DataStore } from "@aws-amplify/datastore";
import { commonStyles } from "../../styles/commonStyles";
import * as NavUtils from "../../utils/NavUtils";
import { ScrollView } from "react-native-gesture-handler";
import { LinkButton } from "../../components/ButtonComponents";
import * as Utils from "../../utils/Utils";
import { useConfirmation } from "../../components/confirmation/ConfirmationService";

export const ViewGroupScreen = ({ navigation, route }) => {
  const confirm = useConfirmation();

  //props needed for going to given screen using navigation
  const [groupState, setGroupState] = useState({
    groupName: "",
    groupPurpose: "",
    numberOfMembers: 0,
  });
  const groupId = route.params?.groupId
    ? route.params.groupId
    : "baee685c-c020-4a2a-a6a4-f46caca16643";

  async function updatePageState() {
    const groupOriginal = await DataStore.query(Group, groupId);
    const groupMembers = (await DataStore.query(GroupClient)).filter(
      (g) => g.group.id === groupId
    );

    setGroupState({ ...groupOriginal, numberOfMembers: groupMembers.length });
  }

  useEffect(() => {
    (async () => {
      await updatePageState();
    })();
  }, []);

  async function showEditGroup() {
    NavUtils.showAddEditGroupScreen(navigation, groupId);
  }

  async function deleteGroup() {
    await Utils.areYouSureDialog(confirm, async () => {
      const groupToDelete = await DataStore.query(Group, groupId);
      await DataStore.delete(groupToDelete);
      NavUtils.showGroupListScreen(navigation);
    });
  }
  function showGroupMemberSelectionScreen(): void {
    NavUtils.showGroupMemberSelectionScreen(navigation, groupId);
  }
  return (
    <SafeAreaView style={commonStyles.pageContainer}>
      <LinkButton
        title="< Back to Group List"
        submit={() => NavUtils.showGroupListScreen(navigation)}
      />
      <ScrollView style={{ margin: 0, marginTop: 10, padding: 0 }}>
        <Card containerStyle={commonStyles.mainCardContainer}>
          <Text style={{ marginBottom: 4 }}>{groupState.groupName}</Text>
          <Text style={{ marginBottom: 4 }}>{groupState.groupPurpose}</Text>

          <View style={{ flexDirection: "row", marginBottom: 8 }}>
            <Text
              style={{ marginTop: 4, marginRight: 8 }}
            >{`Members: ${groupState.numberOfMembers}`}</Text>
            <Button
              buttonStyle={{ margin: 0, padding: 0 }}
              // containerStyle={{ width: "30%" }}
              containerStyle={{ margin: 0, marginTop: 2, padding: 0 }}
              titleStyle={{ fontSize: 16 }}
              title="Update Members"
              onPress={() => showGroupMemberSelectionScreen()}
              type="clear"
            />
          </View>

          <Button
            buttonStyle={commonStyles.buttonRow}
            // containerStyle={{ width: "30%" }}
            title="Edit"
            onPress={() => showEditGroup()}
          />
          <Button
            buttonStyle={commonStyles.buttonDeleteRow}
            // containerStyle={{ width: "30%" }}
            title="Delete"
            onPress={() => deleteGroup()}
          />
        </Card>
      </ScrollView>
    </SafeAreaView>
  );
};
