import { Auth, DataStore } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { ScrollView, Text, View } from "react-native";
import { SubmitAndCancelButtons } from "../../components/ButtonComponents";
import { EulaTracker } from "../../src/models";
import { EulaAccepted } from "../../src/models";
import { getGroupNameAsTenant } from "../../utils/getGroupNameAsTenant";
import { Provider, Paragraph, Dialog, Portal } from "react-native-paper";
import { commonStyles } from "../../styles/commonStyles";
import * as Linking from "expo-linking";
import { Button } from "react-native-elements";

export function TryFreeNotesScreen({ navigation, route }) {
  return (
    <>
      <View
        style={{
          margin: 10,
          borderWidth: 1,
          borderColor: "gray",
          backgroundColor: "white",
        }}
      >
        <Text style={{ margin: 5, color: "red", fontWeight: "bold" }}>
          * No Payment needed
        </Text>

        <Text style={[commonStyles.pageNotes, { color: "black" }]}>
          No Card or Payment needed during trial. You can try free for 15 days.
          If you have Promo or Voucher Code, then trial period will be longer
          depending on the Code you enter. To enter code, first create free
          account now. After logging in, chose the Menu Option titled 'Enter
          Promo Code'. For any questions press Contact Us link below. Grow your
          business now by building lifelong relationships!
        </Text>
        <Button
          type="clear"
          title="Contact Us"
          onPress={() =>
            //<a href="mailto:test@example.com?subject=Testing out mailto!&body=This is only a test!">Second Example</a>
            Linking.openURL(
              "mailto:support@siyamiapps.com?subject=Pricing, Trial - Cloud CRM for Client Records&body=Hi Team, Please provide more info: ( type your message on the screen here) "
            )
          }
        />
      </View>
    </>
  );
}
