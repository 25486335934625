import { DataStore } from "@aws-amplify/datastore";
import * as MailComposer from "expo-mail-composer";
import React, { useEffect, useState } from "react";
import { Text } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import { Button, Input } from "react-native-elements";
import { ScrollView } from "react-native-gesture-handler";
import { styles } from "../../App";
import {
  LinkButton,
  SubmitAndCancelButtons,
  SubmitButton,
} from "../../components/ButtonComponents";
import {
  getCustomFieldsFromDB,
  initialStateForPersonForm,
  PersonFormComponent,
} from "../../components/person/PersonFormComponent";
import { BulkProtocolType, Client, FormType } from "../../src/models/index";
import { commonStyles } from "../../styles/commonStyles";
import { getGroupNameAsTenant } from "../../utils/getGroupNameAsTenant";
import * as NavUtils from "../../utils/NavUtils";
import { EmailHistory, EmailType } from "../../src/models/index";
import { RestoreRounded } from "@material-ui/icons";
import { useConfirmation } from "../../components/confirmation/ConfirmationService";
import * as Utils from "../../utils/Utils";

export default function EmailViewScreen({ navigation, route }) {
  const confirm = useConfirmation();

  //props needed for going to given screen using navigation
  const [email, setEmail] = useState(undefined);
  const [refreshFlag, setRefreshFlag] = useState(true);

  const emailId = route.params?.emailId;
  const bulkProtocolType = route.params?.bulkProtocolType
    ? route.params?.bulkProtocolType
    : BulkProtocolType.EMAIL;

  async function updatePageState() {
    const email = await DataStore.query(EmailHistory, emailId);
    setEmail(email);
  }

  function refresh() {
    setRefreshFlag(!refreshFlag);
  }

  useEffect(() => {
    (async () => {
      await updatePageState();
    })();
  }, [refreshFlag]);

  const deleteEmail = async () => {
    await Utils.areYouSureDialog(confirm, async () => {
      const recordToDelete = await DataStore.query(
        EmailHistory,
        route.params.emailId
      );
      await DataStore.delete(recordToDelete);
      NavUtils.showEmailHistoryScreen(navigation, bulkProtocolType);
    });
  };
  const cancel = () => {
    navigation.goBack();
  };
  async function resendOrEditDraft() {
    if (email.emailType === EmailType.SENT) {
      const emailSource = await DataStore.query(EmailHistory, emailId);
      const emailToResend = await DataStore.save(new EmailHistory(emailSource));
      NavUtils.showEmailClientsSelectionScreen(
        navigation,
        emailToResend.id,
        bulkProtocolType
      );
    } else {
      NavUtils.showEmailClientsSelectionScreen(
        navigation,
        emailId,
        bulkProtocolType
      );
    }
  }
  return (
    <SafeAreaView style={commonStyles.pageContainer}>
      <LinkButton
        title={`< Back to ${bulkProtocolType} List`}
        // submit={() =>
        //   NavUtils.showEmailHistoryScreen(navigation, bulkProtocolType)
        // }
        submit={cancel}
      />
      {email !== undefined && (
        <ScrollView>
          <Text style={commonStyles.pageSubHeader}>{email.subject}</Text>
          <Text style={commonStyles.textRow}>
            {`${email.emailType} to ${
              JSON.parse(email.recipients)?.length
            }  recipients on ${new Date(email.sentAt).toLocaleString()}`}
          </Text>
          <Text style={commonStyles.textRow}>{email.body}</Text>
          <SubmitButton
            navigation={navigation}
            submit={() => resendOrEditDraft()}
            title={
              email.emailType === EmailType.SENT
                ? `Re-Send ${bulkProtocolType}`
                : `Edit Draft`
            }
          />
          <Button
            buttonStyle={commonStyles.buttonDeleteRow}
            title={`Delete ${bulkProtocolType} Record`}
            onPress={deleteEmail}
          />
        </ScrollView>
      )}
    </SafeAreaView>
  );
}
