import {
  FlatList,
  View,
  TouchableOpacity,
  Text,
  ActivityIndicator,
  Platform,
  Image,
} from "react-native";
// import * as Linking from "expo-linking";
import { SafeAreaView } from "react-native-safe-area-context";
import { MaterialIcons } from "@expo/vector-icons";
import { Button } from "react-native-elements";
import React, { useState, useEffect } from "react";
import { DataStore, Predicates, SortDirection } from "@aws-amplify/datastore";
import * as NavUtils from "../../../utils/NavUtils";
import { commonStyles } from "../../../styles/commonStyles";
import * as AppColors from "../../../styles/AppColors";
import * as Utils from "../../../utils/Utils";
import { SearchBar } from "react-native-elements";
import { ScrollView } from "react-native-gesture-handler";
import ErrorMessageComponent from "../../../components/ErrorMessageComponent";
import * as DBUtils from "../../../utils/DBUtils";
import { ZenObservable } from "zen-observable-ts";
// import { Page, PageHeader } from "../../../screens/LoggedInHomeScreen";
import { useConfirmation } from "../../../components/confirmation/ConfirmationService";
import { FormType, Part } from "../../../src/models";
// import { LinkButton } from "../components/ ButtonComponents";
// import Dinero from "dinero.js";
import * as MailComposer from "expo-mail-composer";
import * as Linking from "expo-linking";
import { Subject } from "@material-ui/icons";
import { LinkButton } from "../../../components/ButtonComponents";

const isPaidUser = Utils.isPlanActive();
export const PartsInventoryScreen = ({ navigation, route }) => {
  const [selectedId, setSelectedId] = useState(null);
  const [parts, setParts] = useState([]);
  const [search, setSearch] = useState("");
  const [errors, setErrors] = useState("");
  const [isReady, setIsReady] = useState(false);
  const [isImageReady, setIsImageReady] = useState(false);
  const [refresh, doRefresh] = useState(false);
  const [freeTrialOver, setFreeTrialOver] = useState(false);
  const [forceShowMenu, setForceShowMenu] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [currency, setCurrency] = useState("");

  const confirm = useConfirmation();

  async function showPartsInventory() {
    const parts = await DataStore.query(Part, Predicates.ALL, {
      sort: (s) => s.createdOn(SortDirection.DESCENDING),
    });
    setParts(parts);
    let cur = await Utils.getCurrency();
    setCurrency(cur ?? "");
    await Utils.checkAndShowTrialEndsSoonDialog(
      true,
      navigation,
      confirm,
      setFreeTrialOver
    );
    setIsReady(true);
    if (parts?.length === 0)
      setErrors(
        "No Parts Found. Enter Parts by pressing Add Part button on this page"
      );
    else setErrors("");
  }

  useEffect(() => {
    const s = async () => await showPartsInventory();
    s();
    return () => {};
  }, [refresh]); //unused refresh but check if issues - will be used for pull to refresh later with refreshcontrol

  const Item = ({ item, onPress, style }) => {
    return (
      <TouchableOpacity onPress={onPress} style={[commonStyles.rowItem, style]}>
        <Text style={[commonStyles.listTextMainRow, { marginBottom: 2 }]}>
          {item.name}
        </Text>

        {item.unitPrice && (
          <Text style={[commonStyles.listTextSubRow, { marginTop: 2 }]}>
            {(currency ? currency + " " : "") + item.unitPrice}
          </Text>
        )}

        {item.quantityAvailable > 0 && (
          <Text style={[commonStyles.listTextSubRow, { marginTop: 2 }]}>
            {`Available ${item.quantityAvailable} `}
          </Text>
        )}
      </TouchableOpacity>
    );
  };

  const renderItem = ({ item, index }) => {
    const backgroundColor =
      index % 2 === 0 ? AppColors.ROW_BRIGHT : AppColors.ROW_AFTER_BRIGHT;
    return (
      <Item
        item={item}
        onPress={() => {
          setSelectedId(item.id);
          NavUtils.showViewPartScreen(navigation, item.id);
        }}
        style={{ backgroundColor: backgroundColor }}
      />
    );
  };

  const updateSearch = async (search) => {
    setSearch(search);
    const internalSearch = search.toLowerCase();
    const all = await DataStore.query(Part);
    const searchResult = all.filter((b) => {
      return b.name?.toLowerCase().includes(internalSearch)
        ? true
        : b.details?.toLowerCase().includes(internalSearch)
        ? true
        : b.unitPrice?.toLowerCase().includes(internalSearch)
        ? true
        : false;
    });

    setParts(searchResult);
  };
  const showContent = isPaidUser || !freeTrialOver;
  return (
    <SafeAreaView style={commonStyles.pageContainer}>
      {errors.length > 0 && <ErrorMessageComponent errorMsg={errors} />}

      {/* {isSearching && <ActivityIndicator size="large" />} */}
      {/* {!(errors.length > 0) && ( */}
      <>
        <LinkButton
          title={`< Back to Client List`}
          submit={() =>
            NavUtils.showPersonListScreen(navigation, FormType.CLIENT)
          }
        />
        <Button
          testID="AddParts"
          containerStyle={commonStyles.buttonRowTop}
          title="Add Part"
          onPress={() => NavUtils.showAddEditPartScreen(navigation, undefined)}
        />
        {!isSearching && (
          <SearchBar
            placeholder="Type Parts name or details or unit price"
            onChangeText={updateSearch}
            value={search}
            lightTheme={true}
            inputStyle={{ color: "black" }}
            containerStyle={{ padding: 0, marginTop: 12 }}
            inputContainerStyle={{ backgroundColor: "ultralighgray" }}
          />
        )}
        {!isSearching && (
          <ScrollView>
            <FlatList
              data={parts}
              renderItem={renderItem}
              keyExtractor={(item) => item.id}
              extraData={selectedId}
            />
          </ScrollView>
        )}
      </>
      {/* )} */}
    </SafeAreaView>
  );
};
