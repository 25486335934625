import { DataStore } from "@aws-amplify/datastore";
import React, { useEffect, useState } from "react";
import { Text, View, Modal } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import { Card, CheckBox, Input, Overlay } from "react-native-elements";
import { ScrollView } from "react-native-gesture-handler";
import { SubmitAndCancelButtons } from "../../components/ButtonComponents";
import CustomDatePicker from "../../components/CustomDatePicker.web";
import { Task, TaskStatus } from "../../src/models/index";
import { commonStyles } from "../../styles/commonStyles";
import { getGroupNameAsTenant } from "../../utils/getGroupNameAsTenant";
import * as NavUtils from "../../utils/NavUtils";
import { format, compareAsc } from "date-fns";

export const AddEditTaskScreen = ({ navigation, route }) => {
  //props needed for going to given screen using navigation
  const [form, updateForm] = useState({
    taskName: "",
    taskPurpose: "",
    dueDate: undefined,
    shortDueDate: undefined,
    status: TaskStatus.NOT_DONE,
    timezoneOffset: new Date().getTimezoneOffset(),
  });
  const [dueDateDisplay, setDueDateDisplay] = useState(undefined);
  const [startDate, setStartDate] = useState(new Date());
  const [viewMode, setViewMode] = useState(true);

  async function updatePageState() {
    if (route.params?.taskId === undefined) {
    } else {
      const taskOriginal = await DataStore.query(Task, route.params.taskId);
      updateForm({
        ...form,
        ...taskOriginal,
      });
    }
  }

  useEffect(() => {
    (async () => {
      await updatePageState();
    })();
  }, []);

  async function saveData() {
    var groupForTenant = await getGroupNameAsTenant();
    var task;
    var postData = {
      ...form,
    };
    //avoids true taskname for tenant when null
    if (groupForTenant !== null) postData = { ...form, tenant: groupForTenant };
    if (route.params?.taskId === undefined) {
      task = await DataStore.save(new Task(postData));
    } else {
      const taskOriginal = await DataStore.query(Task, route.params.taskId);
      task = await DataStore.save(
        Task.copyOf(taskOriginal, (updated) => {
          const returnedTarget = Object.assign(updated, postData);
        })
      );
    }
    return task;
  }
  async function submit() {
    setViewMode(false);

    var task = await saveData();
    NavUtils.showViewTaskScreen(navigation, task.id);
  }
  const cancel = () => {
    setViewMode(false);
    if (route.params?.taskId === undefined) {
      //if on add, then history is removed so go back to list screen as goBack() does not work
      NavUtils.showTaskListScreen(navigation);
    } else {
      //View screen is cancel screen from edit
      NavUtils.showViewTaskScreen(navigation, route.params?.taskId);
    }
  };
  return (
    <SafeAreaView style={commonStyles.pageContainer}>
      <SubmitAndCancelButtons
        submit={async () => await submit()}
        cancel={() => cancel()}
        flexDirection="row"
      />
      <Card
        containerStyle={{
          padding: 4,
          margin: 0,
          backgroundColor: "#ebedf0",
          borderWidth: 3,
          // borderColor: "black",
          zIndex: 5,
        }}
      >
        <Input
          containerStyle={{ marginTop: 4 }}
          testID="taskName"
          maxLength={100}
          value={form.taskName}
          placeholder="Type Task Name"
          onChangeText={(e) => updateForm({ ...form, taskName: e })}
        />
        <View
          style={{
            flexDirection: "column",
          }}
        >
          <Text
            style={[
              commonStyles.textFieldName,
              { marginTop: 4, padding: 10, marginLeft: 0 },
            ]}
          >
            Due Date
          </Text>

          <CustomDatePicker
            selected={form.dueDate ? new Date(form.dueDate) : undefined}
            onChange={(date) =>
              updateForm({
                ...form,
                dueDate: date === null ? undefined : date.toISOString(),
                shortDueDate:
                  date === null ? undefined : format(date, "yyyy-MM-dd"),
              })
            }
            placeholder="Tap to select due date"
            isClearable={true}
          />
          <Input
            containerStyle={{ marginTop: 4 }}
            testID="purpose"
            numberOfLines={4}
            maxLength={1000}
            multiline={true}
            value={form.taskPurpose}
            placeholder="Type purpose"
            onChangeText={(e) => updateForm({ ...form, taskPurpose: e })}
          />
          <View
            style={{
              flexDirection: "row",
            }}
          >
            <Text
              style={[
                commonStyles.textFieldName,
                { marginTop: 4, padding: 10, marginLeft: 0 },
              ]}
            >
              Status
            </Text>

            <CheckBox
              containerStyle={{
                //   width: "40%",
                marginTop: 4,
                marginLeft: 16,
                padding: 10,
                // paddingBottom: 8,
                //    backgroundColor: AppColors.ROW_AFTER_BRIGHT,
                borderWidth: 0,
              }}
              checked={form.status === TaskStatus.DONE ? true : false}
              onPress={() =>
                updateForm({
                  ...form,
                  status:
                    form.status === TaskStatus.DONE
                      ? TaskStatus.NOT_DONE
                      : TaskStatus.DONE,
                })
              }
            />
          </View>
        </View>
      </Card>
    </SafeAreaView>
  );
};
