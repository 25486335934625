import React, { useEffect, useState } from "react";
import * as NavUtils from "../../utils/NavUtils";
import * as DBUtils from "../../utils/DBUtils";
import * as UserPermissions from "../../utils/UserPermissions";
import PersonsSelectionScreen from "../../components/person/PersonsSelectionScreen";

export default function GroupMemberSelectionScreen({ navigation, route }) {
  const [errors, setErrors] = useState("");

  const groupId = route.params?.groupId;
  async function seed() {
    const isNotCofirmed = await UserPermissions.isNotConfirmed();
    if (isNotCofirmed) {
      setErrors(
        "User not confirmed. Ask Owner or Admin to check the user status on Users Screen."
      );
      return;
    }
    const retMap = new Map();
    const retArray = [];
    if (groupId !== undefined) {
      //important as id on groupclient table is uuid for n-n relationship and NOT groupId
      const members = await DBUtils.getMembersForGroupId(groupId);
      members?.forEach((item) => {
        retMap.set(item.client.id, item.client);
        retArray.push(item.client);
      });
    }
    return [retMap, retArray];
  }

  return (
    <>
      <PersonsSelectionScreen
        navigation={navigation}
        seed={seed}
        forwardTo={async (who) => {
          //save selected
          const groupClientMembers = await DBUtils.getMembersForGroupId(
            groupId
          );
          const groupClientMembersMap = new Map();
          groupClientMembers?.forEach(async (groupClient) => {
            if (who.get(groupClient.client.id) === undefined) {
              //delete
              //**** DISASTER IF YOU DELETE  CLIENT DATA. CAREFUL to delete only groupclient and NOT client */
              await DBUtils.deleteGroupClient(groupClient);
            } //db entry not found in selected list- so delete
            groupClientMembersMap.set(
              groupClient.client.id,
              groupClient.client
            );
          });
          //find insert ones
          const group = await DBUtils.getGroup(groupId); //here primay key is same as groupid

          who.forEach(async (client, keyClientId, map) => {
            if (groupClientMembersMap.get(keyClientId) === undefined) {
              //insert new one in relationship
              await DBUtils.saveGroupClient(group, client);
            }
          });
          NavUtils.showViewGroupScreen(navigation, groupId);
        }}
        nextButtonTitle="Save"
      />
    </>
  );
}
