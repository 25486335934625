import { useNavigation } from "@react-navigation/native";
import { DataStore, Predicates, SortDirection } from "aws-amplify";
import React, { useEffect, useState } from "react";
import {
  ActivityIndicator,
  FlatList,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import {
  Button,
  ButtonGroup,
  CheckBox,
  SearchBar,
} from "react-native-elements";
import { ScrollView } from "react-native-gesture-handler";
import { Client, EmailHistory, FormType } from "../../src/models";
import * as AppColors from "../../styles/AppColors";
import { commonStyles } from "../../styles/commonStyles";
import * as UserPermissions from "../../utils/UserPermissions";

export default function PersonsSelectionScreen({
  navigation,
  seed,
  forwardTo,
  nextButtonTitle = "Next",
  cancel = () => navigation.goBack(),
}) {
  const [errors, setErrors] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [searchPerson, setSearchPerson] = useState("");
  const [selectedPerson, setSelectedPerson] = useState(new Map());

  const [selectedIndexes, setSelectedIndexes] = useState([]);
  const buttons = [
    FormType.CLIENT,
    FormType.LEAD,
    FormType.STAFF,
    FormType.SUPPLIER,
  ];
  const [filteredPersons, setFilteredPersons] = useState([]);
  // const navigation = useNavigation();

  useEffect(() => {
    (async () => {
      //execute seed and set selected
      const [retMap, retArray] = await seed();
      //set selected map used to set checkboxes in Item component during render
      setSelectedPerson(retMap);
      //select group buttons
      const selectedGroups = [];
      retArray.some((item) => {
        switch (item.formType) {
          case FormType.CLIENT:
            if (!selectedGroups.includes(0)) selectedGroups.push(0);
            break;
          case FormType.LEAD:
            if (!selectedGroups.includes(1)) selectedGroups.push(1);
            break;
          case FormType.STAFF:
            if (!selectedGroups.includes(2)) selectedGroups.push(2);
            break;
          case FormType.SUPPLIER:
            if (!selectedGroups.includes(3)) selectedGroups.push(3);
            break;
          default:
            //default continue theu all elements
            break;
        }
        return selectedGroups.length === 4; //once array is full stop iterating
      });
      setSelectedIndexes([...selectedGroups]);
      //show items in the group that are selected by groupbutton
      //But then second useeffect is supposed to do that exactly - once setIndexes is set.
      // so no need to do that again
      // const preSelected = await DataStore.query(Client, Predicates.ALL, {
      //   sort: (s) => s.firstName(SortDirection.ASCENDING),
      // });
      // setFilteredPersons([...preSelected]);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      await showFilteredResults();
    })();
  }, [
    searchPerson,
    // selectedFormTypeClient,
    // selectedFormTypeLead,
    // selectedFormTypeStaff,
    // selectedFormTypeSupplier,
    selectedIndexes,
    //    selected,
  ]);

  //may want to remove from useeffect and trigger on search typed or button group pressed
  const showFilteredResults = async () => {
    const searchResult = await DataStore.query(Client, (c) =>
      c.or((c) =>
        c
          .firstName("contains", searchPerson)
          .lastName("contains", searchPerson)
          .cellPhone("contains", searchPerson)
          .email("contains", searchPerson)
          .address("contains", searchPerson)
          .notes("contains", searchPerson)
          //to prevent custom field names matching search. This does exact match though as a result compromise
          .customFields("contains", '"value"' + ':"' + searchPerson + '"')
      )
    );
    //filter group
    let selectClients = false,
      selectLeads = false,
      selectStaff = false,
      selectSuppliers = false;

    if (selectedIndexes.includes(0)) selectClients = true;
    if (selectedIndexes.includes(1)) selectLeads = true;
    if (selectedIndexes.includes(2)) selectStaff = true;
    if (selectedIndexes.includes(3)) selectSuppliers = true;
    const filterPersons = searchResult.filter((item: { formType: any }) => {
      switch (item.formType) {
        case FormType.CLIENT: {
          return selectClients;
        }
        case FormType.LEAD: {
          return selectLeads;
        }
        case FormType.STAFF: {
          return selectStaff;
        }
        case FormType.SUPPLIER: {
          return selectSuppliers;
        }
        default: {
          return true; //default no filter- return all
        }
      }
    });
    setFilteredPersons([...filterPersons]);
  };

  const Item = ({ item, onPress, style }) => (
    <TouchableOpacity onPress={onPress} style={[commonStyles.rowItem, style]}>
      <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
        <View>
          <Text style={commonStyles.listTextMainRow}>
            {item.firstName + " " + item.lastName}
          </Text>
          <Text style={commonStyles.listTextSubRow}>{item.email}</Text>
          <Text style={commonStyles.listTextSubRow}>{item.cellPhone}</Text>
          <Text style={commonStyles.listTextSubRow}>{item.address}</Text>
        </View>
        <CheckBox
          containerStyle={{
            //   width: "40%",
            marginBottom: 0,
            //    backgroundColor: AppColors.ROW_AFTER_BRIGHT,
            borderWidth: 0,
          }}
          checked={selectedPerson.has(item.id)}
          onPress={onPress}
        />
      </View>
    </TouchableOpacity>
  );

  const renderItem = ({ item, index }) => {
    const backgroundColor =
      index % 2 === 0 ? AppColors.ROW_BRIGHT : AppColors.ROW_AFTER_BRIGHT;
    return (
      <Item
        item={item}
        //  onPress={() => onItemPress(item)}
        onPress={() => {
          if (!selectedPerson.has(item.id)) {
            selectedPerson.set(item.id, item);
          } else {
            selectedPerson.delete(item.id);
          }
          setSelectedPerson(selectedPerson);
          //follwing line is needed even though technically clients has not changed
          //it seems FlatList has some check to see if clients changed and call render only if it did.
          setFilteredPersons([...filteredPersons]);
        }}
        style={{ backgroundColor: backgroundColor }}
      />
    );
  };

  return (
    <SafeAreaView style={commonStyles.pageContainer}>
      {errors.length > 0 && <Text style={{ color: "red" }}>{errors}</Text>}
      {isSearching && <ActivityIndicator size="large" />}
      <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
        <Button
          testID="Cancel"
          type="outline"
          containerStyle={commonStyles.buttonRowTop}
          title="Cancel"
          onPress={() => cancel()}
        />
        <Button
          testID="Next"
          containerStyle={commonStyles.buttonRowTop}
          title={nextButtonTitle}
          onPress={async () => {
            forwardTo(selectedPerson);
          }}
        />
      </View>
      <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
        <Text style={{ marginBottom: 4 }}>Filter Display By</Text>
      </View>
      <ButtonGroup
        onPress={(indexes) => setSelectedIndexes(indexes)}
        selectedIndexes={selectedIndexes}
        buttons={buttons}
        selectMultiple={true}
        //containerStyle={{ marginBottom: 10 }}
      />
      {searchPerson && (
        <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
          <Text
            style={{ marginBottom: 4 }}
          >{` ${filteredPersons.length} Matched`}</Text>
        </View>
      )}
      <SearchBar
        placeholder="Type name phone address or email"
        onChangeText={(text) => setSearchPerson(text)}
        value={searchPerson}
        lightTheme={true}
        inputStyle={{ color: "black" }}
        containerStyle={{ padding: 0 }}
        inputContainerStyle={{ backgroundColor: "ultralighgray" }}
      />
      <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
        <Text
          style={[
            commonStyles.listTextMainRow,
            { marginTop: 10 },
            { color: selectedPerson.size > 0 ? "black" : "red" },
          ]}
        >
          {selectedPerson.size} selected
        </Text>
        <Button
          testID="SelectAll"
          containerStyle={commonStyles.buttonRowTop}
          title="Select All"
          onPress={() => {
            filteredPersons?.forEach((item) =>
              selectedPerson.set(item.id, item)
            );
            setSelectedPerson(selectedPerson);
            //dummy
            setFilteredPersons([...filteredPersons]);
          }}
          type="clear"
        />
        <Button
          testID="ClearAll"
          containerStyle={commonStyles.buttonRowTop}
          title="Clear All"
          onPress={() => {
            filteredPersons?.forEach((item) => selectedPerson.delete(item.id));
            setSelectedPerson(selectedPerson);
            //dummy
            setFilteredPersons([...filteredPersons]);
          }}
          type="clear"
        />
      </View>
      <ScrollView>
        <FlatList
          data={filteredPersons}
          renderItem={renderItem}
          keyExtractor={(item) => item.id}
        />
      </ScrollView>
    </SafeAreaView>
  );
}

//  function updateIndex(selected) {
//    setSelectedIndexes(selected);

//    if (selected.includes(0)) setSelectedFormTypeClient(true);
//    else setSelectedFormTypeClient(false);

//    if (selected.includes(1)) setSelectedFormTypeLead(true);
//    else setSelectedFormTypeLead(false);

//    if (selected.includes(2)) setSelectedFormTypeStaff(true);
//    else setSelectedFormTypeStaff(false);

//    if (selected.includes(3)) setSelectedFormTypeSupplier(true);
//    else setSelectedFormTypeSupplier(false);
//  }

// const filterPersons = searchResult.filter((item: { formType: any }) => {
//   switch (item.formType) {
//     case FormType.CLIENT: {
//       return selectedFormTypeClient;
//     }
//     case FormType.LEAD: {
//       return selectedFormTypeLead;
//     }
//     case FormType.STAFF: {
//       return selectedFormTypeStaff;
//     }
//     case FormType.SUPPLIER: {
//       return selectedFormTypeSupplier;
//     }
//     default: {
//       return true; //default no filter- return all
//     }
//   }
// });

// const [selectedFormTypeClient, setSelectedFormTypeClient] = useState(true);
// const [selectedFormTypeLead, setSelectedFormTypeLead] = useState(false);
// const [selectedFormTypeStaff, setSelectedFormTypeStaff] = useState(false);
// const [selectedFormTypeSupplier, setSelectedFormTypeSupplier] = useState(
//   false
// );
