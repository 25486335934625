import { Image, View, Modal, Dimensions, ImageBackground } from "react-native";
import { Button, Card, Text, Input, Overlay } from "react-native-elements";
import React, { useState, useEffect } from "react";
import { commonStyles } from "../../styles/commonStyles";
import { ScrollView } from "react-native-gesture-handler";
import { Auth } from "aws-amplify";
import { Ionicons } from "@expo/vector-icons";
import { MaterialIcons } from "@expo/vector-icons";
import { FontAwesome5 } from "@expo/vector-icons";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import FeatureCRM from "./FeatureCRM";
import { LinkButton } from "../../components/ButtonComponents";
import * as Linking from "expo-linking";
import * as Utils from "../../utils/Utils";
import { LinearGradient } from "expo-linear-gradient";
import { ifBigScreen } from "../../utils/ifBigScreen";

export const LoggedOutHomeScreen = ({ navigation, route }) => {
  useEffect(() => {
    const showWhichHome = async () => {
      const user = await Auth.currentAuthenticatedUser();
      if (user) {
        navigation.navigate("LoggedInHomeScreenName");
        return;
      } else {
      }
    };
    showWhichHome();
    return () => {
      //cleanup
    };
  }, []);

  const windowWidth = Dimensions.get("window").width;
  const infoWrap = ifBigScreen ? "wrap" : "nowrap";
  const bgCards = "#f8faf8"; //"#f9f8f5"; //#faf9f7";
  const borderColorCards = "white";
  return (
    <ScrollView contentContainerStyle={{ flex: 1 }}>
      <ImageBackground
        source={require("../../assets/images/orange.jpg")}
        resizeMode="cover"
        style={{
          flex: 1,
          // justifyContent: "center",
          borderColor: "white",
          borderWidth: 0,
          height: "50%",
        }}
      >
        {ifBigScreen && (
          <View
            style={{
              // flex: 1, // this caused footer issues with color border or greay bg
              flexDirection: "row",
              alignItems: "center",
              padding: 5,
              marginVertical: 30,
              // justifyContent: "space-evenly",
              // flexWrap: "wrap",
            }}
          >
            <Text
              h3
              style={{
                marginLeft: 5,
                // marginTop: 30,
                // marginBottom: 20,
                marginRight: 50,
                fontSize: 20,
                fontWeight: "bold",
                // color: "#03adfc",
                color: "white",
                textAlign: "center", // <-- the magic
              }}
            >
              Cloud CRM
            </Text>
            <Button
              type="clear"
              style={{
                marginHorizontal: 10,
              }}
              buttonStyle={
                {
                  // backgroundColor: "#e67300",
                }
              }
              onPress={() => navigation.navigate("SignInScreenName")}
              title="Sign In"
              titleStyle={{ color: "white", fontWeight: "bold" }}
            />
            <Button
              type="outline"
              style={{
                // marginVertical: 10,
                marginHorizontal: 10,
                // width: 300,
              }}
              buttonStyle={{
                // backgroundColor: "#e67300",
                borderColor: "white",
                borderWidth: 4,
              }}
              onPress={() => navigation.navigate("SignUpScreenName")}
              title="Try Free - Create Account"
              titleStyle={{ color: "white", fontWeight: "bold" }}
              // containerStyle={{ borderColor: "white", borderWidth: 2 }}
            />
            {Utils.isWeb() && (
              <Button
                type="clear"
                style={{
                  marginHorizontal: 10,
                }}
                buttonStyle={
                  {
                    // backgroundColor: "#e67300",
                  }
                }
                onPress={() => navigation.navigate("PricingScreenName")}
                title="Pricing"
                titleStyle={{ color: "white", fontWeight: "bold" }}
              />
            )}
            <Button
              type="clear"
              title="Email Us"
              titleStyle={{ color: "white", fontWeight: "bold" }}
              onPress={() =>
                //<a href="mailto:test@example.com?subject=Testing out mailto!&body=This is only a test!">Second Example</a>
                Linking.openURL(
                  "mailto:support@siyamiapps.com?subject= More Info - Cloud CRM for Client Records&body=Hi Team, Please Please provide more information about Cloud CRM: ( type your message on the screen here) "
                )
              }
            />
          </View>
        )}
        {!ifBigScreen && (
          <>
            <View
              style={{
                // flex: 1, // this caused footer issues with color border or greay bg
                flexDirection: "row",
                alignItems: "center",
                padding: 5,
                marginVertical: 10,
                // justifyContent: "space-evenly",
                // flexWrap: "wrap",
              }}
            >
              <Text
                h3
                style={{
                  marginLeft: 5,
                  // marginTop: 30,
                  // marginBottom: 20,
                  marginRight: 50,
                  fontSize: 20,
                  fontWeight: "bold",
                  // color: "#03adfc",
                  color: "white",
                  textAlign: "center", // <-- the magic
                }}
              >
                Cloud CRM
              </Text>
            </View>
            <View
              style={{
                // flex: 1, // this caused footer issues with color border or greay bg
                flexDirection: "row",
                alignItems: "center",
                padding: 5,
                marginBottom: 10,
                // justifyContent: "space-evenly",
                // flexWrap: "wrap",
              }}
            >
              <Button
                type="clear"
                style={{
                  // marginVertical: 10,
                  marginHorizontal: 0,
                  // width: 300,
                }}
                buttonStyle={
                  {
                    // backgroundColor: "#e67300",
                  }
                }
                // onPress={() => navigation.navigate("LoggedInHomeScreenName")}
                onPress={() => navigation.navigate("SignInScreenName")}
                title="Sign In"
                titleStyle={{ color: "white", fontWeight: "bold" }}
              />
              <Button
                type="outline"
                style={{
                  // marginVertical: 10,
                  marginHorizontal: 5,
                  // width: 300,
                }}
                buttonStyle={{
                  // backgroundColor: "#e67300",
                  borderColor: "white",
                  borderWidth: 4,
                }}
                // onPress={() => navigation.navigate("LoggedInHomeScreenName")}
                onPress={() => navigation.navigate("SignUpScreenName")}
                title="Try Free - Create Account"
                titleStyle={{ color: "white", fontWeight: "bold" }}
                // containerStyle={{ borderColor: "white", borderWidth: 2 }}
              />
            </View>
          </>
        )}
        {!ifBigScreen && (
          <Image
            style={[
              {
                width: "100%",
                height: ifBigScreen ? windowWidth * 0.25 : windowWidth * 0.35,
                marginBottom: windowWidth * 0.35 * 0.1,
                zIndex: 5,
              },
            ]}
            resizeMode="contain"
            source={require("../../assets/feature/CloudCRMFeature.png")}
          />
        )}

        {ifBigScreen && (
          <View
            style={{
              // flex: 1,
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-evenly",
              margin: 20,
              marginBottom: 30,
              backgroundColor: "white",
              padding: 20,
            }}
          >
            <View
              style={{
                flex: 3,
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-evenly",
                margin: 0,
                marginBottom: 0,
                backgroundColor: "white",
                padding: 0,
                borderColor: "black",
                borderWidth: 0,
              }}
            >
              <Image
                style={[
                  {
                    // flex: 3,
                    width: "100%",
                    height: ifBigScreen
                      ? windowWidth * 0.25
                      : windowWidth * 0.35,
                    // marginBottom: windowWidth * 0.35 * 0.1,
                    // zIndex: 5,
                  },
                ]}
                resizeMode="contain"
                source={require("../../assets/feature/CloudCRMFeature.png")}
              />
              <Button
                type="outline"
                style={{
                  marginVertical: 10,
                  // marginHorizontal: 10,
                  width: "100%",
                  marginTop: 20,
                }}
                containerStyle={{
                  // borderColor: "#e67300",
                  // borderWidth: 4,
                  width: "50%",
                  // padding: 20,
                }}
                buttonStyle={{
                  // backgroundColor: "#e67300",
                  borderColor: "#e67300",
                  borderWidth: 4,
                }}
                onPress={() => navigation.navigate("SignUpScreenName")}
                title="Try CRM Free Now"
                titleStyle={{ color: "#e67300", fontWeight: "bold" }}
                // containerStyle={{ borderColor: "white", borderWidth: 2 }}
              />
            </View>
            <View
              style={{
                flex: 2,
                flexDirection: "column",
                alignItems: "stretch",
                justifyContent: "space-evenly",
              }}
            >
              <Card
                containerStyle={{
                  // flex: 1,
                  padding: 10,
                  backgroundColor: bgCards,
                  margin: 30,
                  // marginRight: 20,
                  marginTop: 0,
                  borderColor: borderColorCards,
                  borderWidth: 1,
                }}
              >
                <Text style={{ color: "black" }}>
                  Use Cloud CRM from anywhere on any device with a Browser or
                  from iOS and Android Apps.
                </Text>
              </Card>
              <Card
                containerStyle={{
                  // flex: 1,
                  padding: 10,
                  backgroundColor: bgCards,
                  borderColor: borderColorCards,
                  borderWidth: 1,
                  margin: 30,
                  // marginRight: 20,
                }}
              >
                <Text style={{ color: "black" }}>
                  Never miss your Tasks again with Cloud CRM Task Alerts on iOS
                  and Android Apps.
                </Text>
              </Card>
              <Card
                containerStyle={{
                  // flex: 1,
                  padding: 10,
                  backgroundColor: bgCards,
                  borderColor: borderColorCards,
                  borderWidth: 1,
                  margin: 30,
                  // marginRight: 20,
                }}
              >
                <Text style={{ color: "black" }}>
                  Build custom fields to match your business. Organize better by
                  grouping your customers.
                </Text>
              </Card>
              <Card
                containerStyle={{
                  // flex: 1,
                  padding: 10,
                  backgroundColor: bgCards,
                  borderColor: borderColorCards,
                  borderWidth: 1,
                  margin: 30,
                  // marginRight: 20,
                }}
              >
                <Text style={{ color: "black" }}>
                  Keep track of your Bills and Appointments. Use Bulk
                  Communication for marketing or service announcements.
                </Text>
              </Card>
              <Card
                containerStyle={{
                  // flex: 1,
                  padding: 10,
                  backgroundColor: bgCards,
                  borderColor: borderColorCards,
                  borderWidth: 1,
                  margin: 30,
                  // marginRight: 20,
                }}
              >
                <Text style={{ color: "black" }}>
                  Work from anywhere even when offline or when internet
                  connectivity is spotty.
                </Text>
              </Card>
            </View>
          </View>
        )}
        {!ifBigScreen && (
          <>
            <View
              style={{
                // flex: 1, // this caused footer issues with color border or greay bg
                flexDirection: "row",
                alignItems: "center",
                paddingLeft: 5,
                marginBottom: 10,
                // justifyContent: "space-evenly",
                // flexWrap: "wrap",
              }}
            >
              {Utils.isWeb() && (
                <Button
                  type="clear"
                  style={{
                    marginRight: 10,
                  }}
                  buttonStyle={
                    {
                      // backgroundColor: "#e67300",
                    }
                  }
                  onPress={() => navigation.navigate("PricingScreenName")}
                  title="Pricing"
                  titleStyle={{ color: "blue", fontWeight: "bold" }}
                />
              )}
              <Button
                type="clear"
                title="Email Us"
                titleStyle={{
                  color: "blue",
                  fontWeight: "bold",
                }}
                onPress={() =>
                  //<a href="mailto:test@example.com?subject=Testing out mailto!&body=This is only a test!">Second Example</a>
                  Linking.openURL(
                    "mailto:support@siyamiapps.com?subject= More Info - Cloud CRM for Client Records&body=Hi Team, Please Please provide more information about Cloud CRM: ( type your message on the screen here) "
                  )
                }
              />
            </View>
          </>
        )}

        <View
          style={{
            // flex: 1,
            // flexWrap: "wrap",
            backgroundColor: "white",
            alignItems: "center",
            // justifyContent: "flex-start",
            padding: 20,
            paddingTop: 0,
            margin: 20, //gives the appearance of floating bottom view as orange margin is seen until the height of the BG image
          }}
        >
          <View
            style={{
              // flex: 1,
              // flexWrap: "wrap",
              backgroundColor: "white",
              alignItems: "center",
              // justifyContent: "flex-start",
              padding: 20,
            }}
          >
            <Text
              style={{
                backgroundColor: "white",
                fontSize: 20,
                fontWeight: "bold",
                // padding: 20,
                // alignItems: "center",
                // flexWrap: "wrap",
              }}
            >
              Use Cloud CRM now to grow your business by building lifelong
              relationships!
            </Text>
          </View>
          <View
            style={{
              // flex: 1,
              flexDirection: ifBigScreen ? "row" : "column",
              // justifyContent: "flex-start",
              flexWrap: ifBigScreen ? "wrap" : "nowrap",
              backgroundColor: "white",
              paddingLeft: 10,
              // margin: 20,
            }}
          >
            <FeatureCRM
              fIcon={<MaterialIcons name="person-add" size={24} color="blue" />}
              fDesc={"Manage Clients and Contacts"}
            />

            <FeatureCRM
              fIcon={<MaterialIcons name="devices" size={24} color="green" />}
              fDesc={"Multiple Device Support"}
            />

            <FeatureCRM
              fIcon={
                <MaterialIcons
                  name="supervised-user-circle"
                  size={20}
                  color="black"
                />
              }
              fDesc={"Multiple Users Support"}
            />
            <FeatureCRM
              fIcon={
                <MaterialIcons name="monetization-on" size={20} color="green" />
              }
              fDesc={"Track Unpaid Bills"}
            />
            <FeatureCRM
              fIcon={
                <MaterialIcons name="calendar-today" size={20} color="red" />
              }
              fDesc={"Manage Appointments"}
            />
            <FeatureCRM
              fIcon={<MaterialIcons name="create" size={20} color="black" />}
              fDesc={"Create Product Catalog"}
            />
            <FeatureCRM
              fIcon={
                <MaterialIcons name="inventory" size={20} color="orange" />
              }
              fDesc={"Manage Parts Inventory"}
            />
            <FeatureCRM
              fIcon={<MaterialIcons name="groups" size={24} color="black" />}
              fDesc={"Manage Groups"}
            />
            <FeatureCRM
              fIcon={<MaterialIcons name="contacts" size={24} color="red" />}
              fDesc={"Manage Suppliers"}
            />
            <FeatureCRM
              fIcon={<MaterialIcons name="add-task" size={24} color="green" />}
              fDesc={"Create Tasks"}
            />
            <FeatureCRM
              fIcon={<MaterialIcons name="contacts" size={24} color="orange" />}
              fDesc={"Manage Leads"}
            />
            <FeatureCRM
              fIcon={
                <MaterialIcons name="outgoing-mail" size={24} color="blue" />
              }
              fDesc={"Bulk Email"}
            />
            <FeatureCRM
              fIcon={
                <MaterialIcons name="send-to-mobile" size={20} color="red" />
              }
              fDesc={"Bulk SMS"}
            />
            <FeatureCRM
              fIcon={<MaterialIcons name="contacts" size={24} color="green" />}
              fDesc={"Manage Staff "}
            />
            <FeatureCRM
              fIcon={<MaterialIcons name="event" size={24} color="red" />}
              fDesc={"Create Events"}
            />
          </View>
        </View>
        {ifBigScreen && (
          // <ScrollView horizontal={true}>
          <View
            style={{
              // flex: 1,
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-evenly",
              margin: 20,
              marginBottom: 30,
              backgroundColor: "white",
              padding: 20,
            }}
          >
            <Image
              style={[
                {
                  // flex: 3,
                  width: windowWidth * 0.3,
                  height: ifBigScreen ? windowWidth * 0.45 : windowWidth * 0.35,
                  // marginBottom: windowWidth * 0.35 * 0.1,
                  // zIndex: 5,
                  resizeMode: "contain",
                },
              ]}
              source={require("../../assets/images/View_Client_CRM.png")}
            />
            <Image
              style={[
                {
                  // flex: 3,
                  width: windowWidth * 0.3,
                  height: ifBigScreen ? windowWidth * 0.45 : windowWidth * 0.35,
                  // marginBottom: windowWidth * 0.35 * 0.1,
                  // zIndex: 5,
                  resizeMode: "contain",
                },
              ]}
              source={require("../../assets/images/Task_List_CRM.png")}
            />
            <Image
              style={[
                {
                  // flex: 3,
                  width: windowWidth * 0.3,
                  height: ifBigScreen ? windowWidth * 0.45 : windowWidth * 0.35,
                  // marginBottom: windowWidth * 0.35 * 0.1,
                  // zIndex: 5,
                  resizeMode: "contain",
                },
              ]}
              source={require("../../assets/images/Group_List_CRM.png")}
            />
          </View>
          // </ScrollView>
        )}
        {ifBigScreen && (
          // <ScrollView horizontal={true}>
          <View
            style={{
              // flex: 1,
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-evenly",
              margin: 20,
              marginBottom: 30,
              backgroundColor: "white",
              padding: 20,
            }}
          >
            <Image
              style={[
                {
                  // flex: 3,
                  width: windowWidth * 0.85,
                  height: ifBigScreen ? windowWidth * 0.55 : windowWidth * 0.35,
                  // marginBottom: windowWidth * 0.35 * 0.1,
                  // zIndex: 5,
                  resizeMode: "contain",
                  borderWidth: 1,
                  borderColor: "black",
                },
              ]}
              source={require("../../assets/images/ViewCustomerDesktop.png")}
            />
          </View>
        )}
        <View
          style={{
            flexDirection: "row",
            justifyContent: "center",
            marginVertical: 20,
          }}
        >
          <Button
            buttonStyle={{ margin: 10, padding: 0 }}
            titleStyle={{ fontSize: 12 }}
            // containerStyle={{ width: "30%" }}
            containerStyle={{ margin: 0, paddingTop: 4 }}
            title="Privacy"
            onPress={() => {
              navigation.navigate("PrivacyScreenName");
            }}
            type="clear"
          />
          {Utils.isIOS() && (
            <Button
              buttonStyle={{ margin: 10, padding: 0 }}
              titleStyle={{ fontSize: 12 }}
              // containerStyle={{ width: "30%" }}
              containerStyle={{ margin: 0, paddingTop: 4 }}
              title="Terms"
              onPress={() => {
                Linking.openURL(
                  "https://www.apple.com/legal/internet-services/itunes/dev/stdeula/"
                );
              }}
              type="clear"
            />
          )}
        </View>
      </ImageBackground>
    </ScrollView>
  );
};
