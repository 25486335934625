import { FlatList, TouchableOpacity, Text, View } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import { Input, Button, Card } from "react-native-elements";
import { styles } from "../../App";
import React, { useState, useEffect } from "react";
import {
  AddUserToT,
  AddUserToTenantStatus,
  FormType,
} from "../../src/models/index";
import { DataStore } from "@aws-amplify/datastore";
import { Auth } from "aws-amplify";
import { getGroupNameAsTenant } from "../../utils/getGroupNameAsTenant";
import * as NavUtils from "../../utils/NavUtils";
import { commonStyles } from "../../styles/commonStyles";
import * as UserPermissions from "../../utils/UserPermissions";
import ErrorMessageComponent from "../../components/ErrorMessageComponent";
import * as Utils from "../../utils/Utils";
import { RolesDefault } from "../../src/models";
import { ScrollView } from "react-native-gesture-handler";
import { LinkButton } from "../../components/ButtonComponents";

export const ProfileScreen = ({ navigation, route }) => {
  //props needed for going to given screen using navigation
  const [userState, setUserState] = useState(undefined);
  const [errors, setErrors] = useState("");

  async function updatePageState() {
    const loggedInEmailId = await Utils.getUserEmail();

    const matched = (
      await DataStore.query(AddUserToT, (u) =>
        u
          .userStatus("ne", AddUserToTenantStatus.DELETED)
          .userStatus("ne", AddUserToTenantStatus.DISABLED)
      )
    ).filter((u) => u.userEmail === loggedInEmailId);
    const userOriginal = matched[0]; //add validation to check N
    if (matched?.length > 1) {
      throw new Error(matched.length + " users for " + loggedInEmailId);
      return;
    }

    setUserState(userOriginal);
  }

  useEffect(() => {
    (async () => {
      await updatePageState();
    })();
  }, []);

  return (
    <SafeAreaView style={commonStyles.pageContainer}>
      {errors.length > 0 && <ErrorMessageComponent errorMsg={errors} />}
      <ScrollView>
        <LinkButton
          title={`< Back to Client List`}
          submit={() =>
            NavUtils.showPersonListScreen(navigation, FormType.CLIENT)
          }
        />
        {userState !== undefined && (
          <Card containerStyle={commonStyles.mainCardContainerWithThickBorder}>
            <Text style={commonStyles.pageSubHeader}>Owner Info</Text>
            <Text style={commonStyles.pageSubHeaderSmall}>
              Name : {`${userState.firstName} ${userState.lastName}`}
            </Text>

            <Text style={[commonStyles.textFieldValueDark]}>
              {userState.userEmail}
            </Text>
            <Text style={[commonStyles.textFieldValueDark]}>
              {`Status : ${userState.userStatus}`}
            </Text>
            <Text
              style={[commonStyles.textFieldValue]}
            >{`Role : ${userState.userRole}`}</Text>

            <Text style={[commonStyles.textFieldValue]}>
              Cell Phone : {userState.cellPhone}
            </Text>
            <Text style={[commonStyles.textFieldValue]}>
              Address : {userState.address}
            </Text>
            <Text style={[commonStyles.pageSubHeader, { marginTop: 8 }]}>
              Business Info
            </Text>
            <Text style={[commonStyles.pageSubHeaderSmall]}>
              Name : {userState.businessName}
            </Text>
            <Text style={[commonStyles.textFieldValue]}>
              Address : {userState.businessAddress}
            </Text>
            <Text style={[commonStyles.textFieldValue]}>
              Phone 1 :{userState.businessPhone1}
            </Text>
            <Text style={[commonStyles.textFieldValue]}>
              Phone 2 :{userState.businessPhone2}
            </Text>
            <Text style={[commonStyles.textFieldValue]}>
              Email : {userState.businessEmail}
            </Text>
            <Text style={[commonStyles.textFieldValue]}>
              Currency : {userState.businessCurrency}
            </Text>

            <Text style={[commonStyles.textFieldValue]}>
              {userState.businessNotes}
            </Text>
            <Text style={[commonStyles.textFieldValue]}>
              {userState.businessMission}
            </Text>
            <Text style={[commonStyles.textFieldValue]}>
              {userState.businessFacebook}
            </Text>
            <Text style={[commonStyles.textFieldValue]}>
              {userState.businessInstagram}
            </Text>
            <Text style={[commonStyles.textFieldValue]}>
              {userState.businessHours}
            </Text>

            <View
              style={{ flexDirection: "row", justifyContent: "space-between" }}
            >
              <Button
                buttonStyle={commonStyles.buttonRow}
                // containerStyle={{ width: "30%" }}
                title={
                  userState.userRole === RolesDefault.OWNER
                    ? "Edit Owner & Business Profile"
                    : "Edit Profile"
                }
                onPress={async () => {
                  const isOwner =
                    await UserPermissions.isCurrentlyLoggedInUserAnOwner();
                  if (!isOwner) {
                    setErrors(
                      "Only Owner role can perform this action. Please contact Owner"
                    );
                  } else {
                    NavUtils.showEditUserScreen(
                      navigation,
                      userState.id,
                      "ProfileDestination" //used to switch between update profile and update user for EditUser screen
                    );
                  }
                }}
              />
            </View>
          </Card>
        )}
        {/* <Button
        buttonStyle={commonStyles.buttonRow}
        title="Refresh Data From Cloud"
        onPress={() => {
          //location.reload();
          Utils.reload...
        }}
      /> */}
        <View style={{ margin: 10, borderWidth: 1, borderColor: "gray" }}>
          <Text style={{ margin: 10, color: "red" }}>*Important Note</Text>

          <Text style={commonStyles.pageNotes}>
            Log Out - Private Device button keeps copy of the cloud-app data
            locally on your device. This helps make App much faster and
            responsive. Follow security best practices on the device such as
            setting password and enrolling into MDM services etc.
          </Text>
          <Text style={commonStyles.pageNotes}>
            Log Out - Public or Shared Device button deletes the locally stored
            copy of the app data on the device. Your data still remains on the
            Cloud Servers. This is useful when you want to delete the data from
            the public or shared device.
          </Text>
        </View>
        <Button
          buttonStyle={commonStyles.buttonRow}
          //containerStyle={{ margin: 10 }}
          title="Log Out - Private Device"
          onPress={async () => {
            await Auth.signOut();
            Utils.refresh();
          }}
        />
        <Button
          buttonStyle={[commonStyles.buttonDeleteRow, { marginBottom: 60 }]}
          // containerStyle={{ marginBottom: 10 }}
          title="Log Out - Public Device"
          onPress={async () => {
            try {
              await DataStore.clear();
              if (__DEV__) console.log("Data clear in public logout ");

              await Auth.signOut();
              if (__DEV__) console.log("Auth signout in public logout ");
            } catch (error) {
              if (__DEV__) console.log("Error in public logout " + error);
            } finally {
              Utils.refresh();
              if (__DEV__) console.log("Refresh in public logout ");
            }
          }}
        />
      </ScrollView>
    </SafeAreaView>
  );
};
