import React, { forwardRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Modal } from "react-native";
import { Input, Overlay } from "react-native-elements";

// export default function CustomDatePicker({ selected, onChange }) {
//   return <DatePicker selected={selected} onChange={onChange} />;
// }

//basic below and above has same transparency issues
// export default function CustomDatePicker({ selected, onChange }) {
//   return (
//     <DatePicker
//       selected={selected}
//       onChange={onChange}
//       isClearable
//       // customInput={<ExampleCustomInput />}
//     />
//   );
// }

export default function CustomDatePicker({
  selected,
  onChange,
  placeholder = "Select Date",
  isClearable = false,
}) {
  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <Input
      testID="startDate"
      value={value}
      placeholder={placeholder}
      ref={ref}
      editable={true}
      onClick={onClick}
    />
  ));
  return (
    <DatePicker
      selected={selected}
      onChange={onChange}
      isClearable={isClearable}
      customInput={<ExampleCustomInput />}
    />
  );
}
