import { Text, View } from "react-native";
import { Input, Button, Card } from "react-native-elements";
import React, { useState, useEffect } from "react";
import { FormType, Notes } from "../../src/models/index";
import { DataStore } from "@aws-amplify/datastore";
import { commonStyles } from "../../styles/commonStyles";
import * as NavUtils from "../../utils/NavUtils";
import { SubmitAndCancelButtons } from "../ButtonComponents";
import * as DBUtils from "../../utils/DBUtils";
import { getGroupNameAsTenant } from "../../utils/getGroupNameAsTenant";
import CustomDatePicker from "../CustomDatePicker.web";
import * as Utils from "../../utils/Utils";

export const AddEditNotesComponent = ({ navigation, route }) => {
  const [refresh, doRefresh] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [form, updateForm] = useState({
    name: "",

    createdOn: new Date(),
  });
  const noteId = route.params?.noteId;
  const clientId = route.params?.clientId;
  useEffect(() => {
    async function loadNotes() {
      //const comments = (await DataStore.query(Comment)).filter(c => c.postID === "123");
      if (noteId !== undefined) {
        //in the first render of view parent, its empty so do not waste going thru loop
        const notessToShow = await DataStore.query(Notes, noteId);
        updateForm({
          name: notessToShow?.name,

          createdOn: new Date(notessToShow?.createdOn),
        });
        setEditMode(true);
        //ABOVE IS inefficient as ALL events of ALL clients wil be looped thru. We alredy have evetns in state
        //thanks to 1-many relationship
        //BUT one - many lazy loading is not working as below so going back to above solution for NOW
        // setEvents(personState.events);
      } else {
        //add new
      }
    }
    loadNotes();
    return () => {
      //cleanup
    };
  }, [!refresh]);
  // second render time - parent has id due to its own useeffect
  //but need to check that id go undefined to some_id so this componenet can render

  async function addOrUpdateNotes() {
    if (!editMode) {
      const tenant = await getGroupNameAsTenant();
      // if(__DEV__) console.log("in save of event new");
      await DataStore.save(
        new Notes({
          clientId: clientId,
          tenant: tenant,
          name: form.name,
          createdOn: new Date(form.createdOn).toISOString(),
        })
      );
    } else {
      //update
      const notesOriginal = await DataStore.query(Notes, noteId);
      const ret = await DataStore.save(
        Notes.copyOf(notesOriginal, (updated) => {
          const returnedTarget = Object.assign(updated, {
            ...form,
            createdOn: new Date(form.createdOn).toISOString(),
          });
        })
      );
    }
    const formType = await DBUtils.getFormType(clientId);
    NavUtils.showViewClientScreen(
      navigation,
      clientId,
      formType ?? FormType.CLIENT
    );
  }

  return (
    <>
      <Card containerStyle={commonStyles.mainCardContainerWithThickBorder}>
        <Card.Title style={{ marginTop: 8 }}></Card.Title>
        <Card
          containerStyle={{
            padding: 4,
            margin: 0,
            backgroundColor: "#ebedf0",
            borderWidth: 3,
            borderColor: "black",
            zIndex: 5,
          }}
        >
          <CustomDatePicker
            selected={form.createdOn}
            onChange={(date) => updateForm({ ...form, createdOn: date })}
          />
          <Input
            containerStyle={{ marginTop: 4 }}
            testID="name"
            numberOfLines={4}
            maxLength={10000}
            multiline={true}
            value={form.name}
            placeholder="Type Notes"
            onChangeText={(e) => updateForm({ ...form, name: e })}
          />

          <SubmitAndCancelButtons
            submit={async () => await addOrUpdateNotes()}
            cancel={async () => {
              const formType = await DBUtils.getFormType(clientId);
              NavUtils.showViewClientScreen(
                navigation,
                clientId,
                formType ?? FormType.CLIENT
              );
            }}
            flexDirection="row"
          />
        </Card>
      </Card>
    </>
  );
};
