import { Text, View } from "react-native";
import { Button, Card } from "react-native-elements";
import React, { useState, useEffect } from "react";
import { Bills } from "../../src/models/index";
import { DataStore } from "@aws-amplify/datastore";
import { commonStyles } from "../../styles/commonStyles";
import * as NavUtils from "../../utils/NavUtils";
import * as Utils from "../../utils/Utils";
import { useConfirmation } from "../confirmation/ConfirmationService";
import { BillStatus } from "../../src/models";
import { Predicates, SortDirection } from "aws-amplify";
import { useNavigation } from "@react-navigation/core";

export const BillsComponent = ({ personState }) => {
  const [bills, setBills] = useState([]);
  const [purpose, setPurpose] = useState("");
  const [details, setDetails] = useState("");
  // const [amountDue, setAmountDue] = useState(Dinero({ amount: 0 }).toObject);
  const [amountDue, setAmountDue] = useState("");
  const [billStatus, setBillStatus] = useState(BillStatus.UNPAID);

  const [createdOn, setCreatedOn] = useState(new Date());
  const [refresh, doRefresh] = useState(false);
  const confirm = useConfirmation();
  const [currency, setCurrency] = useState("");

  const navigation = useNavigation();
  //getLoggedInUsersOwnerDBDetails for currency
  useEffect(() => {
    async function loadBills() {
      //const comments = (await DataStore.query(Comment)).filter(c => c.postID === "123");
      if (personState?.id !== undefined) {
        //in the first render of view parent, its empty so do not waste going thru loop
        const billssToShow = (
          await DataStore.query(Bills, Predicates.ALL, {
            sort: (s) => s.createdOn(SortDirection.DESCENDING),
          })
        ).filter((c) => c.clientId === personState.id);
        setBills(billssToShow);
        let cur = await Utils.getCurrency();
        setCurrency(cur ?? "");
      }
    }
    loadBills();
    return () => {
      //cleanup
    };
  }, [!refresh, personState.id]);
  // second render time - parent has id due to its own useeffect
  //but need to check that id go undefined to some_id so this componenet can render

  function addBills() {
    NavUtils.showAddEditBillScreen(navigation, undefined, personState.id);
  }

  async function editBills(item) {
    NavUtils.showAddEditBillScreen(navigation, item.id, personState.id);
  }
  async function deleteBills(item) {
    await Utils.areYouSureDialog(confirm, async () => {
      const billsToDelete = await DataStore.query(Bills, item.id);
      await DataStore.delete(billsToDelete);
      doRefresh(!refresh);
    });
  }

  return (
    <>
      <Card containerStyle={commonStyles.mainCardContainerWithThickBorder}>
        <Card.Title style={{ marginTop: 8 }}>{`${
          bills === undefined || bills.length === 0 ? "No" : bills.length
        } Bill(s) Found`}</Card.Title>

        <Button
          containerStyle={commonStyles.buttonRow}
          title="Add New Bills"
          onPress={addBills}
        />
        <>
          {bills?.map((item, idx) => {
            const bg = idx % 2 === 0 ? "white" : "#f3f0f5";
            return (
              <Card
                containerStyle={{
                  padding: 4,
                  marginBottom: 8,
                  backgroundColor: bg,
                }}
              >
                <Text style={{ marginBottom: 4 }}>
                  {new Date(item.createdOn).toLocaleDateString()}
                </Text>
                <Text style={{ marginBottom: 4 }}>{item.purpose}</Text>
                <Text style={{ marginBottom: 4 }}>
                  {/* AA {Dinero().setLocale("fr-FR").getLocale()} {item.amountDue} */}
                  {/* AA {Dinero().getLocale()} {item.amountDue} */}
                  {/* AA {Dinero().setLocale(Localization.locale).getLocale()}{" "} */}
                  {/* {item.amountDue} */}
                  {(currency ? currency + " " : "") + item.amountDue}
                </Text>
                <Text
                  style={{
                    marginBottom: 4,
                    color:
                      item.billStatus === BillStatus.UNPAID ? "red" : "green",
                  }}
                >
                  {item.billStatus}
                </Text>

                <Text style={{ marginBottom: 4 }}>{item.details}</Text>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginBottom: 4,
                  }}
                >
                  <Button
                    buttonStyle={commonStyles.buttonRow}
                    containerStyle={{ width: "30%" }}
                    title="Edit"
                    onPress={() => editBills(item)}
                  />
                  <Button
                    buttonStyle={commonStyles.buttonDeleteRow}
                    containerStyle={{ width: "30%" }}
                    title="Delete"
                    onPress={() => deleteBills(item)}
                  />
                </View>
              </Card>
            );
          })}
        </>
      </Card>
    </>
  );
};
