import {
  Image,
  View,
  Modal,
  ImageBackground,
  SafeAreaView,
} from "react-native";
import { Button, Card, Text, Input, Overlay } from "react-native-elements";
import React, { useState, useEffect } from "react";
import { commonStyles } from "../../styles/commonStyles";
import { ScrollView } from "react-native-gesture-handler";
import { Auth } from "aws-amplify";
import { Ionicons } from "@expo/vector-icons";
import { MaterialIcons } from "@expo/vector-icons";
import { FontAwesome5 } from "@expo/vector-icons";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import FeatureCRM from "./FeatureCRM";
import { LinkButton } from "../../components/ButtonComponents";
import * as Linking from "expo-linking";
import * as Utils from "../../utils/Utils";
import { LinearGradient } from "expo-linear-gradient";

export const SignInScreen = ({ navigation, route }) => {
  const [form, updateForm] = useState({
    userEmail: "",
    pwd: "",
  });

  const [errors, setErrors] = useState("");
  const [errorJSON, setErrorJSON] = useState({});

  const [forgotRequestedForm, updateForgotRequestedForm] = useState({
    userEmail: "",
  });
  const [errorsForgotRequested, setErrorsForgotRequested] = useState("");
  const [errorJSONForgotRequested, setErrorJSONForgotRequested] = useState({});
  const [forgotRequested, setForgotRequested] = useState(false);

  const [confirmCodeForm, updateConfirmCodeForm] = useState({
    code: "",
    newPwd: "",
    cpd: "",
  });
  const [errorsConfirmCode, setErrorsConfirmCode] = useState("");
  const [errorJSONConfirmCode, setErrorJSONConfirmCode] = useState({});
  const [confirmCode, setConfirmCode] = useState(false);

  const [forgotResult, setForgotResult] = useState(false);
  const email = route.params?.signedUpEmail;

  useEffect(() => {
    const showWhichHome = async () => {
      if (__DEV__) console.log("signinscreen Email from signup = " + email);
      updateForm({ ...form, userEmail: email });
      if (__DEV__) console.log("signinscreen Email useeffect inside ");

      const user = await Auth.currentAuthenticatedUser(); //THROWS EXPECTION if no user SO updateform BEFORE this
      if (__DEV__)
        console.log(
          "signinscreen Email useeffect inside after currentauthuser"
        );

      if (user) {
        navigation.navigate("LoggedInHomeScreenName");
        return;
      } else {
      }
    };
    if (__DEV__) console.log("signinscreen Email useeffect before ");

    showWhichHome();
    return () => {
      //cleanup
    };
  }, []);
  const login = async () => {
    try {
      if (form.userEmail?.trim().length <= 0) {
        setErrors("Please enter your email.");
        return;
      }
      if (
        !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
          form.userEmail
        )
      ) {
        setErrors("Please enter valid format for Email");
        return;
      }
      if (form.pwd?.trim().length <= 0) {
        setErrors("Please enter password.");
        return;
      }
      const user = await Auth.signIn(form.userEmail, form.pwd);
      if (user) {
        navigation.navigate("LoggedInHomeScreenName");
        return;
      } else {
        setErrors(
          "Unknown error occurred. Please contact us at support@siyamiapps.com"
        );
      }
    } catch (error) {
      if (__DEV__) console.log("sign in error", error);
      setErrorJSON(error);

      // setErrors(error?.log + " | " + error?.message);
      setErrors(error?.message);
    }
  };

  const forgotPasswordRequested = async () => {
    try {
      if (forgotRequestedForm.userEmail?.trim().length <= 0) {
        setErrorsForgotRequested("Please enter your email.");
        return;
      }
      if (
        !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
          forgotRequestedForm.userEmail
        )
      ) {
        setErrorsForgotRequested("Please enter valid format for Email");
        return;
      }

      const result = await Auth.forgotPassword(forgotRequestedForm.userEmail);
      setConfirmCode(true);
      setForgotRequested(false);
      setErrorsForgotRequested("");
      return;
    } catch (error) {
      if (__DEV__) console.log("forgot request error", error);
      setErrorJSONForgotRequested(error);
      setErrorsForgotRequested(error?.message);
    }
  };

  const confirmCodeSubmitted = async () => {
    try {
      if (forgotRequestedForm.userEmail?.trim().length <= 0) {
        setErrorsConfirmCode("Please enter your email.");
        return;
      }
      if (
        !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
          forgotRequestedForm.userEmail
        )
      ) {
        setErrorsConfirmCode("Please enter valid format for Email");
        return;
      }
      if (confirmCodeForm.code?.trim().length <= 0) {
        setErrorsConfirmCode("Please enter Verification Code.");
        return;
      }

      if (confirmCodeForm.newPwd?.trim().length <= 0) {
        setErrorsConfirmCode("Please enter New Password.");
        return;
      }
      if (confirmCodeForm.newPwd.length < 8) {
        setErrorsConfirmCode(
          "Password length is less than 8. Please enter longer password"
        );
        return;
      }
      if (confirmCodeForm.cpd?.trim().length <= 0) {
        setErrorsConfirmCode("Please confirm password.");
        return;
      }

      if (confirmCodeForm.newPwd !== confirmCodeForm.cpd) {
        setErrorsConfirmCode(
          "Password and Confirm Password fields do not match. Please match and try again."
        );
        return;
      }
      const result = await Auth.forgotPasswordSubmit(
        forgotRequestedForm.userEmail,
        confirmCodeForm.code,
        confirmCodeForm.newPwd
      );
      setConfirmCode(false);
      setForgotResult(true);
      setErrorsConfirmCode("");
      return;
    } catch (error) {
      if (__DEV__) console.log("forgot request error", error);
      setErrorJSONConfirmCode(error);
      setErrorsConfirmCode(error?.message);
    }
  };

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <ImageBackground
        source={require("../../assets/images/orange.jpg")}
        // resizeMode="cover"
        style={{
          flex: 1,
          // justifyContent: "center",
          // borderColor: "white",
          // borderWidth: 0,
        }}
      >
        <View
          style={{
            flex: 1,
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Card
            containerStyle={{
              flex: 1,
              padding: 10,
              backgroundColor: "#ebedf0",
              borderWidth: 3,
              margin: 10,
            }}
          >
            <Card.Title h4>Login to Cloud CRM</Card.Title>
            {errors?.length > 0 && (
              <Text style={{ margin: 20, color: "red" }}>{errors?.trim()}</Text>
            )}

            <Input
              maxLength={100}
              value={form.userEmail}
              placeholder="Your Email "
              onChangeText={(e) => {
                updateForm({ ...form, userEmail: e.trim() });
                updateForgotRequestedForm({
                  ...forgotRequestedForm,
                  userEmail: e.trim(),
                });
              }}
              keyboardType="email-address"
            />
            <Input
              maxLength={100}
              value={form.pwd}
              placeholder="Enter Password"
              onChangeText={(e) => updateForm({ ...form, pwd: e.trim() })}
              secureTextEntry
            />
            <Button
              style={{
                marginVertical: 10,
              }}
              buttonStyle={{
                backgroundColor: "#cf9002",
              }}
              onPress={() => login()}
              title="Sign In"
            />
            <Button
              type="outline"
              style={{
                marginVertical: 10,
                // marginHorizontal: 10,
                // width: "100%",
              }}
              buttonStyle={{
                borderColor: "#cf9002",
              }}
              titleStyle={{ color: "black" }}
              onPress={() => navigation.goBack()}
              title="Cancel"
            />

            <Button
              type="clear"
              style={{
                marginVertical: 5,
              }}
              onPress={() => {
                setForgotRequested(true);
                setConfirmCode(false);
                setForgotResult(false);
              }}
              title="Forgot Password"
            />
          </Card>

          {/* forgot */}
          {(forgotRequested || confirmCode || forgotResult) && (
            <Overlay
              // fullScreen
              ModalComponent={Modal}
              width="auto"
              height="auto"
            >
              <Card
                containerStyle={{
                  padding: 4,
                  margin: 10,
                  backgroundColor: "#ebedf0",
                  borderWidth: 3,
                  // borderColor: "black",
                  zIndex: 5,
                }}
              >
                {forgotRequested && (
                  <Card.Title h4 style={{}}>
                    Forgot Password Form
                  </Card.Title>
                )}
                {confirmCode && (
                  <Card.Title h4 style={{}}>
                    Reset Password
                  </Card.Title>
                )}
                {forgotResult && (
                  <Card.Title h4 style={{}}>
                    Reset Success
                  </Card.Title>
                )}
                {forgotRequested && (
                  <>
                    {errorsForgotRequested?.length > 0 && (
                      <Text style={{ margin: 20, color: "red" }}>
                        {errorsForgotRequested?.trim()}
                      </Text>
                    )}
                    <Text style={{ margin: 20, fontSize: 16 }}>
                      Enter your email used for login. You will get an email
                      from Cloud CRM address with Verification Code to reset
                      password.
                    </Text>
                    <Input
                      maxLength={100}
                      value={forgotRequestedForm.userEmail}
                      placeholder="Your Email used for login"
                      onChangeText={(e) => {
                        updateForgotRequestedForm({
                          ...forgotRequestedForm,
                          userEmail: e.trim(),
                        });
                        updateForm({ ...form, userEmail: e.trim() });
                      }}
                      keyboardType="email-address"
                    />

                    <Button
                      style={{
                        marginVertical: 10,
                        marginHorizontal: 10,
                        // width: 300,
                      }}
                      // onPress={() => navigation.navigate("LoggedInHomeScreenName")}
                      onPress={() => forgotPasswordRequested()}
                      title="Submit"
                    />
                    <Button
                      style={{
                        marginVertical: 10,
                        marginHorizontal: 10,
                        // width: 300,
                      }}
                      // onPress={() => navigation.navigate("LoggedInHomeScreenName")}
                      onPress={() => {
                        setForgotRequested(false);
                        setConfirmCode(false);
                        setForgotResult(false);
                      }}
                      title="Cancel"
                      type="outline"
                    />
                  </>
                )}

                {confirmCode && (
                  <>
                    {errorsConfirmCode?.length > 0 && (
                      <Text style={{ margin: 20, color: "red" }}>
                        {errorsConfirmCode?.trim()}
                      </Text>
                    )}
                    <Text style={{ margin: 20, fontSize: 16 }}>
                      Verification Code sent to {forgotRequestedForm.userEmail}{" "}
                      from Cloud CRM. Enter that code below and choose new
                      Password. If you do not get Verification Code within 2
                      minutes, check if {forgotRequestedForm.userEmail} is your
                      correct email.
                    </Text>
                    <Input
                      maxLength={100}
                      value={confirmCodeForm.code}
                      placeholder="Verification Code"
                      onChangeText={(e) =>
                        updateConfirmCodeForm({
                          ...confirmCodeForm,
                          code: e.trim(),
                        })
                      }
                    />
                    <Input
                      maxLength={100}
                      value={confirmCode.newPwd}
                      placeholder="Enter New Password"
                      onChangeText={(e) =>
                        updateConfirmCodeForm({
                          ...confirmCodeForm,
                          newPwd: e.trim(),
                        })
                      }
                      secureTextEntry
                    />
                    <Input
                      maxLength={100}
                      value={confirmCode.cpd}
                      placeholder="Confirm New Password"
                      onChangeText={(e) =>
                        updateConfirmCodeForm({
                          ...confirmCodeForm,
                          cpd: e.trim(),
                        })
                      }
                      secureTextEntry
                    />
                    <Button
                      style={{
                        marginVertical: 10,
                        marginHorizontal: 10,
                        // width: 300,
                      }}
                      // onPress={() => navigation.navigate("LoggedInHomeScreenName")}
                      onPress={() => confirmCodeSubmitted()}
                      title="Reset Password"
                    />
                    <Button
                      style={{
                        marginVertical: 10,
                        marginHorizontal: 10,
                        // width: 300,
                      }}
                      // onPress={() => navigation.navigate("LoggedInHomeScreenName")}
                      onPress={() => {
                        setForgotRequested(true);
                        setConfirmCode(false);
                        setForgotResult(false);
                      }}
                      title="Cancel"
                      type="outline"
                    />
                  </>
                )}
                {forgotResult && (
                  <>
                    <Text
                      style={{ margin: 20, color: "green", fontWeight: "bold" }}
                    >
                      Password reset successful. Press button below to go to
                      login page and use new password.
                    </Text>
                    <Button
                      style={{
                        marginVertical: 10,
                        marginHorizontal: 10,
                        // width: 300,
                      }}
                      // onPress={() => navigation.navigate("LoggedInHomeScreenName")}
                      onPress={() => {
                        setForgotRequested(false);
                        setConfirmCode(false);
                        setForgotResult(false);
                      }}
                      title="Go To Login"
                    />
                  </>
                )}
              </Card>
            </Overlay>
          )}
        </View>
      </ImageBackground>
    </SafeAreaView>
  );
};
