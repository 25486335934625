import Amplify from "@aws-amplify/core";
import { DataStore } from "@aws-amplify/datastore";
import {
  NavigationContainer,
  getStateFromPath as defaultStateFromPath,
  getPathFromState as defaultPathFromState,
} from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import { Authenticator, withAuthenticator } from "aws-amplify-react-native";
import { StatusBar } from "expo-status-bar";
import React, { useEffect, useState } from "react";
import { AuthState, onAuthUIStateChange } from "@aws-amplify/ui-components";
import {
  Button,
  // StatusBar,
  StyleSheet,
  Text,
} from "react-native";
import awsconfig from "./src/aws-exports"; // if you are using Amplify CLI
import { Post } from "./src/models/index";
import LoggedInHomeScreen from "./screens/LoggedInHomeScreen";
import { Auth, I18n } from "aws-amplify";
import { commonStyles } from "./styles/commonStyles";
import { Provider } from "react-native-paper";
import { LoggedOutHomeScreen } from "./screens/home/LoggedOutHomeScreen";
import { DefaultTheme, Provider as PaperProvider } from "react-native-paper";
import { PrivacyScreen } from "./screens/agreements/PrivacyScreen";
import { ConfirmationServiceProvider } from "./components/confirmation/ConfirmationService";
import * as Analytics from "expo-firebase-analytics";
import { useRef } from "react";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { SafeAreaProvider, SafeAreaView } from "react-native-safe-area-context";
import * as Localization from "expo-localization"; //returns null on web for currency so using Dinero
import Dinero from "dinero.js";
import { OrderScreen } from "./screens/order/OrderScreen";
import { ReturnsScreen } from "./screens/agreements/ReturnsScreen";
import { TermsScreen } from "./screens/agreements/TermsScreen";
import { SignInScreen } from "./screens/home/SignInScreen";
import { SignUpScreen } from "./screens/home/SignUpScreen";
import { PricingScreen } from "./screens/home/PricingScreen";

Amplify.configure(awsconfig);
if (__DEV__) {
  Amplify.Logger.LOG_LEVEL = "DEBUG";
}
if (__DEV__) {
  Sentry.init({
    dsn: "https://e335624df9d048e18833f8b44353151c@o984118.ingest.sentry.io/5939898",
  });
}

/** following is a good way but only works for US english - so need another way to customize Hosted UI labels */

// I18n.putVocabulariesForLanguage("en-US", {
//   [Translations.SIGN_IN_HEADER_TEXT]: "Welcome to Company App",
//   //[Translations.SIGN_IN_ACTION]: "Sign In"
// });

//while create is better namem not using for now as comment in footer says signup
//so there are two solutions - using trasnlations above for all english flavors like canada us and UK
//OR use below. Below seems hardcoded. If they change later, it ma not match but good for now.
const authScreenLabels = {
  en: {
    //     'Sign Up': 'Create new account',
    //   'Sign Up Account': 'Create a new account'
    // "Sign in to your account": "Welcome to Cloud CRM for Client Records",
    "Sign in to your account": "Sign In / Sign Up for FREE Trial",
  },
};

I18n.setLanguage("en");
I18n.putVocabularies(authScreenLabels);
//comment to test git
const Stack = createStackNavigator();
const signUpConfig = {
  header: "Sign Up or Confirm Code",
  hideAllDefaults: true,
  signUpFields: [
    {
      label: "Email",
      key: "email",
      required: true,
      displayOrder: 1,
      type: "string",
    },
    {
      label: "Password",
      key: "password",
      required: true,
      displayOrder: 2,
      type: "password",
    },
  ],
};

const theme = {
  ...DefaultTheme,
  roundness: 2,
  colors: {
    ...DefaultTheme.colors,
    primary: "#3498db",
    accent: "#f1c40f",
  },
};

function App(props) {
  var navigation;

  navigation = props.navigation;
  const navigationRef = useRef();
  const routeNameRef = useRef();

  const linking = {
    prefixes: [
      /* your linking prefixes */
    ],
    config: {
      /* configuration for matching screens with paths */
      screens: {
        PrivacyScreenName: {
          path: "privacy",
        },
        TermsScreenName: {
          path: "terms",
        },
        ReturnsScreenName: {
          path: "returns",
        },
        OrderScreenName: {
          path: "order",
        },
      },
    },
  };
  // Dinero.defaultCurrency = "EUR"; //tested works so assume globalLocale also works
  // Dinero.globalLocale = Localization.locale;
  //Currency needs to be provided to Dinero

  return (
    <ConfirmationServiceProvider>
      <Provider theme={theme}>
        <SafeAreaProvider>
          {/* <SafeAreaView style={commonStyles.pageContainer}> */}
          <NavigationContainer
            linking={linking}
            ref={navigationRef}
            onReady={() =>
              (routeNameRef.current =
                navigationRef.current.getCurrentRoute().name)
            }
            onStateChange={() => {
              const previousRouteName = routeNameRef.current;
              const currentRouteName =
                navigationRef.current.getCurrentRoute().name;

              if (previousRouteName !== currentRouteName) {
                // The line below uses the expo-firebase-analytics tracker
                // https://docs.expo.io/versions/latest/sdk/firebase-analytics/
                // Change this line to use another Mobile analytics SDK
                // Analytics.setCurrentScreen(currentRouteName);
                // alert(`The route changed to ${currentRouteName}`);
              }

              // Save the current route name for later comparision
              routeNameRef.current = currentRouteName;
            }}
          >
            <Stack.Navigator
              // initialRouteName="LoggedInHomeScreenName"
              initialRouteName="LoggedOutHomeScreenName"
              headerMode="none"
              screenOptions={{ gestureEnabled: false }}
            >
              <Stack.Screen
                name="LoggedOutHomeScreenName"
                component={LoggedOutHomeScreen}
                options={{ title: "Cloud CRM Client Records" }}
              />
              <Stack.Screen
                name="SignInScreenName"
                component={SignInScreen}
                options={{ title: "Sign In to Cloud CRM Client Records" }}
              />
              <Stack.Screen
                name="SignUpScreenName"
                component={SignUpScreen}
                options={{ title: "Free Sign Up Cloud CRM Client Records" }}
              />
              <Stack.Screen
                name="PricingScreenName"
                component={PricingScreen}
                options={{ title: "Pricing for Cloud CRM Client Records" }}
              />
              <Stack.Screen
                name="PrivacyScreenName"
                component={PrivacyScreen}
                options={{ title: "Cloud CRM Client Records - Privacy" }}
              />
              <Stack.Screen
                name="TermsScreenName"
                component={TermsScreen}
                options={{ title: "Cloud CRM Client Records - Terms" }}
              />
              <Stack.Screen
                name="ReturnsScreenName"
                component={ReturnsScreen}
                options={{ title: "Cloud CRM Client Records - Web Returns" }}
              />
              <Stack.Screen
                name="OrderScreenName"
                component={OrderScreen}
                options={{
                  title: "Order Result",
                }}
              />
              <Stack.Screen
                name="LoggedInHomeScreenName"
                component={withAuthenticator(LoggedInHomeScreen, {
                  usernameAttributes: "email",
                  signUpConfig,
                })}
                options={{
                  title: "Search Client List - Cloud CRM Client Records",
                }}
              />
            </Stack.Navigator>
          </NavigationContainer>

          {/* </SafeAreaView> */}
        </SafeAreaProvider>
      </Provider>
    </ConfirmationServiceProvider>
  );
}
//how to pass email in signup config
// export default withAuthenticator(App, {
//   usernameAttributes: "email",
//   signUpConfig,
// });
export default App;
