import {
  TouchableOpacity,
  Text,
  View,
  Image,
  Modal,
  Platform,
  ActivityIndicator,
  Linking,
  Share,
} from "react-native";
// import * as Linking from "expo-linking";
import { SafeAreaView } from "react-native-safe-area-context";
import React, { useEffect, useState } from "react";
import { DataStore } from "@aws-amplify/datastore";
import Amplify from "@aws-amplify/core";
import awsconfig from "../src/aws-exports"; // if you are using Amplify CLI
import { Auth } from "aws-amplify";
import PageErrorBoundary from "../utils/PageErrorBoundary";
import { commonStyles } from "../styles/commonStyles";
import MainMenuIcon from "@material-ui/icons/Menu";
import {
  createDrawerNavigator,
  DrawerContentScrollView,
  DrawerItem,
} from "@react-navigation/drawer";
import { ClientListScreen } from "./client/ClientListScreen";
import { AddClientScreen } from "./client/AddClientScreen";
import { EditClientScreen } from "./client/EditClientScreen";

import { ViewClientScreen } from "./client/ViewClientScreen";
import { ifBigScreen } from "../utils/ifBigScreen";
import { UserListScreen } from "./user/UserListScreen";
import { AddUserScreen } from "./user/AddUserScreen";
import { EditUserScreen } from "./user/EditUserScreen";
import { createStackNavigator } from "@react-navigation/stack";
import { ViewUserScreen } from "./user/ViewUserScreen";
import { Navigation, TramRounded } from "@material-ui/icons";
import { CustomizeFieldsFormComponent } from "../components/person/CustomizeFieldsFormComponent";
import { Test } from "../components/person/Test";
import { BulkProtocolType, Client, FormType } from "../src/models";
import * as NavUtils from "../utils/NavUtils";
import * as DBUtils from "../utils/Utils";
import * as Utils from "../utils/Utils";
import EmailPersonsSelectionScreen from "./email/EmailPersonsSelectionScreen";
import EmailComposerScreen from "./email/EmailComposerScreen";
import { EmailHistoryScreen } from "./email/EmailHistoryScreen";
import EmailViewScreen from "./email/EmailViewScreen";
import { GroupListScreen } from "./group/GroupListScreen";
import { AddEditGroupScreen } from "./group/AddEditGroupScreen";
import { ViewGroupScreen } from "./group/ViewGroupScreen";
import GroupMemberSelectionScreen from "./group/GroupMemberSelectionScreen";
import { TaskListScreen } from "./task/TaskListScreen";
import { AddEditTaskScreen } from "./task/AddEditTaskScreen";
import { ViewTaskScreen } from "./task/ViewTaskScreen";
import TaskMemberSelectionScreen from "./task/TaskMemberSelectionScreen";
import { Overlay } from "react-native-elements";
import * as UserPermissions from "../utils/UserPermissions";
import { ProfileScreen } from "./profile/ProfileScreen";
import { HelpScreen } from "./profile/HelpScreen";
import {
  Button as ButtonPaper,
  Provider,
  Paragraph,
  Dialog,
  Portal,
} from "react-native-paper";
import { CRMAlerts } from "../components/CRMAlerts";
import { Ionicons } from "@expo/vector-icons";
import { MaterialIcons } from "@expo/vector-icons";
import { FontAwesome5 } from "@expo/vector-icons";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import * as MailComposer from "expo-mail-composer";
import * as Strings from "./../resources/Strings";
import { Button } from "react-native-elements";
import * as Sentry from "@sentry/react";
import * as WebBrowser from "expo-web-browser";
import { BillsListScreen } from "../components/person/search/BillsListScreen";
import { NotesListScreen } from "../components/person/search/NotesListScreen";
import { AddEditEventComponent } from "../components/person/AddEditEventComponent";
import { AddEditNotesComponent } from "../components/person/AddEditNotesComponent";
import { AddEditBillsComponent } from "../components/person/AddEditBillsComponent";
import * as LinkingExpo from "expo-linking";
import { ScrollView } from "react-native-gesture-handler";
import { ProductCatalogScreen } from "./sales/ProductCatalogScreen";
import { AddEditProductComponent } from "./sales/AddEditProductComponent";
import { ViewProductComponent } from "./sales/ViewProductComponent";
import AppointmentsScreen from "./appointments/AppointmentsScreen";
import { AddEditAppointmentComponent } from "./appointments/AddEditAppointmentComponent";
import { ViewAppointmentComponent } from "./appointments/ViewAppointmentComponent";
import { PartsInventoryScreen } from "./planning/parts/PartsInventoryScreen";
import { ViewPartComponent } from "./planning/parts/ViewPartComponent";
import { AddEditPartComponent } from "./planning/parts/AddEditPartComponent";
import { PromoScreen } from "./promo/PromoScreen";
import { OrderScreen } from "./order/OrderScreen";
import { CartScreen } from "./cart/CartScreen";

const LoggedInHomeScreen = (props) => {
  const [isOwner, setIsOwner] = useState(false);
  const [isPaidUser, setIsPaidUser] = useState(false);

  useEffect(() => {
    async function load() {
      const isOwnerInit =
        (await UserPermissions.isCurrentlyLoggedInUserAnOwner()) ?? false;
      setIsOwner(isOwnerInit);
      const isPaidUser = await Utils.isPlanActive(); //returns promise so redesign to make it work
      setIsPaidUser(isPaidUser);
      if (__DEV__) {
        console.log("LoggedinHome isPaidUser useeffect=" + isPaidUser);
        console.log("LoggedinHome isOwner useeffect=" + isOwner);
      }
    }
    load();
    return () => {
      //cleanup
    };
  }, []);

  return (
    <MainMenuDrawerNavigator
      {...props}
      isOwner={isOwner}
      isPaidUser={isPaidUser}
    />
  );
};
export default LoggedInHomeScreen;

export function Page({
  navigation,
  titleHeader,
  pageContent,
  showHeader = true,
  showMenu = true,
}) {
  return (
    <SafeAreaView
      style={[
        commonStyles.pageContainerMain,
        // { marginTop: Platform.OS === "android" ? 40 : 0 },//did not work as OS is web for webview.
      ]}
    >
      <View style={{ flex: 1 }}>
        {showHeader && (
          <PageHeader
            name={titleHeader}
            navigation={navigation}
            showMenu={showMenu}
          />
        )}
        {/* <PageErrorBoundary> */}
        <Sentry.ErrorBoundary
          fallback={
            <View>
              <Text style={commonStyles.pageNotes}>
                We apologize for inconvenience. While we are working to resolve
                the issue, please use other features of the App by pressing the
                Menu
              </Text>
              <Button
                buttonStyle={{ margin: 0, padding: 0 }}
                // containerStyle={{ width: "30%" }}
                containerStyle={{ margin: 0, paddingTop: 4, marginRight: 10 }}
                title="Contact Email Support"
                onPress={async () => {
                  const subject = "Error : Cloud CRM Application";
                  const body =
                    "Hi, Please explain what feature of the App resulted in error:";
                  const supportEmail = "support@siyamiapps.com";
                  if (Utils.isIOS()) {
                    let encodedSub = encodeURIComponent(subject);
                    let encodedBody = encodeURIComponent(body);
                    LinkingExpo.openURL(
                      `mailto:${supportEmail}?subject=${encodedSub}&body=${encodedBody}`
                    );
                    return;
                  } else {
                    let result = await MailComposer.composeAsync({
                      recipients: [supportEmail],
                      subject: subject,
                      body: body,
                      ccRecipients: [],
                      bccRecipients: [],
                    });
                    return result;
                  }

                  //    alert(result.status);
                }}
                type="clear"
              />
            </View>
          }
          showDialog
        >
          <View style={commonStyles.pageContainer}>{pageContent}</View>
        </Sentry.ErrorBoundary>
        {/* </PageErrorBoundary> */}
      </View>
    </SafeAreaView>
  );
}

export function PageHeader({ name, navigation, showMenu = true }) {
  const showHamburger = !ifBigScreen && showMenu;
  return (
    <View style={commonStyles.pageHeader}>
      <Image
        style={commonStyles.appIcon}
        source={require("../assets/icons/companyApp.png")}
      />
      <Text style={commonStyles.pageHeaderName}>{name}</Text>
      <View style={commonStyles.mainMenuButton}>
        <TouchableOpacity
          onPress={() => {
            navigation.openDrawer();
          }}
        >
          {showHamburger && <MainMenuIcon />}
          {/* good idea of activity indicator is users complain about slow menu- but fix Pages where there is no menu like add client. Acitivy indicator shows up there */}
          {/* {showMenu && !showHamburger && <ActivityIndicator size="small" />} */}
        </TouchableOpacity>
      </View>
    </View>
  );
}

function MainMenuDrawerContent(props) {
  function labelForClose() {
    const labelForCloseText = props.focused ? "Close" : "CLOSE  MENU";
    return (
      <Text style={{ color: "white", fontWeight: "bold" }}>
        {labelForCloseText}
      </Text>
    );
  }
  if (__DEV__)
    console.log(
      "LoggedinHome isPaidUser, isOWner main menu drawer navigator MENU ITEMS=" +
        props.isPaidUser +
        "," +
        props.isOwner
    );
  return (
    <DrawerContentScrollView {...props}>
      {!ifBigScreen && (
        <DrawerItem
          icon={() => <MaterialIcons name="close" size={24} color="white" />}
          label={labelForClose}
          onPress={() => props.navigation.closeDrawer()}
          style={{
            backgroundColor: "#1b95e0",
            marginTop: Utils.isIOS() ? 15 : 5,
          }}
        />
      )}

      <DrawerItem
        //icon={() => <Image style={styles.mainMenuOptionButtons} />}
        icon={() => <MaterialIcons name="search" size={24} color="blue" />}
        label={() => <Text>Search Client List</Text>}
        onPress={() => props.navigation.navigate("ClientListScreenName")}
        // activeTintColor="red"
        style={{ borderBottomColor: "lightgray", borderBottomWidth: 1 }}
      />
      <DrawerItem
        icon={() => <MaterialIcons name="person-add" size={24} color="blue" />}
        label={() => <Text>Add Client</Text>}
        onPress={() =>
          NavUtils.showAddEditClientScreen(
            props.navigation,
            undefined,
            FormType.CLIENT
          )
        }
        style={{ borderBottomColor: "lightgray", borderBottomWidth: 1 }}
      />
      <DrawerItem
        icon={() => <MaterialIcons name="contacts" size={24} color="orange" />}
        label={() => <Text>Leads</Text>}
        onPress={() => props.navigation.navigate("LeadListScreenName")}
        style={{ borderBottomColor: "lightgray", borderBottomWidth: 1 }}
      />
      <DrawerItem
        icon={() => <MaterialIcons name="contacts" size={24} color="green" />}
        label={() => <Text>Staff</Text>}
        onPress={() => props.navigation.navigate("StaffListScreenName")}
        style={{ borderBottomColor: "lightgray", borderBottomWidth: 1 }}
      />
      <DrawerItem
        icon={() => <MaterialIcons name="contacts" size={24} color="red" />}
        label={() => <Text>Suppliers</Text>}
        onPress={() => props.navigation.navigate("SupplierListScreenName")}
        style={{ borderBottomColor: "lightgray", borderBottomWidth: 1 }}
      />
      <DrawerItem
        icon={() => <MaterialIcons name="search" size={24} color="green" />}
        label={() => <Text>Search Bills</Text>}
        onPress={() => props.navigation.navigate("BillsListScreenName")}
        style={{ borderBottomColor: "lightgray", borderBottomWidth: 1 }}
      />
      <DrawerItem
        icon={() => <MaterialIcons name="search" size={24} color="blue" />}
        label={() => <Text>Search Notes</Text>}
        onPress={() => props.navigation.navigate("NotesListScreenName")}
        style={{ borderBottomColor: "lightgray", borderBottomWidth: 1 }}
      />
      <DrawerItem
        icon={() => (
          <MaterialIcons name="calendar-today" size={24} color="green" />
        )}
        label={() => <Text>Appointments</Text>}
        onPress={() => props.navigation.navigate("AppointmentsScreenName")}
        style={{ borderBottomColor: "lightgray", borderBottomWidth: 1 }}
      />
      <DrawerItem
        icon={() => (
          <MaterialIcons name="playlist-add" size={24} color="green" />
        )}
        label={() => <Text>Product Catalog</Text>}
        onPress={() => props.navigation.navigate("ProductCatalogScreenName")}
        style={{ borderBottomColor: "lightgray", borderBottomWidth: 1 }}
      />
      <DrawerItem
        icon={() => <MaterialIcons name="inventory" size={24} color="orange" />}
        label={() => <Text>Parts Iventory</Text>}
        onPress={() => props.navigation.navigate("PartsInventoryScreenName")}
        style={{ borderBottomColor: "lightgray", borderBottomWidth: 1 }}
      />
      <DrawerItem
        icon={() => <MaterialIcons name="group-add" size={24} color="black" />}
        label={() => <Text>Manage App Users</Text>}
        onPress={() => props.navigation.navigate("AppUserListScreenName")}
        style={{ borderBottomColor: "lightgray", borderBottomWidth: 1 }}
      />

      <DrawerItem
        style={{ borderBottomColor: "lightgray", borderBottomWidth: 1 }}
        icon={() => (
          <MaterialIcons name="outgoing-mail" size={24} color="blue" />
        )}
        label={() => <Text>Send Bulk Email</Text>}
        onPress={() => {
          NavUtils.showEmailClientsSelectionScreen(
            props.navigation,
            undefined,
            BulkProtocolType.EMAIL
          );
        }}
        // onPress={sendBulkEmailScreen}
      />
      <DrawerItem
        style={{ borderBottomColor: "lightgray", borderBottomWidth: 1 }}
        icon={() => <MaterialIcons name="history" size={24} color="blue" />}
        label={() => <Text>Bulk Email History</Text>}
        onPress={() =>
          NavUtils.showEmailHistoryScreen(
            props.navigation,
            BulkProtocolType.EMAIL
          )
        }
      />
      <DrawerItem
        style={{ borderBottomColor: "lightgray", borderBottomWidth: 1 }}
        icon={() => <MaterialIcons name="announcement" size={24} color="red" />}
        label={() => <Text>Send Bulk SMS</Text>}
        onPress={() => {
          NavUtils.showEmailClientsSelectionScreen(
            props.navigation,
            undefined,
            BulkProtocolType.SMS
          );
        }}
      />
      <DrawerItem
        style={{ borderBottomColor: "lightgray", borderBottomWidth: 1 }}
        icon={() => <MaterialIcons name="history" size={24} color="red" />}
        label={() => <Text>Bulk SMS History</Text>}
        onPress={() => {
          NavUtils.showEmailHistoryScreen(
            props.navigation,
            BulkProtocolType.SMS
          );
        }}
      />
      <DrawerItem
        style={{ borderBottomColor: "lightgray", borderBottomWidth: 1 }}
        icon={() => <MaterialIcons name="groups" size={24} color="black" />}
        label={() => <Text>Groups</Text>}
        onPress={() => props.navigation.navigate("GroupListScreenName")}
      />
      <DrawerItem
        style={{ borderBottomColor: "lightgray", borderBottomWidth: 1 }}
        icon={() => <MaterialIcons name="add-task" size={24} color="green" />}
        label={() => <Text>Tasks</Text>}
        onPress={() => props.navigation.navigate("TaskListScreenName")}
      />
      <DrawerItem
        style={{ borderBottomColor: "lightgray", borderBottomWidth: 1 }}
        icon={() => <MaterialIcons name="help" size={24} color="black" />}
        label={() => <Text>Help</Text>}
        onPress={() => props.navigation.navigate("HelpScreenName")}
      />
      {false && !Utils.isIOS() && (
        <DrawerItem
          style={{ borderBottomColor: "lightgray", borderBottomWidth: 1 }}
          icon={() => <MaterialIcons name="email" size={24} color="red" />}
          label={() => <Text>Contact Us</Text>}
          // onPress={() => props.navigation.navigate("ContactUsScreenName")}

          onPress={async () => {
            const subject = "Regarding Cloud CRM for Client Records";
            const body = "Hi Team, Please help us with question below:  ";
            const supportEmail = "support@siyamiapps.com";
            if (Utils.isIOS()) {
              let encodedSub = encodeURIComponent(subject);
              let encodedBody = encodeURIComponent(body);
              LinkingExpo.openURL(
                `mailto:${supportEmail}?subject=${encodedSub}&body=${encodedBody}`
              );
              return;
            } else {
              let result = await MailComposer.composeAsync({
                recipients: [supportEmail],
                subject: subject,
                body: body,
                ccRecipients: [],
                bccRecipients: [],
              });
              return result;
            }
          }}
        />
      )}

      {!Utils.isWeb() && !Utils.isIOS() && (
        <DrawerItem
          style={{ borderBottomColor: "lightgray", borderBottomWidth: 1 }}
          icon={() => <MaterialIcons name="web" size={24} color="blue" />}
          label={() => <Text>Access on Website</Text>}
          onPress={() => Linking.openURL("https://cloudcrm.siyamiapps.com")}
        />
      )}
      <DrawerItem
        style={{ borderBottomColor: "lightgray", borderBottomWidth: 1 }}
        icon={() => (
          <MaterialIcons name="add-shopping-cart" size={24} color="green" />
        )}
        label={() => <Text>Buy Premium Plan</Text>}
        onPress={() => {
          Utils.showSubscriptionPlans(props.navigation);
        }}
      />

      <DrawerItem
        style={{ borderBottomColor: "lightgray", borderBottomWidth: 1 }}
        icon={() => <MaterialIcons name="person-pin" size={24} color="blue" />}
        label={() => <Text>Profile</Text>}
        onPress={async () => {
          props.navigation.navigate("ProfileScreenName");
        }}
      />
      {false && !Utils.isIOS() && (
        <DrawerItem
          style={{ borderBottomColor: "lightgray", borderBottomWidth: 1 }}
          icon={() => <MaterialIcons name="share" size={24} color="black" />}
          label={() => <Text>Share App</Text>}
          // onPress={() => props.navigation.navigate("ShareAppScreenName")}
          onPress={async () => {
            try {
              const result = await Share.share({
                message: Strings.shareAppMsg,
              });
              if (result.action === Share.sharedAction) {
                if (result.activityType) {
                  // shared with activity type of result.activityType
                } else {
                  // shared
                }
              } else if (result.action === Share.dismissedAction) {
                // dismissed
              }
            } catch (error) {
              let result = await MailComposer.composeAsync({
                recipients: [""], // users can add friend and fmaily here.
                subject:
                  "Try this really cool app : Cloud CRM for Client Records",
                body: Strings.shareAppMsg,
                ccRecipients: [],
                bccRecipients: [],
              });
              return result;
            }
          }}
        />
      )}
      {/* {!Utils.isIOS() && ( */}
      {false && Utils.isWeb() && (
        <DrawerItem
          style={{ borderBottomColor: "lightgray", borderBottomWidth: 1 }}
          icon={() => <MaterialIcons name="facebook" size={24} color="blue" />}
          label={() => <Text>Facebook Page</Text>}
          onPress={() => Linking.openURL("https://facebook.com/siyamiapps")}
        />
      )}
      {/* {!isPaidUser && Utils.isWeb() && ( */}
      {Utils.isWeb() && !props.isPaidUser && (
        <DrawerItem
          style={{ borderBottomColor: "darkblue", borderBottomWidth: 4 }}
          icon={() => (
            <MaterialIcons name="attach-money" size={24} color="green" />
          )}
          label={() => <Text>Enter Promo Code</Text>}
          onPress={() => props.navigation.navigate("PromoScreenName")}
        />
      )}
      {!Utils.isWeb() && (
        <DrawerItem
          style={{ borderBottomColor: "lightgray", borderBottomWidth: 1 }}
          label={() => {
            const [v, setV] = useState("default");

            useEffect(() => {
              (async () => {
                let v = await Utils.getAppVersion();
                v = Utils.isIOS() ? "i " + v : "a " + v;
                setV(v);
                console.log("*** APP V=" + v + " after");
              })();
              return () => {
                // cleanup
              };
            }, []);

            return <Text>{"App Version : " + v}</Text>;
          }}
          onPress={async () => {}}
        />
      )}
    </DrawerContentScrollView>
  );
}

const MainMenuDrawerNavigator = (props) => {
  const Drawer = createDrawerNavigator();
  //props in a way passed to Addclient not needed as its present by default like clientlistcomponent
  if (__DEV__)
    console.log(
      "LoggedinHome isPaidUser, isOWner main menu drawer navigator screens=" +
        props.isPaidUser +
        "," +
        props.isOwner
    );
  return (
    //unmountonblur is false to retain history otherwise state is lost when going back to parent
    <Drawer.Navigator
      drawerType={ifBigScreen ? "permanent" : "back"}
      initialRouteName="ClientListScreenName"
      // initialRouteName="EmailClientsSelectionScreenName"
      // initialRouteName="EmailHistoryScreenName"
      // initialRouteName="ViewClientScreenName"
      // initialRouteName="Test"
      // initialRouteName="GroupListScreenName"
      // initialRouteName="AddEditTaskScreenName"
      // initialRouteName="AppointmentsScreenName"
      minSwipeDistance={1000}
      edgeWidth={-100}
      drawerPosition="right"
      drawerContent={(props2) => (
        <MainMenuDrawerContent {...props} {...props2} /> //props2 for closeDrawer to work
      )} //needed to convert to function so we can pass props containing paiduser and isowner flags
      // drawerContent={MainMenuDrawerContent} //needed to convert to function so we can pass props containing paiduser and isowner flags
      screenOptions={{ gestureEnabled: true, unmountOnBlur: true }}
      // detachInactiveScreens={false}
    >
      <Drawer.Screen name="Test" component={Test} />
      <Drawer.Screen
        name="ClientListScreenName"
        component={ClientListScreenComponent}
        options={{
          title: "Search Clients List - Cloud CRM Client Records",
        }}
      />
      <Drawer.Screen
        name="AppointmentsScreenName"
        component={AppointmentsScreenComponent}
        options={{
          title: "Appointments - Cloud CRM Client Records",
        }}
      />
      <Drawer.Screen
        name="AddClientScreenName"
        component={AddClientScreenComponent}
        options={{
          title: "Add or Edit - Cloud CRM Client Records",
          unmountOnBlur: false,
        }}
      />
      <Drawer.Screen
        name="LeadListScreenName"
        component={LeadListScreenComponent}
        options={{
          title: "Search Leads List - Cloud CRM Client Records",
        }}
      />
      <Drawer.Screen
        name="StaffListScreenName"
        component={StaffListScreenComponent}
        options={{
          title: "Search Staff List - Cloud CRM Client Records",
        }}
      />
      <Drawer.Screen
        name="SupplierListScreenName"
        component={SupplierListScreenComponent}
        options={{
          title: "Search Supplier List - Cloud CRM Client Records",
        }}
      />
      <Drawer.Screen
        name="BillsListScreenName"
        component={BillsListScreenComponent}
        options={{
          title: "Search Bills - Cloud CRM Client Records",
        }}
      />
      <Drawer.Screen
        name="NotesListScreenName"
        component={NotesListScreenComponent}
        options={{
          title: "Search Notes - Cloud CRM Client Records",
        }}
      />
      <Drawer.Screen
        name="ProductCatalogScreenName"
        component={ProductCatalogScreenComponent}
        options={{
          title: "Products Catalog - Cloud CRM Client Records",
        }}
      />
      <Drawer.Screen
        name="PartsInventoryScreenName"
        component={PartsInventoryScreenComponent}
        options={{
          title: "Parts Inventory - Cloud CRM Client Records",
        }}
      />
      <Drawer.Screen
        name="AddEditEventScreenName"
        component={AddEditEventScreenComponent}
        options={{
          title: "Add Edit Events - Cloud CRM Client Records",
        }}
      />
      <Drawer.Screen
        name="AddEditNotesScreenName"
        component={AddEditNotesScreenComponent}
        options={{
          title: "Add Edit Notes - Cloud CRM Client Records",
        }}
      />
      <Drawer.Screen
        name="AddEditBillsScreenName"
        component={AddEditBillsScreenComponent}
        options={{
          title: "Add Edit Bills - Cloud CRM Client Records",
        }}
      />
      <Drawer.Screen
        name="AppUserListScreenName"
        component={AppUserListScreenComponent}
        options={{
          title: "Search User List - Cloud CRM Client Records",
        }}
      />
      <Drawer.Screen
        name="showCart"
        component={CartScreenComponent}
        options={{
          title: "Search User List - Cloud CRM Client Records",
        }}
      />
      <Drawer.Screen
        name="ViewClientScreenName"
        component={ViewClientScreenComponent}
        options={{
          title: "View Client - Cloud CRM Client Records",
        }}
      />
      <Drawer.Screen
        name="EmailClientsSelectionScreenName"
        component={EmailClientsSelectionScreenComponent}
        options={{
          title: "Select Email Recipients - Cloud CRM Client Records",
        }}
      />
      <Drawer.Screen
        name="EmailComposerScreenName"
        component={EmailComposerScreenComponent}
        options={{
          title: "Compose Email - Cloud CRM Client Records",
        }}
      />
      <Drawer.Screen
        name="EmailHistoryScreenName"
        component={EmailHistoryScreenComponent}
        options={{
          title: "Email History - Cloud CRM Client Records",
        }}
      />
      <Drawer.Screen
        name="EmailViewScreenName"
        component={EmailViewScreenComponent}
        options={{
          title: "View Past Email - Cloud CRM Client Records",
        }}
      />

      <Drawer.Screen
        name="AddUserScreenName"
        component={AddUserScreenComponent}
        options={{
          title: "Add App User - Cloud CRM Client Records",
        }}
      />
      <Drawer.Screen
        name="ViewUserScreenName"
        component={ViewUserScreenComponent}
        options={{
          title: "View App User - Cloud CRM Client Records",
        }}
      />
      <Drawer.Screen
        name="EditUserScreenName"
        component={EditUserScreenComponent}
        options={{
          title: "Edit User - Cloud CRM Client Records",
        }}
      />
      <Drawer.Screen
        name="customizePage"
        component={CustomizePageComponent}
        options={{
          title: "Customize Form - Cloud CRM Client Records",
        }}
      />
      <Drawer.Screen
        name="GroupListScreenName"
        component={GroupListScreenComponent}
        options={{
          title: "Groups List - Cloud CRM Client Records",
        }}
      />
      <Drawer.Screen
        name="AddEditGroupScreenName"
        component={AddEditGroupScreenComponent}
        options={{
          title: "Add or Edit Group - Cloud CRM Client Records",
        }}
      />
      <Drawer.Screen
        name="ViewGroupScreenName"
        component={ViewGroupScreenComponent}
        options={{
          title: "View Group - Cloud CRM Client Records",
        }}
      />
      <Drawer.Screen
        name="GroupMemberSelectionScreenName"
        component={GroupMemberSelectionScreenComponent}
        options={{
          title: "Select Group Members - Cloud CRM Client Records",
        }}
      />
      <Drawer.Screen
        name="TaskListScreenName"
        component={TaskListScreenComponent}
        options={{
          title: "Tasks List - Cloud CRM Client Records",
        }}
      />
      <Drawer.Screen
        name="AddEditTaskScreenName"
        component={AddEditTaskScreenComponent}
        options={{
          title: "Add or Edit Task - Cloud CRM Client Records",
          unmountOnBlur: false,
        }}
      />
      <Drawer.Screen
        name="ViewTaskScreenName"
        component={ViewTaskScreenComponent}
        options={{
          title: "View Task - Cloud CRM Client Records",
        }}
      />
      <Drawer.Screen
        name="TaskMemberSelectionScreenName"
        component={TaskMemberSelectionScreenComponent}
        options={{
          title:
            "Select Members To Assign For Task  - Cloud CRM Client Records",
        }}
      />
      <Drawer.Screen
        name="AddEditProductScreenName"
        component={AddEditProductScreenComponent}
        options={{
          title: "Add or Edit Product - Cloud CRM Client Records",
          unmountOnBlur: false,
        }}
      />
      <Drawer.Screen
        name="ViewProductScreenName"
        component={ViewProductScreenComponent}
        options={{
          title: "View Product - Cloud CRM Client Records",
        }}
      />
      <Drawer.Screen
        name="AddEditPartScreenName"
        component={AddEditPartScreenComponent}
        options={{
          title: "Add or Edit Part - Cloud CRM Client Records",
          unmountOnBlur: false,
        }}
      />
      <Drawer.Screen
        name="ViewPartScreenName"
        component={ViewPartScreenComponent}
        options={{
          title: "View Part - Cloud CRM Client Records",
        }}
      />
      <Drawer.Screen
        name="AddEditAppointmentScreenName"
        component={AddEditAppointmentScreenComponent}
        options={{
          title: "Add or Edit Appointment - Cloud CRM Client Records",
          unmountOnBlur: false,
        }}
      />
      <Drawer.Screen
        name="ViewAppointmentScreenName"
        component={ViewAppointmentScreenComponent}
        options={{
          title: "View Appointment - Cloud CRM Client Records",
        }}
      />
      <Drawer.Screen
        name="HelpScreenName"
        component={HelpScreenComponent}
        options={{
          title: "Help - Cloud CRM Client Records",
        }}
      />
      <Drawer.Screen
        name="PromoScreenName"
        component={PromoScreenComponent}
        options={{
          title: "Enter Code for Free Trial",
        }}
      />
      <Drawer.Screen
        name="OrderScreenName"
        component={OrderScreenComponent}
        options={{
          title: "Order Result",
        }}
      />
      <Drawer.Screen
        name="ContactUsScreenName"
        component={() => {
          useEffect(() => {
            Linking.openURL("mailto:support@siyamiapps.com");
            return () => {
              // cleanup
            };
          }, []);
          // location.reload();
          // Linking.openURL("mailto:abc@def.com");

          return (
            <>
              <Text>We love to help grow your business!</Text>
            </>
          );
        }}
        // component={() => {
        //   useEffect(() => {
        //     (async () => {
        //       let result = await MailComposer.composeAsync({
        //         recipients: ["support@siyamiapps.com"],
        //         subject: "Regarding Cloud CRM for Client Records",
        //         body: "Hi Team, Please help us with question below:  ",
        //         ccRecipients: [],
        //         bccRecipients: [],
        //       });
        //       return result;
        //     })();
        //     return () => {
        //       // cleanup
        //     };
        //   }, []);
        //   location.reload();
        //   return (
        //     <>
        //       <Text>We love to help grow your business!</Text>
        //     </>
        //   );
        // }}
      />
      <Drawer.Screen
        name="ShareAppScreenName"
        component={() => {
          const msg =
            "Hi, this seems like really cool website to checkout. Would be very helpful to grow business. It has nice features to add your own custom fields to match your business need. Would be very easy to maintain and enhance customer relationship. Also mobile apps are available.  https://cloudcrm.siyamiapps.com ";
          useEffect(() => {
            (async () => {
              try {
                const result = await Share.share({
                  message: Strings.shareAppMsg,
                });
                if (result.action === Share.sharedAction) {
                  if (result.activityType) {
                    // shared with activity type of result.activityType
                  } else {
                    // shared
                  }
                } else if (result.action === Share.dismissedAction) {
                  // dismissed
                }
              } catch (error) {
                let result = await MailComposer.composeAsync({
                  recipients: [""], // users can add friend and fmaily here.
                  subject:
                    "Try this really cool app : Cloud CRM for Client Records",
                  body: msg,
                  ccRecipients: [],
                  bccRecipients: [],
                });
                return result;
              }
            })();
            return () => {
              // cleanup
            };
          }, []);
          location.reload();
          return (
            <>
              <Text>We love to help grow your business!</Text>
            </>
          );
        }}
      />
      <Drawer.Screen
        name="ProfileScreenName"
        component={ProfileScreenComponent}
        options={{
          title: "View Profile - Cloud CRM Client Records",
        }}
      />
      <Drawer.Screen
        name="BuyNowFromWebScreen"
        component={() => {
          const [show, setShow] = useState(true);
          //for subusers show this
          let msg =
            "Thank you for your interest in purchasing Premium Subscription Plan. Please contact your Business Owner and request them to Buy from their App";
          let titleOfCloseButton = "OK";
          let onClose = () => {
            Utils.refresh();
          };
          let titleOfOKButton = "Cancel";
          let onOK = () => {
            Utils.refresh();
          };
          //for owners show this
          const { isOwner } = props;

          if (__DEV__)
            console.log("LoggedinHome Props=" + JSON.stringify(props));
          if (isOwner) {
            // after web implementation the control will not come here as it will be handled in Utils.showSub..Plan()
            if (__DEV__) console.log("LoggedinHome is OWner=" + isOwner);
            msg =
              "Thank you for your interest in purchasing Premium Subscription Plan.  Visit Apple AppStore or Google Play Store to install the Android App on your mobile device or tablet. Login to the App using same credentials that you used for the site. Then tap on Menu and press Buy Premium Plans to view the plan prices. Please contact your Business Owner and request them to Buy from their App. ";
            titleOfCloseButton = "Go to Play Store";
            onClose = () => {
              Linking.openURL(
                "https://play.google.com/store/apps/details?id=com.siyami.apps.cloudcrm.androidapp"
              );
              NavUtils.showClientListScreen(props.navigation, FormType.CLIENT);
            };
            titleOfOKButton = "Apple App Store";
            onOK = () => {
              Linking.openURL(
                "https://apps.apple.com/us/app/cloud-crm-client-records-app/id1586208647"
              );
              NavUtils.showClientListScreen(props.navigation, FormType.CLIENT);
            };
          }
          return (
            <CRMAlerts
              navigation={props.navigation}
              show={show}
              title={"Buy Premium Plans"}
              msg={msg}
              onClose={onClose}
              titleOfCloseButton={titleOfCloseButton}
              onOK={onOK}
              titleOfOKButton={titleOfOKButton}
            />
          );
        }}
      />
    </Drawer.Navigator>
  );
};
function ProfileScreenComponent({ navigation, route }) {
  return (
    <Page
      navigation={navigation}
      titleHeader="Profile"
      pageContent={<ProfileScreen navigation={navigation} route={route} />}
      showMenu={true}
    />
  );
}
function AppointmentsScreenComponent({ navigation, route }) {
  //no menu as it cause it blank due to calendar UI component. So refresh happens on back to clients
  return (
    <Page
      navigation={navigation}
      titleHeader="Appointments"
      pageContent={<AppointmentsScreen navigation={navigation} route={route} />}
      showMenu={false}
    />
  );
}
function TaskMemberSelectionScreenComponent({ navigation, route }) {
  return (
    <Page
      navigation={navigation}
      titleHeader="Select Members"
      pageContent={
        <TaskMemberSelectionScreen navigation={navigation} route={route} />
      }
      showMenu={false}
    />
  );
}
function HelpScreenComponent({ navigation, route }) {
  return (
    <Page
      navigation={navigation}
      titleHeader="Help"
      pageContent={<HelpScreen navigation={navigation} route={route} />}
      showMenu={true}
    />
  );
}
function PromoScreenComponent({ navigation, route }) {
  return (
    <Page
      navigation={navigation}
      titleHeader="Promo Code"
      pageContent={<PromoScreen navigation={navigation} route={route} />}
      showMenu={true}
    />
  );
}
function OrderScreenComponent({ navigation, route }) {
  return (
    <Page
      navigation={navigation}
      titleHeader="Order Result"
      pageContent={<OrderScreen navigation={navigation} route={route} />}
      showMenu={true}
    />
  );
}
function ViewTaskScreenComponent({ navigation, route }) {
  return (
    <Page
      navigation={navigation}
      titleHeader="View Task"
      pageContent={<ViewTaskScreen navigation={navigation} route={route} />}
    />
  );
}

function AddEditTaskScreenComponent({ navigation, route }) {
  return (
    // <Overlay
    //   fullScreen
    //   ModalComponent={Modal}
    //   width="auto"
    //   height="auto"
    // >
    <Page
      navigation={navigation}
      titleHeader="Enter Task Info"
      pageContent={<AddEditTaskScreen navigation={navigation} route={route} />}
      showMenu={false}
    />
  );
}
function TaskListScreenComponent({ navigation, route }) {
  return (
    <Page
      navigation={navigation}
      titleHeader="Tasks"
      pageContent={<TaskListScreen navigation={navigation} route={route} />}
    />
  );
}

function GroupMemberSelectionScreenComponent({ navigation, route }) {
  return (
    <Page
      navigation={navigation}
      titleHeader="Select Members"
      pageContent={
        <GroupMemberSelectionScreen navigation={navigation} route={route} />
      }
      showMenu={false}
    />
  );
}

function ViewGroupScreenComponent({ navigation, route }) {
  return (
    <Page
      navigation={navigation}
      titleHeader="View Group"
      pageContent={<ViewGroupScreen navigation={navigation} route={route} />}
    />
  );
}

function AddEditGroupScreenComponent({ navigation, route }) {
  return (
    <Page
      navigation={navigation}
      titleHeader="Enter Group Info"
      pageContent={<AddEditGroupScreen navigation={navigation} route={route} />}
      showMenu={false}
    />
  );
}
function GroupListScreenComponent({ navigation, route }) {
  return (
    <Page
      navigation={navigation}
      titleHeader="Groups"
      pageContent={<GroupListScreen navigation={navigation} route={route} />}
    />
  );
}
function AppUserListScreenComponent({ navigation, route }) {
  // route = { ...route, params: { formType: FormType.USER } };

  return (
    <Page
      navigation={navigation}
      titleHeader="Manage App Users"
      pageContent={<UserListScreen navigation={navigation} route={route} />}
    />
  );
}
function CartScreenComponent({ navigation, route }) {
  // route = { ...route, params: { formType: FormType.USER } };
  return (
    <Page
      navigation={navigation}
      titleHeader="Select Premium Plan"
      pageContent={<CartScreen navigation={navigation} route={route} />}
    />
  );
}
function SupplierListScreenComponent({ navigation, route }) {
  route = { ...route, params: { formType: FormType.SUPPLIER } };

  return (
    <Page
      navigation={navigation}
      titleHeader="Supplier List"
      pageContent={<ClientListScreen navigation={navigation} route={route} />}
      showHeader={false}
      showMenu={false}
    />
  );
}
function BillsListScreenComponent({ navigation, route }) {
  return (
    <Page
      navigation={navigation}
      titleHeader="Bills"
      pageContent={<BillsListScreen navigation={navigation} route={route} />}
    />
  );
}
function NotesListScreenComponent({ navigation, route }) {
  return (
    <Page
      navigation={navigation}
      titleHeader="Notes"
      pageContent={<NotesListScreen navigation={navigation} route={route} />}
    />
  );
}
function ProductCatalogScreenComponent({ navigation, route }) {
  return (
    <Page
      navigation={navigation}
      titleHeader="Product Catalog"
      pageContent={
        <ProductCatalogScreen navigation={navigation} route={route} />
      }
    />
  );
}
function ViewProductScreenComponent({ navigation, route }) {
  return (
    <Page
      navigation={navigation}
      titleHeader="View Product"
      pageContent={
        <ViewProductComponent navigation={navigation} route={route} />
      }
    />
  );
}

function AddEditProductScreenComponent({ navigation, route }) {
  return (
    <Page
      navigation={navigation}
      titleHeader="Enter Product Info"
      pageContent={
        <AddEditProductComponent navigation={navigation} route={route} />
      }
      showMenu={false}
    />
  );
}

function PartsInventoryScreenComponent({ navigation, route }) {
  return (
    <Page
      navigation={navigation}
      titleHeader="Part Inventory"
      pageContent={
        <PartsInventoryScreen navigation={navigation} route={route} />
      }
    />
  );
}
function ViewPartScreenComponent({ navigation, route }) {
  return (
    <Page
      navigation={navigation}
      titleHeader="View Part"
      pageContent={<ViewPartComponent navigation={navigation} route={route} />}
    />
  );
}

function AddEditPartScreenComponent({ navigation, route }) {
  return (
    <Page
      navigation={navigation}
      titleHeader="Enter Part Info"
      pageContent={
        <AddEditPartComponent navigation={navigation} route={route} />
      }
      showMenu={false}
    />
  );
}

function ViewAppointmentScreenComponent({ navigation, route }) {
  return (
    <Page
      navigation={navigation}
      titleHeader="View Appointment"
      pageContent={
        <ViewAppointmentComponent navigation={navigation} route={route} />
      }
      showMenu={false}
    />
  );
}

function AddEditAppointmentScreenComponent({ navigation, route }) {
  return (
    // <Overlay
    //   fullScreen
    //   ModalComponent={Modal}
    //   width="auto"
    //   height="auto"
    // >
    <Page
      navigation={navigation}
      titleHeader="Enter Appointment Info"
      pageContent={
        <AddEditAppointmentComponent navigation={navigation} route={route} />
      }
      showMenu={false}
    />
  );
}
function AddEditEventScreenComponent({ navigation, route }) {
  return (
    <Page
      navigation={navigation}
      titleHeader="Event Details"
      pageContent={
        <AddEditEventComponent navigation={navigation} route={route} />
      }
    />
  );
}
function AddEditNotesScreenComponent({ navigation, route }) {
  return (
    <Page
      navigation={navigation}
      titleHeader="Note Details"
      pageContent={
        <AddEditNotesComponent navigation={navigation} route={route} />
      }
    />
  );
}
function AddEditBillsScreenComponent({ navigation, route }) {
  return (
    <Page
      navigation={navigation}
      titleHeader="Bill Details"
      pageContent={
        <AddEditBillsComponent navigation={navigation} route={route} />
      }
    />
  );
}
function StaffListScreenComponent({ navigation, route }) {
  route = { ...route, params: { formType: FormType.STAFF } };

  return (
    <Page
      navigation={navigation}
      titleHeader="Staff List"
      pageContent={<ClientListScreen navigation={navigation} route={route} />}
      showHeader={false}
      showMenu={false}
    />
  );
}
//DO NOT USE RENDER FUNCTION HERE as it loses state of parent during mount and unmount when navigator goes back.
//basically new component is rendered via new function as opposed to retaining and displaying old parent
function AddClientScreenComponent({ navigation, route }) {
  //route.params.formType = FormType.CLIENT;//only use this line for direct menu add button but if it goes theu list, then use passed in routte
  //perhaps always pass title header and route form outside anyway
  return (
    <Page
      navigation={navigation}
      titleHeader={`Enter ${route.params.formType}`}
      pageContent={<AddClientScreen navigation={navigation} route={route} />}
      showMenu={false}
    />
  );
}
function LeadListScreenComponent({ navigation, route }) {
  route = { ...route, params: { formType: FormType.LEAD } };

  return (
    <Page
      navigation={navigation}
      titleHeader="Leads List"
      pageContent={<ClientListScreen navigation={navigation} route={route} />}
      showHeader={false}
      showMenu={false}
    />
  );
}
function CustomizePageComponent({ navigation, route }) {
  //route.formType = FormType.CLIENT;
  return (
    <Page
      navigation={navigation}
      titleHeader="Customize Fields"
      pageContent={
        <CustomizeFieldsFormComponent navigation={navigation} route={route} />
      }
      showMenu={false}
    />
  );
}

function ViewClientScreenComponent({ navigation, route }) {
  return (
    <Page
      navigation={navigation}
      titleHeader={`View ${route.params.formType}`}
      pageContent={<ViewClientScreen navigation={navigation} route={route} />}
    />
  );
}
function ClientListScreenComponent({ navigation, route }) {
  route = { ...route, params: { formType: FormType.CLIENT } };
  return (
    <Page
      navigation={navigation}
      titleHeader="Client List"
      pageContent={<ClientListScreen navigation={navigation} route={route} />}
      showHeader={false}
      showMenu={false}
    />
  );
}
function EmailHistoryScreenComponent({ navigation, route }) {
  const bulkProtocolType = route.params?.bulkProtocolType
    ? route.params?.bulkProtocolType
    : BulkProtocolType.EMAIL;
  return (
    <Page
      navigation={navigation}
      titleHeader={`Bulk ${bulkProtocolType} History`}
      pageContent={<EmailHistoryScreen navigation={navigation} route={route} />}
    />
  );
}
// const SMSHistoryScreenComponent = ({ navigation, route }) => {
//   route = { ...route, params: { bulkProtocolType: "SMS" } };
//   return (
//     <Page
//       navigation={navigation}
//       titleHeader="Bulk SMS History"
//       pageContent={<EmailHistoryScreen navigation={navigation} route={route} />}
//     />
//   );
// };
function EmailClientsSelectionScreenComponent({ navigation, route }) {
  const bulkProtocolType = route.params?.bulkProtocolType
    ? route.params?.bulkProtocolType
    : BulkProtocolType.EMAIL;
  return (
    <Page
      navigation={navigation}
      titleHeader="Select Persons"
      pageContent={
        <EmailPersonsSelectionScreen navigation={navigation} route={route} />
      }
      showMenu={false}
    />
  );
}
function EmailComposerScreenComponent({ navigation, route }) {
  return (
    <Page
      navigation={navigation}
      titleHeader={`New Bulk ${route.params.bulkProtocolType}`}
      pageContent={
        <EmailComposerScreen navigation={navigation} route={route} />
      }
      showMenu={false}
    />
  );
}

function EmailViewScreenComponent({ navigation, route }) {
  const bulkProtocolType = route.params?.bulkProtocolType
    ? route.params?.bulkProtocolType
    : BulkProtocolType.EMAIL;
  return (
    <Page
      navigation={navigation}
      titleHeader={`View Bulk ${bulkProtocolType}`}
      pageContent={<EmailViewScreen navigation={navigation} route={route} />}
    />
  );
}
function AddUserScreenComponent({ navigation, route }) {
  return (
    <Page
      navigation={navigation}
      titleHeader="Add App User"
      pageContent={<AddUserScreen navigation={navigation} route={route} />}
      showMenu={false}
    />
  );
}
function EditUserScreenComponent({ navigation, route }) {
  return (
    <Page
      navigation={navigation}
      titleHeader="Edit User"
      pageContent={<EditUserScreen navigation={navigation} route={route} />}
      showMenu={false}
    />
  );
}
function ViewUserScreenComponent({ navigation, route }) {
  return (
    <Page
      navigation={navigation}
      titleHeader="View User"
      pageContent={<ViewUserScreen navigation={navigation} route={route} />}
    />
  );
}
