import { FlatList, TouchableOpacity, Text, View } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import { Input, Button } from "react-native-elements";
import { styles } from "../../App";
import React, { useState, useEffect } from "react";
import { Client, AddUserToT, FormType } from "../../src/models/index";
import { DataStore } from "@aws-amplify/datastore";
import { Auth } from "aws-amplify";
import { getGroupNameAsTenant } from "../../utils/getGroupNameAsTenant";
import { Hub } from "aws-amplify";
import * as UserPermissions from "../../utils/UserPermissions";
import * as NavUtils from "../../utils/NavUtils";
import { commonStyles } from "../../styles/commonStyles";
import * as AppColors from "../../styles/AppColors";
import { ScrollView } from "react-native-gesture-handler";
import ErrorMessageComponent from "../../components/ErrorMessageComponent";
import { LinkButton } from "../../components/ButtonComponents";

export const UserListScreen = ({ navigation, route }) => {
  const [users, setUsers] = useState({ result: [] });
  const [errors, setErrors] = useState("");
  const [isOwner, setIsOwner] = useState(false);

  async function showUserList() {
    const isNotCofirmed = await UserPermissions.isNotConfirmed();
    if (isNotCofirmed) return;
    const users = await DataStore.query(AddUserToT);
    // alert('db called len'+clients.length);
    setUsers({ result: users });
  }

  //this even is called everytime - first time after login there is nothing to show until load above gets data
  useEffect(() => {
    const subscription = DataStore.observe(AddUserToT).subscribe((msg) => {
      //setRefresh(!refresh);
      //docs say do not put await in observe as it is long running
      showUserList();
      if (__DEV__)
        console.log(
          "user sub received in subscription",
          msg.model,
          msg.opType,
          msg.element
        );
    });
    (async () => {
      const isOwnerInit =
        await UserPermissions.isCurrentlyLoggedInUserAnOwner();
      setIsOwner(isOwnerInit);
      if (isOwnerInit) {
        await showUserList();
      } else {
        setErrors(
          "Only Owner role is allowed to Manage App Users. Please contact Owner to Manage App Users."
        );
      }
    })();
    return () => subscription.unsubscribe();
  }, []);

  const Item = ({ item, onPress, deleteItem, style }) => (
    <TouchableOpacity onPress={onPress} style={[commonStyles.rowItem, style]}>
      <View style={{ flexDirection: "row" }}>
        <Text style={commonStyles.listTextMainRow}>
          {item.firstName + " " + item.lastName}
        </Text>
      </View>
      <Text style={[commonStyles.listTextSubRow, { color: "black" }]}>
        {item.userEmail}
      </Text>
      <Text style={commonStyles.listTextSubRow}>{item.cellPhone}</Text>
      <Text
        style={commonStyles.listTextSubRow}
      >{`Status : ${item.userStatus}`}</Text>
      <Text style={commonStyles.listTextSubRow}>{item.address}</Text>
    </TouchableOpacity>
  );

  const renderItem = ({ item, index }) => {
    const backgroundColor =
      index % 2 === 0 ? AppColors.ROW_BRIGHT : AppColors.ROW_AFTER_BRIGHT;
    return (
      <Item
        item={item}
        onPress={() => {
          // Auth.currentSession()
          //   .then((data) => if(__DEV__) console.log('got session',data))
          //   .catch((err) => if(__DEV__) console.log('got session error',err));
          //alert("on press " + item.id);

          //Admin is not currently used
          if (isOwner) NavUtils.showViewUserScreen(navigation, item.id);
        }}
        // deleteItem={async () => {
        //   const todelete = await DataStore.query(AddUserToT, item.id);
        //   DataStore.delete(todelete);
        //   showUserList();
        // }}
        style={{ backgroundColor: backgroundColor }}
      />
    );
  };

  return (
    <SafeAreaView style={commonStyles.pageContainer}>
      {errors.length > 0 && <ErrorMessageComponent errorMsg={errors} />}
      {isOwner && (
        <>
          <LinkButton
            title={`< Back to Client List`}
            submit={() =>
              NavUtils.showPersonListScreen(navigation, FormType.CLIENT)
            }
          />
          <Button
            title="Add App User"
            containerStyle={commonStyles.buttonRowTop}
            onPress={() => {
              NavUtils.showAddUserScreen(navigation);
            }}
          />

          <ScrollView>
            <FlatList
              data={users.result}
              renderItem={renderItem}
              keyExtractor={(item) => item.id}
            />
          </ScrollView>
        </>
      )}
    </SafeAreaView>
  );
};
