import { FlatList, View, TouchableOpacity, Text } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import { Input, Button } from "react-native-elements";
import React, { useState, useEffect } from "react";
import { Task, AddUserToT } from "../../src/models/index";
import { DataStore, Predicates, SortDirection } from "@aws-amplify/datastore";
import { Hub } from "aws-amplify";
import * as UserPermissions from "../../utils/UserPermissions";
import * as NavUtils from "../../utils/NavUtils";
import { commonStyles } from "../../styles/commonStyles";
import * as AppColors from "../../styles/AppColors";
import { SearchBar } from "react-native-elements";
import { LinkButton, SubmitButton } from "../../components/ButtonComponents";
import { Asset } from "expo-asset";
import AppLoading from "expo-app-loading";
import { ScrollView } from "react-native-gesture-handler";
import ErrorMessageComponent from "../../components/ErrorMessageComponent";
import * as DBUtils from "../../utils/DBUtils";
import { TaskStatus } from "../../src/models";
import { FormType } from "../../src/models";
import * as Utils from "../../utils/Utils";
import { useConfirmation } from "../../components/confirmation/ConfirmationService";

export const TaskListScreen = ({ navigation, route }) => {
  const [selectedId, setSelectedId] = useState(null);
  const [tasks, setTasks] = useState([]);
  const [search, setSearch] = useState("");
  const [errors, setErrors] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const confirm = useConfirmation();

  async function showTaskList() {
    const isNotCofirmed = await UserPermissions.isNotConfirmed();
    if (isNotCofirmed) {
      setErrors(
        "User not confirmed. Ask Owner or Admin to check the user status on Users Screen."
      );
      return;
    }
    const tasks = await DataStore.query(Task, Predicates.ALL, {
      sort: (s) => s.taskName(SortDirection.ASCENDING),
    });
    // alert('db called len'+tasks.length);
    await DBUtils.checkDBSync(tasks, setErrors, setTasks);
    Utils.checkAndShowTrialEndsSoonDialog(
      false,
      navigation,
      confirm,
      undefined
    );
    //// setTasks(tasks);
  }

  //this even is called everytime - first time after login there is nothing to show until load above gets data
  useEffect(() => {
    (async () => {
      await showTaskList();
    })();
  }, []);

  const Item = ({ item, onPress, style }) => {
    let color = "black";
    if (item.status === TaskStatus.DONE) color = "green";
    else if (new Date(item.dueDate) <= new Date()) color = "red";

    return (
      <TouchableOpacity onPress={onPress} style={[commonStyles.rowItem, style]}>
        <View style={{ flexDirection: "row" }}>
          <Text style={[commonStyles.listTextMainRow, { color: color }]}>
            {item.taskName}
          </Text>
        </View>
        <Text style={commonStyles.listTextSubRow}>{item.taskPurpose}</Text>
        {item.dueDate && (
          <Text style={commonStyles.listTextSubRow}>
            {new Date(item.dueDate).toLocaleDateString()}
          </Text>
        )}
      </TouchableOpacity>
    );
  };

  const renderItem = ({ item, index }) => {
    const backgroundColor =
      index % 2 === 0 ? AppColors.ROW_BRIGHT : AppColors.ROW_AFTER_BRIGHT;
    return (
      <Item
        item={item}
        onPress={() => {
          setSelectedId(item.id);
          NavUtils.showViewTaskScreen(navigation, item.id);
        }}
        style={{ backgroundColor: backgroundColor }}
      />
    );
  };
  const updateSearch = async (search) => {
    setSearch(search);
    const searchCapWords = search
      .split(" ")
      .map(Utils.capitalizeFirstCharOfWord)
      .join(" ");
    const searchCapFirstWord = Utils.capitalizeFirstCharOfWord(search);
    const searchResult = await DataStore.query(Task, (c) =>
      c.or((c) =>
        c
          .taskName("contains", search)
          .taskName("contains", searchCapWords)
          .taskPurpose("contains", search)
          .taskPurpose("contains", searchCapFirstWord)
      )
    );
    setTasks(searchResult);
  };

  return (
    <SafeAreaView style={commonStyles.pageContainer}>
      {errors.length > 0 && <ErrorMessageComponent errorMsg={errors} />}

      {/* {isSearching && <ActivityIndicator size="large" />} */}
      {!(errors.length > 0) && (
        <>
          <LinkButton
            title={`< Back to Client List`}
            submit={() =>
              NavUtils.showPersonListScreen(navigation, FormType.CLIENT)
            }
          />
          <Button
            testID="AddTask"
            containerStyle={commonStyles.buttonRowTop}
            title="Add Task"
            onPress={() =>
              NavUtils.showAddEditTaskScreen(navigation, undefined)
            }
          />
          {!isSearching && (
            <SearchBar
              placeholder="Type task name or purpose"
              onChangeText={updateSearch}
              value={search}
              lightTheme={true}
              inputStyle={{ color: "black" }}
              containerStyle={{ padding: 0 }}
              inputContainerStyle={{ backgroundColor: "ultralighgray" }}
            />
          )}
          {!isSearching && (
            <ScrollView>
              <FlatList
                data={tasks}
                renderItem={renderItem}
                keyExtractor={(item) => item.id}
                extraData={selectedId}
              />
            </ScrollView>
          )}
        </>
      )}
    </SafeAreaView>
  );
};
