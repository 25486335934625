import { Auth, DataStore, Predicates, SortDirection } from "aws-amplify";
import {
  getCustomFieldsFromDB,
  initialStateForPersonForm,
} from "../components/person/PersonFormComponent";
import {
  AddUserToTenantStatus,
  EulaAccepted,
  EulaTracker,
  TaskStatus,
} from "../src/models";
import {
  AddUserToT,
  Client,
  CustomForm,
  FormType,
  Group,
  GroupClient,
  RolesDefault,
  Task,
  TaskClient,
  EventRepeat,
  Event,
} from "../src/models";
import { getGroupNameAsTenant } from "./getGroupNameAsTenant";
import * as Utils from "./Utils";
import { v4 as uuidv4 } from "uuid";
import * as Analytics from "expo-firebase-analytics";

export async function getCustomForm(formType: FormType) {
  const customFormOriginal = await DataStore.query(CustomForm, (c) =>
    c.formType("eq", formType)
  );
  return customFormOriginal;
}

export async function getClients(search: string | undefined) {
  let clients;
  if (search === undefined) {
    clients = await DataStore.query(Client, Predicates.ALL, {
      sort: (s) => s.firstName(SortDirection.ASCENDING),
    });
  } else {
    clients = await DataStore.query(Client, (c) =>
      c.or((c) =>
        c
          .firstName("contains", search)
          .lastName("contains", search)
          .cellPhone("contains", search)
          .email("contains", search)
          .address("contains", search)
          .notes("contains", search)
          //to prevent custom field names matching search. This does exact match though as a result compromise
          .customFields("contains", '"value"' + ':"' + search + '"')
      )
    );
  }
  return clients;
}

export async function deleteClient(client: any) {
  await DataStore.delete(client);
}
// GROUPS
export async function getMembersForGroupId(groupId: string) {
  const members = (await DataStore.query(GroupClient)).filter(
    (g) => g.group.id === groupId
  );
  return members;
}
export async function getGroup(groupId: string) {
  const group = await DataStore.query(Group, groupId);
  return group;
}
export async function deleteGroupClient(groupClient: GroupClient) {
  await DataStore.delete(groupClient);
}
export async function getGroupClientsForClient(clientId: string) {
  const groups = (await DataStore.query(GroupClient)).filter(
    (g) => g.client.id === clientId
  );
  return groups;
}
export async function saveGroupClient(
  group: Group | undefined,
  client: { id: string }
) {
  const groups = (await DataStore.query(GroupClient)).filter(
    (g) => g.client.id === client.id && g.group.id === group.id
  );
  // client is already in the group so return otherwise duplicate add happens

  if (groups?.length > 0) return;
  const tenant = await getGroupNameAsTenant();
  await DataStore.save(
    new GroupClient({
      group: group,
      client: client,
      tenant: tenant,
    })
  );
}

export async function getAllGroups() {
  const groups = await DataStore.query(Group);
  return groups;
}

export async function getAllMatchingGroups(search: string) {
  const groups = (await DataStore.query(Group)).filter((g) =>
    g.groupName?.includes(search)
  );
  return groups;
}

// TASKS
export async function getMembersForTaskId(taskId: string) {
  const members = (await DataStore.query(TaskClient)).filter(
    (g) => g.task.id === taskId
  );
  return members;
}
export async function getTask(taskId: string) {
  const task = await DataStore.query(Task, taskId);
  return task;
}
export async function deleteTaskClient(taskClient: any) {
  await DataStore.delete(taskClient);
}
export async function getTaskClientsForClient(clientId: string) {
  const tasks = (await DataStore.query(TaskClient)).filter(
    (g) => g.client.id === clientId
  );
  return tasks;
}
export async function saveTaskClient(
  task: Task | undefined,
  client: { id: string }
) {
  if (task) {
    const tasks = (await DataStore.query(TaskClient)).filter(
      (g) => g.client.id === client.id && g.task?.id === task?.id
    );
    // client is already in the task so return otherwise duplicate add happens

    if (tasks?.length > 0) return;
    const tenant = await getGroupNameAsTenant();
    await DataStore.save(
      new TaskClient({
        task: task,
        client: client,
        tenant: tenant,
      })
    );
  }
}

export async function getAllTasks() {
  const tasks = await DataStore.query(Task);
  return tasks;
}

export async function getAllMatchingTasks(search: string) {
  const tasks = (await DataStore.query(Task)).filter((g) =>
    g.taskName?.includes(search)
  );
  return tasks;
}

export async function insertOwnerIfNull() {
  //also used for demo data  and creating first/user record
  // Need to do this as owner is reserved keyword in dynamo
  //and cannot add owner in post confirmation trigger as a result

  //remember sub-users are alerady added thru UI by owner and record in this table is already created.
  //so there will be always a match.

  const user = await Auth.currentAuthenticatedUser();
  const userEmailCurrent = user.attributes.email;
  const group = await getGroupNameAsTenant();

  const userMatched = await DataStore.query(AddUserToT, (u) =>
    u.userEmail("eq", userEmailCurrent).tenant("eq", group)
  );
  if (userMatched.length === 0) {
    if (__DEV__)
      console.log(
        "Inserting new user" + userEmailCurrent + " for group =" + group
      );
    const user = await DataStore.save(
      new AddUserToT({
        firstName: "",
        lastName: "",
        userEmail: userEmailCurrent,
        tenant: userEmailCurrent, //or tenant like other models? check lambdas too
        userStatus: AddUserToTenantStatus.CONFIRMED,
        userRole: RolesDefault.OWNER,
        createdOnDate: new Date().toISOString(),
        daysFree: Utils.getFreeTrialLimit(),
        extIdForAnalytics: uuidv4(),
      })
    );
    //Add demo
    await insertDemoData();
  } else if (userMatched.length === 1) {
    if (__DEV__)
      console.log(
        "CONFIRM USER: In checking whether to CONFIRM status of new user" +
          userEmailCurrent +
          " for group =" +
          group +
          " whose status =" +
          userMatched[0].userStatus
      );
    //sub-user who logged in and if first time then status is unconfirmed. Change to confirmed. Test pasword reset
    if (userMatched[0].userStatus === AddUserToTenantStatus.UNCONFIRMED) {
      //update status to cofirmed. But dont do this if disabled or deleted . May be if ENABLED but do it when implemented enabling
      if (__DEV__)
        console.log(
          "CONFIRM USER: REALLY Updating new user" +
            userEmailCurrent +
            " for group =" +
            group
        );
      const subUser = await DataStore.save(
        AddUserToT.copyOf(userMatched[0], (updated) => {
          const returnedTarget = Object.assign(updated, {
            userStatus: AddUserToTenantStatus.CONFIRMED,
          });
        })
      );
    } else {
      if (__DEV__)
        console.log(
          "User status is not UNCOFIRMED but is",
          userMatched[0].userStatus
        );
    }
    // add extIdForAnalytics if blank

    if (
      userMatched[0].extIdForAnalytics === undefined ||
      userMatched[0].extIdForAnalytics === null ||
      userMatched[0].extIdForAnalytics.trim().length === 0
    ) {
      await DataStore.save(
        AddUserToT.copyOf(userMatched[0], (updated) => {
          const returnedTarget = Object.assign(updated, {
            extIdForAnalytics: uuidv4(),
          });
        })
      );
    }
  } else {
    if (__DEV__)
      console.error(
        "Multiple users exists for 'if owner exists' check. So RESEARCH cause."
      );
  }
}
export async function checkDBSync(expectedObjs, setError, setObj) {
  const tenantLoggedIn = await getGroupNameAsTenant();
  const staleData = expectedObjs.filter((c) => {
    if (
      c.tenant === null ||
      c.tenant === undefined ||
      c.tenant.trim().length === 0
    ) {
      console.error("Null tenant. Report to App Developer");
      return false;
    }
    return c.tenant !== tenantLoggedIn;
  });
  if (__DEV__)
    console.log(
      "obj len curr=" + expectedObjs.length + " stale=" + staleData.length
    );
  if (staleData.length > 0) {
    if (__DEV__) {
      staleData.forEach((element, i) => {
        console.log("staledata " + i + " =" + JSON.stringify(element));
      });
    }
    setError(
      "Sorry! Data Error detected. Need Re-Sync. Logging out automatically. If it doesn't, please press Menu > Profile > Logout button to delete Local Data. If problem continues, please send an email to support@siyamiaspp.com"
    );
    // await Analytics.logEvent("Resync", {
    //   staleCount: staleData.length,
    // });

    await DataStore.clear();
    await new Promise((resolve) => setTimeout(resolve, 10000)); // 3 sec
    //throw Sentry error with staledata id if less than 100
    await Auth.signOut();
    Utils.refresh();
  } else {
    setObj(expectedObjs);
  }
}

export async function isEulaAccepted() {
  const user = await Auth.currentAuthenticatedUser();
  const email = user.attributes.email;
  const userMatched = await DataStore.query(
    EulaTracker,
    (u) => u.email("eq", email) //.accepted("eq", EulaAccepted.YES)
  );
  if (userMatched.length === 0) {
    if (__DEV__) console.log("user not accepted eula");
    return false;
  } else {
    return true;
  }
}

export async function getLoggedInUserDBDetails() {
  const userEmailCurrent = await Utils.getUserEmail();

  const userMatched = await DataStore.query(AddUserToT, (u) =>
    u.userEmail("eq", userEmailCurrent)
  );
  if (userMatched.length > 1) {
    throw new Error(
      userMatched.length + " users for email=" + userEmailCurrent
    );
    return undefined;
  }
  return userMatched[0];
}

export async function getTotalUsers() {
  const allUsers = await DataStore.query(AddUserToT);
  return allUsers.length;
}
export async function insertDemoData() {
  //add clients
  const initFormState = initialStateForPersonForm(FormType.CLIENT);
  const customFieldsFromDB = await getCustomFieldsFromDB(FormType.CLIENT);
  const clientDemo = { ...initFormState, customFields: customFieldsFromDB };
  const tenant = await getGroupNameAsTenant();
  const client = await DataStore.save(
    new Client({
      ...clientDemo,
      firstName: "Demo",
      lastName: "Client",
      cellPhone: "1-555-555-5555",
      email: "democlient@examplemail123.com",
      address: "1 Crm Street, Biz Town, USA",
      notes: "Needs our services every month",
      tenant: tenant,
      formType: FormType.CLIENT,
    })
  );

  //add leads
  const initFormStateLead = initialStateForPersonForm(FormType.LEAD);
  const customFieldsFromDBLead = await getCustomFieldsFromDB(FormType.LEAD);
  const leadDemo = {
    ...initFormStateLead,
    customFields: customFieldsFromDBLead,
  };
  await DataStore.save(
    new Client({
      ...leadDemo,
      firstName: "Demo",
      lastName: "Lead",
      cellPhone: "1-555-555-5555",
      email: "demolead@examplemail123.com",
      address: "1 Lead Street, Biz Town, USA",
      notes: "Very interested in our offers",
      tenant: tenant,
      formType: FormType.LEAD,
    })
  );

  //add supplier
  const initFormStateSupplier = initialStateForPersonForm(FormType.SUPPLIER);
  const customFieldsFromDBSuppler = await getCustomFieldsFromDB(
    FormType.SUPPLIER
  );
  const supplierDemo = {
    ...initFormStateSupplier,
    customFields: customFieldsFromDBSuppler,
  };
  await DataStore.save(
    new Client({
      ...supplierDemo,
      firstName: "Demo",
      lastName: "Supplier",
      cellPhone: "1-555-555-5555",
      email: "demosupplier@examplemail123.com",
      address: "1 Supply Chain Street, Biz Town, USA",
      notes: "Reliable supplier of goods",
      tenant: tenant,
      formType: FormType.SUPPLIER,
    })
  );
  //add staff
  const initFormStateStaff = initialStateForPersonForm(FormType.STAFF);
  const customFieldsFromDBStaff = await getCustomFieldsFromDB(FormType.STAFF);
  const staffDemo = {
    ...initFormStateStaff,
    customFields: customFieldsFromDBStaff,
  };
  await DataStore.save(
    new Client({
      ...staffDemo,
      firstName: "Demo",
      lastName: "Staff",
      cellPhone: "1-555-555-5555",
      email: "demostaff@examplemail123.com",
      address: "1 Staff Street, Biz Town, USA",
      notes: "Employee of the Year!",
      tenant: tenant,
      formType: FormType.STAFF,
    })
  );
  //add tasks
  const today = new Date();
  const tomorrow = new Date();
  const dayAfterTomorrow = new Date();
  const dayAfterTomorrowPlusOne = new Date();

  // Add 1 Day
  tomorrow.setDate(today.getDate() + 1);
  dayAfterTomorrow.setDate(today.getDate() + 2);
  dayAfterTomorrowPlusOne.setDate(today.getDate() + 3);

  const task = {
    taskName: "Call Demo Lead",
    taskPurpose: "Try to convert lead into client",
    dueDate: tomorrow.toISOString(),
    status: TaskStatus.NOT_DONE,
    tenant: tenant,
  };
  await DataStore.save(new Task({ ...task }));
  const task2 = {
    taskName: "Send Bulk Email with Coupon Code",
    taskPurpose: "Retain clients. Increase sales. Grow business",
    dueDate: dayAfterTomorrow.toISOString(),
    status: TaskStatus.NOT_DONE,
    tenant: tenant,
  };
  await DataStore.save(new Task({ ...task2 }));
  const task3 = {
    taskName: "Plan office party",
    taskPurpose: "Increase employee morale",
    dueDate: dayAfterTomorrowPlusOne.toISOString(),
    status: TaskStatus.NOT_DONE,
    tenant: tenant,
  };
  await DataStore.save(new Task({ ...task3 }));
  //add groups
  const group1 = {
    groupName: "VIP Customers",
    groupPurpose: "Group of VIP customers - Treat them very well!",
    tenant: tenant,
  };
  await DataStore.save(new Group(group1));

  const group2 = {
    groupName: "Low cost, High quality suppliers",
    groupPurpose: "Group of best suppliers!",
    tenant: tenant,
  };
  await DataStore.save(new Group(group2));
  //add events
  await DataStore.save(
    new Event({
      clientId: client.id,
      tenant: tenant,
      name: "Birthday!",
      notes: "",
      startTime: new Date().toISOString(),
      otherData: "",
      repeats: EventRepeat.YEAR,
      repeatsCustom: -1,
    })
  );
}

export async function getFormType(clientId) {
  const client = await DataStore.query(Client, clientId);
  return client?.formType ?? FormType.CLIENT;
}
