import { FlatList, TouchableOpacity, Text, View, Modal } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import {
  Input,
  Button,
  Card,
  ButtonGroup,
  Overlay,
} from "react-native-elements";
import { styles } from "../../App";
import React, { useState, useEffect } from "react";
import { Client, FormType, Group } from "../../src/models/index";
import { DataStore } from "@aws-amplify/datastore";
import { commonStyles } from "../../styles/commonStyles";
import * as NavUtils from "../../utils/NavUtils";
import * as DBUtils from "../../utils/DBUtils";
import * as Utils from "../../utils/Utils";
import { useConfirmation } from "../../components/confirmation/ConfirmationService";
import { ScrollView } from "react-native-gesture-handler";
import * as AppColors from "../../styles/AppColors";
import { blue } from "@material-ui/core/colors";
import { SubmitAndCancelButtons } from "../ButtonComponents";
//import Modal from "react-native-web";
import Autocomplete from "react-native-autocomplete-input";
import { useNavigation } from "@react-navigation/core";
export const GroupsListComponent = ({
  formType = FormType.CLIENT,
  personState,
  //   editPerson,
  //   editButtonText,
  //   deletePerson,
  //   deleteButtonText,
  //   listPersons,
  //   listPersonsButtonText,
}) => {
  const [viewMode, setViewMode] = useState(true);
  const [groupsForPerson, setGroupsForPerson] = useState([]);
  const [matchedGroups, setMatchedGroups] = useState([]);
  const [allGroups, setAllGroups] = useState([]);

  const [query, setQuery] = useState("");
  const [refresh, doRefresh] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(undefined);
  const navigation = useNavigation();
  const confirm = useConfirmation();

  useEffect(() => {
    async function loadGroups() {
      if (personState?.id !== undefined) {
        //in the first render of view parent, its empty so do not waste going thru loop
        const groupsToShow = await DBUtils.getGroupClientsForClient(
          personState?.id
        );
        setGroupsForPerson(groupsToShow);
      }
      let groups = await DBUtils.getAllGroups();
      setMatchedGroups(groups);
      setAllGroups(groups);
    }
    loadGroups();
    return () => {
      //cleanup
    };
  }, [!refresh, personState.id]);

  function addNewGroup() {
    setViewMode(false);
    setQuery("");
  }
  async function saveGroup() {
    //get group
    //get client
    //save groupclient
    await DBUtils.saveGroupClient(selectedGroup, personState);
    //   await DataStore.save(
    //     new Group({
    //       clientId: personState.id,
    //       tenant: personState.tenant,
    //       name: name,
    //       createdOn: createdOn.toISOString(),
    //       otherData: "",
    //     })
    //   );

    setViewMode(true);
    doRefresh(!refresh);
  }

  async function deleteGroupClient(groupClientItem) {
    await Utils.areYouSureDialog(confirm, async () => {
      await DBUtils.deleteGroupClient(groupClientItem);
      doRefresh(!refresh);
    });
  }
  var stopOverlapOnExpand = viewMode ? 20 : 200;
  return (
    <>
      <Card
        containerStyle={[
          commonStyles.mainCardContainerWithThickBorder,
          { marginBottom: stopOverlapOnExpand },
        ]}
      >
        <Card.Title style={{ marginTop: 8 }}>{`${
          groupsForPerson === undefined || groupsForPerson.length === 0
            ? "No"
            : groupsForPerson.length
        } Group(s) Found`}</Card.Title>
        {!viewMode && (
          // <Overlay fullScreen ModalComponent={Modal} width="auto" height="auto">
          <View
            style={{
              flex: 1,
              justifyContent: "space-between",
              borderColor: "black",
              backgroundColor: "#ebedf0",
              borderWidth: 3,
              zIndex: 5,
            }}
          >
            <SubmitAndCancelButtons
              submit={async () => await saveGroup()}
              cancel={() => {
                setViewMode(true);
                //otherwise hitting cancel after selecting item,
                //resets matchedgroup array
                //and next time when you again Add Group, the autocomplet elist is empty
                setMatchedGroups(allGroups);
              }}
              flexDirection="row"
            />
            <Card
              wrapperStyle={{ zIndex: 50 }}
              containerStyle={{
                padding: 4,
                margin: 0,
                backgroundColor: "#ebedf0",
                borderWidth: 3,
                zIndex: 50,
              }}
            >
              <Autocomplete
                data={matchedGroups}
                placeholder="Type to select the group"
                value={query}
                onChangeText={async (text) => {
                  const groupsMatched = await DBUtils.getAllMatchingGroups(
                    text
                  );
                  setMatchedGroups(groupsMatched);
                  setQuery(text);
                }}
                flatListProps={{
                  keyExtractor: (_, idx) => idx,
                  // renderItem: ({ group }) => {
                  //   return <Text>{group}</Text>;
                  // },
                  renderItem: ({ item }) => {
                    return (
                      <TouchableOpacity
                        onPress={() => {
                          setSelectedGroup(item);
                          setMatchedGroups([]);
                          setQuery(item.groupName);
                        }}
                      >
                        <Text
                          style={{
                            fontSize: 15,
                            paddingTop: 5,
                            paddingBottom: 5,
                            margin: 2,
                          }}
                        >
                          {item.groupName}
                        </Text>
                      </TouchableOpacity>
                    );
                  },
                }}
              />
            </Card>
          </View>
          // </Overlay>
        )}
        {viewMode && allGroups?.length > 0 && (
          <Button
            containerStyle={commonStyles.buttonRow}
            title="Assign Group"
            onPress={addNewGroup}
          />
        )}

        {viewMode && allGroups?.length === 0 && (
          <>
            <Text style={{ color: "red", margin: 8 }}>
              No groups created yet
            </Text>
            <Button
              containerStyle={commonStyles.buttonRow}
              title="Create New Group"
              onPress={() =>
                NavUtils.showAddEditGroupScreen(navigation, undefined)
              }
            />
          </>
        )}
        <>
          {groupsForPerson?.map((groupClientItem, idx) => {
            const bg = idx % 2 === 0 ? "white" : "#f3f0f5";
            return (
              <Card
                containerStyle={{
                  padding: 4,
                  marginBottom: 8,
                  backgroundColor: bg,
                }}
              >
                <TouchableOpacity
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                  onPress={() =>
                    NavUtils.showViewGroupScreen(
                      navigation,
                      groupClientItem.group.id
                    )
                  }
                >
                  <Text style={{ marginTop: 18 }}>
                    {groupClientItem.group.groupName}
                  </Text>

                  <Button
                    buttonStyle={commonStyles.buttonDeleteRow}
                    containerStyle={{ width: "30%" }}
                    title="Delete"
                    onPress={() => deleteGroupClient(groupClientItem)}
                  />
                </TouchableOpacity>
              </Card>
            );
          })}
        </>
      </Card>
    </>
  );
};
