import {
  FlatList,
  TouchableOpacity,
  Text,
  View,
  ScrollView,
} from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import { Input, Button } from "react-native-elements";
import { styles } from "../../App";
import React, { useState, useEffect } from "react";
import { Client, AddUserToT } from "../../src/models/index";
import { DataStore } from "@aws-amplify/datastore";
import { Auth } from "aws-amplify";
import { getGroupNameAsTenant } from "../../utils/getGroupNameAsTenant";
import * as NavUtils from "../../utils/NavUtils";
import { commonStyles } from "../../styles/commonStyles";
import * as UserPermissions from "../../utils/UserPermissions";
import { SubmitAndCancelButtons } from "../../components/ButtonComponents";
import * as Utils from "../../utils/Utils";

export const EditUserScreen = ({ navigation, route }) => {
  //props needed for going to given screen using navigation
  const [form, updateForm] = useState({
    firstName: "",
    lastName: "",
    cellPhone: "",
    userEmail: "",
    address: "",
    otherData: "",
    notes: "",
    businessName: "",
    businessAddress: "",
    businessPhone1: "",
    businessPhone2: "",
    businessEmail: "",
    businessNotes: "",
    businessMission: "",
    businessVision: "",
    businessLogo: "",
    businessFacebook: "",
    businessInstagram: "",
    businessSocial: "",
    businessHours: "",
    businessCurrency: "",
  });
  const [errors, setErrors] = useState("");
  const [isOwnerEditingOwnerUser, setIsOwnerEditingOwnerUser] = useState(false);

  async function updatePageState() {
    // alert(route.params.userId);
    const userOriginal = await DataStore.query(
      AddUserToT,
      route.params?.userId
    );
    if (userOriginal) {
      const isOwner = await UserPermissions.isCurrentlyLoggedInUserAnOwner();
      if (userOriginal.tenant === userOriginal.userEmail && isOwner) {
        //form userEmail is not same as owner for non-owner emails
        setIsOwnerEditingOwnerUser(true);
      } else {
        setIsOwnerEditingOwnerUser(false);
      }
      updateForm({
        firstName: userOriginal.firstName,
        lastName: userOriginal.lastName,
        cellPhone: userOriginal.cellPhone,
        userEmail: userOriginal.userEmail,
        address: userOriginal.address,
        otherData: userOriginal.otherData,
        notes: userOriginal.notes,
        businessName: userOriginal.businessName,
        businessAddress: userOriginal.businessAddress,
        businessPhone1: userOriginal.businessPhone1,
        businessPhone2: userOriginal.businessPhone2,
        businessEmail: userOriginal.businessEmail,
        businessNotes: userOriginal.businessNotes,
        businessMission: userOriginal.businessMission,
        businessVision: userOriginal.businessVision,
        businessLogo: userOriginal.businessLogo,
        businessFacebook: userOriginal.businessFacebook,
        businessInstagram: userOriginal.businessInstagram,
        businessSocial: userOriginal.businessSocial,
        businessHours: userOriginal.businessHours,
        businessCurrency: userOriginal.businessCurrency,
      });
    }
  }

  useEffect(() => {
    (async () => {
      await updatePageState();
    })();
  }, []);

  async function submit() {
    var groupForTenant = await getGroupNameAsTenant();
    var user;
    var postData = { ...form };
    //avoids true groupname for tenant when null
    if (groupForTenant !== null) postData = { ...form, tenant: groupForTenant };
    //TODO - schema for primary sort key like addUserTOtenant. Also need GSI everywhere
    const userOriginal = await DataStore.query(AddUserToT, route.params.userId);
    user = await DataStore.save(
      AddUserToT.copyOf(userOriginal, (updated) => {
        const returnedTarget = Object.assign(updated, postData);
      })
    );
    if (route.params?.destination === "ProfileDestination") {
      NavUtils.showProfileScreen(navigation);
    } else {
      NavUtils.showViewUserScreen(navigation, user.id);
    }
  }

  return (
    <SafeAreaView style={commonStyles.pageContainer}>
      <SubmitAndCancelButtons
        submit={async () => await submit()}
        cancel={() => navigation.goBack()}
        flexDirection="row"
      />

      <ScrollView>
        {errors.length > 0 && <Text style={{ color: "red" }}>{errors}</Text>}
        <Text style={commonStyles.pageSubHeaderMedium}>{form.userEmail}</Text>
        <View style={{ borderColor: "black", margin: 5, borderWidth: 0 }}>
          <Text style={commonStyles.pageNotes}>
            * Email cannot be updated. To change email of existing user, first
            delete the existing user. Then add them as new user and have them
            confirm the email by entering the Verification Code as described in
            Add App User screen.
          </Text>
        </View>
        <Input
          maxLength={100}
          // containerStyle={{ width: "90%" }}
          labelStyle={{ marginTop: 16 }}
          value={form.firstName}
          placeholder="First Name"
          onChangeText={(e) => updateForm({ ...form, firstName: e })}
        />
        <Input
          maxLength={100}
          value={form.lastName}
          placeholder="Last Name"
          onChangeText={(e) => updateForm({ ...form, lastName: e })}
        />
        <Input
          maxLength={100}
          value={form.cellPhone}
          placeholder="Cell Phone"
          onChangeText={(e) => updateForm({ ...form, cellPhone: e })}
          keyboardType="phone-pad"
        />
        <Input
          maxLength={100}
          value={form.address}
          placeholder="Address"
          onChangeText={(e) => updateForm({ ...form, address: e })}
        />

        <Input
          maxLength={1000}
          testID="notes"
          value={form.notes}
          multiline={true}
          placeholder="Notes"
          onChangeText={(e) => updateForm({ ...form, notes: e })}
          numberOfLines={4}
        />

        {isOwnerEditingOwnerUser && (
          <>
            <Input
              maxLength={100}
              value={form.businessName}
              placeholder="Business Name"
              onChangeText={(e) => updateForm({ ...form, businessName: e })}
            />
            <Input
              maxLength={100}
              value={form.businessAddress}
              placeholder="Business Address"
              onChangeText={(e) => updateForm({ ...form, businessAddress: e })}
            />
            <Input
              maxLength={100}
              value={form.businessPhone1}
              placeholder="Business Phone 1"
              onChangeText={(e) => updateForm({ ...form, businessPhone1: e })}
            />
            <Input
              maxLength={100}
              value={form.businessPhone2}
              placeholder="Business Phone 2"
              onChangeText={(e) => updateForm({ ...form, businessPhone2: e })}
            />
            <Input
              maxLength={100}
              value={form.businessEmail}
              placeholder="Business Email"
              onChangeText={(e) => updateForm({ ...form, businessEmail: e })}
            />
            <Input
              maxLength={100}
              value={form.businessCurrency}
              placeholder="Currency"
              onChangeText={(e) => updateForm({ ...form, businessCurrency: e })}
            />
            <Input
              maxLength={1000}
              multiline={true}
              value={form.businessNotes}
              placeholder="Business Notes"
              numberOfLines={4}
              onChangeText={(e) => updateForm({ ...form, businessNotes: e })}
            />
            <Input
              maxLength={100}
              value={form.businessMission}
              placeholder="Business Mission"
              onChangeText={(e) => updateForm({ ...form, businessMission: e })}
            />
            <Input
              maxLength={100}
              value={form.businessFacebook}
              placeholder="Business Facebook"
              onChangeText={(e) => updateForm({ ...form, businessFacebook: e })}
            />
            <Input
              maxLength={100}
              value={form.businessInstagram}
              placeholder="Business Instagram"
              onChangeText={(e) =>
                updateForm({ ...form, businessInstagram: e })
              }
            />
            <Input
              maxLength={100}
              value={form.businessHours}
              placeholder="Business Hours"
              onChangeText={(e) => updateForm({ ...form, businessHours: e })}
            />
          </>
        )}

        {/* <Button
          buttonStyle={commonStyles.buttonRow}
          //containerStyle={{ margin: 10 }}
          title="Manage App Users"
          type="outline"
          onPress={() => NavUtils.showUserListScreen(navigation)}
        />
        <Button
          buttonStyle={commonStyles.buttonRow}
          type="outline"
          title="Add App User"
          onPress={() => {
            NavUtils.showAddUserScreen(navigation);
          }}
        /> */}
      </ScrollView>
    </SafeAreaView>
  );
};
