import { Auth, DataStore } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { ScrollView, StyleSheet, Share, Text, View } from "react-native";
import { Button } from "react-native-elements";
import * as Linking from "expo-linking";
import * as WebBrowser from "expo-web-browser";
import { SubmitAndCancelButtons } from "../../components/ButtonComponents";
import { EulaTracker } from "../../src/models";
import { EulaAccepted } from "../../src/models";
import { getGroupNameAsTenant } from "../../utils/getGroupNameAsTenant";
import { Provider, Paragraph, Dialog, Portal } from "react-native-paper";
import { Video, AVPlaybackStatus } from "expo-av";
import * as Utils from "./../../utils/Utils";
import * as MailComposer from "expo-mail-composer";
import * as Strings from "../../resources/Strings";

export function HelpScreen({ navigation, route, setShowEula }) {
  const [visible, setVisible] = React.useState(false);
  const video = React.useRef(null);
  const [status, setStatus] = React.useState({});

  function HelpFeature({ title, desc }) {
    return (
      <>
        <Text style={{ fontWeight: "bold", marginBottom: 5 }}>{title}</Text>
        <Text style={{ marginBottom: 10 }}>{desc}</Text>
      </>
    );
  }

  return (
    <>
      <ScrollView>
        {/* <Video
          ref={video}
          style={styles.video}
          source={{
            uri: "http://d23dyxeqlo5psv.cloudfront.net/big_buck_bunny.mp4",
          }}
          useNativeControls
          resizeMode="contain"
          isLooping
          onPlaybackStatusUpdate={(status) => setStatus(() => status)}
        />
        <View style={styles.buttons}>
          <Button
            title={status.isPlaying ? "Pause" : "Play"}
            onPress={() =>
              status.isPlaying
                ? video.current.pauseAsync()
                : video.current.playAsync()
            }
          />
        </View> */}
        <View style={{ flexDirection: "row", margin: 2, flexWrap: "wrap" }}>
          <View style={styles.buttons}>
            <Button
              type="clear"
              title="Contact Us"
              onPress={() =>
                //<a href="mailto:test@example.com?subject=Testing out mailto!&body=This is only a test!">Second Example</a>

                Linking.openURL(
                  "mailto:support@siyamiapps.com?subject=Regarding Cloud CRM for Client Records&body=Hi Team, Please help us with question below:  "
                )
              }
            />
          </View>
          <View style={{ width: 2 }}></View>

          <View style={styles.buttons}>
            <Button
              type="clear"
              title="Share App"
              // onPress={async () => {
              //   try {
              //     const result = await Share.share({
              //       message: Strings.shareAppMsg,
              //     });
              //     if (result.action === Share.sharedAction) {
              //       if (result.activityType) {
              //         // shared with activity type of result.activityType
              //       } else {
              //         // shared
              //       }
              //     } else if (result.action === Share.dismissedAction) {
              //       // dismissed
              //     }
              //   } catch (error) {
              //     let result = await MailComposer.composeAsync({
              //       recipients: [""], // users can add friend and fmaily here.
              //       subject:
              //         "Try this really cool app : Cloud CRM for Client Records",
              //       body: Strings.shareAppMsg,
              //       ccRecipients: [],
              //       bccRecipients: [],
              //     });
              //     return result;
              //   }
              // }}
              onPress={() =>
                Linking.openURL(
                  `mailto:?subject=Try this really cool app : Cloud CRM for Client Records&body=${Strings.shareAppMsg}`
                )
              }
            />
          </View>
          <View style={{ width: 2 }}></View>
          {/* {!Utils.isIOS() && ( */}
          {true && (
            <View style={styles.buttons}>
              <Button
                type="clear"
                title="Website"
                onPress={() =>
                  //<a href="mailto:test@example.com?subject=Testing out mailto!&body=This is only a test!">Second Example</a>

                  Linking.openURL("https://cloudcrm.siyamiapps.com")
                }
              />
            </View>
          )}
          <View style={{ width: 2 }}></View>
          {/* {!Utils.isIOS() && ( */}
          {true && (
            <View style={styles.buttons}>
              <Button
                type="clear"
                title="Facebook"
                onPress={() =>
                  //<a href="mailto:test@example.com?subject=Testing out mailto!&body=This is only a test!">Second Example</a>

                  Linking.openURL("https://facebook.com/siyamiapps")
                }
              />
            </View>
          )}
        </View>
        <HelpFeature
          title="Add Client"
          desc="Allows you to add new client by customizing the form to suit your
          business needs. Design your App by custom labels or by showing just
          enough fields. You can even rename the custom field names to match client onboarding. Add events like birthdays or anniversaries and more to foster great relationship with your clients"
        />
        <HelpFeature
          title="Add Lead"
          desc="Allows you to add new lead by customizing the form to suit your
          business needs. Design your App by custom labels or by showing just
          enough fields. You can even rename the custom field names to match lead onboarding"
        />
        <HelpFeature
          title="Add Supplier / Vendor / Partner"
          desc="Allows you to add new supplier or vendor or partner by customizing the form to suit your
          business needs. Design your App by custom labels or by showing just
          enough fields. You can even rename the custom field names to match supplier onboarding"
        />
        <HelpFeature
          title="Add Staff / Employee / Contractor"
          desc="Allows you to add new staff or employees by customizing the form to suit your
          business needs. Design your App by custom labels or by showing just
          enough fields. You can even rename the custom field names to match staff, contractor or employee onboarding"
        />
        <HelpFeature
          title="Manage App Users"
          desc="Allows you to manage app users by adding or deleting new users to use the App.  After successful user creation, user will get Verification Code to
            the email address entered here. User will need to tap on 'Sign Up'
            link from the Home page of the App.Then tap on 'Confirm Code' link.
            Then enter email and the Verification Code received in the email to
            confirm the email address. After that, user can login to the App
            using email and password. If user forgets password, they can use
            'Forgot Password' link from the Home Page to change password. They
            will get Verification Code to the email to validate the request."
        />
        <HelpFeature
          title="Send Bulk Email / SMS"
          desc="Broadcast bulk Email / SMS for greetings, emergency messages, special offers and more."
        />
        <HelpFeature
          title="History of Bulk Email / SMS"
          desc="Reuse past bulk emails and SMS for greetings, emergency messages, special offers and more."
        />
        <HelpFeature
          title="Groups"
          desc="Create new groups as tags or collection of of your relationships. You can combine clients, leads, staff or suppliers if desired."
        />
        <HelpFeature
          title="Tasks"
          desc="Create new tasks or to-dos with due dates to manage your projects better. You can assign clients, leads, staff or suppliers if desired."
        />
        {Utils.isWeb() && (
          <View style={styles.buttons}>
            <Button
              title="Watch Video"
              onPress={
                () =>
                  WebBrowser.openBrowserAsync(
                    "https://www.youtube.com/watch?v=sFk-PAf2-zY"
                  )
                // WebBrowser.openBrowserAsync("https://cloudcrm.siyamiapps.com")
                // Linking.openURL("https://www.youtube.com/watch?v=sFk-PAf2-zY")
                // Linking.openURL("https://cloudcrm.siyamiapps.com")
              }
            />
          </View>
        )}
      </ScrollView>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    backgroundColor: "#ecf0f1",
  },
  video: {
    alignSelf: "center",
    width: 320,
    height: 200,
  },
  buttons: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
});
