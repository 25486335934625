import { Text, View } from "react-native";
import { Button, Card } from "react-native-elements";
import React, { useState, useEffect } from "react";
import { Event } from "../../src/models/index";
import { DataStore } from "@aws-amplify/datastore";
import { commonStyles } from "../../styles/commonStyles";
import * as NavUtils from "../../utils/NavUtils";
import * as Utils from "../../utils/Utils";
import { useConfirmation } from "../../components/confirmation/ConfirmationService";
import { Predicates, SortDirection } from "aws-amplify";
import { useNavigation } from "@react-navigation/core";

export const EventComponent = ({ personState }) => {
  const [events, setEvents] = useState([]);
  const [refresh, doRefresh] = useState(false);
  const confirm = useConfirmation();
  const navigation = useNavigation();

  useEffect(() => {
    async function loadEvents() {
      if (personState?.id !== undefined) {
        //in the first render of view parent, its empty so do not waste going thru loop
        const eventsToShow = (
          await DataStore.query(Event, Predicates.ALL, {
            sort: (s) => s.startTime(SortDirection.DESCENDING),
          })
        ).filter((c) => c.clientId === personState.id);
        setEvents(eventsToShow);
      }
    }
    loadEvents();
    return () => {
      //cleanup
    };
  }, [!refresh, personState.id]);
  // second render time - parent has id due to its own useeffect
  //but need to check that id go undefined to some_id so this componenet can render

  function addEvent() {
    NavUtils.showAddEditEventScreen(navigation, undefined, personState.id);
  }

  async function editEvent(item) {
    NavUtils.showAddEditEventScreen(navigation, item.id, personState.id);
  }
  async function deleteEvent(item) {
    const deleteAfterConfirm = async () => {
      const eventToDelete = await DataStore.query(Event, item.id);
      if (eventToDelete) await DataStore.delete(eventToDelete);
      doRefresh(!refresh);
    };
    await Utils.areYouSureDialog(confirm, deleteAfterConfirm);
  }

  return (
    <>
      <Card containerStyle={commonStyles.mainCardContainerWithThickBorder}>
        <Card.Title style={{ marginTop: 8 }}>{`${
          events === undefined || events.length === 0 ? "No" : events.length
        } Event(s) Found`}</Card.Title>

        <Button
          containerStyle={commonStyles.buttonRow}
          title="Add New Event"
          onPress={addEvent}
          // style={{ marginBottom: 8 }}
        />

        <>
          {events?.map((item, idx) => {
            const bg = idx % 2 === 0 ? "white" : "#f3f0f5";
            return (
              <Card
                containerStyle={{
                  padding: 4,
                  marginBottom: 8,
                  backgroundColor: bg,
                }}
              >
                <Text style={{ marginBottom: 4 }}>{item.name}</Text>
                <Text style={{ marginBottom: 4 }}>
                  {new Date(item.startTime).toLocaleDateString()}
                </Text>
                <Text
                  style={{ marginBottom: 4 }}
                >{`Repeats every ${item.repeats}`}</Text>

                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginBottom: 4,
                  }}
                >
                  <Button
                    buttonStyle={commonStyles.buttonRow}
                    containerStyle={{ width: "30%" }}
                    title="Edit"
                    onPress={() => editEvent(item)}
                  />
                  <Button
                    buttonStyle={commonStyles.buttonDeleteRow}
                    containerStyle={{ width: "30%" }}
                    title="Delete"
                    onPress={() => deleteEvent(item)}
                  />
                </View>
              </Card>
            );
          })}
        </>
      </Card>
    </>
  );
};
