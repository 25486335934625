import { DataStore } from "@aws-amplify/datastore";
import React, { useEffect, useState } from "react";
import { Text, View, Modal, ActivityIndicator } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import { Button, Card, CheckBox, Input, Overlay } from "react-native-elements";
import { ScrollView } from "react-native-gesture-handler";
import {
  LinkButton,
  SubmitAndCancelButtons,
} from "../../components/ButtonComponents";
import CustomDatePicker from "../../components/CustomDatePicker.web";
import {
  AddUserToT,
  FormType,
  SubReceipt,
  Task,
  TaskStatus,
  WhichStore,
} from "../../src/models/index";
import { commonStyles } from "../../styles/commonStyles";
import { getGroupNameAsTenant } from "../../utils/getGroupNameAsTenant";
import * as NavUtils from "../../utils/NavUtils";
import * as Utils from "../../utils/Utils";
import * as DBUtils from "../../utils/DBUtils";
import { format, compareAsc } from "date-fns";
import Amplify, { API } from "aws-amplify";
import ErrorMessageComponent from "../../components/ErrorMessageComponent";
import * as Linking from "expo-linking";

export const OrderScreen = ({ navigation, route }) => {
  //props needed for going to given screen using navigation
  const [form, updateForm] = useState({
    id: "",
    tenant: "",
  });
  const [errors, setErrors] = useState("");
  const [success, setSuccess] = useState("");
  const [showActivityIndicator, setShowActivityIndicator] = useState(false);
  const [isPaidUser, setPaidUser] = useState(false);

  async function updatePageState() {
    //call webSubStatus API with checkout session id and create a record of success or ACTIVE SUBSCRPTION
    setShowActivityIndicator(true);
    const cloudCRMUser = await DBUtils.getLoggedInUserDBDetails();
    try {
      const ret = await API.put("webSubStatusAPI", "/status", {
        body: {
          providerSessionId: sessionId,
          cloudCRMUserId: cloudCRMUser?.id,
          cloudCRMEmail: cloudCRMUser?.userEmail,
          isDev: Utils.isDevEnv(), // for all NONPROD env signature of test key is used
        },
      });
      if (__DEV__)
        console.log(
          "order screen insert session return=" + JSON.stringify(ret)
        );

      //create SUbreceipt record
      //save web receipt. Also do GET Call to above API if receipt is needed as it is read on server side by calling stripe
      const receiptsWeb = await DataStore.query(SubReceipt, (c) =>
        c.or((c) => c.store("eq", WhichStore.WEB))
      );
      const r = receiptsWeb[0]; // one owner can buy means only one record inserted in Orderscreen.
      // Orderscreen will lookup and update Subreceipt table as receipt willmost likely exist because of the call above
      const updatedSubRet = await DataStore.save(
        SubReceipt.copyOf(r, (updated) => {
          const returnedTarget = Object.assign(updated, {
            kharedi: "true",
            receipt: "", //make get api call if you want receipt rightaway, otherwise wait for periodic update in clientlist
            lastUpdatedAt: new Date().toISOString(),
          });
        })
      );
      if (__DEV__)
        console.log(
          "order screen updated sub=" + JSON.stringify(updatedSubRet)
        );
      setSuccess(
        "Congratulations! Thank you for using the App. We wish you all the very best in growing your business"
      );
      setErrors("");
    } catch (err) {
      setErrors(
        "System Error: Press Contact Us Button below so we can help you right away to grow your business. Message ="
      );
      setSuccess("");
      if (__DEV__)
        console.log(
          "order screen insert session return=" + JSON.stringify(err)
        );
    }

    setShowActivityIndicator(false);
  }

  useEffect(() => {
    (async () => {
      await updatePageState();
    })();
  }, []);

  return (
    <SafeAreaView style={commonStyles.pageContainer}>
      <LinkButton
        title={`< Back to Client List`}
        submit={() => Utils.refresh()}
      />
      <View style={{ marginVertical: 5 }} />

      {errors.length > 0 && <ErrorMessageComponent errorMsg={errors} />}
      {success.length > 0 && <ErrorMessageComponent errorMsg={success} />}
      {showActivityIndicator && <ActivityIndicator size="large" />}
      <Button
        type="clear"
        title="Contact Us"
        onPress={() =>
          //<a href="mailto:test@example.com?subject=Testing out mailto!&body=This is only a test!">Second Example</a>
          Linking.openURL(
            "mailto:support@siyamiapps.com?subject=Regarding Cloud CRM for Client Records&body=Hi Team, Please help us with Error on Order Screen. We get the message: ( type your message on the screen here) "
          )
        }
      />
    </SafeAreaView>
  );
};

let jsonQuery = Object.fromEntries(new URLSearchParams(location.search));
let sessionId = jsonQuery.session_id;
if (__DEV__) console.log("orders screen session id=" + sessionId);
