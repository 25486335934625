// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const EventRepeat = {
  "NO": "NO",
  "DAY": "DAY",
  "WEEK": "WEEK",
  "MONTH": "MONTH",
  "YEAR": "YEAR",
  "TWOWEEKS": "TWOWEEKS",
  "CUSTOM": "CUSTOM"
};

const BillStatus = {
  "PAID": "PAID",
  "UNPAID": "UNPAID"
};

const TaskStatus = {
  "DONE": "DONE",
  "NOT_DONE": "NOT_DONE"
};

const FormType = {
  "CLIENT": "Client",
  "USER": "User",
  "STAFF": "Staff",
  "LEAD": "Lead",
  "SUPPLIER": "Supplier",
  "COMPETITOR": "Competitor"
};

const EmailType = {
  "DRAFT": "Draft",
  "SENT": "Sent"
};

const EmailToType = {
  "CC": "CC",
  "BCC": "BCC",
  "TO": "TO"
};

const BulkProtocolType = {
  "EMAIL": "Email",
  "WHATS_APP": "WhatsApp",
  "SMS": "SMS",
  "OTHER": "Other"
};

const PostStatus = {
  "ACTIVE": "ACTIVE",
  "INACTIVE": "INACTIVE"
};

const AddUserToTenantStatus = {
  "UNCONFIRMED": "UNCONFIRMED",
  "CONFIRMED": "CONFIRMED",
  "DELETED": "DELETED",
  "DISABLED": "DISABLED"
};

const EulaAccepted = {
  "YES": "YES",
  "NO": "NO"
};

const WhichStore = {
  "APPLE": "APPLE",
  "GOOGLE": "GOOGLE",
  "OTHER1": "OTHER1",
  "OTHER2": "OTHER2",
  "OTHER3": "OTHER3",
  "MS": "MS",
  "SAMSUNG": "SAMSUNG",
  "AVQ": "AVQ",
  "WEB": "WEB"
};

const RolesDefault = {
  "OWNER": "OWNER",
  "ADMIN": "ADMIN",
  "READ_WRITE": "READ_WRITE",
  "READ_ONLY": "READ_ONLY",
  "CUSTOM": "CUSTOM"
};

const { Event, Notes, Bills, Product, Part, Appointment, GroupClient, Client, TaskClient, Task, Group, EmailHistory, CustomForm, Post, Comment, AddUserToT, EulaTracker, SubReceipt, Device, Activity } = initSchema(schema);

export {
  Event,
  Notes,
  Bills,
  Product,
  Part,
  Appointment,
  GroupClient,
  Client,
  TaskClient,
  Task,
  Group,
  EmailHistory,
  CustomForm,
  Post,
  Comment,
  AddUserToT,
  EulaTracker,
  SubReceipt,
  Device,
  Activity,
  EventRepeat,
  BillStatus,
  TaskStatus,
  FormType,
  EmailType,
  EmailToType,
  BulkProtocolType,
  PostStatus,
  AddUserToTenantStatus,
  EulaAccepted,
  WhichStore,
  RolesDefault
};