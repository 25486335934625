import {
  FlatList,
  TouchableOpacity,
  Text,
  View,
  ImageBackground,
} from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import { Input, Button, Card } from "react-native-elements";
import { styles } from "../../App";
import React, { useState, useEffect } from "react";
import { Client, AddUserToT, FormType } from "../../src/models/index";
import { DataStore } from "@aws-amplify/datastore";
import { Auth } from "aws-amplify";
import { getGroupNameAsTenant } from "../../utils/getGroupNameAsTenant";
import { Hub } from "aws-amplify";
import * as UserPermissions from "../../utils/UserPermissions";
import * as NavUtils from "../../utils/NavUtils";
import * as Utils from "../../utils/Utils";
import { commonStyles } from "../../styles/commonStyles";
import * as AppColors from "../../styles/AppColors";
import { ScrollView } from "react-native-gesture-handler";
import ErrorMessageComponent from "../../components/ErrorMessageComponent";
import { LinkButton } from "../../components/ButtonComponents";
import { loadStripe } from "@stripe/stripe-js";
import { ReturnsScreen } from "../agreements/ReturnsScreen";
import { TryFreeNotesScreen } from "./TryFreeNotesScreen";
import { ifBigScreen } from "../../utils/ifBigScreen";

export const PricingScreen = ({ navigation, route }) => {
  const [errors, setErrors] = useState("");
  //this even is called everytime - first time after login there is nothing to show until load above gets data
  useEffect(() => {
    (async () => {})();
    return () => {
      //cleanup
    };
  }, []);

  const PricingButton = ({}) => {
    return (
      <View style={{ marginBottom: 10 }}>
        <Button
          containerStyle={commonStyles.buttonRow}
          title="Try Free Now"
          onPress={() => navigation.navigate("SignUpScreenName")}
          buttonStyle={{
            backgroundColor: "#cf9002",
          }}
        />
      </View>
    );
  };
  const marginRightOfPrice = 10;
  return (
    // <SafeAreaView style={commonStyles.pageContainer}>
    <ScrollView>
      <ImageBackground
        source={require("../../assets/images/orange.jpg")}
        style={{
          flex: 1,
          height: "50%",
        }}
      >
        <View
          style={{
            flex: 1,
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            // backgroundColor: "white",
            margin: 10,
          }}
        >
          <Card
            containerStyle={{
              flex: 1,
              padding: 10,
              backgroundColor: "#ebedf0",
              borderWidth: 3,
              margin: 10,
            }}
          >
            {errors.length > 0 && <ErrorMessageComponent errorMsg={errors} />}
            <>
              <LinkButton
                title={`< Back to Home Page`}
                submit={() => navigation.navigate("LoggedOutHomeScreenName")}
              />
              <View
                style={{
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <TryFreeNotesScreen navigation={navigation} route={route} />
                <View
                  style={{
                    // flex: 1,
                    flexDirection: ifBigScreen ? "row" : "column",
                    // justifyContent: "flex-start",
                    flexWrap: ifBigScreen ? "wrap" : "nowrap",
                    // backgroundColor: "white",
                    paddingLeft: 5,
                    marginBottom: 20,
                  }}
                >
                  <Card
                    containerStyle={[
                      commonStyles.mainCardContainerWithThickBorder,
                      { marginRight: marginRightOfPrice },
                    ]}
                  >
                    <Card.Title style={{ marginTop: 8 }}>
                      1 User Plan
                    </Card.Title>

                    <PricingButton />
                    <>
                      <Card
                        containerStyle={{
                          padding: 4,
                          marginBottom: 8,
                          // backgroundColor: "",
                        }}
                      >
                        <Text style={{ marginBottom: 4 }}>Renews Yearly</Text>
                        <Text
                          style={{
                            fontSize: 16,
                            fontWeight: "bold",
                            marginBottom: 3,
                          }}
                        >
                          USD 90 per year
                        </Text>

                        <View
                          style={{
                            flexDirection: "row",
                            justifyContent: "space-between",
                            marginBottom: 4,
                          }}
                        ></View>
                      </Card>
                    </>
                  </Card>

                  <Card
                    containerStyle={[
                      commonStyles.mainCardContainerWithThickBorder,
                      { marginRight: marginRightOfPrice },
                    ]}
                  >
                    <Card.Title style={{ marginTop: 8 }}>
                      5 User Plan
                    </Card.Title>
                    <PricingButton />

                    <>
                      <Card
                        containerStyle={{
                          padding: 4,
                          marginBottom: 8,
                          // backgroundColor: "",
                        }}
                      >
                        <Text style={{ marginBottom: 4 }}>Renews Yearly</Text>
                        <Text
                          style={{
                            fontSize: 16,
                            fontWeight: "bold",
                            marginBottom: 4,
                          }}
                        >
                          USD 225 per year
                        </Text>

                        <View
                          style={{
                            flexDirection: "row",
                            justifyContent: "space-between",
                            marginBottom: 4,
                          }}
                        ></View>
                      </Card>
                    </>
                  </Card>

                  <Card
                    containerStyle={[
                      commonStyles.mainCardContainerWithThickBorder,
                      { marginRight: marginRightOfPrice },
                    ]}
                  >
                    <Card.Title style={{ marginTop: 8 }}>
                      10 User Plan
                    </Card.Title>
                    <PricingButton />

                    <>
                      <Card
                        containerStyle={{
                          padding: 4,
                          marginBottom: 8,
                          // backgroundColor: "",
                        }}
                      >
                        <Text style={{ marginBottom: 4 }}>Renews Yearly</Text>
                        <Text
                          style={{
                            fontSize: 16,
                            fontWeight: "bold",
                            marginBottom: 4,
                          }}
                        >
                          USD 350 per year
                        </Text>

                        <View
                          style={{
                            flexDirection: "row",
                            justifyContent: "space-between",
                            marginBottom: 4,
                          }}
                        ></View>
                      </Card>
                    </>
                  </Card>

                  <Card
                    containerStyle={[
                      commonStyles.mainCardContainerWithThickBorder,
                      { marginRight: marginRightOfPrice },
                    ]}
                  >
                    <Card.Title style={{ marginTop: 8 }}>
                      25 User Plan
                    </Card.Title>
                    <PricingButton />

                    <>
                      <Card
                        containerStyle={{
                          padding: 4,
                          marginBottom: 8,
                          // backgroundColor: "",
                        }}
                      >
                        <Text style={{ marginBottom: 4 }}>Renews Monthly</Text>
                        <Text
                          style={{
                            fontSize: 16,
                            fontWeight: "bold",
                            marginBottom: 4,
                          }}
                        >
                          USD 100 per month
                        </Text>

                        <View
                          style={{
                            flexDirection: "row",
                            justifyContent: "space-between",
                            marginBottom: 4,
                          }}
                        ></View>
                      </Card>
                    </>
                  </Card>

                  <Card
                    containerStyle={[
                      commonStyles.mainCardContainerWithThickBorder,
                      // { marginRight: marginRightOfPrice },
                    ]}
                  >
                    <Card.Title style={{ marginTop: 8 }}>
                      100 User Plan
                    </Card.Title>
                    <PricingButton />

                    <>
                      <Card
                        containerStyle={{
                          padding: 4,
                          marginBottom: 8,
                          // backgroundColor: "",
                        }}
                      >
                        <Text style={{ marginBottom: 4 }}>Renews Monthly</Text>
                        <Text
                          style={{
                            fontSize: 16,
                            fontWeight: "bold",
                            marginBottom: 4,
                          }}
                        >
                          USD 300 per month
                        </Text>

                        <View
                          style={{
                            flexDirection: "row",
                            justifyContent: "space-between",
                            marginBottom: 4,
                          }}
                        ></View>
                      </Card>
                    </>
                  </Card>
                </View>
              </View>
            </>
          </Card>
        </View>
      </ImageBackground>
    </ScrollView>
    // </SafeAreaView>
  );
};
