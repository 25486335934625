import { Navigation } from "@material-ui/icons";
import React, { useState } from "react";
import {
  Button as ButtonPaper,
  Provider,
  Paragraph,
  Dialog,
  Portal,
} from "react-native-paper";

export function CRMAlerts({
  navigation,
  show,
  title = "Alert",
  msg = "Alert Message",
  onClose = () => location.reload(),
  titleOfCloseButton,
  onDismiss = () => location.reload(),
  onOK = () => location.reload(),
  titleOfOKButton,
}) {
  const [showAlert, setShowAlert] = useState(show);
  const [alertTitle, setAlertTitle] = useState(title);
  const [alertMessage, setAlertMessage] = useState(msg);

  return (
    <>
      <Portal>
        <Dialog
          visible={showAlert}
          onDismiss={() => {
            setShowAlert(false);
            onDismiss();
          }}
        >
          <Dialog.Title>{alertTitle}</Dialog.Title>
          <Dialog.Content>
            <Paragraph>{alertMessage}</Paragraph>
          </Dialog.Content>
          <Dialog.Actions>
            <ButtonPaper
              onPress={() => {
                setShowAlert(false);
                onClose();
              }}
            >
              {titleOfCloseButton}
            </ButtonPaper>
            <ButtonPaper
              onPress={() => {
                setShowAlert(false);
                onOK();
              }}
            >
              {titleOfOKButton}
            </ButtonPaper>
          </Dialog.Actions>
        </Dialog>
      </Portal>
      ;
    </>
  );
}
