import React from "react";
import { View, Text } from "react-native";
import { SubmitButton } from "../ButtonComponents";
import * as Utils from "../../utils/Utils";
import { commonStyles } from "../../styles/commonStyles";
import * as DBUtils from "../../utils/Utils";

export default function TrialOverComponent({ navigation }) {
  return (
    <View style={{ marginTop: 20 }}>
      <Text style={{ margin: 10, color: "red" }}>*Important Note</Text>

      <Text style={commonStyles.pageNotes}>
        You Free Trial period is over. The content on this page can be seen
        after buying one of our Premium Plans designed to serve you.
      </Text>
      <SubmitButton
        title={"View Premium Plans"}
        navigation={navigation}
        submit={async () => {
          Utils.showSubscriptionPlans(navigation);
        }}
        // width="50%"
      />
    </View>
  );
}
