import { BulkProtocolType, FormType } from "../src/models";

//CLIENT
export async function showClientListScreen(navigation, formType) {
  navigation.navigate("ClientListScreenName", { formType: formType });
}
export async function showViewClientScreen(navigation, id, formType) {
  navigation.navigate("ViewClientScreenName", {
    clientId: id,
    formType: formType,
  });
}
export async function showAddEditClientScreen(navigation, id, formType) {
  navigation.reset({
    index: 0,
    routes: [
      {
        name: "AddClientScreenName",
        params: { clientId: id, formType: formType },
      },
    ],
  });
}

// EMAIL
export async function showEmailClientsSelectionScreen(
  navigation,
  id = undefined,
  bulkProtocolType = BulkProtocolType.EMAIL
) {
  navigation.navigate("EmailClientsSelectionScreenName", {
    emailId: id,
    bulkProtocolType: bulkProtocolType,
  });
}
export async function showEmailComposeScreen(
  navigation,
  map,
  emailId = undefined,
  bulkProtocolType = BulkProtocolType.EMAIL
) {
  navigation.navigate("EmailComposerScreenName", {
    clientMap: map,
    emailId: emailId,
    bulkProtocolType: bulkProtocolType,
  });

  // navigation.navigate("AddClientScreenName", { clientId: id });
}

export function showEmailHistoryScreen(
  navigation: any,
  bulkProtocolType = BulkProtocolType.EMAIL
) {
  navigation.navigate("EmailHistoryScreenName", {
    bulkProtocolType: bulkProtocolType,
  });
}
export function showViewEmailScreen(
  navigation: any,
  id: any,
  bulkProtocolType = BulkProtocolType.EMAIL
) {
  navigation.navigate("EmailViewScreenName", {
    emailId: id,
    bulkProtocolType: bulkProtocolType,
  });
}

//GROUP
export function showAddEditGroupScreen(navigation: any, id: undefined): void {
  navigation.reset({
    index: 0,
    routes: [{ name: "AddEditGroupScreenName", params: { groupId: id } }],
  });
}
export function showViewGroupScreen(navigation: any, id: string) {
  navigation.navigate("ViewGroupScreenName", { groupId: id });
}

export function showGroupListScreen(navigation: any) {
  navigation.navigate("GroupListScreenName");
}

export function showGroupMemberSelectionScreen(navigation: any, id: string) {
  navigation.navigate("GroupMemberSelectionScreenName", { groupId: id });
}

//TASK
export function showAddEditTaskScreen(navigation: any, id: undefined): void {
  navigation.reset({
    index: 0,
    routes: [{ name: "AddEditTaskScreenName", params: { taskId: id } }],
  });
}
export function showViewTaskScreen(navigation: any, id: string) {
  navigation.navigate("ViewTaskScreenName", { taskId: id });
}

export function showTaskListScreen(navigation: any) {
  navigation.navigate("TaskListScreenName");
}

export function showTaskMemberSelectionScreen(navigation: any, id: string) {
  navigation.navigate("TaskMemberSelectionScreenName", { taskId: id });
}

//User
export function showAddUserScreen(navigation) {
  navigation.reset({
    index: 0,
    routes: [{ name: "AddUserScreenName" }],
  });
}
export function showEditUserScreen(navigation, id, destination) {
  navigation.navigate("EditUserScreenName", {
    userId: id,
    destination: destination,
  });
}
export function showViewUserScreen(navigation, id) {
  navigation.navigate("ViewUserScreenName", { userId: id });
}

export function showUserListScreen(navigation: any): void {
  navigation.navigate("AppUserListScreenName");
}

//Profile
export function showProfileScreen(navigation) {
  navigation.navigate("ProfileScreenName");
}

export function showPersonListScreen(navigation: any, formType: any) {
  if (formType === FormType.LEAD) navigation.navigate("LeadListScreenName");
  else if (formType === FormType.CLIENT)
    navigation.navigate("ClientListScreenName");
  else if (formType === FormType.STAFF)
    navigation.navigate("StaffListScreenName");
  else if (formType === FormType.SUPPLIER)
    navigation.navigate("SupplierListScreenName");
}

//EVENT
export function showAddEditEventScreen(
  navigation: any,
  eventId: undefined,
  clientId: undefined
): void {
  navigation.reset({
    index: 0,
    routes: [
      {
        name: "AddEditEventScreenName",
        params: { eventId: eventId, clientId: clientId },
      },
    ],
  });
}

//BILL
export function showAddEditBillScreen(
  navigation: any,
  billId: undefined,
  clientId: undefined
): void {
  navigation.reset({
    index: 0,
    routes: [
      {
        name: "AddEditBillsScreenName",
        params: { billId: billId, clientId: clientId },
      },
    ],
  });
}

//NOTES
export function showAddEditNotesScreen(
  navigation: any,
  noteId: undefined,
  clientId: undefined
): void {
  navigation.reset({
    index: 0,
    routes: [
      {
        name: "AddEditNotesScreenName",
        params: { noteId: noteId, clientId: clientId },
      },
    ],
  });
}

//PRODUCT
export function showAddEditProductScreen(navigation: any, id: undefined): void {
  navigation.reset({
    index: 0,
    routes: [{ name: "AddEditProductScreenName", params: { productId: id } }],
  });
}
export function showViewProductScreen(navigation: any, id: string) {
  navigation.navigate("ViewProductScreenName", { productId: id });
}

export function showProductCatalogScreen(navigation: any) {
  navigation.navigate("ProductCatalogScreenName");
}
//PART
export function showAddEditPartScreen(navigation: any, id: undefined): void {
  navigation.reset({
    index: 0,
    routes: [{ name: "AddEditPartScreenName", params: { partId: id } }],
  });
}
export function showViewPartScreen(navigation: any, id: string) {
  navigation.navigate("ViewPartScreenName", { partId: id });
}

export function showPartsInventoryScreen(navigation: any) {
  navigation.navigate("PartsInventoryScreenName");
}
//APPOINTMENT
export function showAddEditAppointmentScreen(
  navigation: any,
  id: undefined,
  selectedDate: string
): void {
  navigation.reset({
    index: 0,
    routes: [
      {
        name: "AddEditAppointmentScreenName",
        params: { appointmentId: id, selectedDate: selectedDate },
      },
    ],
  });
}
export function showViewAppointmentScreen(navigation: any, id: string) {
  navigation.navigate("ViewAppointmentScreenName", { appointmentId: id });
}

export function showAppointmentsScreen(navigation: any) {
  navigation.navigate("AppointmentsScreenName");
}
