import { FlatList, TouchableOpacity, Text, View, Modal } from "react-native";
import {
  Input,
  Button,
  Card,
  ButtonTask,
  Overlay,
} from "react-native-elements";
import React, { useState, useEffect } from "react";
import { FormType } from "../../src/models/index";
import { commonStyles } from "../../styles/commonStyles";
import * as NavUtils from "../../utils/NavUtils";
import * as DBUtils from "../../utils/DBUtils";
import { ScrollView } from "react-native-gesture-handler";
import * as AppColors from "../../styles/AppColors";
import { blue } from "@material-ui/core/colors";
import { SubmitAndCancelButtons } from "../ButtonComponents";
//import Modal from "react-native-web";
import Autocomplete from "react-native-autocomplete-input";
import { useNavigation } from "@react-navigation/core";
import { useConfirmation } from "../../components/confirmation/ConfirmationService";
import * as Utils from "../../utils/Utils";

export const TasksListComponent = ({
  formType = FormType.CLIENT,
  personState,
  //   editPerson,
  //   editButtonText,
  //   deletePerson,
  //   deleteButtonText,
  //   listPersons,
  //   listPersonsButtonText,
}) => {
  const [viewMode, setViewMode] = useState(true);
  const [tasksForPerson, setTasksForPerson] = useState([]);
  const [allTasks, setAllTasks] = useState([]);
  const [matchedTasks, setMatchedTasks] = useState([]);
  const [query, setQuery] = useState("");
  const [refresh, doRefresh] = useState(false);
  const [selectedTask, setSelectedTask] = useState(undefined);
  const navigation = useNavigation();
  const confirm = useConfirmation();

  useEffect(() => {
    async function loadTasks() {
      if (personState?.id !== undefined) {
        //in the first render of view parent, its empty so do not waste going thru loop
        const tasksToShow = await DBUtils.getTaskClientsForClient(
          personState?.id
        );
        setTasksForPerson(tasksToShow);
      }
      const tasks = await DBUtils.getAllTasks();
      setMatchedTasks(tasks);
      setAllTasks(tasks);
    }
    loadTasks();
    return () => {
      //cleanup
    };
  }, [!refresh, personState.id]);

  function addNewTask() {
    setViewMode(false);
    setQuery("");
  }
  async function saveTask() {
    //get task
    //get client
    //save taskclient
    await DBUtils.saveTaskClient(selectedTask, personState);
    //   await DataStore.save(
    //     new Task({
    //       clientId: personState.id,
    //       tenant: personState.tenant,
    //       name: name,
    //       createdOn: createdOn.toISOString(),
    //       otherData: "",
    //     })
    //   );

    setViewMode(true);
    doRefresh(!refresh);
  }

  async function deleteTaskClient(taskClientItem) {
    await Utils.areYouSureDialog(confirm, async () => {
      await DBUtils.deleteTaskClient(taskClientItem);
      doRefresh(!refresh);
    });
  }

  return (
    <>
      <Card containerStyle={commonStyles.mainCardContainerWithThickBorder}>
        <Card.Title style={{ marginTop: 8 }}>{`${
          tasksForPerson === undefined || tasksForPerson.length === 0
            ? "No"
            : tasksForPerson.length
        } Task(s) Found`}</Card.Title>
        {!viewMode && (
          // <Overlay fullScreen ModalComponent={Modal} width="auto" height="auto">
          <View
            style={{
              flex: 1,
              justifyContent: "space-between",
              borderColor: "black",
              backgroundColor: "#ebedf0",
              borderWidth: 3,
              zIndex: 0,
            }}
          >
            <Card
              containerStyle={{
                padding: 4,
                margin: 0,
                backgroundColor: "#ebedf0",
                borderWidth: 3,
                zIndex: 0,
              }}
            >
              <SubmitAndCancelButtons
                submit={async () => await saveTask()}
                cancel={() => {
                  setViewMode(true);
                  //otherwise hitting cancel after selecting item,
                  //resets matchedtask array
                  //and next time when you again Add task, the autocomplet elist is empty
                  setMatchedTasks(allTasks);
                }}
                flexDirection="row"
              />
              <Autocomplete
                data={matchedTasks}
                placeholder="Type to select the task"
                value={query}
                onChangeText={async (text) => {
                  const tasksMatched = await DBUtils.getAllMatchingTasks(text);
                  setMatchedTasks(tasksMatched);
                  setQuery(text);
                }}
                flatListProps={{
                  keyExtractor: (_, idx) => idx,
                  // renderItem: ({ task }) => {
                  //   return <Text>{task}</Text>;
                  // },
                  renderItem: ({ item }) => {
                    return (
                      <TouchableOpacity
                        onPress={() => {
                          setSelectedTask(item);
                          setMatchedTasks([]);
                          setQuery(item.taskName);
                        }}
                      >
                        <Text
                          style={{
                            fontSize: 15,
                            paddingTop: 5,
                            paddingBottom: 5,
                            margin: 2,
                          }}
                        >
                          {item.taskName}
                        </Text>
                      </TouchableOpacity>
                    );
                  },
                }}
              />
            </Card>
          </View>
          // </Overlay>
        )}
        {viewMode && allTasks.length > 0 && (
          <Button
            containerStyle={commonStyles.buttonRow}
            title="Assign Task"
            onPress={addNewTask}
          />
        )}

        {viewMode && allTasks?.length === 0 && (
          <>
            <Text style={{ color: "red", margin: 8 }}>
              No tasks created yet
            </Text>
            <Button
              containerStyle={commonStyles.buttonRow}
              title="Create New Task"
              onPress={() =>
                NavUtils.showAddEditTaskScreen(navigation, undefined)
              }
            />
          </>
        )}
        <>
          {tasksForPerson?.map((taskClientItem, idx) => {
            const bg = idx % 2 === 0 ? "white" : "#f3f0f5";
            return (
              <Card
                containerStyle={{
                  padding: 4,
                  marginBottom: 8,
                  backgroundColor: bg,
                }}
              >
                <TouchableOpacity
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                  onPress={() =>
                    NavUtils.showViewTaskScreen(
                      navigation,
                      taskClientItem.task.id
                    )
                  }
                >
                  <Text style={{ marginTop: 18 }}>
                    {taskClientItem.task.taskName}
                  </Text>

                  <Button
                    buttonStyle={commonStyles.buttonDeleteRow}
                    containerStyle={{ width: "30%" }}
                    title="Delete"
                    onPress={() => deleteTaskClient(taskClientItem)}
                  />
                </TouchableOpacity>
              </Card>
            );
          })}
        </>
      </Card>
    </>
  );
};
