import { Text, View } from "react-native";
import { Button, Card } from "react-native-elements";
import React, { useState, useEffect } from "react";
import { Notes } from "../../src/models/index";
import { DataStore } from "@aws-amplify/datastore";
import { commonStyles } from "../../styles/commonStyles";
import * as NavUtils from "../../utils/NavUtils";
import * as Utils from "../../utils/Utils";
import { useConfirmation } from "../../components/confirmation/ConfirmationService";
import { Predicates, SortDirection } from "aws-amplify";
import { useNavigation } from "@react-navigation/core";

export const NotesComponent = ({ personState }) => {
  const [notes, setNotes] = useState([]);
  const [refresh, doRefresh] = useState(false);
  const confirm = useConfirmation();
  const navigation = useNavigation();

  useEffect(() => {
    async function loadNotes() {
      //const comments = (await DataStore.query(Comment)).filter(c => c.postID === "123");
      if (personState?.id !== undefined) {
        //in the first render of view parent, its empty so do not waste going thru loop
        const notessToShow = (
          await DataStore.query(Notes, Predicates.ALL, {
            sort: (s) => s.createdOn(SortDirection.DESCENDING),
          })
        ).filter((c) => c.clientId === personState.id);
        setNotes(notessToShow);

        //ABOVE IS inefficient as ALL events of ALL clients wil be looped thru. We alredy have evetns in state
        //thanks to 1-many relationship
        //BUT one - many lazy loading is not working as below so going back to above solution for NOW
        // setEvents(personState.events);
      }
    }
    loadNotes();
    return () => {
      //cleanup
    };
  }, [!refresh, personState.id]);
  // second render time - parent has id due to its own useeffect
  //but need to check that id go undefined to some_id so this componenet can render

  function addNotes() {
    NavUtils.showAddEditNotesScreen(navigation, undefined, personState.id);
  }

  async function editNotes(item) {
    NavUtils.showAddEditNotesScreen(navigation, item.id, personState.id);
  }
  async function deleteNotes(item) {
    await Utils.areYouSureDialog(confirm, async () => {
      const notesToDelete = await DataStore.query(Notes, item.id);
      await DataStore.delete(notesToDelete);
      doRefresh(!refresh);
    });
  }

  return (
    <>
      <Card containerStyle={commonStyles.mainCardContainerWithThickBorder}>
        <Card.Title style={{ marginTop: 8 }}>{`${
          notes === undefined || notes.length === 0 ? "No" : notes.length
        } Note(s) Found`}</Card.Title>
        <Button
          containerStyle={commonStyles.buttonRow}
          title="Add New Notes"
          onPress={addNotes}
        />
        <>
          {notes?.map((item, idx) => {
            const bg = idx % 2 === 0 ? "white" : "#f3f0f5";
            return (
              <Card
                containerStyle={{
                  padding: 4,
                  marginBottom: 8,
                  backgroundColor: bg,
                }}
              >
                <Text style={{ marginBottom: 4 }}>
                  {new Date(item.createdOn).toLocaleDateString()}
                </Text>
                <Text style={{ marginBottom: 4 }}>{item.name}</Text>

                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginBottom: 4,
                  }}
                >
                  <Button
                    buttonStyle={commonStyles.buttonRow}
                    containerStyle={{ width: "30%" }}
                    title="Edit"
                    onPress={() => editNotes(item)}
                  />
                  <Button
                    buttonStyle={commonStyles.buttonDeleteRow}
                    containerStyle={{ width: "30%" }}
                    title="Delete"
                    onPress={() => deleteNotes(item)}
                  />
                </View>
              </Card>
            );
          })}
        </>
      </Card>
    </>
  );
};
