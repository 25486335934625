import { blue, green } from "@material-ui/core/colors";

// @ts-expect-error
import { Agenda, Calendar } from "react-native-calendars";
import {
  FlatList,
  View,
  TouchableOpacity,
  Text,
  ActivityIndicator,
  Platform,
  Image,
} from "react-native";
// import * as Linking from "expo-linking";
import { SafeAreaView } from "react-native-safe-area-context";
import { MaterialIcons } from "@expo/vector-icons";
import { Button } from "react-native-elements";
import React, { useState, useEffect } from "react";
import { DataStore, Predicates, SortDirection } from "@aws-amplify/datastore";
import * as NavUtils from "../../utils/NavUtils";
import { commonStyles } from "../../styles/commonStyles";
import * as AppColors from "../../styles/AppColors";
import * as Utils from "../../utils/Utils";
import { SearchBar } from "react-native-elements";
import { ScrollView } from "react-native-gesture-handler";
import ErrorMessageComponent from "../../components/ErrorMessageComponent";
import * as DBUtils from "../../utils/DBUtils";
import { ZenObservable } from "zen-observable-ts";
// import { Page, PageHeader } from "../../../screens/LoggedInHomeScreen";
import { useConfirmation } from "../../components/confirmation/ConfirmationService";
import { Appointment, FormType } from "../../src/models";
// import { LinkButton } from "../components/ ButtonComponents";
// import Dinero from "dinero.js";
import * as MailComposer from "expo-mail-composer";
import * as Linking from "expo-linking";
import { Subject } from "@material-ui/icons";
import { LinkButton } from "../../components/ButtonComponents";
import { format, parse } from "date-fns";
import { ifBigScreen } from "../../utils/ifBigScreen";

const isPaidUser = Utils.isPlanActive();

export default function AppointmentsScreen({ navigation, route }) {
  const [selected, setSelected] = useState(format(new Date(), "yyyy-MM-dd"));
  const [selectedId, setSelectedId] = useState(null);
  const [appointments, setAppointments] = useState([]);
  const [search, setSearch] = useState("");
  const [errors, setErrors] = useState("");
  const [isReady, setIsReady] = useState(false);
  const [isImageReady, setIsImageReady] = useState(false);
  const [refresh, doRefresh] = useState(false);
  const [freeTrialOver, setFreeTrialOver] = useState(false);
  const [forceShowMenu, setForceShowMenu] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const confirm = useConfirmation();
  async function showAppointmentCatalog() {
    // const all = await DataStore.query(
    //   Appointment,

    //   Predicates.ALL,
    //   {
    //     sort: (s) =>
    //       s
    //         .hoursStart(SortDirection.ASCENDING)
    //         .minutesStart(SortDirection.ASCENDING),
    //   }
    // );

    //query appts of the visible month only to avoid memory issues as future optimization
    // on arrow press call refresh to refetch and show that month's appt
    const all = await DataStore.query(Appointment);
    const appointmentsUnsorted = all.filter((a) => {
      //const result = format(date, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx")
      const apptDate = format(new Date(a.appointmentDate), "yyyy-MM-dd");
      if (__DEV__) console.log("AppointmentsScreen ", apptDate, selected);
      return apptDate === selected ? true : false;
    });
    const appointments = appointmentsUnsorted.sort((a, b) => {
      // //https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort
      // if (a.ampmStart === "am" && b.ampmStart !== "am") return 1;
      // if (a.hoursStart === b.hoursStart) {
      //   return a.minutesStart - b.minutesStart;
      // }
      // return a.hoursStart - b.hoursStart;

      let aFormat = format(new Date(a.appointmentDate), "yyyy-MM-dd");
      let bFormat = format(new Date(b.appointmentDate), "yyyy-MM-dd");
      aFormat =
        aFormat + " " + a.hoursStart + ":" + a.minutesStart + " " + a.ampmStart;
      bFormat =
        bFormat + " " + b.hoursStart + ":" + b.minutesStart + " " + b.ampmStart;
      if (__DEV__) console.log("AppointmentsScreen aFormat", aFormat, bFormat);

      const aParsed = parse(aFormat, "yyyy-MM-dd h:m b", new Date());
      const bParsed = parse(bFormat, "yyyy-MM-dd h:m b", new Date());
      if (__DEV__) console.log("AppointmentsScreen parsed", aParsed, bParsed);

      return aParsed.getTime() - bParsed.getTime();
    });
    setAppointments(appointments);
    await Utils.checkAndShowTrialEndsSoonDialog(
      true,
      navigation,
      confirm,
      setFreeTrialOver
    );
    setIsReady(true);
    if (appointments?.length === 0)
      setErrors("No Appointments yet. Press New Appointment");
    else setErrors("");
  }
  useEffect(() => {
    const s = async () => await showAppointmentCatalog();
    s();
    return () => {};
  }, [refresh]); //unused refresh but check if issues - will be used for pull to refresh later with refreshcontrol

  const Item = ({ item, onPress, style }) => {
    const formattedStart =
      item.minutesStart < 10 ? "0" + item.minutesStart : item.minutesStart;
    const formattedEnd =
      item.minutesEnd < 10 ? "0" + item.minutesEnd : item.minutesEnd;
    return (
      <TouchableOpacity onPress={onPress} style={[commonStyles.rowItem, style]}>
        <Text style={commonStyles.listTextMainRow}>{item.title}</Text>
        <Text style={commonStyles.listTextSubRow}>
          {item.hoursStart +
            ":" +
            formattedStart +
            " " +
            item.ampmStart +
            " - " +
            item.hoursEnd +
            ":" +
            formattedEnd +
            " " +
            item.ampmEnd}
        </Text>
      </TouchableOpacity>
    );
  };

  const renderItem = ({ item, index }) => {
    const backgroundColor =
      index % 2 === 0 ? AppColors.ROW_BRIGHT : AppColors.ROW_AFTER_BRIGHT;
    return (
      <Item
        item={item}
        onPress={() => {
          setSelectedId(item.id);
          NavUtils.showViewAppointmentScreen(navigation, item.id);
        }}
        style={{ backgroundColor: backgroundColor }}
      />
    );
  };

  const updateSearch = async (search) => {
    setSearch(search);
    const internalSearch = search.toLowerCase();
    const all = await DataStore.query(Appointment);
    const searchResult = all.filter((b) => {
      return b.title?.toLowerCase().includes(internalSearch)
        ? true
        : b.details?.toLowerCase().includes(internalSearch)
        ? true
        : false;
    });

    setAppointments(searchResult);
  };
  const showContent = isPaidUser || !freeTrialOver;
  const onDayPress = (day) => {
    setSelected(day.dateString);
    if (__DEV__) console.log("AppointmentsScreen set ", day.dateString, day);
    doRefresh(!refresh);
  };

  return (
    <SafeAreaView style={commonStyles.pageContainer}>
      {errors.length > 0 && <ErrorMessageComponent errorMsg={errors} />}
      <View
        style={{
          flexDirection: "row",
          // flex: 1,
          justifyContent: "space-between",
          // alignContent: "flex-end",
          marginBottom: 8,
        }}
      >
        <LinkButton
          title={`< Back to Client List`}
          submit={() => {
            if (ifBigScreen)
              NavUtils.showPersonListScreen(navigation, FormType.CLIENT);
            else Utils.refresh();
          }}
          width="50%"
        />
        <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
          <LinkButton
            title={`New Appointment`}
            submit={() =>
              NavUtils.showAddEditAppointmentScreen(
                navigation,
                undefined,
                selected
              )
            }
            // width="90%"
          />
        </View>
        {/* <Button
          buttonStyle={[commonStyles.buttonLinkBack]}
          titleStyle={{ fontSize: 14, fontWeight: "bold" }}
          containerStyle={[
            commonStyles.buttonContainerStyleLinkBankRow,
            // { width: width },
          ]}
          title={"< Back to Clients"}
          // onPress={submit}
          type="clear"
        />
        <Button
          buttonStyle={[commonStyles.buttonLinkBack]}
          titleStyle={{ fontSize: 14, fontWeight: "bold" }}
          containerStyle={[
            commonStyles.buttonContainerStyleLinkBankRow,
            // { width: width },
          ]}
          title={"New Appointment"}
          // onPress={submit}
          type="clear"
        /> */}
      </View>
      <Calendar
        testID="AppointmentCalendar"
        renderArrow={(direction) =>
          direction === "left" ? (
            <View>
              <Text>{"<"}</Text>
            </View>
          ) : (
            <View>
              <Text>{">"}</Text>
            </View>
          )
        }
        onDayPress={onDayPress}
        markedDates={{
          [selected]: {
            selected: true,
            disableTouchEvent: true,
            selectedColor: "orange",
            selectedTextColor: "red",
          },
        }}
      />
      <FlatList
        data={appointments}
        renderItem={renderItem}
        keyExtractor={(item) => item.id}
        extraData={selectedId}
      />
    </SafeAreaView>
  );
}
