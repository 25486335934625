import { FlatList, TouchableOpacity, Text, View } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import { Input, Button, Card } from "react-native-elements";
import { styles } from "../../App";
import React, { useState, useEffect } from "react";
import { Task, AddUserToT, FormType, TaskClient } from "../../src/models/index";
import { DataStore } from "@aws-amplify/datastore";
import { commonStyles } from "../../styles/commonStyles";
import * as NavUtils from "../../utils/NavUtils";
import { ScrollView } from "react-native-gesture-handler";
import { LinkButton } from "../../components/ButtonComponents";
import * as Utils from "../../utils/Utils";
import { useConfirmation } from "../../components/confirmation/ConfirmationService";

export const ViewTaskScreen = ({ navigation, route }) => {
  const confirm = useConfirmation();

  //props needed for going to given screen using navigation
  const [taskState, setTaskState] = useState({
    taskName: undefined,
    taskPurpose: undefined,
    numberOfMembers: 0,
    status: undefined,
    dueDate: undefined,
  });

  const taskId = route.params?.taskId;

  async function updatePageState() {
    const taskOriginal = await DataStore.query(Task, taskId);
    const taskMembers = (await DataStore.query(TaskClient)).filter(
      (g) => g.task.id === taskId
    );

    setTaskState({ ...taskOriginal, numberOfMembers: taskMembers.length });
  }

  useEffect(() => {
    (async () => {
      await updatePageState();
    })();
  }, []);

  async function showEditTask() {
    NavUtils.showAddEditTaskScreen(navigation, taskId);
  }

  async function deleteTask() {
    await Utils.areYouSureDialog(confirm, async () => {
      const taskToDelete = await DataStore.query(Task, taskId);
      await DataStore.delete(taskToDelete);
      NavUtils.showTaskListScreen(navigation);
    });
  }
  function showTaskMemberSelectionScreen(): void {
    NavUtils.showTaskMemberSelectionScreen(navigation, taskId);
  }
  return (
    <SafeAreaView style={commonStyles.pageContainer}>
      <LinkButton
        title="< Back to Task List"
        submit={() => NavUtils.showTaskListScreen(navigation)}
      />
      <ScrollView style={{ margin: 0, marginTop: 10, padding: 0 }}>
        <Card containerStyle={commonStyles.mainCardContainer}>
          <Text
            style={[
              commonStyles.textFieldName,
              { marginLeft: 0, marginBottom: 4 },
            ]}
          >
            Task Name
          </Text>
          <Text style={{ marginBottom: 4 }}>{taskState.taskName}</Text>
          <Text
            style={[
              commonStyles.textFieldName,
              { marginLeft: 0, marginBottom: 4 },
            ]}
          >
            Task Purpose
          </Text>
          <Text style={{ marginBottom: 4 }}>{taskState.taskPurpose}</Text>

          <View style={{ flexDirection: "row", marginBottom: 8 }}>
            <Text
              style={[
                commonStyles.textFieldName,
                { marginLeft: 0, marginRight: 10 },
              ]}
            >{`Members: ${taskState.numberOfMembers}`}</Text>
            <Button
              buttonStyle={{ margin: 0, padding: 0 }}
              // containerStyle={{ width: "30%" }}
              containerStyle={{ margin: 0, marginTop: 7, padding: 0 }}
              titleStyle={{ fontSize: 16 }}
              title="Update Members"
              onPress={() => showTaskMemberSelectionScreen()}
              type="clear"
            />
          </View>
          <Text
            style={[
              commonStyles.textFieldName,
              { marginLeft: 0, marginBottom: 4 },
            ]}
          >
            Task Status
          </Text>
          <Text style={{ marginBottom: 4 }}>{taskState.status}</Text>
          <Text
            style={[
              commonStyles.textFieldName,
              { marginLeft: 0, marginBottom: 4 },
            ]}
          >
            Due Date
          </Text>
          {taskState.dueDate && (
            <Text style={{ marginBottom: 4 }}>
              {new Date(taskState.dueDate).toLocaleDateString()}
            </Text>
          )}
          <Button
            buttonStyle={commonStyles.buttonRow}
            // containerStyle={{ width: "30%" }}
            title="Edit"
            onPress={() => showEditTask()}
          />
          <Button
            buttonStyle={commonStyles.buttonDeleteRow}
            // containerStyle={{ width: "30%" }}
            title="Delete"
            onPress={() => deleteTask()}
          />
        </Card>
      </ScrollView>
    </SafeAreaView>
  );
};
