import { Auth, DataStore } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { ScrollView, Text, View } from "react-native";
import { SubmitAndCancelButtons } from "../../components/ButtonComponents";
import { EulaTracker } from "../../src/models";
import { EulaAccepted } from "../../src/models";
import { getGroupNameAsTenant } from "../../utils/getGroupNameAsTenant";
import {
  Button,
  Provider,
  Paragraph,
  Dialog,
  Portal,
} from "react-native-paper";

export function TermsScreen({ navigation, route }) {
  return (
    <>
      <ScrollView>
        <Text>
          By downloading any application from Siyami Apps, LLC (here after
          referred to as "The Company"), installing or using this application
          over web or any portion thereof ("Application"), you agree to the
          following terms and conditions (the "Terms and Conditions").
        </Text>
        <Text> 1. USE OF APPLICATION</Text>
        <Text></Text>
        <Text>
          a. The Company grants you the non-exclusive, non-transferable, limited
          right and license to install and use this Application solely and
          exclusively for your personal use.
        </Text>
        <Text>
          b. You may not use the Application in any manner that could damage,
          disable, over-burden, or impair the Application (or servers or
          networks connected to the Application), nor may you use the
          Application in any manner that could interfere with any other party's
          use and enjoyment of the Application (or servers or networks connected
          to the Application).
        </Text>
        <Text>
          c. You agree that you are solely responsible for (and that The Company
          has no responsibility to you or to any third party for) your use of
          the Application, any breach of your obligations under the Terms and
          Conditions, and for the consequences (including any loss or damage
          which The Company may suffer) of any such breach.
        </Text>
        <Text>
          d. You agree that you will be responsible for usage and payment of
          charges related data, SMS and voice or phone based on your mobile plan
          with your carrier.
        </Text>
        <Text>
          e. The Application from The Company may present other Applications on
          your device. You understand that The Company does not control or
          manage the content, privacy or security policies of third-party
          applications installed on your device. It is your responsibility to
          contact them for review and clarification.
        </Text>
        <Text>
          f. You understand the need to use different Test and Production
          Devices of identical specs and content. You agree to test the
          Application and new version upgrades on the Test Device first to
          ensure application satisfies your needs before applying similar
          changes to the Production Device. You also understand that you need to
          read and agree to the App Release Notes before installing the App or
          Upgrades.
        </Text>
        <Text>
          g. You understand that you will need to follow Back-Up procedures. It
          is your responsibility to take backup periodically and before updating
          the App. You agree to save them at secure place so you can restore in
          future.
        </Text>
        <Text>
          h. You agree that The Company reserves the right to add or change
          pricing or subscription programs for the use of App at any point in
          time without any notice. You also understand that the Company can
          change or add subscription pricing for new upgrades or versions of the
          App. You agree to review the release notes and determine if the new
          pricing will work for you before installing that version. You
          understand that once the App is upgraded, you cannot go back to the
          previous version of the App.
        </Text>
        <Text>2. PROPRIETARY RIGHTS</Text>
        <Text> You acknowledge that</Text>
        <Text>
          (a) the Application contains proprietary and confidential information
          that is protected by applicable intellectual property and other laws,
          and
        </Text>
        <Text>
          (b) The Company and/or third parties own all right, title and interest
          in and to the Application and content, excluding content provided by
          you, that may be presented or accessed through the Application,
          including without limitation all Intellectual Property Rights therein
          and thereto. "Intellectual Property Rights" means any and all rights
          existing from time to time under patent law, copyright law, trade
          secret law, trademark law, unfair competition law, and any and all
          other proprietary rights, and any and all applications, renewals,
          extensions and restorations thereof, now or hereafter in force and
          effect worldwide. You agree that you will not, and will not allow any
          third party to,
        </Text>
        <Text>
          (i) copy, sell, license, distribute, transfer, modify, adapt,
          translate, prepare derivative works from, decompile, reverse engineer,
          disassemble or otherwise attempt to derive source code from the
          Application or content that may be presented or accessed through the
          Application for any purpose, unless otherwise permitted,
        </Text>
        <Text>
          (ii) take any action to circumvent or defeat the security or content
          usage rules provided, deployed or enforced by any functionality
          (including without limitation digital rights management functionality)
          contained in the Application,
        </Text>
        <Text>
          (iii) use the Application to access, copy, transfer, transcode or
          retransmit content in violation of any law or third party rights, or
        </Text>
        <Text>
          (iv) remove, obscure, or alter The Company's or any third partys'
          copyright notices, trademarks, or other proprietary rights notices
          affixed to or contained within or accessed in conjunction with or
          through the Application.
        </Text>
        <Text>3. THE COMPANY TERMS OF SERVICE AND PRIVACY POLICY </Text>
        <Text>
          a. The Company's Privacy Policy explains how the Company treats your
          information and protects your privacy when you use the Application.
          You agree to the use of your data in accordance with the Company's
          privacy policies.
        </Text>
        <Text>
          b. The Company's Privacy Policy for this App is located at
          http://cloudcrm.siyamiapps.com/#privacy-policy and policy may be
          updated from time to time and without notice.
        </Text>
        <Text>
          c. The Company reserves the right to anonymously track and report a
          user's activity inside of the app.
        </Text>
        <Text>
          d. You understand the need to protect the privacy of client data. You
          understand that you need to procure tools, services, software etc to
          encrypt the data or the files produced on the device by this app. To
          protect the data in cases when the device gets lost, you understand
          the need to enroll or procure services and software that can erase the
          device remotely to protect the client privacy. You understand the need
          to use encryption when sharing or emailing private and sensitive
          client data. You also understand the importance of password protecting
          the device as well as this application by using or procuring features
          available in the device or from other Apps, Vendors, Software in the
          marketplace. When sharing sensitive data via email or other means
          ensure that you and your receivers are using the secure email client
          and provider. If not, consider removing the identifying information
          from data or content in the email or other apps before sending to
          protect the privacy of the client. Moreover, before discarding the
          device, it is your responsibility to eliminate any sensitive data from
          the device, internal and external memory or anywhere else you may have
          stored it.
        </Text>
        <Text>
          e. The Application from The Company may present other Applications on
          your device. You understand that The Company does not control or
          manage the content, privacy or security policies of third-party
          applications installed on your device. It is your responsibility to
          contact them for review and clarification.
        </Text>
        <Text>
          4. U.S. GOVERNMENT RESTRICTED RIGHTS This Application, related
          materials and documentation have been developed entirely with private
          funds. If the user of the Application is an agency, department,
          employee, or other entity of the United States Government, the use,
          duplication, reproduction, release, modification, disclosure, or
          transfer of the Application, including technical data or manuals, is
          restricted by the terms, conditions and covenants contained in these
          Terms and Conditions. In accordance with Federal Acquisition
          Regulation 12.212 for civilian agencies and Defense Federal
          Acquisition Regulation Supplement 227.7202 for military agencies, use
          of the Application is further restricted by these Terms and
          Conditions.
        </Text>
        <Text>
          5. EXPORT RESTRICTIONS The Application may be subject to export
          controls or restrictions by the United States or other countries or
          territories. You agree to comply with all applicable U.S. and
          international export laws and regulations. These laws include
          restrictions on destinations, end users, and end use.
        </Text>
        <Text>
          6. TERMINATION These Terms and Conditions will continue to apply until
          terminated by either you or The Company as set forth below. You may
          terminate these Terms and Conditions at any time by permanently
          deleting the Application from your mobile device in its entirety. Your
          rights automatically and immediately terminate without notice from The
          Company or any Third Party if you fail to comply with any provision of
          these Terms and Conditions. In such event, you must immediately delete
          the Application.
        </Text>
        <Text>
          7. INDEMNITY To the maximum extent permitted by law, you agree to
          defend, indemnify and hold harmless The Company, its affiliates and
          their respective directors, officers, employees and agents from and
          against any and all claims, actions, suits or proceedings, as well as
          any and all losses, liabilities, damages, costs and expenses
          (including reasonable attorneys fees) arising out of or accruing from
          your use of the Application, including your downloading, installation,
          or use of the Application, or your violation of these Terms and
          Conditions.
        </Text>
        <Text>8. DISCLAIMER OF WARRANTIES </Text>
        <Text>
          a. YOU EXPRESSLY UNDERSTAND AND AGREE THAT YOUR USE OF THE APPLICATION
          IS AT YOUR SOLE DISCRETION AND RISK AND THAT THE APPLICATION IS
          PROVIDED AS IS AND AS AVAILABLE WITHOUT WARRANTY OF ANY KIND.
        </Text>
        <Text>
          b. YOU ARE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR MOBILE DEVICE, OR
          OTHER DEVICE, OR LOSS OF DATA THAT RESULTS FROM SUCH USE.
        </Text>
        <Text>
          c. THE COMPANY FURTHER EXPRESSLY DISCLAIMS ALL WARRANTIES AND
          CONDITIONS OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT
          LIMITED TO THE IMPLIED WARRANTIES AND CONDITIONS OF MERCHANTABILITY,
          FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT, WITH RESPECT TO
          THE APPLICATION.
        </Text>
        <Text>
          d. THE APPLICATION IS NOT INTENDED FOR USE IN THE OPERATION OF NUCLEAR
          FACILITIES, LIFE SUPPORT SYSTEMS, EMERGENCY COMMUNICATIONS, AIRCRAFT
          NAVIGATION OR COMMUNICATION SYSTEMS, AIR TRAFFIC CONTROL SYSTEMS, OR
          ANY OTHER ACTIVITIES IN WHICH THE FAILURE OF THE APPLICATION COULD
          LEAD TO DEATH, PERSONAL INJURY, SEVERE BUSINESS DAMAGE OR SEVERE
          PHYSICAL OR ENVIRONMENTAL DAMAGE.
        </Text>
        <Text>
          9. LIMITATION OF LIABILITY YOU EXPRESSLY UNDERSTAND AND AGREE THAT THE
          COMPANY, ITS SUBSIDIARIES AND AFFILIATES, AND ITS LICENSORS ARE NOT
          LIABLE TO YOU UNDER ANY THEORY OF LIABILITY FOR ANY DIRECT, INDIRECT,
          INCIDENTAL, SPECIAL CONSEQUENTIAL OR EXEMPLARY DAMAGES THAT MAY BE
          INCURRED BY YOU THROUGH YOUR USE OF THE APPLICATION, INCLUDING ANY
          LOSS OF DATA OR DAMAGE TO YOUR BUSINESS OR DAMAGE TO YOUR ASSETS,
          WHETHER OR NOT THE COMPANY OR ITS REPRESENTATIVES HAVE BEEN ADVISED OF
          OR SHOULD HAVE BEEN AWARE OF THE POSSIBILITY OF ANY SUCH LOSSES
          ARISING.
        </Text>
        <Text>10. MISCELLANEOUS </Text>
        <Text>
          a. These Terms and Conditions constitute the entire Agreement between
          you and The Company relating to the Application and govern your use of
          the Application, and completely replace any prior or contemporaneous
          agreements between you and The Company regarding the Application.
        </Text>
        <Text>
          b. The failure of The Company to exercise or enforce any right or
          provision of these Terms and Conditions does not constitute a waiver
          of such right or provision, which will still be available to The
          Company.
        </Text>
        <Text>
          c. If any court of law, having the jurisdiction to decide on this
          matter, rules that any provision of these Terms and Conditions is
          invalid, then that provision will be removed from the Terms and
          Conditions without affecting the rest of the Terms and Conditions. The
          remaining provisions of these Terms and Conditions will continue to be
          valid and enforceable.
        </Text>
        <Text>
          d. The rights granted in these Terms and Conditions may not be
          assigned or transferred by either you without the prior written
          approval of the other party. You are not permitted to delegate your
          responsibilities or obligations under these Terms and Conditions
          without the prior written approval of the other party.
        </Text>
        <Text>
          e. These Terms and Conditions and your relationship with The Company
          under these Terms and Conditions will be governed by the laws of the
          State of Delaware without regard to its conflict of laws provisions.
          You and The Company agree to submit to the exclusive jurisdiction of
          the courts located within the State Delaware to resolve any legal
          matter arising from these Terms and Conditions. Notwithstanding this,
          you agree that The Company will still be allowed to apply for
          injunctive remedies (or an equivalent type of urgent legal relief) in
          any jurisdiction.
        </Text>
      </ScrollView>
    </>
  );
}
