import { DataStore } from "@aws-amplify/datastore";
import * as MailComposer from "expo-mail-composer";
import React, { useEffect, useState } from "react";
import { Linking, Platform, Text, View } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import { Button, ButtonGroup, Input } from "react-native-elements";
import { ScrollView } from "react-native-gesture-handler";
import { styles } from "../../App";
import { SubmitAndCancelButtons } from "../../components/ButtonComponents";
import {
  getCustomFieldsFromDB,
  initialStateForPersonForm,
  PersonFormComponent,
} from "../../components/person/PersonFormComponent";
import {
  BulkProtocolType,
  Client,
  EmailToType,
  FormType,
} from "../../src/models/index";
import { commonStyles } from "../../styles/commonStyles";
import { getGroupNameAsTenant } from "../../utils/getGroupNameAsTenant";
import * as NavUtils from "../../utils/NavUtils";
import { EmailHistory, EmailType } from "../../src/models/index";
import * as Utils from "../../utils/Utils";
import * as SMS from "expo-sms";
import * as LinkingExpo from "expo-linking";

export default function EmailComposerScreen({ navigation, route }) {
  //props needed for going to given screen using navigation
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");
  const [toType, setToType] = useState(EmailToType.BCC);
  const [refreshFlag, setRefreshFlag] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [recipientEmails, setRecipientEmails] = useState([]);
  const [recipientIds, setRecipientIds] = useState([]);

  const selectedClients = route.params?.clientMap;
  const emailId = route.params?.emailId;
  const bulkProtocolType = route.params?.bulkProtocolType
    ? route.params?.bulkProtocolType
    : "Email";
  async function updatePageState() {
    if (emailId !== undefined) {
      const email = await DataStore.query(EmailHistory, emailId);
      setSubject(email?.subject);
      setBody(email?.body);
      setToType(email?.toType);
      if (email?.toType === EmailToType.CC) setSelectedIndex(1);
      // if undefined then BCC default
      else setSelectedIndex(0);
    }
    let recipeientEmailsInit = [];
    let recipeientIds = [];

    for (let value of selectedClients.values()) {
      recipeientEmailsInit.push(
        bulkProtocolType === BulkProtocolType.SMS
          ? value.cellPhone
          : value.email
      );
      recipeientIds.push(value.id);
    }
    setRecipientEmails(recipeientEmailsInit);
    setRecipientIds(recipeientIds);
  }

  function refresh() {
    setRefreshFlag(!refreshFlag);
  }

  useEffect(() => {
    (async () => {
      await updatePageState();
    })();
  }, [refreshFlag]);

  async function openEmailClient() {
    let bccRecipients = [];
    let ccRecipients = [];

    if (toType === EmailToType.CC) {
      ccRecipients = recipientEmails;
    } else {
      bccRecipients = recipientEmails;
    }
    const toEmail = await Utils.getUserEmail();

    let result = await MailComposer.composeAsync({
      recipients: [toEmail],
      subject: subject,
      body: body,
      ccRecipients: ccRecipients,
      bccRecipients: bccRecipients,
    });
    //    alert(result.status);
    return result;
  }
  async function openSMSClient() {
    // const isAvailable = await SMS.isAvailableAsync();
    // let resultFinal = "";
    // if (isAvailable) {
    //   // do your SMS stuff here
    //   let { result } = await SMS.sendSMSAsync(
    //     ["8602558329"],
    //     body
    //     // {
    //     //   attachments: {
    //     //     uri: "path/myfile.png",
    //     //     mimeType: "image/png",
    //     //     filename: "myfile.png",
    //     //   },
    //     // }
    //   );
    //   resultFinal = result;
    // } else {
    //   // misfortune... there's no SMS available on this device
    //   resultFinal = "Unavailable";
    //   if(__DEV__) console.error("NO SMS AVAILABLE");
    //   //TO DO ALert
    // }
    // alert(resultFinal);
    // return resultFinal;

    //works
    // Linking.openURL(`sms:8602558329,8604692771?body=hi, how are you`);

    let numbers = "";
    recipientEmails.forEach((phoneNumber: string) => {
      // numbers += `1${phoneNumber},`;
      numbers += `${phoneNumber},`;
    });
    numbers = numbers.slice(0, -1);

    const url =
      // Platform.OS === "android" || true
      //   ? `sms:${numbers}?body=${body}`
      //   : `sms:/open?addresses=${numbers}&body=${body}`;
      !Utils.isIOS()
        ? `sms:${numbers}?body=${body}`
        : `sms:/open?addresses=${numbers}&body=${body}`;
    if (__DEV__) console.log("smsurl=" + url);
    Linking.openURL(url);
  }
  async function submit() {
    let result;
    if (bulkProtocolType === "Email") result = await openEmailClient();
    else result = await openSMSClient();
    await saveData(EmailType.SENT);

    NavUtils.showEmailHistoryScreen(navigation, bulkProtocolType);
  }
  async function saveData(emailType) {
    var groupForTenant = await getGroupNameAsTenant();
    var result;
    var postData = {
      subject: subject,
      body: body,
      emailType: emailType,
      recipients: JSON.stringify(recipientIds),
      sentAt: new Date().toISOString(),
      toType: toType,
      bulkProtocolType: bulkProtocolType,
    };
    //avoids true groupname for tenant when null
    if (groupForTenant !== null)
      postData = { ...postData, tenant: groupForTenant };

    if (emailId === undefined) {
      result = await DataStore.save(new EmailHistory(postData));
    } else {
      const emailOriginal = await DataStore.query(EmailHistory, emailId);
      result = await DataStore.save(
        EmailHistory.copyOf(emailOriginal, (updated) => {
          const returnedTarget = Object.assign(updated, postData);
        })
      );
    }
    return result;
  }
  const cancel = async () => {
    //navigation.goBack();
    await saveData(EmailType.DRAFT);
    NavUtils.showEmailClientsSelectionScreen(
      navigation,
      emailId,
      bulkProtocolType
    );
  };
  const buttons = [EmailToType.BCC, EmailToType.CC];

  function updateIndex(selectedIndex) {
    setSelectedIndex(selectedIndex);
    if (selectedIndex === 0) setToType(EmailToType.BCC);
    else setToType(EmailToType.CC);
  }

  return (
    <SafeAreaView style={commonStyles.pageContainer}>
      <SubmitAndCancelButtons
        navigation={navigation}
        submit={submit}
        cancel={cancel}
        titleSubmit={`Send ${bulkProtocolType}`}
        titleCancel="Back"
        flexDirection="row"
      />
      <ScrollView>
        {/* <Text>{JSON.stringify(Platform)}</Text> */}

        {bulkProtocolType === "Email" && (
          <View>
            <ButtonGroup
              onPress={updateIndex}
              selectedIndex={selectedIndex}
              buttons={buttons}
              // containerStyle={{ height: 10 }}
            />
            <Text style={{ marginBottom: 4 }}>BCC hides email addresses</Text>
            <Input
              testID="subject"
              value={subject}
              maxLength={100}
              placeholder="Subject of Email"
              onChangeText={(e) => setSubject(e)}
            />
            <Input
              testID="body"
              value={body}
              maxLength={10000}
              multiline={true}
              placeholder="Body of the Email"
              onChangeText={(e) => setBody(e)}
              numberOfLines={10}
            />
            <View
              style={{
                margin: 0,
                marginTop: 5,
                borderWidth: 0,
                borderColor: "gray",
              }}
            >
              <Text style={{ margin: 0, color: "red" }}>*Important Note</Text>

              <Text style={commonStyles.pageNotes}>
                Just so you know, your local email client will open after you
                tap Send Button. Remember to press Send button in that App too!
              </Text>
            </View>

            {(Utils.isAndroid() || Utils.isIOS()) && (
              <View
                style={{
                  margin: 0,
                  marginTop: 2,
                  borderWidth: 0,
                  borderColor: "gray",
                }}
              >
                <Text style={{ margin: 0, color: "red" }}>
                  *Even more Important Note
                </Text>

                <Text style={commonStyles.pageNotes}>
                  Bulk send may not work in some devices. In that case, just
                  press{" "}
                  <Button
                    type="clear"
                    title="here"
                    onPress={() =>
                      //<a href="mailto:test@example.com?subject=Testing out mailto!&body=This is only a test!">Second Example</a>

                      LinkingExpo.openURL("https://cloudcrm.siyamiapps.com")
                    }
                  />{" "}
                  to go to our website and do bulk send operations.
                </Text>
              </View>
            )}
          </View>
        )}

        {bulkProtocolType === "SMS" && (
          <View>
            <Input
              testID="body"
              value={body}
              maxLength={1000}
              multiline={true}
              placeholder="Type SMS text"
              onChangeText={(e) => setBody(e)}
              numberOfLines={6}
            />
            <View style={{ margin: 0, borderWidth: 0, borderColor: "gray" }}>
              <Text style={{ margin: 0, color: "red" }}>*Important Note</Text>

              <Text style={commonStyles.pageNotes}>
                Just so you know, your local SMS App will open after you tap
                Send SMS Button below. Remember to press Send button in that App
                too!
              </Text>
            </View>
            {(Utils.isAndroid() || Utils.isIOS()) && (
              <View
                style={{
                  margin: 0,
                  marginTop: 2,
                  borderWidth: 0,
                  borderColor: "gray",
                }}
              >
                <Text style={{ margin: 0, color: "red" }}>
                  *Even more Important Note
                </Text>

                <Text style={commonStyles.pageNotes}>
                  Bulk send may not work in some devices. In that case, just
                  press{" "}
                  <Button
                    type="clear"
                    title="here"
                    onPress={() =>
                      //<a href="mailto:test@example.com?subject=Testing out mailto!&body=This is only a test!">Second Example</a>

                      LinkingExpo.openURL("https://cloudcrm.siyamiapps.com")
                    }
                  />{" "}
                  to go to our website and do bulk send operations.
                </Text>
              </View>
            )}
          </View>
        )}
      </ScrollView>
    </SafeAreaView>
  );
}
