import { Auth, DataStore } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { Linking, ScrollView, Text, View } from "react-native";
import { SubmitAndCancelButtons } from "../../components/ButtonComponents";
import { EulaTracker } from "../../src/models";
import { EulaAccepted } from "../../src/models";
import { getGroupNameAsTenant } from "../../utils/getGroupNameAsTenant";
import { Provider, Paragraph, Dialog, Portal } from "react-native-paper";
import { Button } from "react-native-elements";
import * as Utils from "../../utils/Utils";
let url = location.href;
//url.replace("/Privacy", "");
export function PrivacyScreen({ navigation, route }) {
  return (
    <>
      <Button
        buttonStyle={{ margin: 20, padding: 0 }}
        // containerStyle={{ width: "30%" }}
        // containerStyle={{ margin: 20, paddingTop: 4 }}
        title="Go to App Home Page"
        onPress={() => {
          Utils.refresh();
          // navigation.goBack();
        }}
        type="clear"
      />

      <ScrollView style={{ margin: 10 }}>
        <Text style={{ marginBottom: 10 }}>Updated on July 5, 2021 </Text>

        <Text style={{ marginBottom: 10 }}>
          This privacy policy has been compiled to better serve those who are
          concerned with how their ‘Personally Identifiable Information’ (PII)
          is being used online. PII, as described in US privacy law and
          information security, is information that can be used on its own or
          with other information to identify, contact, or locate a single
          person, or to identify an individual in context. Please read our
          privacy policy carefully to get a clear understanding of how we
          collect, use, protect or otherwise handle your Personally Identifiable
          Information in accordance with our website.
        </Text>
        <Text style={{ marginBottom: 10 }}>
          Do we have access to private information inside the App?
        </Text>
        <Text style={{ marginBottom: 10 }}>
          We have access to data you entered inside the App for support
          purposes. We do not have access to private device level data including
          SMS and Phone logs. We do not sell any of your private information
          inside the App.
        </Text>
        <Text style={{ marginBottom: 10 }}>
          What personal information do we collect from the people that visit our
          blog, website or app?
        </Text>
        <Text style={{ marginBottom: 10 }}>
          When ordering or registering on our site, as appropriate, you may be
          asked to enter your email address or other details to help you with
          your experience.
        </Text>
        <Text style={{ marginBottom: 10 }}>
          When do we collect information?
        </Text>
        <Text style={{ marginBottom: 10 }}>
          We collect information from you when you subscribe to a newsletter or
          enter information on our site.
        </Text>
        <Text style={{ marginBottom: 10 }}>
          How do we use your information?
        </Text>
        <Text style={{ marginBottom: 10 }}>
          We may use the information we collect from you when you register, make
          a purchase, sign up for our newsletter, respond to a survey or
          marketing communication, surf the website, or use certain other site
          features in the following ways:
        </Text>
        <Text style={{ marginBottom: 10 }}>
          • To send periodic emails regarding your order or other products and
          services.
        </Text>
        <Text style={{ marginBottom: 10 }}>
          {" "}
          How do we protect your information?{" "}
        </Text>
        <Text style={{ marginBottom: 10 }}>
          We use modern encryption standard to ensure information exchange
          between your device and our servers is fully encrypted to protect
          security and privacy of your data. We do not use scanning to PCI
          standards. We never ask for credit card numbers. All transactions are
          processed through a gateway provider and are not stored or processed
          on our servers.
        </Text>
        <Text style={{ marginBottom: 10 }}>Do we use ‘cookies’? </Text>
        <Text style={{ marginBottom: 10 }}>
          We use cookies for essential functions of the Application. You can
          choose to have your computer warn you each time a cookie is being
          sent, or you can choose to turn off all cookies. You do this through
          your browser settings. Since every browser is a little different, look
          at your browser’s Help Menu to learn the correct way to modify your
          cookies. If you turn cookies off, some features will be disabled, that
          make your site experience more efficient and may not function
          properly.
        </Text>
        <Text style={{ marginBottom: 10 }}>Third-party disclosure</Text>
        <Text style={{ marginBottom: 10 }}>
          We do not sell, trade, or otherwise transfer to outside parties your
          Personally Identifiable Information unless we provide users with
          advance notice. This does not include website hosting partners and
          other parties who assist us in operating our website, conducting our
          business, or serving our users, so long as those parties agree to keep
          this information confidential. We may also release information when
          it’s release is appropriate to comply with the law, enforce our site
          policies, or protect ours or others’ rights, property or safety.
          However, non-personally identifiable visitor information may be
          provided to other parties for marketing, advertising, or other uses.
          Third-party links occasionally, at our discretion, we may include or
          offer third-party products or services on our website. These
          third-party sites have separate and independent privacy policies. We
          therefore have no responsibility or liability for the content and
          activities of these linked sites. Nonetheless, we seek to protect the
          integrity of our site and welcome any feedback about these sites.
        </Text>
        <Text style={{ marginBottom: 10 }}>Contacting Us</Text>
        <Text style={{ marginBottom: 10 }}>
          If there are any questions regarding this privacy policy, you may
          contact us using support@siyamiapps.com
        </Text>
        <Text style={{ marginBottom: 10 }}>Last Edited on July 5th 2021</Text>
      </ScrollView>
    </>
  );
}
