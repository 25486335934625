import { Auth } from "aws-amplify";
import { DataStore } from "@aws-amplify/datastore";
import { Client, AddUserToT, RolesDefault } from "../src/models/index";
import { AddUserToTenantStatus } from "../src/models";

//not used yet and needs to be checked if it works
export const isConfirmed = async () => {
  //check whether confirmed in user table
  //ALSO CHECK COGNITO - BUT NOT REALLY AS WOULD NOT BE ALLOWED TO LOGIN
  //so if table is NOT confirmed , just in case cognito lamda trigger to disable failed, this will catch it
  //owner is normally not i users table per current design so null is OK as single user company
  //or do we need another table for owner?But it may get syncd to non users SO NO.

  // WRONG username is cognito uuid below.. Use isowner like below
  const userInfo = await Auth.currentUserInfo();
  if (userInfo === null || userInfo === undefined) return false;
  const userName = userInfo.username;
  const user = await DataStore.query(AddUserToT, (u) =>
    u.userEmail("eq", userName)
  );
  if (user === undefined || user.length === 0) {
    return true; //single user company
  } else if (user.length === 1) {
    if (user.userStatus === AddUserToTenantStatus.CONFIRMED) return true;
    return false;
    //why mulitple users with same email..
  } else if (user.length > 1) {
    return false;
    //why mulitple users with same email..
  }
  return true;
};
export const isNotConfirmed = async () => {
  const iConfirmed = await isConfirmed();
  return !isConfirmed;
};

export const isAllowed = () => {
  //BE CAREFUL TO NOT UPDATE owner field of OBJECT as this caused SYNC issues when owner was updated on AddToT
  //check permissions attribute
  //{ operation1:y .. OR to beginwith readonly:y, admin:y, readwrite: y, owner:y}
  //owner can create users, admin can Manage App Users and permissions but not create users, readwrite can update data,
};

export async function isCurrentlyLoggedInUserAnOwner() {
  //return false;

  const user = await Auth.currentAuthenticatedUser();
  const userEmailCurrent = user.attributes.email;

  const userMatched = await DataStore.query(AddUserToT, (u) =>
    u.userEmail("eq", userEmailCurrent)
  );
  //array--------------fix
  if (userMatched.length === 0) return false;
  if (userMatched.length > 1) {
    throw new Error(userMatched.length + " users for " + userEmailCurrent);
    return;
  }
  const isOwner = userMatched[0].userRole === RolesDefault.OWNER;
  return isOwner;
}
