import { DataStore } from "@aws-amplify/datastore";
import React, { useEffect, useState } from "react";
import { View } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import { Button } from "react-native-elements";
import { ScrollView } from "react-native-gesture-handler";
import { styles } from "../../App";
import { SubmitButton } from "../../components/ButtonComponents";
import {
  getCustomFieldsFromDB,
  initialStateForPersonForm,
  PersonFormComponent,
} from "../../components/person/PersonFormComponent";
import { Client, FormType } from "../../src/models/index";
import { commonStyles } from "../../styles/commonStyles";
import { getGroupNameAsTenant } from "../../utils/getGroupNameAsTenant";
import * as NavUtils from "../../utils/NavUtils";
import * as Utils from "../../utils/Utils";

export const AddClientScreen = ({ navigation, route }) => {
  //props needed for going to given screen using navigation
  const { formType } = route.params;
  const initFormState = initialStateForPersonForm(formType);

  const [form, updateForm] = useState({
    ...initFormState,
  });
  const [refreshFlag, setRefreshFlag] = useState(true);

  async function updatePageState() {
    if (route.params?.clientId === undefined) {
      const customFieldsFromDB = await getCustomFieldsFromDB(formType);
      updateForm({ ...form, customFields: customFieldsFromDB });
    } else {
      const clientOriginal = await DataStore.query(
        Client,
        route.params.clientId
      );
      updateForm({ ...form, ...clientOriginal });
    }
  }

  function refresh() {
    setRefreshFlag(!refreshFlag);
  }
  async function refreshCustomFields() {
    const customFieldsFromDB = await getCustomFieldsFromDB(formType);
    //merge current values from state of form object as DB values are blank
    // if not done, then changing custom field names cause parent form to lose values
    //not cool especially for edit as it will lose existing values
    const customFieldObjectInForm = JSON.parse(form.customFields);
    const customFieldsObjectFromDB = JSON.parse(customFieldsFromDB);

    const customFieldMergedArray = customFieldObjectInForm.map(
      (field, index) => {
        const customFieldMatched = customFieldsObjectFromDB.find(
          (dbField) => dbField.seq === field.seq
        );
        const mergedField = { ...customFieldMatched, value: field.value };
        return mergedField;
      }
    );
    const customFieldMergedStringArray = JSON.stringify(customFieldMergedArray);
    updateForm({ ...form, customFields: customFieldMergedStringArray });
  }

  useEffect(() => {
    (async () => {
      await updatePageState();
    })();
  }, [refreshFlag]);

  async function saveData() {
    var groupForTenant = await getGroupNameAsTenant();
    var client;
    var postData = { ...form };
    //avoids true groupname for tenant when null
    if (groupForTenant !== null) postData = { ...form, tenant: groupForTenant };
    postData = { ...postData, formType: formType };

    if (route.params?.clientId === undefined) {
      client = await DataStore.save(new Client(postData));
    } else {
      //can this be saved in state or as a constant outside of function but it will get
      //reinitialized when componenet re-renders. Not needed in state as no re-render needed when it change
      // and more importantly it will complicate form state.
      //useRef?
      const clientOriginal = await DataStore.query(
        Client,
        route.params.clientId
      );
      client = await DataStore.save(
        Client.copyOf(clientOriginal, (updated) => {
          const returnedTarget = Object.assign(updated, postData);
        })
      );
    }
    return client;
  }
  async function submit() {
    var client = await saveData();
    NavUtils.showViewClientScreen(navigation, client.id, formType);
  }
  const cancel = () => {
    if (route.params?.clientId === undefined) {
      //if on add, then history is removed so go back to list screen as goBack() does not work
      NavUtils.showClientListScreen(navigation, formType);
    } else {
      //View screen is cancel screen from edit
      NavUtils.showViewClientScreen(
        navigation,
        route.params?.clientId,
        formType
      );
    }
  };
  return (
    <SafeAreaView style={commonStyles.pageContainer}>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Button
          data-testid="cancel"
          containerStyle={[commonStyles.buttonRow, { width: "30%" }]}
          type="outline"
          title="Cancel"
          onPress={cancel}
        />
        <Button
          testID="CustomizePage"
          containerStyle={{ marginVertical: 10 }}
          title="Customize Page"
          onPress={() => {
            navigation.navigate("customizePage", {
              onSubmitCustomFieldChanges: () => refreshCustomFields(),
              formType: formType,
            });
          }}
          type="clear"
        />
        <SubmitButton
          title={"Submit"}
          navigation={navigation}
          submit={submit}
          width="30%"
        />
      </View>
      <PersonFormComponent form={form} updateForm={updateForm} />
    </SafeAreaView>
  );
};
