import {
  FlatList,
  View,
  TouchableOpacity,
  Text,
  ActivityIndicator,
  ScrollView,
} from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import { Input, Button } from "react-native-elements";
import { styles } from "../../App";
import React, { useState, useEffect } from "react";
import {
  EmailHistory,
  BulkProtocolType,
  FormType,
} from "../../src/models/index";
import { DataStore, Predicates, SortDirection } from "@aws-amplify/datastore";
import { Hub } from "aws-amplify";
import * as UserPermissions from "../../utils/UserPermissions";
import * as NavUtils from "../../utils/NavUtils";
import { commonStyles } from "../../styles/commonStyles";
import * as AppColors from "../../styles/AppColors";
import { SearchBar } from "react-native-elements";
import { LinkButton, SubmitButton } from "../../components/ButtonComponents";
import { Asset } from "expo-asset";
import AppLoading from "expo-app-loading";
import ErrorMessageComponent from "../../components/ErrorMessageComponent";
import * as DBUtils from "../../utils/DBUtils";
import * as Utils from "../../utils/Utils";

export const EmailHistoryScreen = ({ navigation, route }) => {
  const [selectedId, setSelectedId] = useState(null);
  const [emails, setEmails] = useState([]);
  const [search, setSearch] = useState("");
  const [errors, setErrors] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [errorsDBClear, setErrorsDBClear] = useState(false);

  const bulkProtocolType = route.params?.bulkProtocolType
    ? route.params?.bulkProtocolType
    : BulkProtocolType.EMAIL;

  async function showEmailList() {
    const isNotCofirmed = await UserPermissions.isNotConfirmed();
    if (isNotCofirmed) {
      setErrors(
        "User not confirmed. Ask Owner or Admin to check the user status on Users Screen."
      );
      return;
    }
    const emails = (
      await DataStore.query(EmailHistory, Predicates.ALL, {
        sort: (s) => s.sentAt(SortDirection.DESCENDING),
      })
    ).filter((c) => c.bulkProtocolType === bulkProtocolType);
    await DBUtils.checkDBSync(emails, setErrors, setEmails);
    // setEmails(emails);
  }

  //this even is called everytime - first time after login there is nothing to show until load above gets data
  useEffect(() => {
    (async () => {
      await showEmailList();
    })();
    setSearch(""); //reset search from prior email /sms visits
  }, [bulkProtocolType]); // cahced version returned between email and sms clicks on main menu so forced to useeffect on type

  const Item = ({ item, onPress, style }) => (
    //  return(
    <TouchableOpacity onPress={onPress} style={[commonStyles.rowItem, style]}>
      <View style={{ flexDirection: "column" }}>
        <Text style={commonStyles.listTextMainRowNotBold}>
          {bulkProtocolType === BulkProtocolType.SMS ? item.body : item.subject}
        </Text>
        <Text style={commonStyles.listTextSubRow}>
          {`${item.emailType} to ${
            JSON.parse(item.recipients)?.length
          }  recipients on ${new Date(item.sentAt).toLocaleString()}`}
        </Text>
      </View>
      {/* <Text style={commonStyles.listTextMainRow}>{item.body}</Text> */}
    </TouchableOpacity>
    //)
  );

  const renderItem = ({ item, index }) => {
    const backgroundColor =
      index % 2 === 0 ? AppColors.ROW_BRIGHT : AppColors.ROW_AFTER_BRIGHT;
    return (
      <Item
        item={item}
        onPress={() => {
          setSelectedId(item.id);
          NavUtils.showViewEmailScreen(navigation, item.id, bulkProtocolType);
        }}
        style={{ backgroundColor: backgroundColor }}
      />
    );
  };
  const updateSearch = async (search) => {
    setSearch(search);

    const searchCapWords = search
      .split(" ")
      .map(Utils.capitalizeFirstCharOfWord)
      .join(" ");
    const searchCapFirstWord = Utils.capitalizeFirstCharOfWord(search);

    const searchResult = (
      await DataStore.query(
        EmailHistory,
        (c) =>
          c.or((c) =>
            c
              .subject("contains", search)
              .subject("contains", searchCapWords)
              .body("contains", search)
              .body("contains", searchCapFirstWord)
          ),
        {
          sort: (s) => s.sentAt(SortDirection.DESCENDING),
        }
      )
    ).filter((c) => c.bulkProtocolType === bulkProtocolType);
    setEmails(searchResult);
  };

  return (
    <SafeAreaView style={commonStyles.pageContainer}>
      {errors.length > 0 && <ErrorMessageComponent errorMsg={errors} />}
      {isSearching && <ActivityIndicator size="large" />}
      {!(errors.length > 0) && (
        <>
          <LinkButton
            title={`< Back to Client List`}
            submit={() =>
              NavUtils.showPersonListScreen(navigation, FormType.CLIENT)
            }
          />
          <Button
            testID="SendAnotherEmail"
            containerStyle={commonStyles.buttonRowTop}
            title={`Send New Bulk ${bulkProtocolType}`}
            onPress={() =>
              NavUtils.showEmailClientsSelectionScreen(
                navigation,
                undefined,
                bulkProtocolType
              )
            }
          />
          {/* <Text>{emails.length}</Text> */}
          {!isSearching && (
            <SearchBar
              placeholder="Type text to search"
              onChangeText={updateSearch}
              value={search}
              lightTheme={true}
              inputStyle={{ color: "black" }}
              containerStyle={{ padding: 0 }}
              inputContainerStyle={{ backgroundColor: "ultralighgray" }}
            />
          )}
          {!isSearching && (
            <ScrollView>
              <FlatList
                data={emails}
                renderItem={renderItem}
                keyExtractor={(item) => item.id}
                extraData={selectedId}
              />
            </ScrollView>
          )}
        </>
      )}
    </SafeAreaView>
  );
};
