import { FlatList, TouchableOpacity, Text, Image, View } from "react-native";
import React, { useEffect, useState } from "react";
import { Card } from "react-native-elements";

export default function FeatureCRM({ fIcon, fDesc }) {
  return (
    // <Text>sdhfsdhshdslhdglshdg</Text>
    <>
      {/* WORKS FOR BOTH BIG AND SMALL SCREENS BUT EVENTUALLY MAKE BETTER using ifBigScreen for better user education */}
      <View
        style={{
          // flex: 1,
          // alignItems: "center",
          // justifyContent: "center",
          flexDirection: "row",
          // width: "100%",
        }}
      >
        {fIcon}

        <Text
          style={{
            marginLeft: 15,
            marginTop: 5,
            marginBottom: 20,
            marginRight: 10,
            // fontSize: 20,
            // fontWeight: "bold",
            // textAlign: "center", // <-- the magic
            color: "black",
          }}
        >
          {fDesc}
        </Text>
      </View>

      {/* OLD WAY BELOW */}
      {/* {!true && (
        <View
          style={{
            justifyContent: "center",
            flexDirection: "row",
            width: "100%",
          }}
        >
          {fIcon}

          <Text
            style={{
              marginLeft: 15,
              marginTop: 5,
              marginBottom: 20,
              marginRight: 10,
              textAlign: "center", // <-- the magic
            }}
          >
            {fDesc}
          </Text>
        </View>
      )} */}
    </>
  );
}
