import { FlatList, TouchableOpacity, Text, View } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import { Input, Button, Card } from "react-native-elements";
import { styles } from "../../App";
import React, { useState, useEffect } from "react";
import {
  Client,
  AddUserToT,
  AddUserToTenantStatus,
} from "../../src/models/index";
import { DataStore } from "@aws-amplify/datastore";
import { Auth } from "aws-amplify";
import { getGroupNameAsTenant } from "../../utils/getGroupNameAsTenant";
import * as NavUtils from "../../utils/NavUtils";
import { commonStyles } from "../../styles/commonStyles";
import * as UserPermissions from "../../utils/UserPermissions";
import ErrorMessageComponent from "../../components/ErrorMessageComponent";
import * as Strings from "../../resources/Strings";
import { LinkButton } from "../../components/ButtonComponents";
import * as Utils from "../../utils/Utils";
import { useConfirmation } from "../../components/confirmation/ConfirmationService";

export const ViewUserScreen = ({ navigation, route }) => {
  //props needed for going to given screen using navigation
  const [userState, setUserState] = useState({});
  const [errors, setErrors] = useState("");
  const [isOwnerViewingOwnerUser, setIsOwnerViewingOwnerUser] = useState(true);
  const confirm = useConfirmation();

  async function updatePageState() {
    //alert(route.params.userId);
    const userOriginal = await DataStore.query(
      AddUserToT,
      route.params?.userId
    );
    if (userOriginal) {
      const isOwner = await UserPermissions.isCurrentlyLoggedInUserAnOwner();
      if (userOriginal.tenant === userOriginal.userEmail && isOwner) {
        //form userEmail is not same as owner for non-owner emails
        setIsOwnerViewingOwnerUser(true);
      } else {
        //VIMP as def is true and it never gets false unless set here
        setIsOwnerViewingOwnerUser(false);
      }
      setUserState(userOriginal);
    }
  }

  useEffect(() => {
    (async () => {
      await updatePageState();
    })();
  }, []);

  const deleteUser = async () => {
    // var groupForTenant = await getGroupNameAsTenant();
    // var user;
    // var postData = { ...userState };
    // //avoids true groupname for tenant when null
    // if (groupForTenant !== null)
    //   postData = { ...userState, tenant: groupForTenant };
    //TODO - schema for primary sort key like addUserTOtenant. Also need GSI everywhere

    const isOwner = await UserPermissions.isCurrentlyLoggedInUserAnOwner();
    if (!isOwner) {
      setErrors(
        "Only Owner role can perform this action. Please contact Owner"
      );
      return;
    }
    if (isOwnerViewingOwnerUser) {
      // shoudl never come here as delete button is never displayed to the OWNER to delete themselves!
      setErrors(
        "Owner cannot delete themselves. Please contact support@siyamiapps.com"
      );
      return;
    }
    await Utils.areYouSureDialog(confirm, async () => {
      const userOriginal = await DataStore.query(
        AddUserToT,
        route.params.userId
      );
      var postData = {
        // ...userState,
        userStatus: AddUserToTenantStatus.DELETED,
      };
      var user = await DataStore.save(
        AddUserToT.copyOf(userOriginal, (updated) => {
          const returnedTarget = Object.assign(updated, postData);
        })
      );
      NavUtils.showUserListScreen(navigation);
    });
  };
  return (
    <SafeAreaView style={commonStyles.pageContainer}>
      <LinkButton
        title="< Back to User List"
        submit={() => NavUtils.showUserListScreen(navigation)}
      />
      {errors.length > 0 && <ErrorMessageComponent errorMsg={errors} />}

      <Card containerStyle={commonStyles.mainCardContainerWithThickBorder}>
        <Text
          style={commonStyles.pageSubHeader}
        >{`${userState.firstName} ${userState.lastName}`}</Text>
        <Text style={[commonStyles.textFieldValueDark]}>
          {userState.userEmail}
        </Text>
        <Text style={[commonStyles.textFieldValueDark]}>
          {`Status : ${userState.userStatus}`}
        </Text>
        {AddUserToTenantStatus.UNCONFIRMED === userState.userStatus && (
          <View style={{ margin: 10, borderWidth: 1, borderColor: "gray" }}>
            <Text style={{ margin: 10, color: "red" }}>*Important Note</Text>

            <Text style={commonStyles.pageNotes}>
              {Strings.unConfirmedHelp}
            </Text>
          </View>
        )}
        <Text
          style={[commonStyles.textFieldValue]}
        >{`Role : ${userState.userRole}`}</Text>

        <Text style={[commonStyles.textFieldValue]}>{userState.cellPhone}</Text>
        <Text style={[commonStyles.textFieldValue]}>{userState.address}</Text>
        <Text style={[commonStyles.textFieldValue]}>{userState.notes}</Text>

        <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
          {userState.userStatus !== AddUserToTenantStatus.DELETED && (
            <>
              <Button
                buttonStyle={commonStyles.buttonRow}
                // containerStyle={{ width: "30%" }}
                title="Edit User"
                onPress={async () => {
                  const isOwner =
                    await UserPermissions.isCurrentlyLoggedInUserAnOwner();
                  if (!isOwner) {
                    setErrors(
                      "Only Owner role can perform this action. Please contact Owner"
                    );
                  } else {
                    NavUtils.showEditUserScreen(
                      navigation,
                      route.params?.userId
                    );
                  }
                }}
              />
              {/* Owner cannot delete themselves so do NOT SHOW delete */}
              {!isOwnerViewingOwnerUser && (
                <Button
                  buttonStyle={commonStyles.buttonDeleteRow}
                  // containerStyle={{ width: "30%" }}
                  title="Delete User"
                  onPress={() => deleteUser()}
                />
              )}
            </>
          )}
        </View>
      </Card>
      <Button
        buttonStyle={commonStyles.buttonRow}
        type="outline"
        title="Add App User"
        onPress={() => {
          NavUtils.showAddUserScreen(navigation);
        }}
      />
      {/* <Button
        buttonStyle={commonStyles.buttonRow}
        //containerStyle={{ margin: 10 }}
        title="Manage App Users"
        type="outline"
        onPress={() => NavUtils.showUserListScreen(navigation)}
      /> */}
    </SafeAreaView>
  );
};
